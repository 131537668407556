import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { Link } from 'react-router-dom'
import ProfileNav from '../ProfileNav/ProfileNav'
import logo from '../../assets/icons/logo.svg'
import { useSelector } from 'react-redux'

const ProfileAside = () => {
  const { admin } = useSelector(({ authentication }) => authentication)

  return (
    <aside className={cn(styles.aside, { admin_active_border_left: admin?.isAdmin })}>
      <div className={styles.header}>
        <Link
          to='/'
          className={cn(styles.link_to_home, {
            [styles.link_to_home_disabled]: admin?.isAdmin,
          })}
        >
          <img src={logo} alt='small logotype' />
        </Link>
      </div>

      <nav>
        <ProfileNav />
      </nav>
    </aside>
  )
}

export default ProfileAside
