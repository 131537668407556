import React, { useEffect, useRef, useState } from 'react'
import styles from './resizableTextarea.module.scss'

const ResizableTextarea = ({
  minRows = 1,
  maxRows = 7,
  lineHeight = 15,
  onChange,
  value,
  autoFocus,
  setIsCurrentRows,
  ...rest
}) => {
  const [rows, setRows] = useState(minRows)

  const ref = useRef()

  useEffect(() => {
    if (autoFocus) ref.current.focus()
  })

  useEffect(() => {
    const elem = ref.current

    const previousRows = elem.rows

    elem.rows = minRows // reset number of rows in textarea

    const currentRows = ~~(elem.scrollHeight / lineHeight)

    if (currentRows === previousRows) elem.rows = currentRows

    if (currentRows >= maxRows) {
      elem.rows = maxRows
      elem.scrollTop = elem.scrollHeight
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
    if (setIsCurrentRows) setIsCurrentRows(currentRows < maxRows ? currentRows : maxRows)
    //eslint-disable-next-line
  }, [setRows, maxRows, minRows, value, lineHeight])

  return (
    <textarea
      ref={ref}
      rows={rows}
      value={value}
      placeholder={'Enter your...'}
      className={styles.textarea}
      onChange={onChange}
      autoFocus
      style={{
        lineHeight: `${lineHeight}px`,
        '--line-height': `${lineHeight}px`,
      }}
      {...rest}
    />
  )
}

export default ResizableTextarea
