import { useEffect } from 'react'
import Socket from '../websocket'

export default function useIndicateBrowserTab(username, title = 'NextShip job at sea for seafarers') {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!!document.hidden) {
        if (Socket) {
          Socket.onmessage = (action) => {
            const { event, data } = JSON.parse(action.data)

            if (!data.is_outgoing) {
              if (event === 'support.new.chat.message' || event === 'new.chat.message') {
                document.title = `${username || 'You'} have, new messages!`
              }
            }
          }
        }
      } else {
        document.title = title
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleVisibilityChange)
    Socket.addEventListener('message', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleVisibilityChange)
      Socket.removeEventListener('message', handleVisibilityChange)
    }
  }, [username, title])
}
