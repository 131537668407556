import React from 'react'
import { useSelector } from 'react-redux'
import UserProfile from '../UserProfile/UserProfile'
import { NavLink, useHistory } from 'react-router-dom'
import styles from './HeaderNav.module.scss'
import cn from 'classnames'
import LogInMobile from '../UserProfile/LogInMobile'
import { getCurrentDomain } from '../../helpers/apiHelper'

const HeaderNav = () => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)
  const { location } = useHistory()

  return (
    <>
      <nav className={styles.navigation}>
        {isAuth ? (
          <div className={cn(styles.list, { [styles.listLogin]: isAuth })}>
            <div className={styles.item}>
              <UserProfile />
            </div>
          </div>
        ) : (
          <div className={cn(styles.list, { [styles.listLogin]: location.pathname !== '/' })}>
            <a
              className={cn(styles.for_employers_link, { [styles.disabled_link]: location.pathname !== '/' })}
              href={`${getCurrentDomain()}employers.nextship.net/`}
            >
              For CrewManager
            </a>
            <NavLink className={styles.item_button} activeClassName={styles.active} to='/auth/signin'>
              Log in
            </NavLink>
            <NavLink
              className={cn(styles.item, styles.item_filled, {
                [styles.item_filled_dark]: location.pathname !== '/',
              })}
              activeClassName={styles.active}
              to='/auth/signup'
            >
              Sign up
            </NavLink>
          </div>
        )}
        <LogInMobile />
      </nav>
    </>
  )
}

export default HeaderNav
