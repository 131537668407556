import React from 'react'
import PropTypes from 'prop-types'

const FlagIconSvg = ({ width = 10, height = 12, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.641375 12C0.30215 12 0.00283421 11.7002 0.00283421 11.3148V2.00089C-0.0171202 1.67972 0.0626972 1.18726 0.421877 0.780444C0.900783 0.202338 1.73887 -0.054599 2.89623 0.00963511C3.73431 0.0524578 4.49258 0.523508 5.15107 0.951735C5.51025 1.18726 5.84948 1.40137 6.12884 1.50843C7.66533 2.04371 8.72292 0.780444 8.76283 0.71621C8.94241 0.502096 9.20182 0.416451 9.46123 0.523508C9.70068 0.609153 9.88027 0.86609 9.88027 1.14444L10 8.08172C10 8.2316 9.96009 8.40289 9.86032 8.50995C9.82041 8.57418 8.80273 9.85886 6.6277 9.3664C6.1887 9.25935 5.7098 8.981 5.21094 8.70265C4.65221 8.38148 4.07354 8.0389 3.53477 7.93184C2.29759 7.69632 1.55928 8.40289 1.27992 8.72406V11.3362C1.27992 11.7002 0.980601 12 0.641375 12ZM3.03591 6.51869C3.25541 6.51869 3.49486 6.5401 3.73431 6.58292C4.47263 6.7328 5.19098 7.13962 5.80957 7.50361C6.22861 7.73914 6.60775 7.97466 6.88711 8.0389C7.88483 8.27442 8.46351 7.97466 8.72292 7.78196L8.6431 2.51476C7.94469 2.90017 6.94697 3.19993 5.74971 2.7717C5.33067 2.62182 4.91162 2.36489 4.51253 2.10795C3.95381 1.74396 3.39509 1.37996 2.85632 1.35855C1.83864 1.31573 1.49942 1.55125 1.37969 1.67972C1.27992 1.78678 1.27992 1.89384 1.27992 1.91525V7.01115C1.71891 6.7328 2.31755 6.51869 3.03591 6.51869Z'
        fill={color}
      />
    </svg>
  )
}

FlagIconSvg.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string || PropTypes.number,
  height: PropTypes.string || PropTypes.number,
}

export default FlagIconSvg
