import React, { useState } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

import Table from '../../../UI/Table/Table'
import Row from '../../../UI/Table/Row'
import TableHeadField from '../../../UI/Table/TableHeadField'
import TableField from '../../../UI/Table/TableField'
import EmptyBanner from './EmptyBanner/EmptyBanner'
import Spinner from '../../../UI/Spinner/Spinner'
import { colorExpirationDateMap, colorExpireMap, colorsMapStatus, tHead } from './config'

import { ReactComponent as FileIcon } from './icons/file.svg'
import FlagIconSvg from '../../../UI/Icons/FlagIconSvg'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { getBillingInvoiceHistory } from '../../../api/getApi'
import { centToDollar } from '../Services/utils'
import { StyledTooltip } from '../../../UI/Tooltip/Tooltip.config'
import moment from 'moment'
import { getDateByUserFormat } from '../../../helpers/timeHelper'

const History = () => {
  const [invoiceHistory, setInvoiceHistory] = useState([])

  const { handleScroll, loading } = useInfiniteScroll(getBillingInvoiceHistory, setInvoiceHistory, null, '', null, true)

  const renderHead = () => {
    return (
      <Row isHead>
        {tHead.map((item, idx) => {
          const style = idx === 0 || idx === 1 ? {} : { textAlign: 'center' }

          return (
            <TableHeadField key={item} style={style}>
              {item}
            </TableHeadField>
          )
        })}
      </Row>
    )
  }

  const renderBody = () => {
    return invoiceHistory.map(({ product_plan, date, due_date, amount, status, currency, paid, pdf_url }, index) => {
      const { products } = product_plan
      const isExpireSubscription = moment(new Date()).format() > due_date
      const description = (() => {
        if (!paid) return moment(date).format('MMM DD, yyyy, HH:mm')

        const startDate = moment(date).format('MMM DD')
        const endDate = due_date
          ? moment(due_date).format('MMM DD, yyyy')
          : moment(date).add(1, 'month').format('MMM DD, yyyy')

        return `${startDate} - ${endDate}`
      })()
      const price = `${centToDollar(amount)}${currency.symbol}`
      const newStatus = status === 'processing' ? `${status}...` : status

      const latestProductId = products.length - 1
      const tooltipProducts = products.filter(({ price }) => price > 0)
      const TooltipContent = (
        <div className={styles.tooltip}>
          {tooltipProducts.map((item) => {
            const { price } = item
            const { id, name, prices } = item.product
            const { currency } = prices
            return (
              <div className={styles.tooltip_products_info} key={id}>
                <span className={styles.product_name}>{name}</span>
                <span className={styles.product_price}>
                  {price / 100}
                  {currency.symbol}
                </span>
              </div>
            )
          })}
        </div>
      )
      return (
        <Row key={index}>
          <TableField>{description}</TableField>

          {products.filter(({ price }) => price > 0).length === 1 && (
            <TableField>{products.filter(({ price }) => price > 0)[0].product.name}</TableField>
          )}
          {products.length > 1 && products.filter(({ price }) => price > 0).length > 1 && (
            <TableField>
              <StyledTooltip placement='bottom' title={TooltipContent}>
                <p className={styles.products}>
                  <span>{products[latestProductId].product.name}</span>
                  <span className={styles.count}>{`+${products.filter(({ price }) => price > 0).length - 1}`}</span>
                </p>
              </StyledTooltip>
            </TableField>
          )}

          <TableField className={styles.field} style={{ color: isExpireSubscription ? '#06251e' : '#43927c' }}>
            {price}
          </TableField>
          <TableField
            style={{
              color: colorsMapStatus[status],
              textTransform: 'capitalize',
            }}
            className={styles.field}
          >
            {newStatus}
          </TableField>
          <TableField
            style={{ color: isExpireSubscription ? colorExpireMap[status] : colorExpirationDateMap[status] }}
            className={cn(styles.field, styles.gap4, styles.expiration_date)}
          >
            {!!due_date && newStatus === 'paid' ? (
              <>
                <FlagIconSvg color={isExpireSubscription ? colorExpireMap[status] : colorExpirationDateMap[status]} />{' '}
                {getDateByUserFormat(moment(due_date))}
              </>
            ) : (
              '—'
            )}
          </TableField>
          <TableField className={styles.field}>
            <a href={pdf_url} target='pdf' rel='noopener noreferrer'>
              <FileIcon />
            </a>
          </TableField>
        </Row>
      )
    })
  }

  const emptyMessage = () => {
    return <div className={styles.noContent}>You've seen everything</div>
  }

  return (
    <div className={cn(styles.main, { [styles.slideIn]: !loading })}>
      {!invoiceHistory.length && loading && <Spinner size={100} />}

      {!invoiceHistory.length && !loading && <EmptyBanner />}

      {!!invoiceHistory.length && (
        <Table
          head={renderHead()}
          body={renderBody()}
          onScroll={handleScroll}
          loading={loading}
          colSpan={5}
          emptyMessage={emptyMessage()}
        />
      )}
    </div>
  )
}

export default History
