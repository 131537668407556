import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { useOnlyProd } from '../api/constans'

export default function usePageGoogleAnalytics() {
  if (useOnlyProd) {
    ReactGA.initialize('G-RLLHXV1YZT')
  }

  const location = useLocation()

  useEffect(() => {
    if (useOnlyProd) {
      ReactGA.send({ hitType: 'pageview', page: `${location.pathname}${location.search}` })
    }
  }, [location])
}
