export const autocompleteHelper = (name) => {
  switch (name) {
    case 'vessel':
      return 'vessels'

    case 'shipowner':
      return 'owners'

    case 'engine':
      return 'vessel_engine_type'

    default:
      return name
  }
}

export const getCompleteVesselInfo = (data, title) => {
  const { [title]: name, vessel_type, engine_type, dwt, flag, owner } = data

  return [name, vessel_type?.name, engine_type?.name, dwt, flag?.name, owner?.name].filter(Boolean).join(' / ')
}
