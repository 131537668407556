import { API_BASE, CLIENT_SECRET, CLIENT_ID, API_BASE_V2, initHeaders, METHOD } from './constans'
import { authGetRequestV2, fetchHealthData, request } from './auth.service'
import {
  isImo,
  // isImo,
  objToQueryConverter,
  searchQueryConverter,
  sortOptionsPrettier,
} from '../helpers/apiHelper'
import { authGetRequest, fetchData } from './auth.service'

import { CardElement } from '@stripe/react-stripe-js'

// PUBLIC REQUESTS
export const fetchVacancyList = async ({ sort, ...rest }) => {
  const options = `${API_BASE}vacancies/?${objToQueryConverter(rest)}&ordering=${sortOptionsPrettier(sort)}`
  return await fetchData(options)
}

export const getVacancies = async (page = 1, filter = '', signal) => {
  const response = await authGetRequest(`vacancies/?page=${page}&page_size=15&${filter}`, signal)
  return await response.json()
}

export const fetchFilterData = async () => {
  return await fetchData(`${API_BASE}vacancies/filter_fields/`)
}

export const fetchFilterInfiniteScrollData = async (page, query) => {
  return await fetchData(`${API_BASE}vacancies/?page=${page}&page_size=15&${query}`)
}
export const fetchVacancyView = async (id) => {
  return await fetchData(`${API_BASE}vacancies/${id}/?save_click=True`)
}
export const getVacancy = async (id) => {
  return await fetchData(`${API_BASE}vacancies/${id}/`)
}

// export function loginFetch(url, data, scope = 'seafarer') {
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       ...customHeaders,
//     },
//     body: JSON.stringify({
//       ...data,
//       client_id: CLIENT_ID,
//       client_secret: CLIENT_SECRET,
//       scope: scope,
//     }),
//   }
//   return fetch(`${API_BASE_V2}${url}`, requestOptions)
// }

export const getRefreshToken = async (refreshToken) => {
  const response = await fetch(`${API_BASE_V2}token/`, {
    method: METHOD.POST,
    headers: initHeaders,
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: 'seafarer',
    }),
  })

  return await response.json()
}

export const isExpired = (exp) => {
  if (!exp) {
    return false
  }
  return Date.now() > exp
}

export const userAgentRequest = async (params) => {
  return await fetchData(`${API_BASE}reset-password-user-agent${params.replace('&user_agent=true', '')}`)
}

export const getAuthProfileFilters = async (...args) => {
  const response = await authGetRequest(`alpha/filters/?models=${args.join(',')}`)

  return await response.json()
}

export const getAuthProfile = async () => {
  const response = await authGetRequest('seafarer/profile/')
  return await response.json()
}

export const getAuthAddresses = async () => {
  const response = await authGetRequest('seafarer/addresses/')
  return await response.json()
}

export const getAuthBio = async () => {
  const response = await authGetRequest('seafarer/anthropometry/')
  return await response.json()
}

export const getSeafarerEducation = async (id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/educations/${id}`)
  return await response.json()
}

export const getListAuthEducation = async (page = 1, query = '', id = '') => {
  if (id !== '') id += '/'
  const url = page || id ? `seafarer/educations/?page=${page}&${query}` : `seafarer/educations/`
  const response = await authGetRequest(url)
  return await response.json()
}

export const getSeafarerPassport = async (id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/passports/${id}`)
  return await response.json()
}

export const getListAuthPassports = async (page = 1, query = '', signal) => {
  const url = page || query ? `seafarer/passports/?page=${page}&${query}` : `seafarer/passports/`
  const response = await authGetRequest(url, signal)
  if (response) {
    return await response.json()
  }
}

export const getSeafarerVisa = async (id = '') => {
  if (id !== '') id += '/'

  const response = await authGetRequest(`seafarer/visas/${id}`)

  return await response.json()
}

export const getListAuthVisas = async (page = 1, query = '', signal) => {
  const response = await authGetRequest(`seafarer/visas/?page=${page}&${query}`, signal)
  if (response) {
    return await response.json()
  }
}

export const getAuthRelatives = async (id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/relatives/${id}`)
  return await response.json()
}

export const getListAuthRelatives = async (page = 1, id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/relatives/?page=${page}&${id}`)
  return await response.json()
}

export const getAuthContact = async (id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/contacts/${id}`)
  return await response.json()
}

export const getListAuthContact = async (page = 1, id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/contacts/?page=${page}&${id}`)
  return await response.json()
}

export const getSeafarerCertificate = async (id = '') => {
  if (id !== '') id += '/'

  const response = await authGetRequest(`seafarer/certificates/${id}`)

  return await response.json()
}

export const getListAuthCertificates = async (page = 1, query = '', signal) => {
  const response = await authGetRequest(`seafarer/certificates/?page=${page}&${query}`, signal)
  if (response) {
    return await response.json()
  }
}

export const getSeafarerExperience = async (id = '') => {
  if (id !== '') id += '/'
  const response = await authGetRequest(`seafarer/experiences-all/${id}`)
  return await response.json()
}

export const fetchListSeafarerContracts = async (page = 1, query = '', id = '') => {
  if (id !== '') id += '/'
  const url = `seafarer/experiences-all/?page=${page}&${query}`
  const response = await authGetRequest(url)
  return await response.json()
}

export const getSeafarerLicense = async (id = '') => {
  if (id !== '') id += '/'

  const response = await authGetRequest(`seafarer/licenses/${id}`)

  return await response.json()
}

export const getListAuthLicenses = async (page = 1, query = '', signal) => {
  const response = await authGetRequest(`seafarer/licenses/?page=${page}&${query}`, signal)
  if (response) {
    return await response.json()
  }
}

export const getProcessingDocumentsWithoutPagination = async (query, signal) => {
  const response = await authGetRequest(`seafarer/uploads/?&${query}`, signal)
  return await response.json()
}

export const getProcessingDocuments = async (page = 1, query = '', signal) => {
  const url = page || query ? `seafarer/uploads/?&page=${page}&${query}` : `seafarer/uploads/`
  const response = await authGetRequest(url, signal)
  if (response) {
    return await response.json()
  }
}

export const getProcessingDocument = async (id) => {
  const response = await authGetRequest(`seafarer/uploads/${id}/`)
  return await response.json()
}

export const getAuthMe = async (scope = 'seafarer') => {
  try {
    const response = await authGetRequest(`${scope}/me/`)
    return await response.json()
  } catch (err) {
    localStorage.clear()
    window.location.reload()
  }
}

export const getProfilePicture = async (id) => {
  return await authGetRequest(`seafarer/uploads/${id}`)
}

export const getGooglePlacesCities = async (city, countryCode) => {
  const response = await authGetRequest(
    `services/places-autocomplete/?input_text=${city}&components={"country":"${countryCode}"}&types=(cities)`
  )
  return await response.json()
}

export const getAutocompleteItems = async (text, model) => {
  const response = await authGetRequest(`alpha/search/?model=${model}&text=${text}&limit=5`)
  return await response.json()
}

export const getAutocompleteVessels = async (page, query = '') => {
  const searchParams = searchQueryConverter({ search: query, type: 'search' })
  return await request(`vessels/elastic-search/?same_ordering=True&${searchParams}&page=${page}&page_size=10`, {
    api: API_BASE_V2,
  })
}
export const getAutocompleteShipowner = async (page, query = '') => {
  const searchParams = searchQueryConverter({ search: query, type: 'search' })
  return await request(`shipowner/elastic-search/?same_ordering=True&${searchParams}&page=${page}&page_size=15`, {
    api: API_BASE_V2,
  })
}

export const getAutocompleteCompanies = async (page, query = '') => {
  const searchParams = searchQueryConverter({ search: query, type: 'search' })
  return await request(`shipowner/land-company-search/?${searchParams}&page=${page}&page_size=15`, {
    api: API_BASE_V2,
  })
}

export const getAutocompleteShipCrew = async (page, query = '') => {
  const searchParams = searchQueryConverter({ search: query, type: 'search' })
  return await request(`shipowner/crew/elastic-search/?same_ordering=True&${searchParams}&page=${page}&page_size=15`, {
    api: API_BASE_V2,
  })
}

export const getAutocompleteImo = async (value, query = '') => {
  const clearValue = !isNaN(value) && value.trim()

  const response = await authGetRequestV2(`vessels/elastic-search/?imo=${clearValue}&cursor=${query}&page_size=15`)

  return await response.json()
}

export const getGooglePlacesAddress = async (address, place_id) => {
  const response = await authGetRequest(
    `services/places-autocomplete-by-place-id/?input_text=${address}&place_id=${place_id}&types=address`
  )
  return await response.json()
}

export const getGooglePlacesUniversity = async (name, place_id, degree) => {
  let degreeType = ''
  if (degree === 'higher') {
    degreeType = 'university'
  } else if (degree === 'secondary') {
    degreeType = 'secondary_school'
  } else if (degree === 'school') {
    degreeType = 'school'
  }
  const response = await authGetRequest(
    `services/places-by-place-id/?input_text=${name}&place_id=${place_id}&types=${degreeType}`
  )
  return await response.json()
}

export const getCv = async () => {
  const response = await authGetRequest('seafarer/pdf/')
  return response.blob()
}

export const getVideoResume = async () => {
  const response = await authGetRequest(`seafarer/uploads/?type=video`)
  return await response.json()
}

export const getVideoResumeStatus = async () => {
  const response = await authGetRequest(`seafarer/upload-transcoder/`)
  return await response.json()
}

export const getNotifications = async (page = 1, query = '') => {
  const response = await authGetRequest(`seafarer/events/?page=${page}&${query}`)
  return await response.json()
}

export const getUserCardInfo = async (section = '') => {
  const response = await authGetRequest(`seafarer/info/?section=${section}`)
  return await response.json()
}
export const getColleagues = async () => {
  const response = await authGetRequest(`seafarer/colleagues-info/`)
  return await response.json()
}

export const getSettings = async (scope) => {
  const response = await authGetRequest(`${scope}/settings/`)
  return await response.json()
}

export const getNotificationSettings = async (scope = 'seafarer') => {
  const response = await authGetRequestV2(`${scope}/notifications/`)
  return await response.json()
}

// ui
export const getUiInfo = async () => {
  const response = await authGetRequest(`seafarer/ui/`)
  return await response.json()
}

//reviews

export const getRequests = async (page = 1, filter) => {
  const response = await authGetRequest(`seafarer/requests/?page=${page}&page_size=15&${filter}`)
  return await response.json()
}

export const getRequestsBySearch = async (page = 1, filter) => {
  const response = await authGetRequest(`seafarer/request-search/?page=${page}&page_size=15&${filter}`)
  return await response.json()
}

export const getRequestMoreInfo = async (id = '') => {
  return await request(`seafarer/requests/${id}/`)
}

export const getReviews = async (page = 1, filter = '') => {
  const response = await authGetRequest(`seafarer/reviews/?page=${page}&page_size=15&${filter}`)
  return await response.json()
}

export const getReviewsBySearch = async (page = 1, filter) => {
  const response = await authGetRequest(`seafarer/review-search/?page=${page}&page_size=15&${filter}`)
  return await response.json()
}

export const getReviewsMoreInfo = async (id = '') => {
  const response = await authGetRequest(`seafarer/reviews/${id}/`)
  return await response.json()
}

// export const getUkrainianLocationInfo = async () => {
//   const response = await authGetRequest('seafarer/out-of-residence/')
//   return await response.json()
// }

export const getHealth = async () => {
  const response = await fetchHealthData(`${API_BASE_V2}healthcheck`)
  if (response.ok) {
    return await response.json()
  } else {
    throw new Error('error')
  }
}

export const getMtVessels = async (value) => {
  const clearValue = encodeURI(value.trim())
  const searchBy = isImo(clearValue) ? 'imo' : 'name'

  const response = await authGetRequestV2(`vessels/mt-name-search/?${searchBy}=${clearValue}&page_size=500`)

  return await response.json()
}

export const getVesselNameHistory = async (vesselId, query = '') => {
  const params = query ? `?cursor=${query}` : ''

  const response = await authGetRequestV2(`vessels/name-history/${vesselId}/${params}`)

  return await response.json()
}

export const getLocationUser = async (city) => {
  const response = await authGetRequest(`services/places-autocomplete/?input_text=${city}&types=(cities)`)

  return await response.json()
}

// BILLING //

export const getPaymentMethodStripe = async (stripe, elements, data) => {
  const config =
    data.elementType === 'expressCheckout'
      ? { elements }
      : {
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              city: data.city.name,
              country: data.country.iso2,
              line1: data.street.name,
              postal_code: data.zipCode,
            },
            name: data.name,
            phone: data.phone,
          },
        }
  const { paymentMethod, errorPaymentMethod } = await stripe.createPaymentMethod(config)

  return { paymentMethod, errorPaymentMethod }
}

export const getBillingCard = async () => {
  return await request('billing/card/', {
    api: API_BASE_V2,
  })
}

export const getBillingSubscriptionServices = async () => {
  return await request('billing/product/?page=1&page_size=15', { api: API_BASE_V2 })
}

export const getBillingSubscriptionPlans = async () => {
  return await request('billing/subscription-plan/', {
    api: API_BASE_V2,
  })
}

export const getBillingSubscription = async () => {
  return await request('billing/subscription/', {
    api: API_BASE_V2,
  })
}

export const getBillingSubscriptionPlanPrice = async (ids) => {
  return await request('billing/subscription-plan/price-preview/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({ products: ids }),
  })
}

export const getBillingInvoiceHistory = async (page = 1, params) => {
  return await request(`billing/invoice-history/?page=${page}&page_size=15&${params}`, {
    api: API_BASE_V2,
  })
}

export const getBillingSettingsGlobal = async () => {
  return await request('billing/settings/global/?type=seafarer', { api: API_BASE_V2 })
}

export const getServiceDataByAction = async (action, service) => {
  return await request(`seafarer/events/?action=${action}&service=${service}`)
}

export const getPersonalStatus = async () => {
  return await request('seafarer/personal-status/')
}

export const getServiceDataByCategory = async (page = 1, query = '', service, category) => {
  const newQuery = !query ? `service=${service}&category=${category}` : query

  return await request(`seafarer/events/?action=info&${newQuery}&page=${page}&page_size=15`)
}

export const getLastPaymentIntent = async () => {
  return await request('billing/payment-intents/', {
    api: API_BASE_V2,
  })
}

export const getPublicStripeKey = async () => {
  return await request('billing/stripe-client-key/', {
    api: API_BASE_V2,
  })
}

// BILLING //
