import { useState } from 'react'
import useInfiniteScrollVessel from '../../../../hooks/useInfiniteScrollVessel'
import { getVesselNameHistory } from '../../../../api/getApi'
import { getUniqueCursorHelper } from '../../../../helpers/apiHelper'
import { notify } from '../../../../helpers/notify'

const useVesselNameHistory = (vesselId) => {
  const [initialCursor, setInitialCursor] = useState(null)
  const [isOpenHistory, setIsOpenHistory] = useState(false)
  const [history, setHistory] = useState([])

  const { handleScroll, loading } = useInfiniteScrollVessel({
    setItems: setHistory,
    requestFunction: getVesselNameHistory,
    name: vesselId,
    initialCursor,
  })

  const getRenameHistory = async (id) => {
    setInitialCursor(null)
    setIsOpenHistory(!isOpenHistory)

    try {
      if (!isOpenHistory) {
        const response = await getVesselNameHistory(id)

        setHistory(response.results)
        setInitialCursor(getUniqueCursorHelper(response.next))
      }
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const closeHistory = () => {
    setIsOpenHistory(false)
  }

  const props = {
    vessels: history,
    onClick: closeHistory,
    onScroll: handleScroll,
    loading,
  }

  return { props, closeHistory, getRenameHistory, isOpenHistory }
}

export default useVesselNameHistory
