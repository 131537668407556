import { BILLING } from './types'
import { notify } from '../../helpers/notify'
import { getBillingSettingsGlobal } from '../../api/getApi'

export const getBillingSettingsData = () => {
  return async (dispatch) => {
    try {
      const { is_active } = await getBillingSettingsGlobal()

      dispatch(setIsActiveBilling(is_active))
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }
}

export const setIsActiveBilling = (payload) => ({
  type: BILLING.SET_IS_ACTIVE,
  payload,
})

export const setServiceData = (payload) => ({
  type: BILLING.SET_SERVICE_DATA,
  payload,
})

export const setBillingPermissions = (payload) => ({
  type: BILLING.SET_BILLING_PERMISSIONS,
  payload,
})

export const setBillingPermissionCurrent = (payload) => ({
  type: BILLING.SET_BILLING_PERMISSIONS_CURRENT,
  payload,
})

export const setBillingEvent = (payload) => ({
  type: BILLING.SET_BILLING_EVENT,
  payload,
})
