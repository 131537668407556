import React from 'react'
import styles from './Benefits.module.scss'
import cn from 'classnames'
import { useSelector } from 'react-redux'

export const Benefits = () => {
  const { company_count, seafarer_count, vacancy_count } = useSelector(({ landingReducer }) => landingReducer)
  return (
    <section className={styles.benefits_section}>
      <div className={cn(styles.benefits, 'container')}>
        <div className={styles.benefits_statistics}>
          <h2>
            Create a Personal Profile And <span>Manage</span> Your Account
          </h2>
          <div className={styles.statistics_users}>
            <div className={styles.position}>
              <h4 className={styles.count}>{vacancy_count}</h4>
              <p className={styles.count_props}>Job Positions</p>
            </div>
            <div className={styles.active_users}>
              <h4 className={styles.count}>{seafarer_count}</h4>
              <p className={styles.count_props}>Active Users</p>
            </div>
            <div className={styles.join_company}>
              <h4 className={styles.count}>{company_count}</h4>
              <p className={styles.count_props}>Join Company</p>
            </div>
          </div>
        </div>
        <div className={styles.description_mobile}>
          <p>
            That saves your background experience and business information so recruiters can easily find you when new
            jobs come up.
          </p>
          <p>
            The more details you add, the more likely the job will match your interests. Also, your profile is
            automatically attached to any job you apply to.
          </p>
          <p>
            You can track and manage the jobs you've found. Use our organizational tools to save jobs to review later.
          </p>
          <p>
            See the status of jobs you applied to, and create job alerts so you'll be notified when relevant jobs are
            posted.
          </p>
        </div>
        <div className={styles.description_desktop}>
          <p>
            That saves your background experience and business information so recruiters can easily find you when new
            jobs come up. The more details you add, the more likely the job will match your interests.
          </p>
          <p>
            Also, your profile is automatically attached to any job you apply to. You can track and manage the jobs
            you've found. Use our organizational tools to save jobs to review later. See the status of jobs you applied
            to, and create job alerts so you'll be notified when relevant jobs are posted.
          </p>
        </div>
      </div>
    </section>
  )
}
