import React from 'react'

import Wrapper from '../Wrapper/Wrapper'
import EnhancedVisibilityActivated from './EnhancedVisibilityActivated/EnhancedVisibilityActivated'
import EnhancedVisibilityHasExpired from './EnhancedVisibilityHasExpired/EnhancedVisibilityHasExpired'
import EnhancedVisibilityNotActivated from './EnhancedVisibilityNotActivated/EnhancedVisibilityNotActivated'

import { action } from '../../constants'

const PopupEnhancedVisibility = ({ notificationAction, isServiceActive, handleClose }) => {
  return (
    <Wrapper notificationAction={notificationAction} isServiceActive={isServiceActive} onClose={handleClose}>
      {isServiceActive && <EnhancedVisibilityActivated />}

      {notificationAction === action.hasExpired && <EnhancedVisibilityHasExpired />}

      {!isServiceActive && notificationAction !== action.hasExpired && <EnhancedVisibilityNotActivated />}
    </Wrapper>
  )
}

export default PopupEnhancedVisibility
