import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import JobSearchTableItem from '../JobSearchTableItem/JobSearchTableItem'
import JobSearchTableHeader from '../JobSearchTableHeader/JobSearchTableHeader'
import LoadingJobSearchTableItem from '../JobSearchTableItem/LoadingJobSearchTableItem'
import { useDispatch, useSelector } from 'react-redux'
import { postVacancyRequest } from '../../../api/postApi'
import { notify } from '../../../helpers/notify'
import { addVacanciesLanding } from '../../../redux/actions/vacancies.action'
import { createItemRef } from '../../../helpers/createItemRef'
import { determinationFullnessVacancy } from '../../../helpers/vacanciesSearchHelper'
import JobItemWithoutVesselInform from '../JobSearchTableItem/JobItemWithoutVesselInform'
import '../../../scss/App.scss'
import { isEmpty } from 'lodash'
import useHeightWithScroll from '../../../hooks/useHeightWithScroll'
import cn from 'classnames'
import JobSearchTableItemLand from '../JobSearchTableItem/JobSearchTableItemLand'
import { vacancyType } from '../../ProfileVacancies/VacancyForm/config'
import { fetchVacancyView } from '../../../api/getApi'

const JobSearchTable = ({ data = [], emptyTitle, loading, tableSize, handleScroll }) => {
  const heightVacancyList = 415

  const [animationEnd, setAnimationEnd] = useState(0)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [openItemId, setOpenItemId] = useState(null)

  const dispatch = useDispatch()

  const { scope, isAdmin } = useSelector(({ authentication }) => authentication.user)

  const handleClick = useCallback((id, isOpen) => {
    setOpenItemId(isOpen ? null : id)
    if (!isOpen) fetchVacancyView(id)
  }, [])

  const { divRef, heightWithScroll } = useHeightWithScroll({ state: animationEnd })
  const refs = createItemRef(data)

  const handleAccept = useCallback(
    (event, company, vacancy) => {
      event.stopPropagation()

      setAcceptLoading(true)

      postVacancyRequest({ company, vacancy })
        .then(({ request_sent_date, ok, ...rest }) => {
          if (ok) {
            notify('Success', 'Your request was successfully sent')
            dispatch(addVacanciesLanding(vacancy, request_sent_date))
          } else {
            notify.errorsList(rest.errors)
          }
        })
        .finally(() => setAcceptLoading(false))
    },

    //eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (openItemId && animationEnd) {
      const childElement = refs[openItemId].current
      const parentElement = divRef.current

      parentElement.scrollTop = childElement.offsetTop - parentElement.offsetTop
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemId, animationEnd])

  const handleAnimationEnd = ({ newHeight }) => {
    setAnimationEnd(newHeight)
  }

  return (
    <div className={styles.table}>
      <JobSearchTableHeader isScrollList={heightWithScroll > heightVacancyList} />

      <div
        style={{ height: heightVacancyList }}
        className={cn(styles.vacancy_list, { [styles.vacancy_list_scroll]: heightWithScroll > heightVacancyList })}
        ref={divRef}
        onScroll={handleScroll}
      >
        {loading && !data.length ? (
          <LoadingJobSearchTableItem tableSize={tableSize} />
        ) : data.length ? (
          <>
            {data.map((item, index) => {
              if (!isEmpty(determinationFullnessVacancy(item))) {
                return (
                  <JobSearchTableItem
                    refs={refs[item.id]}
                    isCanAccept={scope === 'seafarer'}
                    key={index}
                    data={item}
                    onClick={handleClick}
                    onAccept={handleAccept}
                    isOpen={openItemId === item.id}
                    acceptLoading={acceptLoading}
                    onAnimationEnd={handleAnimationEnd}
                    isScrollList={heightWithScroll > heightVacancyList}
                  />
                )
              }

              if (item.vacancy_type === vacancyType.land) {
                return (
                  <JobSearchTableItemLand
                    refs={refs[item.id]}
                    isCanAccept={scope === 'seafarer'}
                    key={index}
                    data={item}
                    onClick={handleClick}
                    onAccept={handleAccept}
                    isOpen={openItemId === item.id}
                    acceptLoading={acceptLoading}
                    isAdmin={isAdmin}
                    onAnimationEnd={handleAnimationEnd}
                    isScrollList={heightWithScroll > heightVacancyList}
                  />
                )
              }

              return (
                <JobItemWithoutVesselInform
                  refs={refs[item.id]}
                  isCanAccept={scope === 'seafarer'}
                  key={index}
                  data={item}
                  onClick={handleClick}
                  onAccept={handleAccept}
                  isOpen={openItemId === item.id}
                  acceptLoading={acceptLoading}
                  isAdmin={isAdmin}
                  onAnimationEnd={handleAnimationEnd}
                  isScrollList={heightWithScroll > heightVacancyList}
                />
              )
            })}

            {loading && data.length && <LoadingJobSearchTableItem tableSize={tableSize} />}
          </>
        ) : (
          <div className={styles.empty}>{emptyTitle}</div>
        )}
      </div>
    </div>
  )
}

export default JobSearchTable
