import React from "react";
import PropTypes from "prop-types";
import styles from "./SocialButton.module.scss";

const SocialButton = ({ icon, children, ...rest }) => {
  return (
    <button className={styles.button} {...rest}>
      {icon && (
        <img
          className={styles.icon}
          src={icon}
          alt={`${children} social network logo`}
        />
      )}
      <span>{children}</span>
    </button>
  );
};

SocialButton.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default SocialButton;
