import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./styles.module.scss";

export default function ButtonFileUploader({
  text,
  onChange,
  file,
  disabled,
  accept,
  name = "doc",
}) {
  return (
    <div className={styles.inputFile}>
      <input
        onChange={onChange}
        className={styles.inputFile_input}
        name={name}
        type="file"
        accept={accept}
        id={`${name}_cv_file`}
      />
      <label
        className={cn(styles.inputFile_label_btn, {
          [styles.disabled]: disabled,
        })}
        htmlFor={`${name}_cv_file`}
      >
        {text}
      </label>
    </div>
  );
}

ButtonFileUploader.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  accept: PropTypes.string,
};
