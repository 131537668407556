import React from 'react'

import PropTypes from 'prop-types'

import { Redirect, Route, Switch } from 'react-router-dom'
import ProfileHeader from '../ProfileHeader/ProfileHeader'

const TabNav = ({ title, links, redirectLink = '' }) => {
  return (
    <>
      <ProfileHeader title={title} links={links} />

      <main className='profile-container'>
        <Switch>
          {links.map(({ url, component: Component }, index) => (
            <Route key={index} path={url} component={Component} exact />
          ))}

          <Redirect to={redirectLink} />
        </Switch>
      </main>
    </>
  )
}

TabNav.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  redirectLink: PropTypes.string,
}

export default TabNav
