import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import styles from './popupRenameVesselName.module.scss'
import stylesForm from '../../../../components/Documents/form.module.scss'
import cn from 'classnames'

import KeyBoardDatePicker from '../../../KeyBoardDatePicker/KeyBoardDatePicker'
import Button from '../../../Button/Button'
import Input from '../../../Input/Input'
import Spinner from '../../../Spinner/Spinner'

import { getVesselNameHistory } from '../../../../api/getApi'
import { postEditVesselName } from '../../../../api/postApi'
import useInfiniteScrollVessel from '../../../../hooks/useInfiniteScrollVessel'
import { useNotify } from '../../../../hooks/useNotify'
import useUserDateFormat from '../../../../hooks/useUserDateFormat'
import { getUniqueCursorHelper } from '../../../../helpers/apiHelper'
import { convertDateToSubmit, getDateByUserFormat, getMinDate, today } from '../../../../helpers/timeHelper'
import { getValidationSchema, label } from './config'
import moment from 'moment'

const PopupRenameVesselName = ({ onCloseClick, dateRange, vesselId }) => {
  const userFormat = useUserDateFormat()
  const { notify } = useNotify()

  const [vesselNames, setVesselNames] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialCursor, setInitialCursor] = useState(null)

  const viewVessels = vesselNames && !loading && vesselNames?.length !== 0

  const initialValues = useMemo(() => {
    return {
      name: '',
      dateFrom: dateRange?.from,
      dateTo: dateRange?.to,
    }
  }, [dateRange])

  const formik = useFormik({
    initialValues: { ...initialValues },

    validationSchema: getValidationSchema(userFormat),
    enableReinitialize: true,

    onSubmit: (values) => {
      if (!vesselId) return null

      setInitialCursor(null)

      setLoading(true)

      postEditVesselName({
        vessel: vesselId,
        name: values.name,
        date_from: convertDateToSubmit(values.dateFrom),
        date_till: convertDateToSubmit(values.dateTo),
      })
        .then(() => {
          getVesselNameHistory(vesselId).then((response) => {
            setVesselNames(response.results)
            setInitialCursor(getUniqueCursorHelper(response.next))
          })
        })
        .catch((err) => {
          notify.info('Info', err.message)
        })
        .finally(() => {
          setLoading(false)
          formik.resetForm()
        })
    },
  })

  useEffect(() => {
    if (!vesselId) return

    setLoading(true)

    getVesselNameHistory(vesselId)
      .then((response) => {
        setVesselNames(response.results)
        setInitialCursor(getUniqueCursorHelper(response.next))
      })
      .finally(() => setLoading(false))

    /* eslint-disable */
  }, [])

  const { errors, touched, values } = formik

  const { handleScroll, loading: next } = useInfiniteScrollVessel({
    setItems: setVesselNames,
    requestFunction: getVesselNameHistory,
    name: vesselId,
    initialCursor,
  })

  const handleChangeValue = (event) => {
    if (!touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    formik.handleChange(event)
  }

  const handleClosePopup = () => {
    onCloseClick()
  }

  const handleInputChange = (e) => {
    handleChangeValue({ target: { value: e.target.value, name: e.target.name } })
  }

  const handleItemClick = (e, dateFrom, dateTo) => {
    const vesselName = e.target.getAttribute('data-text')
    formik.setValues({
      ...values,
      dateFrom,
      dateTo,
    })
    handleChangeValue({ target: { value: vesselName, name: 'name' } })
  }

  return (
    <form className={cn(styles.form, { [styles.form_disabled]: !vesselId })} onSubmit={formik.handleSubmit}>
      <Input
        name='name'
        label={label}
        value={values?.name}
        textarea
        className={styles.text}
        onChange={handleInputChange}
        isInvalid={!!(errors.name && touched.name)}
        errorMessage={errors.name}
        required
      />

      <div className={cn(stylesForm.formRow, styles.wrapperDate)}>
        <div className={cn(stylesForm.formItem, styles.text)}>
          <KeyBoardDatePicker
            formik={formik}
            keyBoardLabel='From'
            value={values.dateFrom}
            nameOfValue='dateFrom'
            minDate={getMinDate}
            maxDate={today}
            format='YYYY/MM'
            errorMessage={errors.dateFrom}
            isInvalid={!!(errors.dateFrom && touched.dateFrom)}
            required={true}
          />
        </div>

        <div className={cn(stylesForm.formItem, styles.text)}>
          <KeyBoardDatePicker
            formik={formik}
            keyBoardLabel='Till'
            value={values.dateTo}
            nameOfValue='dateTo'
            minDate={values.dateFrom ? moment(values.dateFrom) : today}
            maxDate={today}
            format='YYYY/MM'
            errorMessage={errors.dateTo}
            isInvalid={!!(errors.dateTo && touched.dateTo)}
            required
          />
        </div>
      </div>

      {viewVessels && <p className={styles.choose}>Or choose from previously created variants of other seafarers</p>}

      <div className={cn(styles.wrapperNames, { [styles.scrollBar]: !loading })} onScroll={handleScroll}>
        {loading && <Spinner />}

        {viewVessels &&
          vesselNames?.map((item, index) => {
            return (
              <p
                className={styles.vessel}
                key={index}
                data-text={item.name}
                onClick={(e) => handleItemClick(e, item.date_from, item.date_till)}
              >
                {item.name}{' '}
                <span className={styles.date} data-text={item.name}>
                  ({getDateByUserFormat(moment(item.date_from))} - {getDateByUserFormat(moment(item.date_till))})
                </span>
              </p>
            )
          })}

        {next && <Spinner />}
      </div>
      <div className={styles.wrapperButton}>
        <Button type='submit' size='small'>
          Save
        </Button>
        <Button size='small' theme='bordered' onClick={handleClosePopup}>
          Close
        </Button>
      </div>
    </form>
  )
}

PopupRenameVesselName.propTypes = {
  onCloseClick: PropTypes.func,
  dateRange: PropTypes.object,
  vesselId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PopupRenameVesselName
