import differenceInYears from 'date-fns/differenceInYears'
import { convertDateToSubmit, userTimeZoneDateToISO } from '../../../helpers/timeHelper'

export const getDataPrettier = (arr) => {
  if (arr.length === 0 || !arr) return []

  return arr.map((item) => {
    let years_old_str

    if (item.year_of_birth) {
      years_old_str = differenceInYears(new Date(), new Date(userTimeZoneDateToISO(item.year_of_birth)))
    } else {
      years_old_str = null
    }

    const address = `${item.country.name}, ${
      item.city.name
        ? `${item.city.name}, ${item.address?.name}, apt: ${item.apartment || '—'}, zip: ${item.zip || '—'}`
        : '—'
    }`

    return {
      id: item.id,
      header: [item.type.name],
      age: years_old_str,
      fullName: `${item.last_name} ${item.first_name} ${item.middle_name}`,
      address,
      contacts: item.contacts.map(({ contact }) => contact).join(', '),
      files: null,
      is_main: item.next_of_kin,
      main_label: 'Next of kin',

      // content: (
      //   <>
      //     <div className="itemsGroupContentRow">
      //       <div className="itemsGroupContent">
      //         Full name:{" "}
      //         <b>
      //           {item.last_name} {item.first_name} {item.middle_name}
      //         </b>
      //       </div>
      //     </div>
      //     <div className="itemsGroupContentRow">
      //       <div className="itemsGroupContent">
      //         Address: <b>{item.city.name || null}</b>
      //       </div>
      //     </div>
      //     <div className="itemsGroupContentRow">
      //       <div className="itemsGroupContent">
      //         Contacts:{" "}
      //         <b>{item.contacts.map(({ contact }) => contact).join(", ")}</b>
      //       </div>
      //     </div>
      //   </>
      // ),
    }
  })
}

export const submitHelper = (values) => {
  const result = {
    first_name: values.first_name,
    last_name: values.last_name,
    middle_name: values.middle_name,
    year_of_birth: convertDateToSubmit(values.year_of_birth),
    country: values.country.id,
    address: values.address,
    city: values.city,
    apartment: values.apartment,
    zip: values.zip,
    next_of_kin: values.next_of_kin,
    type: parseInt(values.type.id),
    contacts: [
      {
        contact: values.contact,
        type: 2,
      },
    ],
  }

  if (values.sec_contact) {
    result.contacts.push({
      contact: values.sec_contact,
      type: 2,
    })
  }

  return result
}
