import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'
import CrossSvg from '../../UI/Icons/CrossSvg'
import './fileUploader.scss'
import Spinner from '../../UI/Spinner/Spinner'

export default function FileUploader({
  name = 'doc',
  text,
  theme = 'default',
  icon,
  files,
  onChange,
  onDelete,
  isUpload,
  disabled = false,
}) {
  if (files.length) {
    return (
      <>
        <div className={cn(styles.input_active_files, { [styles.input_active_files_cv]: theme === 'cv' })}>
          {files?.map((file, index) => (
            <div key={index} className={cn(styles.inputFile_active, { [styles.inputFile_active_cv]: theme === 'cv' })}>
              <span className={cn(styles.inputFile_label_text, 'input_active')}>
                <a target='_blank' href={file.file} rel='noopener noreferrer'>
                  {file.name}
                </a>
              </span>

              <button
                onClick={() => onDelete(file.id ? file.id : index, name, file.id ? 'byId' : 'byIndex')}
                type='button'
              >
                <CrossSvg stroke='var(--color-accent-light)' />
              </button>
            </div>
          ))}
        </div>

        <div
          className={cn(`input_${theme}`, {
            [`input_active_${theme}`]: files.length,
            [`input_to_long_${theme}`]: files.length >= 5,
          })}
        >
          <input
            id={`${name}cv_file`}
            type='file'
            name={name}
            value={''}
            onChange={onChange}
            className={styles.inputFile_input}
            accept='image/png, .jpeg, .jpg, .pdf'
          />

          <label className={cn(styles.inputFile_label)} htmlFor={`${name}cv_file`}>
            {icon}

            <span className={styles.inputFile_label_text}>{isUpload ? <Spinner /> : text}</span>
          </label>
        </div>
      </>
    )
  }

  return (
    <div
      className={cn(`input_${theme}`, {
        [`input_${theme}_disabled`]: disabled,
      })}
    >
      <input
        id={`${name}cv_file`}
        type='file'
        name={name}
        value={''}
        onChange={onChange}
        className={styles.inputFile_input}
        accept='image/png, .jpeg, .jpg, .pdf'
        disabled={disabled}
      />

      <label className={styles.inputFile_label} htmlFor={`${name}cv_file`}>
        {icon}

        <span className={styles.inputFile_label_text}>{isUpload ? <Spinner /> : text}</span>
      </label>
    </div>
  )
}

FileUploader.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string,
}
