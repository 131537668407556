import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSocialToken } from '../redux/actions/auth'
import { getCodeFromUrl } from '../helpers/apiHelper'
import ModalAlert from '../components/ModalAlert/ModalAlert'

const FacebookSignin = () => {
  const dispatch = useDispatch()
  const [isVerifySuccess, setIsVerifySuccess] = React.useState(null)
  const { isAuth, errors } = useSelector(({ authentication }) => authentication)

  React.useEffect(() => {
    const code = getCodeFromUrl()
    if (code) {
      dispatch(
        getUserSocialToken({
          code: code,
          grant_type: 'convert_token',
          backend: 'facebook',
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    setIsVerifySuccess(isAuth)
  }, [isAuth])

  // if (isVerifySuccess === false) {
  //   return <Redirect to='/' />
  // }

  return (
    <div className='page'>
      <main>
        {!!isVerifySuccess && (
          <ModalAlert isOk={true} title='Thank you!' content='You are successfully login from facebook.' />
        )}
        {isVerifySuccess === false && (
          <ModalAlert isOk={false} title='Oops..' content={errors ? errors[0].message : 'Something went wrong.'} />
        )}
      </main>
    </div>
  )
}

export default FacebookSignin
