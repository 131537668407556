import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Spinner from '../UI/Spinner/Spinner'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

export default function MobStoreRedirect() {
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  useEffect(() => {
    const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    const isAndroid = /android/i.test(userAgent)
    if (isIos) {
      window.location.assign('https://apps.apple.com/ua/app/nextship/id1554236157')
      setLoading(false)
    } else if (isAndroid) {
      window.location.assign('https://play.google.com/store/apps/details?id=com.nextship.client')
      setLoading(false)
    } else {
      setLoading(false)
      history.push('/')
    }
  }, [userAgent, history])

  return (
    <div id='page' className='page'>
      <Header />
      <div>
        <div className='container'>{loading && <Spinner size={250} />}</div>
      </div>
      <Footer />
    </div>
  )
}
