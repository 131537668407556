import React from 'react'
import { useFormik } from 'formik'
import Select from '../../../UI/Select/Select'
import SelectSearch from '../../../UI/Select/SelectSearch'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import ItemsGroup from '../../../components/ItemsGroup/ItemsGroup'
import { confirm } from '../../../UI/Confirm/Confirm'
import {
  getAuthRelatives,
  getListAuthRelatives,
  getGooglePlacesAddress,
  getGooglePlacesCities,
  getAuthMe,
} from '../../../api/getApi'
import { submitHelper } from './relatives.helper'
import { initialValues, getValidationSchema } from './config'
import { postRelative } from '../../../api/postApi'
import { getAuthProfileFilters } from '../../../api/getApi'
import { putRelative } from '../../../api/putApi'
import { deleteRelativeById } from '../../../api/deleteApi'
import Autocomplete from '../../../UI/Select/Autocomplete'
import CheckBox from '../../../UI/CheckBox/CheckBox'
import { setIsNextOfKin } from '../../../api/patchApi'
import { getDataPrettier } from './relatives.helper'
import { useNotify } from '../../../hooks/useNotify'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import {
  addRelativesContact,
  clearAuthRelativesContacts,
  deleteRelativesContact,
  editRelativesContact,
  setAuthRelativesContacts,
  setMainRelativesContact,
} from '../../../redux/actions/contacts.action'
import { getRelativesContactsHelper, objToQueryConverter } from '../../../helpers/apiHelper'
import { useDispatch, useSelector } from 'react-redux'
import KeyBoardDatePicker from '../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import styles from './RelativesContacts.module.scss'
import { getUserInfo } from '../../../redux/actions/user.action'
import { convertDateFromGet, getMinDate, today } from '../../../helpers/timeHelper'
import { isCompletelyFilled } from '../../../helpers/objectsHelper'
import stylesForm from '../../../components/Documents/form.module.scss'
import cn from 'classnames'

export default function RelativesContacts() {
  const dispatch = useDispatch()
  const allRelatives = useSelector(({ contactsRelatives }) => contactsRelatives.contactsRelatives)
  const allRelativesCount = useSelector(({ contactsRelatives }) => contactsRelatives.count)
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const { notify } = useNotify()
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [currentId, setCurrentId] = React.useState(null)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentData, setDocumentData] = React.useState()

  const [filters, setFilters] = React.useState({
    country: [],
    relation_type: [],
    contact_type: [],
  })

  const formik = useFormik({
    initialValues: currentId && documentData ? documentData : initialValues,
    validationSchema: getValidationSchema(dateFormat),
    enableReinitialize: true,

    onSubmit: (values) => {
      if (currentId) {
        editSome(submitHelper(values))
      } else {
        postContact(submitHelper(values))
      }
    },
  })
  const { values, errors, touched, dirty } = formik

  React.useEffect(() => {
    const completelyFilled = isCompletelyFilled(values, ['type', 'first_name', 'last_name', 'country', 'contact'])

    setIsButtonEnable(completelyFilled && dirty)
    // eslint-disable-next-line
  }, [values, dirty])

  React.useEffect(() => {
    getAuthProfileFilters('country', 'relation_type', 'contact_type').then((data) => setFilters(() => data))
    return () => dispatch(clearAuthRelativesContacts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const { handleScroll: handleRelativesContactScroll, loading } = useInfiniteScroll(
    getListAuthRelatives,
    setAuthRelativesContacts,
    getRelativesContactsHelper,
    objToQueryConverter({ page_size: 1000 }),
    clearAuthRelativesContacts
  )

  const postContact = (values) => {
    setIsButtonEnable(false)
    postRelative(values)
      .then((data) => {
        if (data.ok) {
          getAuthRelatives(data.id).then((data) => {
            dispatch(addRelativesContact(data))
            if (allRelatives.length === 0) {
              getAuthMe('seafarer').then((profile) => dispatch(getUserInfo(profile)))
            }
          })
          formik.resetForm()
          notify('Success', 'Your contact was successfully added.')
        } else {
          data.errors[0].message.forEach(({ contact }) => notify.error('Error', `${contact[0]}`))
        }
      })
      .catch((error) => {
        notify.errorsList(error.errors)
      })
      .finally(() => setIsButtonEnable(true))
  }

  const editSome = (data) => {
    setIsButtonEnable(false)
    putRelative(data, currentId)
      .then((data) => {
        if (data.ok) {
          getAuthRelatives(data.id).then((data) => {
            dispatch(editRelativesContact(data.id, data))
          })
          formik.resetForm()
          setCurrentId(null)
          notify('Success', 'Your changes were successfully saved.')
        } else {
          data.errors[0].message.forEach(({ contact }) => notify.error('Error', `${contact[0]}`))
        }
      })
      .catch((error) => {
        notify.errorsList(error.errors)
      })
      .finally(() => setIsButtonEnable(true))
  }

  const toggleCheckbox = () => {
    formik.setValues({
      ...values,
      next_of_kin: !values.next_of_kin,
    })
  }

  const handleEdit = (id, isActive) => {
    if (isActive) {
      formik.resetForm()
      setCurrentId(null)
    } else {
      getAuthRelatives(id).then((data) => {
        setDocumentData({
          ...data,
          contact_type: data.contacts[0]
            ? { name: data.contacts[0].type.name, id: data.contacts[0].type.id }
            : { name: '', id: null },
          contact: data.contacts[0] ? data.contacts[0].contact : '',
          sec_contact_type: data.contacts[1]
            ? { name: data.contacts[1].type.name, id: data.contacts[1].type.id }
            : { name: '', id: null },
          sec_contact: data.contacts[1] ? data.contacts[1].contact : '',
          year_of_birth: convertDateFromGet(data.year_of_birth, 'start'),
          type: data.type ? { name: data.type.name, id: data.type.id } : { name: '', id: null },
        })
        setCurrentId(id)
        formik.resetForm()
      })
    }
  }

  React.useEffect(() => {}, [values])
  /* autocomplete fields logic */
  const handleAutocompleteChange = (type, { target }) => {
    handleChangeFormikValue({
      target: { value: { name: target.value, id: null }, name: type },
    })
    getPlacesItems(type, target)
  }

  const getPlacesItems = (type, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      if (type === 'city') {
        getGooglePlacesCities(target.value, values.country.iso2.toLowerCase()).then((data) => {
          setAutocompleteItems(data)
        })
      } else if (type === 'address') {
        getGooglePlacesAddress(target.value, values.city.id).then((data) => {
          setAutocompleteItems(data)
        })
      }
    }
  }

  const handleAutocompleteFocus = (type, { target }) => {
    getPlacesItems(type, target)
  }

  const handleAutocompleteClick = (type, data) => {
    handleChangeFormikValue({ target: { value: data, name: type } })
    setAutocompleteItems([])
  }

  /* end autocomplete fields logic */

  const handleDelete = (id) => {
    confirm('Are you sure?', 'You want to delete this contact?', () => {
      deleteRelativeById(id)
        .then((data) => {
          // we want to clear the form only when user wants to delete a contact that is in edit state
          if (data.ok) {
            if (currentId === id) {
              formik.resetForm()
              setCurrentId(null)
            }
            if (allRelatives.length === 1) {
              getAuthMe('seafarer').then((profile) => dispatch(getUserInfo(profile)))
            }
            dispatch(deleteRelativesContact(id))
            notify('Success', 'Your contact was successfully deleted.')
          }
        })
        .catch(({ response }) => {
          const errorsData = response.data
          errorsData.data.errors.forEach((err) => notify.error('Error', err.message))
        })
    })
  }

  const setMain = (id, isActive) => {
    setIsNextOfKin(id, !isActive).then((result) => {
      if (result.ok) {
        dispatch(setMainRelativesContact(id, result.next_of_kin))
      } else {
        result.errors.forEach(({ message }) => notify.error('Error', `${message}`))
      }
    })
  }

  const handleFieldChange = (type, data) => {
    handleChangeFormikValue({ target: { value: data, name: type } })
    if (type === 'sec_contact_type' && !data.id) {
      formik.setValues({
        ...values,
        sec_contact: '',
        sec_contact_type: { name: '', id: null },
      })
    }
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  return (
    <section className={styles.relative_contacts}>
      <form className={styles.relative_contacts_form} onSubmit={formik.handleSubmit}>
        <div className={styles.contacts_form_column}>
          <div className='field-item'>
            <Select
              label='Select relation type'
              required
              name='type'
              id={values.type.id}
              selectedItem={values.type.name}
              items={filters.relation_type}
              onClick={handleFieldChange}
              isInvalid={!!(errors.type && touched.type)}
            />
          </div>
          <div className='field-item'>
            <Input
              label='First Name'
              required
              name='first_name'
              value={values.first_name}
              onChange={handleChangeFormikValue}
              isInvalid={!!(errors.first_name && touched.first_name)}
            />

            {errors.first_name && touched.first_name && <p className={stylesForm.formItemError}>{errors.first_name}</p>}
          </div>
          <div className='field-item'>
            <Input
              label='Last Name'
              required
              name='last_name'
              value={values.last_name}
              onChange={handleChangeFormikValue}
              isInvalid={!!(errors.last_name && touched.last_name)}
            />

            {errors.last_name && touched.last_name && <p className={stylesForm.formItemError}>{errors.last_name}</p>}
          </div>
          <div className='field-item'>
            <Input
              label='Middle Name'
              name='middle_name'
              value={values.middle_name}
              onChange={handleChangeFormikValue}
              isInvalid={!!(errors.middle_name && touched.middle_name)}
            />
          </div>

          <div className={styles.year_of_birth}>
            <KeyBoardDatePicker
              formik={formik}
              keyBoardLabel='Year of Birth'
              value={values.year_of_birth}
              nameOfValue='year_of_birth'
              minDate={getMinDate}
              maxDate={today}
              today={true}
              isInvalid={!!(errors.year_of_birth || (errors.year_of_birth && touched.year_of_birth))}
              errorMessage={errors.year_of_birth}
              className={styles.year_of_birth_field}
              views={['year']}
              format='YYYY'
            />

            <div className={cn(styles.next_of_kin, { [styles.error]: !!errors.year_of_birth })}>
              <CheckBox onClick={toggleCheckbox} checked={values.next_of_kin}>
                Next of kin
              </CheckBox>
            </div>
          </div>
        </div>
        <div className={styles.contacts_form_column}>
          <div className='field-item'>
            <SelectSearch
              label='Country'
              required
              name='country'
              items={filters.country}
              id={values.country.id}
              selectedItem={values.country.name}
              onClick={(type, data) => {
                const countryName = data.name
                if (countryName === 'Country') {
                  formik.setValues({
                    ...values,
                    city: { name: '', id: null },
                    address: { name: '', id: null },
                  })
                } else {
                  if (countryName === values.country.name) {
                    return null
                  } else {
                    formik.setValues({
                      ...values,
                      city: { name: '', id: null },
                      address: { name: '', id: null },
                    })
                  }
                }
                handleFieldChange(type, data)
              }}
              isInvalid={!!(errors.country && touched.country)}
            />
          </div>
          <div className='field-item'>
            <Autocomplete
              autoComplete={'new-password'}
              label={`City`}
              name={'city'}
              disabled={!values.country.id}
              value={values.city.name}
              onChange={(type, data) => {
                formik.setValues({
                  ...values,
                  address: { name: '', id: null },
                })
                handleAutocompleteChange(type, data)
              }}
              onFocus={handleAutocompleteFocus}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
              isInvalid={!!(errors.city && touched.city)}
            />
          </div>
          <div className='field-item'>
            <Autocomplete
              autoComplete={'new-password'}
              label='Street and house'
              name='address'
              disabled={!values.city.id}
              value={values.address.name}
              onChange={handleAutocompleteChange}
              onFocus={handleAutocompleteFocus}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
              isInvalid={!!(errors.address && touched.address)}
            />
          </div>
          <div className='field-item-row-sec' style={{ gap: '4px' }}>
            <div className='field-item'>
              <Input
                label='Apt'
                name='apartment'
                value={values.apartment}
                onChange={handleChangeFormikValue}
                disabled={!values.address?.id}
              />
            </div>
            <div className='field-item'>
              <Input
                label='ZIP'
                name='zip'
                value={values.zip}
                onChange={handleChangeFormikValue}
                disabled={!values.address?.id}
              />
            </div>
          </div>
          <div className='field-item-row-sec' style={{ gap: '4px' }}>
            <div className='field-item'>
              <Input
                label='Main Phone'
                name='contact'
                value={values.contact}
                onChange={handleChangeFormikValue}
                isInvalid={!!(errors.contact && touched.contact)}
                required
              />

              {errors.contact && touched.contact && <p className={stylesForm.formItemError}>{errors.contact}</p>}
            </div>
            <div className='field-item'>
              <Input
                label='Additional Phone'
                name='sec_contact'
                value={values.sec_contact}
                onChange={handleChangeFormikValue}
                isInvalid={!!(errors.sec_contact && touched.sec_contact)}
              />
            </div>
          </div>
        </div>

        <Button className={styles.relative_contacts_save} disabled={!isButtonEnable} type='submit'>
          Save
        </Button>
      </form>

      <div className={styles.relative_contacts_list}>
        <ItemsGroup
          onDelete={handleDelete}
          onEdit={handleEdit}
          currentId={currentId}
          title='All Relatives'
          items={getDataPrettier(allRelatives)}
          setMain={setMain}
          scroll={handleRelativesContactScroll}
          count={allRelativesCount}
          type='relative contacts'
          isLoading={loading}
        />
      </div>
    </section>
  )
}
