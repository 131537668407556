import React, { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../../UI/Select/Select'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import ItemsGroup from '../../../components/ItemsGroup/ItemsGroup'
import { confirm } from '../../../UI/Confirm/Confirm'
import { getAuthContact, getAuthMe, getListAuthContact } from '../../../api/getApi'
import { contactsDataPrettier, messengersIcons, placeholderTexts, submitHelper } from './contacts.helper'
import { postProfileContacts } from '../../../api/postApi'
import { getAuthProfileFilters } from '../../../api/getApi'
import styles from './MyContacts.module.scss'
import { putProfileContact } from '../../../api/putApi'
import { deleteContactById } from '../../../api/deleteApi'

import CheckBox from '../../../UI/CheckBox/CheckBox'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import {
  addAuthContact,
  clearAuthContacts,
  deleteAuthContact,
  editAuthContact,
  setAuthContacts,
  setMainContact,
} from '../../../redux/actions/contacts.action'
import { getContactsHelper } from '../../../helpers/apiHelper'
import { getUserInfo } from '../../../redux/actions/user.action'
import { useNotify } from '../../../hooks/useNotify'
import { validationSchema } from './config'
import stylesForm from '../../../components/Documents/form.module.scss'
import { isCompletelyFilled, sortMessengers } from '../../../helpers/objectsHelper'
import { setIsContactMain } from '../../../api/patchApi'
import { isEqual } from 'lodash'

const MyContacts = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()

  const allContacts = useSelector(({ contacts }) => contacts.contacts)

  const [placeholder, setPlaceholder] = useState('')
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [currentId, setCurrentId] = React.useState(null)
  const [documentData, setDocumentData] = React.useState()

  const [filters, setFilters] = React.useState({
    contact_type: [],
  })
  const [messengers, setMessengers] = React.useState([])

  const initialValues = useMemo(() => {
    if (currentId) {
      const currentContact = allContacts.find((item) => item.id === currentId)

      return {
        id: currentContact.id,
        type: currentContact.type,
        contact: currentContact.contact,
        is_main: currentContact.is_main,
        messengers: currentContact.messengers,
      }
    }

    return {
      type: { name: 'Type', id: null },
      contact: '',
      is_main: true,
      messengers: messengers,
    }
    //eslint-disable-next-line
  }, [currentId, allContacts])

  const formik = useFormik({
    initialValues: currentId && documentData ? documentData : initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlue: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (currentId) {
        const isTypeChanged = values.type.id !== formik.initialValues.type.id
        editSome(submitHelper({ ...values, is_main: isTypeChanged ? false : values.is_main }, messengers))
        setMessengers([])
      } else {
        postContact(submitHelper(values, messengers))
        setMessengers([])
      }
    },
  })

  const { values, errors, touched, dirty } = formik

  React.useEffect(() => {
    const completelyFilled = isCompletelyFilled({ type: values?.type?.id, contact: values?.contact })
    const isMessengersDirty = !isEqual(
      sortMessengers(values.messengers),
      sortMessengers(currentId && documentData ? documentData?.messengers : initialValues.messengers)
    )
    const isContactDirty = !isEqual(
      values.contact,
      currentId && documentData ? documentData?.contact : initialValues.contact
    )
    if (values.type.id !== 2) {
      setIsButtonEnable(completelyFilled && dirty)
    } else {
      setIsButtonEnable((completelyFilled && isMessengersDirty) || (completelyFilled && isContactDirty))
    }
  }, [values, dirty, currentId, documentData, initialValues])

  React.useEffect(() => {
    getAuthProfileFilters('contact_type').then((data) => {
      setFilters(data)
    })
  }, [])

  React.useEffect(() => {
    return () => {
      dispatch(clearAuthContacts())
    }
  }, [dispatch])

  const { handleScroll: handleContactScroll, loading: loadingScroll } = useInfiniteScroll(
    getListAuthContact,
    setAuthContacts,
    getContactsHelper,
    '',
    clearAuthContacts
  )

  const postContact = (values) => {
    postProfileContacts(values)
      .then((data) => {
        if (data.ok) {
          dispatch(addAuthContact(data))
          if (allContacts.length === 0) {
            getAuthMe('seafarer').then((profile) => dispatch(getUserInfo(profile)))
          }
          formik.resetForm()
          setPlaceholder('')
          notify('Success', 'Your contact was successfully added.')
        } else {
          data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
        }
      })
      .catch(({ response }) => {
        const errorsData = response.data
        errorsData.data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
      })
  }

  const editSome = (data) => {
    putProfileContact(data, currentId)
      .then((data) => {
        if (data.ok) {
          getAuthContact(data.id).then((data) => {
            dispatch(editAuthContact(data.id, data))
            formik.resetForm()
          })
          setCurrentId(null)
          setPlaceholder('')
          notify('Success', 'Your changes were successfully saved.')
        } else {
          data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
        }
      })
      .catch(({ response }) => {
        const errorsData = response.data
        errorsData.data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
      })
  }

  const handleEdit = (id, isActive) => {
    if (id !== null) {
      if (isActive) {
        formik.setValues({
          type: { name: 'Type', id: null },
          contact: '',
          is_main: true,
        })
        setCurrentId(null)
        setMessengers([])
      } else {
        getAuthContact(id).then((data) => {
          setDocumentData(data)
          formik.resetForm()

          if (data.type.name === 'phone') {
            setMessengers(data.messengers)
          }

          setCurrentId(id)
        })
      }
    } else {
      notify.info("You can't change general email!")
    }
  }

  const handleDelete = (id) => {
    if (id !== null) {
      confirm('Are you sure?', 'You want to delete this contact?', () => {
        deleteContactById(id)
          .then((data) => {
            // we want to clear the form only when user wants to delete a contact that is in edit state
            if (data.ok) {
              if (currentId === id) {
                formik.setValues({
                  type: { name: 'Type', id: null },
                  contact: '',
                  is_main: true,
                })
                setCurrentId(null)
              }

              if (allContacts.length === 1) {
                getAuthMe('seafarer').then((profile) => dispatch(getUserInfo(profile)))
              }
              dispatch(deleteAuthContact(id))
              notify('Success', 'Your contact was successfully deleted.')
            }
          })
          .catch(({ response }) => {
            const errorsData = response.data
            errorsData.data.errors.forEach((err) => notify.error('Error', err.message))
          })
      })
    } else {
      notify.info("You can't delete general email!")
    }
  }

  const setMain = (id, isActive) => {
    const type = allContacts.find((el) => el.id === id)?.type.name

    if (isActive) {
      notify.info('Info', `At least one contact of type ${type} should be main`)
    } else {
      if (id !== null) {
        setIsContactMain(id, true).then(() => {
          dispatch(setMainContact(id, type))
        })
      } else {
        notify.info("You can't change general email!")
      }
    }
  }

  const handleFieldChange = (type, data) => {
    if (data.id === null) {
      formik.setValues({ ...values, contact: '' })
      handleChangeFormikValue({ target: { value: data, name: type } })
    } else {
      if (data.id === values.type.id) {
        return null
      } else {
        formik.setValues({ ...values, contact: '' })
        handleChangeFormikValue({ target: { value: data, name: type } })
      }
    }

    for (let item in placeholderTexts) {
      if (item === data.name) setPlaceholder(placeholderTexts[item])
    }
    if (data.id === null) setPlaceholder('')
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }
    formik.handleChange(value)
  }

  function handleCheck(target) {
    const newVal = messengers.includes(target) ? messengers.filter((item) => item !== target) : [...messengers, target]
    setMessengers(newVal)
    handleChangeFormikValue({ target: { value: newVal, name: 'messengers' } })
  }

  return (
    <div className={styles.my_contacts}>
      <form className={styles.my_contacts_form} onSubmit={formik.handleSubmit}>
        <div className='field-item'>
          <Select
            label='Contact'
            required
            name='type'
            id={values.type.id}
            selectedItem={values.type.name}
            items={filters.contact_type}
            onClick={handleFieldChange}
            isInvalid={!!(errors.type && touched.type)}
          />
        </div>

        <div className='field-item'>
          <Input
            name='contact'
            placeholder={placeholder}
            value={values.contact}
            onChange={handleChangeFormikValue}
            disabled={!values.type.id}
            isInvalid={!!(errors.contact && touched.contact)}
            required
          />

          {errors.contact && touched.contact && <p className={stylesForm.formItemError}>{errors.contact}</p>}
        </div>

        {values.type.name === 'phone' && (
          <div className={styles.checkbox_list}>
            {messengersIcons.map(({ name, icon: Icon }) => {
              return (
                <div className={styles.checkbox} key={name}>
                  <CheckBox id={name} onClick={handleCheck} checked={messengers.includes(name)}>
                    <div className={styles.checkbox_content}>
                      {name}
                      <Icon />
                    </div>
                  </CheckBox>
                </div>
              )
            })}
          </div>
        )}
        <Button className={styles.button_save_my_contacts} disabled={!isButtonEnable} type='submit' size='middle-full'>
          Save
        </Button>
      </form>
      <div className={styles.my_contacts_list}>
        <ItemsGroup
          onDelete={handleDelete}
          onEdit={handleEdit}
          currentId={currentId}
          title='All contacts'
          items={contactsDataPrettier(allContacts)}
          setMain={setMain}
          scroll={handleContactScroll}
          count={allContacts.length}
          type='my contacts'
          isLoading={loadingScroll}
        />
      </div>
    </div>
  )
}

export default MyContacts
