import React from 'react'
import Logo from '../../UI/Logo/Logo'
import styles from './Header.module.scss'
import HeaderNav from '../HeaderNav/HeaderNav'
import cn from 'classnames'
import CircleTextButton from '../CircleTextButton/CircleTextButton'

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={cn('container', styles.wrapper)}>
        <div className={styles.logo_wrapper}>
          <Logo theme='dark' />
          <CircleTextButton style={{ marginLeft: 15 }} />
        </div>
        <HeaderNav />
      </div>
    </header>
  )
}

Header.propTypes = {}

export default Header
