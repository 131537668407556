import React, { memo, useEffect, useRef, useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import { motion, useAnimation } from 'framer-motion'
// import Tip from 'react-tooltip-lite'
// import ReactTooltip from 'react-tooltip'

import styles from './styles.module.scss'
import '../filesGroupAnimation.scss'
import cn from 'classnames'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'
import { ReactComponent as CalcOfFilesIcon } from '../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as DeleteFileIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as HistoryIcon } from '../../../assets/icons/revers.svg'

import VesselNameHistory from './VesselNameHistory/VesselNameHistory'
import useVesselNameHistory from './VesselNameHistory/useVesselNameHistory'
import ImageComponent from '../../../UI/Image/ImageComponent'

import { isDefaultVesselImg } from '../../../helpers/helpers'
import { StyledTooltip } from '../../../UI/Tooltip/Tooltip.config'

const ExperienceGroupItem = memo(({ type, data, onDelete, onEdit, deleteFile, isActive }) => {
  const [visibleListOfFiles, setVisibleListOfFiles] = useState(false)
  const [expTypeSea, setExpTypeSea] = useState(true)
  const nodeRef = useRef(null)
  const control = useAnimation()

  useEffect(() => {
    if (visibleListOfFiles) {
      control.start((i) => ({
        opacity: 1,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    } else {
      control.start((i) => ({
        opacity: 0,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    }

    // eslint-disable-next-line
  }, [visibleListOfFiles])

  useEffect(() => {
    if (data.experience_type !== 'sea') {
      setExpTypeSea(false)
    } else {
      setExpTypeSea(true)
    }
  }, [data])

  const { props, closeHistory, getRenameHistory, loading } = useVesselNameHistory(data.vessel.id)

  useEffect(() => {
    if (!isActive) closeHistory()
  }, [isActive, closeHistory])

  const handleFilesEnter = () => {
    setVisibleListOfFiles(true)
  }
  const handleFilesLeave = () => {
    setVisibleListOfFiles(false)
  }

  return (
    <div className={cn(styles.card, { [styles.card__active]: isActive })}>
      <div className={styles.cardInfo}>
        <div className={styles.rank}>{data.rank}</div>

        <div className={styles.cardInfoBody}>
          <div className={styles.cardMainInfo}>
            <div className={styles.field}>
              <div className={styles.fieldName}>Location:</div>

              <div className={styles.fieldValue}>{expTypeSea ? 'Onboard Job' : 'Shore-Based Job'}</div>
            </div>

            {expTypeSea ? (
              <div className={styles.field}>
                <div className={styles.fieldName}>Vessel name:</div>

                <div className={styles.fieldValue}>
                  <StyledTooltip
                    placement='left-start'
                    title={
                      <ImageComponent
                        src={data.vessel.mt_photo_mini_url}
                        alt='vassel'
                        className={cn({ [styles.vesselImg]: isDefaultVesselImg(data.vessel.mt_photo_mini_url) })}
                      />
                    }
                  >
                    <b className={styles.vesselName}>{data.vessel.name}</b>
                  </StyledTooltip>

                  <span className={styles.historyIcon}>
                    <StyledTooltip
                      placement='bottom'
                      title={
                        <VesselNameHistory
                          id={data.vessel.id}
                          getRenameHistory={getRenameHistory}
                          loading={loading}
                          {...props}
                        />
                      }
                    >
                      <HistoryIcon />
                    </StyledTooltip>
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.field}>
                <div className={styles.fieldName}>Country:</div>

                <div className={styles.fieldValue}>{data?.country?.name || '–'}</div>
              </div>
            )}

            {expTypeSea ? (
              <div className={styles.field}>
                <div className={styles.fieldName}>Vessel type:</div>

                <div className={styles.fieldValue}>{data.vesselType}</div>
              </div>
            ) : (
              <div className={styles.field}>
                <div className={styles.fieldName}>City:</div>

                <div className={styles.fieldValue}>{data?.city?.name || '–'}</div>
              </div>
            )}

            <div className={styles.field}>
              <div className={styles.fieldName}>Period:</div>

              <div className={styles.fieldValue}>{data.period}</div>
            </div>

            {expTypeSea && (
              <div className={styles.field}>
                <div className={styles.fieldName}>Engine:</div>

                <div className={styles.fieldValue}>{data.engineType}</div>
              </div>
            )}
          </div>

          <div className={styles.cardAdditionalInfo}>
            <div className={styles.files}>
              {data?.files?.length ? (
                <div key={data.files[0].id} className={styles.file} onMouseEnter={handleFilesEnter}>
                  <div key={data.files[0].id} className={styles.file}>
                    <div className={styles.calcOfFiles}>
                      <label className={styles.calcOfFilesLabel}>{data.files.length}</label>

                      <CalcOfFilesIcon />
                    </div>

                    <a rel='noopener noreferrer' target='_blank' href={data.files[0].file}>
                      <img src={data.files[0].file_mini} alt='file' />
                    </a>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <CSSTransition nodeRef={nodeRef} in={visibleListOfFiles} timeout={500} unmountOnExit classNames='listOfFiles'>
            <div ref={nodeRef} className='listOfFiles' onMouseLeave={handleFilesLeave}>
              <div className={styles.field}>
                <div className={styles.fieldName}>Vessel name:</div>

                <div className={styles.fieldValue}>{data.header}</div>
              </div>

              <div className='files_list'>
                {data?.files?.length &&
                  data?.files?.map((file) => (
                    <motion.div
                      key={file.id}
                      className={styles.file}
                      custom={file.index}
                      initial={{ opacity: 1, x: 20 }}
                      animate={control}
                      style={{ width: '68px', height: '68px' }}
                    >
                      <div className={styles.deleteFile} onClick={() => deleteFile(file.id, data.id, type, isActive)}>
                        <DeleteFileIcon />
                      </div>

                      <a rel='noopener noreferrer' target='_blank' href={file.file}>
                        <img src={file.file_mini} alt='file' />
                      </a>
                    </motion.div>
                  ))}
              </div>
            </div>
          </CSSTransition>
        </div>

        {data.id !== 'default' && (
          <>
            <div className={styles.cardControls}>
              <button
                className={cn(styles.cardControl, styles.cardControlEdit)}
                onClick={() => onEdit(data.id, !isActive)}
              >
                {isActive ? (
                  <span>
                    Close <CloseIcon />
                  </span>
                ) : (
                  <span>
                    Edit <EditIcon />
                  </span>
                )}
              </button>

              <button
                className={cn(styles.cardControl, styles.cardControlDelete)}
                onClick={() => onDelete(data.id, data.experience_type, isActive)}
              >
                <span>
                  Delete <TrashIcon />
                </span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
})

export default ExperienceGroupItem
