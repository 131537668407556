import React, { useEffect } from 'react'

import styles from './styles.module.scss'

import VacancyList from './VacancyList/VacancyList'
import { getVacancies } from '../../api/getApi'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { vacanciesSearchHelper } from '../../helpers/vacanciesSearchHelper'
import { objToQueryConverter } from '../../helpers/apiHelper'
import Spinner from '../../UI/Spinner/Spinner'
import { clearAppliedJobs, setAppliedJobs, setJobsCount } from '../../redux/actions/vacancies.action'
import { useDispatch, useSelector } from 'react-redux'

const AppliedJobs = () => {
  const { appliedJobs, jobsCount } = useSelector(({ vacancies }) => vacancies)

  const dispatch = useDispatch()

  const params = { applied: 'True', show_sea: true, show_land: true }

  const { handleScroll, dataCount, loading, handleListDelete, nextPage } = useInfiniteScroll(
    getVacancies,
    setAppliedJobs,
    vacanciesSearchHelper,
    objToQueryConverter(params)
  )

  useEffect(() => {
    dispatch(setJobsCount(dataCount))

    // eslint-disable-next-line
  }, [dataCount])

  useEffect(() => {
    return () => {
      dispatch(clearAppliedJobs())
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (jobsCount !== 0 && appliedJobs.length === 0 && nextPage) handleListDelete()

    // eslint-disable-next-line
  }, [jobsCount, appliedJobs])

  return (
    <div className={styles.vacanciesApplied}>
      {!appliedJobs.length && loading ? (
        <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
      ) : (
        <VacancyList vacancies={appliedJobs} count={jobsCount} onScroll={handleScroll} appliedJobs />
      )}
    </div>
  )
}

export default AppliedJobs
