import React from 'react'

const NotificationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        fill='var(--color-accent-light)'
        strokeWidth='0'
        d='M23.077 18.012h-.923V9.237c0-2.45-.973-4.8-2.704-6.532A9.228 9.228 0 0012.923 0h-1.846A9.228 9.228 0 004.55 2.705a9.24 9.24 0 00-2.704 6.532v8.775H.923a.923.923 0 000 1.848h6.088A5.081 5.081 0 0012 24a5.075 5.075 0 004.99-4.14h6.087a.922.922 0 000-1.848zM12 22.17a3.23 3.23 0 01-3.097-2.31h6.194A3.233 3.233 0 0112 22.17zm-8.308-4.157V9.237c0-1.96.778-3.84 2.163-5.225a7.382 7.382 0 015.222-2.165h1.846a7.392 7.392 0 017.385 7.39v8.775H3.692z'
      ></path>
    </svg>
  )
}

export default NotificationIcon
