import React, { PureComponent } from 'react'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import cn from 'classnames'

import arrowSvg from '../../../../assets/icons/flex-arrow.svg'
import noAvatar from '../../../../assets/images/no-avatar.svg'

import ImageComponent from '../../../../UI/Image/ImageComponent'
import CheckBox from '../../../../UI/CheckBox/CheckBox'

import { dateToTwelveHourFormat } from '../../../../helpers/timeHelper'

const SENDER_TYPE_SEAFARER = 'seafarer'

export default class AsideItem extends PureComponent {
  render() {
    const {
      isOnline,
      isSelected,
      isActive,
      onCheck,
      picture,
      id,
      unread,
      user_name,
      company_name,
      lastMessage,
      url,
    } = this.props
    const userName = (() => {
      if (user_name !== null) return `(${user_name?.toLowerCase()})`

      return ''
    })()

    const previewTitle = (() => {
      if (company_name) return `${company_name?.toLowerCase()} ${userName}`

      return user_name
    })()

    return (
      <NavLink to={url} activeClassName={styles.active} className={styles.item}>
        {unread ? <div className={styles.unread}>{unread}</div> : null}

        <CheckBox
          id={id}
          theme={isActive ? 'bordered-white' : 'bordered'}
          onClick={onCheck}
          checked={isSelected}
          className={styles.checkbox}
        />

        <div className={styles.avatarWrapper}>
          <ImageComponent
            className={cn(styles.avatar, { [styles.avatar__online]: isOnline })}
            src={picture ?? noAvatar}
            alt='user avatar'
          />
        </div>

        <div className={styles.preview}>
          <div className={styles.previewTop}>
            <div className={styles.previewTitle}>{previewTitle}</div>

            {lastMessage && <div className={styles.previewTime}>{dateToTwelveHourFormat(lastMessage.date)}</div>}
          </div>

          {lastMessage && (
            <div className={styles.previewBottom}>
              <div className={styles.previewStatus}>
                <img
                  src={arrowSvg}
                  alt='indicator'
                  style={{
                    transform:
                      lastMessage.is_outgoing || lastMessage.sender_type === SENDER_TYPE_SEAFARER
                        ? 'unset'
                        : 'rotate(180deg)',
                  }}
                />
              </div>

              <div className={styles.previewMessage}>{lastMessage.message}</div>
            </div>
          )}
        </div>
      </NavLink>
    )
  }
}

AsideItem.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  picture: PropTypes.string,
  id: PropTypes.number.isRequired,
  unread: PropTypes.number.isRequired,
  user_name: PropTypes.string,
  url: PropTypes.string.isRequired,
  lastMessage: PropTypes.object,
}
