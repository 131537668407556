import { isObject } from 'lodash'
import Moment from 'moment'

export const getIdfromObj = (obj) => {
  const result = {}
  Object.entries(obj).forEach((item) => {
    if (item[1].id !== null) {
      result[item[0]] = item[1].id
    } else {
      result[item[0]] = null
    }
  })
  return result
}

export const changeSelects = (apiData, stateObj) => {
  const result = {}
  Object.entries(stateObj).forEach((item) => {
    if (apiData[item[0]]) {
      result[item[0]] = { ...apiData[item[0]] }
    }
  })
  return result
}

export const changeDates = (apiData, stateObj) => {
  const result = {}
  Object.entries(stateObj).forEach((item) => {
    if (apiData[item[0]]) {
      result[item[0]] = apiData[item[0]]
    }
  })
  return result
}

export const emptyStringToNull = (obj) => {
  const result = {}
  Object.keys(obj).forEach((item) => {
    obj[item] === '' ? (result[item] = null) : (result[item] = obj[item])
  })
  return result
}

export const isCompletelyFilled = (values, fieldsToCheck = '') => {
  const valuesArr = Object.entries(values)
  const arrToCheck = fieldsToCheck ? valuesArr.filter(([key]) => fieldsToCheck.includes(key)) : valuesArr

  return arrToCheck.every(([, value]) => {
    const isDate = value instanceof Date || value instanceof Moment
    return isObject(value) && !isDate ? value?.id : value
  })
}

export const sortMessengers = (messengers) => {
  if (!messengers) return
  return messengers.slice().sort()
}
