import React, { memo } from 'react'

import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import cn from 'classnames'

import { getGroupItem } from './utils'

const isEqual = (prevProps, nextProps) => {
  return prevProps.items === nextProps.items && prevProps.currentId === nextProps.currentId
}

const ItemsGroup = memo(
  ({
    type = 'default',
    title = 'title',
    items = [],
    deleteFile,
    currentId,
    halfHeight = false,
    scroll,
    count = 0,
    size,
    isLoading = false,
    onDelete,
    onEdit,
    setMain,
  }) => {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          {title} ({count})
        </div>

        <div className={styles.group} style={{ width: `${size}px` }}>
          <div
            className={cn(styles.wrapper, { [styles.halfHeight]: halfHeight })}
            onScroll={(event) => scroll && scroll(event)}
          >
            {items.map((item) => {
              const GroupItem = getGroupItem(type)

              return (
                <GroupItem
                  key={item.id}
                  type={type}
                  data={item}
                  deleteFile={deleteFile}
                  isActive={currentId && currentId === item.id}
                  isLoading={isLoading}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  setMain={setMain}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  },
  isEqual
)

ItemsGroup.propTypes = {
  title: PropTypes.node.isRequired,
  items: PropTypes.array,
  lastItemId: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default ItemsGroup
