import styles from '../vacancy.module.scss'
import React from 'react'
import { vacancyIconsToRender, vacancyLandIconsToRender } from '../config'
import UseSmartTooltip from '../../../../hooks/useSmartTooltip'

const VacancyIconsInfo = ({ type, vacancy }) => {
  const data = type === 'land' ? vacancyLandIconsToRender(vacancy) : vacancyIconsToRender(vacancy)
  return (
    <>
      {data?.map(({ Icon, value }, index) => (
        <div key={index}>
          <div className={styles.vacancyField} key={index}>
            <div className={styles.vacancyFieldName}>
              <Icon />
            </div>
            <div className={styles.vacancy_field_info}>
              <div className={styles.vacancy_field_tooltip}>{value}</div>
              <UseSmartTooltip className={styles.mainInfo} tagType={'p'} children={value} amount_of_line={1} />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default VacancyIconsInfo
