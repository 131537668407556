import * as Yup from 'yup'

import { getDateByUserFormat, today } from '../../../../helpers/timeHelper'

export const initialValuesForSea = {
  rank: { name: '', id: null },
  vessel: { name: '', id: null },
  vesselType: '',
  engine_type: '',
  shipowner: { name: '', id: null },
  crewing: { name: '', id: null },
  imo: '',
  imo_search: { name: '', id: null },
  country: { name: '', id: null },
  wage: '',
  currency: { name: '', id: null },
  date_from: null,
  date_to: null,
  year_of_build: null,
  reasonEOC: { name: '', id: null },
  description: '',
  experience: '',
  location: { id: 1, name: 'sea' },
}
export const initialValuesForLand = {
  rank: { name: '', id: null },
  country: { name: '', id: null, iso2: '', iso3: '' },
  city: { name: '', id: null },
  company: { name: '', id: null },
  crewing: { name: '', id: null },
  wage: '',
  currency: { name: '', id: null },
  date_from: null,
  date_to: null,
  reasonEOC: { name: '', id: null },
  description: '',
  experience: '',
  location: { id: 2, name: 'land' },
}

export const getValidationSchemaForSea = (dateFormat, fromMinDate) => {
  const onlyPositiveDigits = /^\+?[1-9]\d*$/
  const imoDigits = /^\+?[0-9]\d*$/

  return Yup.object().shape({
    rank: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    vessel: Yup.object().shape({
      id: Yup.mixed().test('Name check', 'Select a vessel name from the list please', function (value) {
        const { name } = this.parent
        if (name) {
          return !!value
        } else return true
      }),
    }),
    shipowner: Yup.object().shape({
      id: Yup.mixed().test('Name check', 'Select shipowner from list', function (value) {
        const { name } = this.parent
        if (name) {
          return !!value
        } else return true
      }),
    }),
    city: Yup.object().shape({
      id: Yup.mixed().test('Name check', 'Select city from list', function (value) {
        const { name } = this.parent
        if (name) {
          return !!value
        } else return true
      }),
    }),
    crewing: Yup.object().shape({
      id: Yup.mixed().test('Name check', 'Select crewing from list', function (value) {
        const { name } = this.parent
        if (name) {
          return !!value
        } else return true
      }),
    }),
    imo_search: Yup.object().shape({
      name: Yup.string()
        .matches(imoDigits, 'Only digits')
        .min(3, 'Min - 3 digits')
        .max(7, 'Limit - 7 digits')
        .nullable(),
    }),
    dateFrom: Yup.date()
      .min(fromMinDate.startOf('day').toDate(), `Min date is ${getDateByUserFormat(fromMinDate.toDate())}`)
      .max(today, `Max date is ${getDateByUserFormat(today)}`)
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
    dateTo: Yup.date()
      .min(Yup.ref('dateFrom'), 'Date of ending of contract should be later than date of begging')
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
    wage: Yup.string()
      .max(6, 'Limit - 6 digits')
      .matches(onlyPositiveDigits, 'Only positive digits')
      .test('Salary check', 'Wage is required', function (value) {
        const { currency } = this.parent
        if (currency.id) {
          return !!value
        } else return true
      }),
    currency: Yup.object().when('wage', (field) => {
      if (field) {
        return Yup.object().shape({
          id: Yup.number().positive().integer().typeError('Currency is required'),
        })
      }
    }),
    experience: Yup.string(),
  })
}
