import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import EnvelopeSvg from '../../UI/Icons/EnvelopeSvg'
import styles from './styles.module.scss'
const ModalAlert = ({ isOk, title, content, signUp }) => {
  return (
    <div className={cn(styles.modal, { [styles.modal_invalid]: isOk === false })}>
      <div className={styles.envelope}>
        <EnvelopeSvg color={isOk ? 'var(--color-accent)' : '#E77A03'} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>

      <div className={styles.cloudFirst} />
      <div className={styles.cloudSecond} />
      <div className={styles.cloudThird} />
      {isOk ? (
        <Link to={signUp ? '/auth/signup' : '/auth/signin'}>{signUp ? 'Sign up' : 'Log in'}</Link>
      ) : (
        <Link to='/'>Main page</Link>
      )}
    </div>
  )
}

ModalAlert.propTypes = {
  isOk: PropTypes.bool.isRequired,
}

export default ModalAlert
