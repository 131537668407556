import React from 'react'
import styles from './createPassword.module.scss'
import GoogleIcon from '../../../assets/icons/g_logo.svg'
import appleLogo from '../../../assets/icons/apple_logo.svg'
import FacebookIcon from '../../../assets/icons/fb_logo.svg'
import { passwordRecoverRequest } from '../../../api/postApi'
import { useSelector } from 'react-redux'
import Button from '../../../UI/Button/Button'
import { useNotify } from '../../../hooks/useNotify'

const CreatePassword = () => {
  const { notify } = useNotify()

  const email = useSelector(({ user }) => user.profile.email)

  const onPasswordResetClick = () => {
    passwordRecoverRequest({ email }, 'create_password')
      .then(() => {
        notify('Success', 'A confirmation letter has been sent to your email!')
      })
      .catch((error) => notify.errorsList(error.errors))
  }

  return (
    <div className={styles.resetInfo}>
      NextShip has no access to your
      <div>
        <img className={styles.google_logo} src={GoogleIcon} alt='google logo' />
        <span>
          <img className={styles.apple_logo} src={appleLogo} alt='apple logo' />
          <span className={styles.apple_text}>Apple ID </span>
        </span>
        or <img className={styles.fb_logo} src={FacebookIcon} alt='facebook logo' />
      </div>
      <p>
        Please click on the button below if you wish to create a separate password for your NextShip profile using the
        e-mail address provided at registration.
      </p>
      <Button onClick={onPasswordResetClick} size='middle'>
        Confirm
      </Button>
    </div>
  )
}

export default CreatePassword
