import React, { useEffect } from 'react'
import { getTokenFromUrl } from '../../helpers/apiHelper'
import { notify } from '../../helpers/notify'
import WithPreloader from '../../HOC/WithPreloader'
import ModalAlert from '../../components/ModalAlert/ModalAlert'
import { postDeleteEmail } from '../../api/postApi'
import { userLogout } from '../../redux/actions/auth'
import { useDispatch } from 'react-redux'

const DeleteAccountEmail = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = React.useState(null)
  const scope = new URL(window.location).searchParams.get('scope')

  useEffect(() => {
    postDeleteEmail(getTokenFromUrl())
      .then(({ status }) => {
        dispatch(userLogout())
        setStatus(status)
      })
      .catch((error) => {
        setStatus(false)
        notify.errorsList(error.errors)
      })
  }, [dispatch])

  return (
    <div className='page' style={{ height: '100%' }}>
      <main>
        <WithPreloader
          component={ModalAlert}
          isActive={status === null}
          size={120}
          type='magnifier'
          isOk={status}
          signUp={true}
          title={status ? 'Thank you!' : 'Oops..'}
          scope={scope}
          content={status ? 'Your account was deleted.' : 'Something went wrong. Link is invalid.'}
        />
      </main>
    </div>
  )
}

export default DeleteAccountEmail
