import { useEffect, useRef, useState } from 'react'

export default function useHeightWithScroll(props) {
  const divRef = useRef(null)
  const [heightWithScroll, setHeightWithScroll] = useState(0)
  const { state } = props
  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const { scrollHeight } = divRef.current
        setHeightWithScroll(scrollHeight)
      }
    }

    const observer = new MutationObserver(handleResize)

    if (divRef.current) {
      observer.observe(divRef.current, { childList: true, subtree: true })
      handleResize()
    }

    return () => {
      observer.disconnect()
    }
  }, [state])
  return { divRef, heightWithScroll }
}
