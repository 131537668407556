import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import VideoAd from "../components/VideoAd/VideoAd";

const VideoAdPage = () => {
  return (
    <div>
      <div className="page">
        <Header />
        <main>
          <VideoAd />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default VideoAdPage;
