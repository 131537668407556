import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const Wrapper = ({ children, className }) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.popup, className)}>{children}</div>
    </div>
  )
}

export default Wrapper
