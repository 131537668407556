import React, { memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'
import { ReactComponent as ViberIcon } from '../../../assets/icons/viber.svg'
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg'
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg'

import Switcher from '../../../UI/Switcher/Switcher'
import Spinner from '../../../UI/Spinner/Spinner'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'

const MESSENGERS_ICONS = [
  { name: 'viber', icon: ViberIcon },
  { name: 'telegram', icon: TelegramIcon },
  { name: 'whatsapp', icon: WhatsAppIcon },
]

const MyContactGroupItem = memo(({ data, onDelete, onEdit, setMain, isActive, isLoading }) => {
  const isGeneralMail = data.id === null && data.header[0] === 'email'
  const isToggleActive = data.is_main || isGeneralMail
  return (
    <>
      <div className={cn(styles.card, { [styles.active]: isActive })}>
        <div className={styles.cardInfo}>
          <div className={styles.contactType}>{data?.header?.map((type) => type)}</div>

          {setMain && (
            <div className={styles.isMain}>
              {data.id !== null ? 'Main' : <span className={styles.isGeneral}>General</span>}

              {data.id !== null && <Switcher onClick={setMain} id={data.id} isActive={isToggleActive} />}
            </div>
          )}

          <UseSmartTooltip
            className={cn(styles.contact, {
              [styles.messengers]: data?.messengers?.length > 0,
            })}
            tagType={'a'}
            typeLink={data?.header[0] === 'email' ? 'email' : data?.header[0] === 'phone' ? 'tel' : 'a'}
            amount_of_line={1}
            children={data.contact}
          />

          {data?.header[0] === 'phone' && data?.messengers?.length > 0 && (
            <div className={styles.contactMessengers}>
              {data.messengers.map((item) => {
                return MESSENGERS_ICONS.map(({ name, icon: Icon }) => item === name && <Icon key={name} />)
              })}
            </div>
          )}

          {data.id !== 'null' && (
            <>
              <div className={styles.cardControls}>
                <button
                  className={cn(styles.cardControl, styles.cardControlEdit)}
                  onClick={() => onEdit(data.id, isActive)}
                >
                  {isActive ? (
                    <span>
                      Close <CloseIcon />
                    </span>
                  ) : (
                    <span>
                      Edit <EditIcon />
                    </span>
                  )}
                </button>

                <button
                  className={cn(styles.cardControl, styles.cardControlDelete)}
                  onClick={() => onDelete(data.id, isActive)}
                >
                  <span>
                    Delete <TrashIcon />
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {isLoading && <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />}
    </>
  )
})

export default MyContactGroupItem
