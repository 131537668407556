import { API_BASE, API_BASE_V2, METHOD } from './constans'
import { customHeaders, request } from './auth.service'
import { notify } from '../helpers/notify'

async function authDeleteRequest(url) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(`${API_BASE}${url}`, {
      method: 'DELETE',
      headers: {
        ...customHeaders,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access_token}`,
      },
    })
    if (!response.ok) {
      notify.errorsList(response.error)
      const json = await response.json()
      if (json.errors) {
        notify.errorsList(json.errors)
      }
    }
    return response
  }
}

export const deleteProfileEducation = async (id) => {
  return await authDeleteRequest(`seafarer/educations/${id}/`)
}

export const deleteProfilePassport = async (id) => {
  return await authDeleteRequest(`seafarer/passports/${id}/`)
}

export const deleteProfileVisa = async (id) => {
  return await authDeleteRequest(`seafarer/visas/${id}/`)
}

export async function deleteRelativeById(id) {
  return await authDeleteRequest(`seafarer/relatives/${id}/`)
}

export async function deleteContactById(id) {
  return await authDeleteRequest(`seafarer/contacts/${id}/`)
}

export const deleteProfileCertificate = async (id) => {
  return await authDeleteRequest(`seafarer/certificates/${id}/`)
}

export const deleteProfileContract = async (id, type) => {
  return await authDeleteRequest(`seafarer/experiences-${type}/${id}/`)
}

export const deleteProfileLicense = async (id) => {
  return await authDeleteRequest(`seafarer/licenses/${id}/`)
}

export async function deleteProfileAddressByType(type) {
  return await authDeleteRequest(`seafarer/addresses/?type=${type}`)
}

export const deleteSeafarerFile = async (id) => {
  return await authDeleteRequest(`seafarer/uploads/${id}/`)
}

export async function deleteFilesByType(type) {
  return await authDeleteRequest(`seafarer/delete-files-by-type/?file_type=${type}`)
}

export async function deleteNotification(id) {
  return await request(`seafarer/events/`, {
    api: API_BASE,
    method: METHOD.DELETE,
    body: JSON.stringify({
      events: [...id],
    }),
  })
}

export const deleteVacancyRequest = async (id) => {
  return await authDeleteRequest(`seafarer/vacancy-requests/${id}`)
}

// BILLING

export const deleteBillingPaymentIntents = async (id) => {
  return await request(`billing/payment-intents/${id}/`, {
    api: API_BASE_V2,
    method: METHOD.DELETE,
  })
}

export const deleteEmailSubscription = async (id) => {
  return await request(`billing/email-subscription/${id}/`, {
    api: API_BASE_V2,
    method: METHOD.DELETE,
  })
}

// BILLING
