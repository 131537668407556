import React from 'react'

import styles from './styles.module.scss'

const SocialMedia = ({ Icon, label, link, name }) => {
  const handleClick = () => {
    window.open(link, 'blank')
  }

  return (
    <button className={styles.wrapper} onClick={handleClick}>
      <Icon className={styles.icon} />

      <div className={styles.description}>
        <span className={styles.link}>{label}</span>
        <span className={styles.text}>{`Join our ${name} group`}</span>
      </div>
    </button>
  )
}

export default SocialMedia
