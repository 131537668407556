import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'
import mainStyles from '../../styles.module.scss'

import Spinner from '../../../../../../UI/Spinner/Spinner'

import { useNotify } from '../../../../../../hooks/useNotify'
import { getServiceDataByCategory } from '../../../../../../api/getApi'
import { viewEvent } from '../../../utils'
import { profilePaths } from '../../../../../../constants/constans'
import { action, category, serviceMap } from '../../../constants'
import { setServiceData } from '../../../../../../redux/billing/billing.action'

const EarlyAccessNewVacancies = () => {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const { notify } = useNotify()

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const getUnreadVacancies = async () => {
      try {
        const { count } = await getServiceDataByCategory(1, '', serviceMap.earlyAccess, category.unread)

        setCount(count)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getUnreadVacancies()

    // eslint-disable-next-line
  }, [])

  const handleClick = () => {
    history.push(profilePaths.vacancies)

    viewEvent(action.info, serviceMap.earlyAccess)

    // set the data: {} after checking the service event,
    // so that there is no function getServiceData call, it should be called once, when initializing the application
    dispatch(setServiceData({ service: serviceMap.earlyAccess, data: {} }))
  }

  return (
    <>
      {loading && <Spinner className={styles.spinner} />}

      {!loading && (
        <div className={mainStyles.description}>
          <p className={mainStyles.description_item}>
            Attention! <span>{count}</span> new vacancies have appeared in the
            <button type='button' onClick={handleClick} className={styles.vacancySection}>
              vacancies section!
            </button>
            &nbsp; Hurry up to apply before others
          </p>
        </div>
      )}
    </>
  )
}

export default EarlyAccessNewVacancies
