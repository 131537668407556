import { CHAT } from '../types'
import { createAction } from '../store'
import { notify } from '../../helpers/notify'

const getCurrentUserId = () => {
  return window.location.href.split('/chat/')[1]
}

export const startPaginationLoading = createAction(CHAT.START_PAGINATION_LOADING)

export const updateMessage = (payload) => ({
  type: CHAT.UPDATE_MESSAGE,
  payload,
})

export const clearChatRoomTotalUndelivered = (payload) => ({
  type: CHAT.CLEAR_ROOM_TOTAL_UNDELIVERED,
  payload,
})

export const deleteMessage = (chat_id, message_id, total_undelivered) => ({
  type: CHAT.DELETE_MESSAGE,
  payload: { chat_id, message_id, total_undelivered },
})

export const editMessageStart = (id) => ({
  type: CHAT.EDIT_MESSAGE_START,
  payload: { id },
})

export const replyMessageStart = (id) => ({
  type: CHAT.REPLY_MESSAGE_START,
  payload: { id },
})

export const changeMessageValue = (value) => ({
  type: CHAT.CHANGE_MESSAGE_VALUE,
  payload: { value },
})

export const actionMessageClose = () => ({
  type: CHAT.ACTION_MESSAGE_CLOSE,
})

export const setNewMessage = (payload) => {
  const currentUser = getCurrentUserId()

  return {
    type: CHAT.NEW_MESSAGE,
    payload,

    current_user: currentUser ? Number(currentUser) : null,
  }
}

export const setNextPageMessages = (payload) => ({
  type: CHAT.SET_NEXT_PAGE_MESSAGES,
  payload,
})

export const setMessages = (payload, chat_id) => ({
  type: CHAT.SET_MESSAGES,
  payload,
  chat_id,
})

export const setDialogs = (payload) => ({
  type: CHAT.SET_DIALOGS,
  payload: payload.chats.map((room) => {
    return {
      ...room,
      last_message: room.last_message
        ? {
            ...room.last_message,
            date: new Date(room.last_message.date),
          }
        : null,
    }
  }),
})

export const setIsDialogChecked = (payload) => ({
  type: CHAT.SET_IS_DIALOG_CHECKED,
  payload,
})

export const deleteSelected = () => ({
  type: CHAT.DELETE_SELECTED,
})

export const setAllSelected = () => ({
  type: CHAT.SET_ALL_SELECTED,
})

export const setAllUnselected = () => ({
  type: CHAT.SET_ALL_UNSELECTED,
})

export const restoreChatRoom = (payload) => ({
  type: CHAT.RESTORE_ROOM,
  payload,
})

export const chatLastMessageUpdate = (payload) => ({
  type: CHAT.LAST_MESSAGE_WAS_UPDATED,
  payload,
})

export const setGlobalUndelivered = (payload) => {
  const user_id = Number(getCurrentUserId())

  return {
    type: CHAT.SET_GLOBAL_UNDELIVERED,
    payload: {
      ...payload,
      user_id,
    },
  }
}

export const setEditMessageError = (message) => {
  notify.error('Error', message)

  return {
    type: CHAT.SET_EDIT_MESSAGE_ERROR,
  }
}

export const setDeleteMessageError = (message) => {
  notify.error('Error', message)

  return {
    type: CHAT.SET_DELETE_MESSAGE_ERROR,
  }
}

export const searchChatMessage = (payload) => ({
  type: CHAT.SEARCH_CHAT_MESSAGE,
  payload,
})

export const deleteRoomFromList = (chat_id) => ({
  type: CHAT.DELETE_ROOM,
  payload: { chat_id },
})
