const useTestWebP = () => {
  function testWebP(callback) {
    const webP = new Image()
    webP.onload = webP.onerror = function () {
      callback(webP.height === 2)
    }
    webP.src =
      'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
  }

  testWebP(function (support) {
    if (support === true) {
      document.querySelector('body').dataset.webp = 'webp'
    } else {
      document.querySelector('body').dataset.webp = 'no-webp'
    }
  })
}

export { useTestWebP }
