import React, { useEffect } from 'react'
import cn from 'classnames'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthProfileFilters } from '../../../api/getApi'
import { salaryCurrenciesHelper, vacanciesFormSearchHelper } from '../../../helpers/vacanciesSearchHelper'
import KeyBoardDatePicker from '../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import SelectSearch from '../../../UI/Select/SelectSearch'
import Select from '../../../UI/Select/Select'
import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'
import styles from './vacancyForm.module.scss'
import { clearFilters, setFilters } from '../../../redux/actions/vacancies.action'
import { getValidationSchema, initialValues, vacancyType } from './config'

const WORK_LOCATION = [
  { name: 'Shore-Based Job', id: vacancyType.land },
  { name: 'Onboard Job', id: vacancyType.sea },
]

const VacancyForm = ({ setExcludeId }) => {
  const dispatch = useDispatch()
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const [stateFilters, setStateFilters] = React.useState({
    ranks: [],
    vesselTypes: [],
    currencies: [],
    rates: [],
    durationPeriod: [],
  })

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(dateFormat),

    onSubmit: (values, actions) => {
      dispatch(setFilters(vacanciesFormSearchHelper(values)))

      actions.setSubmitting(false)

      setExcludeId(null)
    },
  })

  const { values, errors, touched } = formik

  useEffect(() => {
    getAuthProfileFilters('rank', 'vessel_type', 'currency', 'salary_rate').then((data) => {
      setStateFilters({
        ranks: data.rank,
        vesselTypes: data.vessel_type,
        currencies: salaryCurrenciesHelper(data.currency),
        rates: data.salary_rate,
        durationPeriod: data.salary_rate,
      })
    })

    return () => {
      clearFiltersForm()

      dispatch(clearFilters())
    }

    // eslint-disable-next-line
  }, [])

  const handleInputChange = (value) => {
    if (!formik.touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const handleSelect = (type, data) => {
    if (!data.name) {
      if (type === 'vesselType' || type === 'rank') {
        data = { name: 'All', id: null }
      } else if (type === 'salaryCurrency') {
        data = { name: '–', id: null }
      } else {
        data = { name: 'None', id: null }
      }
    }

    handleInputChange({ target: { value: data, name: type } })
  }

  const clearFiltersForm = () => {
    formik.resetForm()
  }

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.formWrapper}>
        <div className={styles.formBody}>
          <div className={styles.formField}>
            <p className={styles.formFieldLabel}>rank</p>

            <SelectSearch
              name='rank'
              defaultName='All'
              onClick={handleSelect}
              id={values.rank?.id}
              selectedItem={values.rank?.name}
              items={stateFilters?.ranks}
            />
          </div>

          <div className={styles.formField}>
            <p className={styles.formFieldLabel}>vessel type</p>

            <SelectSearch
              name='vesselType'
              defaultName='All'
              onClick={handleSelect}
              id={values.vesselType?.id}
              selectedItem={values.vesselType?.name}
              items={stateFilters?.vesselTypes}
            />
          </div>

          <div className={styles.formGroupFields}>
            <p className={styles.formFieldLabel}>salary (from)</p>

            <div className={cn(styles.formGroup, { [styles.salary]: true })}>
              <div className={styles.formField}>
                <Input
                  name='salary'
                  value={values.salary}
                  onChange={handleInputChange}
                  isInvalid={!!(errors.salary && touched.salary)}
                />

                {errors.salary && touched.salary && <p className={styles.formFieldError}>{errors.salary}</p>}
              </div>

              <div className={styles.formField}>
                <Select
                  name='salaryRate'
                  defaultName=''
                  onClick={handleSelect}
                  id={values.salaryRate?.id}
                  selectedItem={values.salaryRate?.name}
                  items={stateFilters?.rates}
                  isInvalid={!!(errors.salaryRate?.id && touched.salaryRate)}
                />

                {errors.salaryRate?.id && touched.salaryRate && (
                  <p className={styles.formFieldError}>{errors.salaryRate?.id}</p>
                )}
              </div>

              <div className={styles.formField}>
                <Select
                  name='salaryCurrency'
                  defaultName='—'
                  onClick={handleSelect}
                  id={values.salaryCurrency?.id}
                  selectedItem={values.salaryCurrency?.name}
                  items={stateFilters?.currencies}
                  isInvalid={!!errors.salaryCurrency?.id && touched.salaryCurrency}
                />

                {errors.salaryCurrency?.id && touched.salaryCurrency && (
                  <p className={styles.formFieldError}>{errors.salaryCurrency?.id}</p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.formGroupFields}>
            <p className={styles.formFieldLabel}>duration (from)</p>

            <div className={cn(styles.formGroup, { [styles.duration]: true })}>
              <div className={styles.formField}>
                <Input
                  name='duration'
                  value={values.duration}
                  onChange={handleInputChange}
                  isInvalid={!!(errors.duration && touched.duration)}
                />

                {errors.duration && touched.duration && <p className={styles.formFieldError}>{errors.duration}</p>}
              </div>

              <div className={styles.formField}>
                <Select
                  name='durationPeriod'
                  defaultName=''
                  onClick={handleSelect}
                  id={values.durationPeriod?.id}
                  selectedItem={values.durationPeriod?.name}
                  items={stateFilters?.durationPeriod}
                  isInvalid={!!errors.durationPeriod?.id && touched.durationPeriod}
                />

                {errors.durationPeriod?.id && touched.durationPeriod && (
                  <p className={styles.formFieldError}>{errors.durationPeriod?.id}</p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.formGroupFields}>
            <p className={styles.formFieldLabel}>join date (from)</p>

            <div className={cn(styles.formGroup, { [styles.joinDate]: true })}>
              <div className={styles.formField}>
                <KeyBoardDatePicker
                  formik={formik}
                  value={values.joinDate}
                  nameOfValue='joinDate'
                  isInvalid={!!(errors.joinDate || (errors.joinDate && touched.joinDate))}
                  errorMessage={errors.joinDate}
                  today={true}
                  viewFormat={'spacious'}
                />
              </div>
            </div>
          </div>

          <div className={styles.formField}>
            <p className={styles.formFieldLabel}>work location</p>

            <Select
              name='workLocation'
              id={values.workLocation?.id}
              defaultName='All'
              onClick={handleSelect}
              selectedItem={values.workLocation?.name}
              items={WORK_LOCATION}
            />
          </div>
        </div>

        <div className={styles.formFooter}>
          <Button type='submit' size='middle-full' theme='bordered'>
            Search
          </Button>

          <button onClick={clearFiltersForm} className={styles.clearButton} type='button'>
            Clear filters
          </button>
        </div>
      </div>
    </form>
  )
}

export default VacancyForm
