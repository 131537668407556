import React from 'react'

export const MailSvg = ({ width = '24', height = '16', fill= '#43927C' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.22508 0.25C1.88596 0.25 1.57472 0.351877 1.30858 0.519529L11.1554 9.11776C11.6758 9.57239 12.3053 9.57239 12.8265 9.11776L22.6913 0.510801C22.4261 0.344751 22.1121 0.250298 21.7747 0.250298H2.22492L2.22508 0.25ZM0.598869 1.41796C0.539105 1.59323 0.5 1.77873 0.5 1.97488V13.4748C0.5 14.4303 1.26926 15.1999 2.22509 15.1999H21.7749C22.7305 15.1999 23.5 14.4306 23.5 13.4748V1.97488C23.5 1.77875 23.4609 1.59325 23.4011 1.41775L13.581 9.98877C12.6662 10.7867 11.3153 10.7789 10.4007 9.97995L0.598869 1.41796Z"
                fill={fill}/>
        </svg>
    )
}