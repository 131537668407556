import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StoreIcon } from './icons/store.svg'

import { profilePaths } from '../../../../constants/constans'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'

const Store = () => {
  const location = useLocation()
  const history = useHistory()

  const handleClick = () => {
    history.push(profilePaths.billing_services)
  }

  const isActive = location.pathname === profilePaths.billing_services

  return (
    <button onClick={handleClick} className={cn(styles.storeIconWrapper, { [styles.active]: isActive })}>
      <StoreIcon />

      <span className={styles.title}>store</span>
    </button>
  )
}

export default Store
