import * as Yup from 'yup'

// const digitsOnly = (value) => {
//   return value === undefined ? true : /^\d*$/.test(value)
// }
const errorMsg = 'Max length - 5 characters'
// const zipErrorMsg = 'Must be exactly 5 digits'

export const initialValuesAddress = {
  residence_country: { name: '', id: null },
  residence_city: { name: '', id: null },
  residence_address: { name: '', id: null },
  residence_apartment: '',
  residence_zip: '',
  residence_house: '',
  is_same: false,

  birth_country: { name: '', id: null },
  birth_city: { name: '', id: null },
  birth_address: { name: '', id: null },
  birth_apartment: '',
  birth_zip: '',
  birth_house: '',
}

export const validationSchemaAddress = Yup.object().shape({
  // residence_zip: Yup.string()
  //   .test('Digits only', 'The field should have digits only', digitsOnly)
  //   .min(5, zipErrorMsg)
  //   .max(5, zipErrorMsg),
  // birth_zip: Yup.string()
  //   .test('Digits only', 'The field should have digits only', digitsOnly)
  //   .max(5, zipErrorMsg)
  //   .min(5, zipErrorMsg),
  residence_apartment: Yup.string().max(5, errorMsg),
  residence_house: Yup.string().max(5, errorMsg),
  birth_apartment: Yup.string().max(5, errorMsg),
  birth_house: Yup.string().max(5, errorMsg),
  residence_city: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select city from list', function (value) {
      const { name } = this.parent
      if (name) {
        return !!value
      } else return true
    }),
  }),
  birth_city: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select city from list', function (value) {
      const { name } = this.parent
      if (name) {
        return !!value
      } else return true
    }),
  }),
  residence_address: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select street from list', function (value) {
      const { name } = this.parent
      if (name) {
        return !!value
      } else return true
    }),
  }),
  birth_address: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select street from list', function (value) {
      const { name } = this.parent
      if (name) {
        return !!value
      } else return true
    }),
  }),
})

export const initialValuesAnthropometry = {
  weight: '',
  foot_size: '',
  height: '',
  collar: '',
  waist: '',
  hair_color: { name: '', id: null },
  eye_color: { name: '', id: null },
  suit_size: null,
}

export const validationSchemaAnthropometry = Yup.object().shape({
  weight: Yup.number()
    .typeError('you must specify a number')
    .min(40, 'Weight must be more than 40kg')
    .max(150, 'Weight must be less than 150kg'),
  foot_size: Yup.number()
    .typeError('you must specify a number')
    .min(35, 'Foot size must be more than 35')
    .max(49, 'Foot size must be less than 49'),
  collar: Yup.number()
    .typeError('you must specify a number')
    .min(5, 'Collar size must be more than 5')
    .max(60, 'Collar size must be less than 60 cm'),
  waist: Yup.number()
    .typeError('you must specify a number')
    .min(40, 'Waist size must be more than 40 cm')
    .max(150, 'Waist size must be less than 150 cm'),
  height: Yup.number()
    .typeError('you must specify a number')
    .min(120, 'Height must be more than 120 cm')
    .max(230, 'Height must be less than 230 cm'),
})

export const isSameValues = (values) => {
  return (
    values.residence_address?.id === values.birth_address?.id &&
    values.residence_country?.id === values.birth_country?.id &&
    values.residence_city?.id === values.birth_city?.id &&
    values.residence_house === values.birth_house &&
    values.residence_apartment === values.birth_apartment &&
    values.residence_zip === values.birth_zip
  )
}
