import { CHAT } from '../types'
import { operationType } from '../../constants/constans'

const initialState = {
  last_chat_id: null,
  global_undelivered: 0,
  pagination: null,
  dialogs: {
    loading: true,
    list: [],
  },
  open_dialog: null,
  company_user_email: null,
  message_action: {
    isActive: false,
    text: '',
    id: null,
    operation: '',
    isMessageDelete: false,
  },
  message: '',
}

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT.NEW_MESSAGE: {
      const { chat_id, message, date, is_outgoing, total_undelivered, id, user, sender_type } = action.payload
      const { current_user } = action
      const SENDER_TYPE_SEAFARER = 'seafarer'

      return {
        ...state,
        last_chat_id: chat_id,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list
            .map((item) => {
              if (item.chat_id !== chat_id) return item

              return {
                ...item,
                last_message: {
                  message,
                  date,
                  is_outgoing,
                  id,
                },
                total_undelivered: current_user === chat_id ? 0 : total_undelivered,
                user_name: SENDER_TYPE_SEAFARER === sender_type ? item.user_name : user.name,
              }
            })
            .sort((a, b) => b.last_message?.date - a.last_message?.date),
        },
        open_dialog:
          current_user === chat_id
            ? {
                ...state.open_dialog,
                messages:
                  // if message from current user or outgoing, will show it in open dialog
                  [{ ...action.payload }, ...(state.open_dialog?.messages ?? [])],
                // if new message from another user, return old messages list
                interlocutor:
                  SENDER_TYPE_SEAFARER === sender_type ? { ...state.open_dialog.interlocutor } : { ...user },
              }
            : state.open_dialog
            ? { ...state.open_dialog }
            : null,
      }
    }

    case CHAT.SET_MESSAGES: {
      const { user, messages, has_more, total, reverse, company_user_email } = action.payload

      return {
        ...state,
        open_dialog: {
          interlocutor: user,
          messages: !messages.length ? state.open_dialog?.messages ?? [] : messages.reverse(),
        },
        company_user_email,
        pagination: {
          has_more_up: !reverse ? has_more : state.pagination?.has_more_up,
          has_more_down: reverse ? has_more : state.pagination?.has_more_down,
          total,
          loading: false,
        },
      }
    }

    case CHAT.SET_NEXT_PAGE_MESSAGES: {
      const { user, messages, has_more, total, reverse, company_user_email } = action.payload
      const result = !reverse
        ? [...state.open_dialog.messages, ...messages.reverse()]
        : [...messages.reverse(), ...state.open_dialog.messages]

      return {
        ...state,
        company_user_email,
        open_dialog: {
          interlocutor: user,
          messages: result,
        },
        pagination: {
          has_more_up: !reverse ? has_more : state.pagination?.has_more_up,
          has_more_down: reverse ? has_more : state.pagination?.has_more_down,
          total,
          loading: false,
        },
      }
    }

    case CHAT.SET_DIALOGS:
      return {
        ...state,
        dialogs: {
          loading: false,
          list: action.payload.map((item) => ({
            ...item,
            is_selected: false,
            is_active: false,
            unread_count: 0,
          })),
        },
      }

    case CHAT.SET_IS_DIALOG_CHECKED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((dialog) => ({
            ...dialog,
            is_selected: dialog.chat_id === action.payload ? !dialog.is_selected : dialog.is_selected,
          })),
        },
      }

    case CHAT.SET_GLOBAL_UNDELIVERED: {
      const { other, user_id, total_undelivered } = action.payload

      const result = state.last_chat_id === user_id ? state.global_undelivered : other || total_undelivered

      return {
        ...state,
        global_undelivered: result,
      }
    }

    case CHAT.RESTORE_ROOM:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: [{ ...action.payload, is_selected: false, is_active: false }, ...state.dialogs.list],
        },
      }

    case CHAT.DELETE_SELECTED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.filter((el) => !el.is_selected),
        },
      }

    case CHAT.SET_ALL_SELECTED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((dialog) => ({
            ...dialog,
            is_selected: true,
          })),
        },
      }

    case CHAT.SET_ALL_UNSELECTED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((dialog) => ({
            ...dialog,
            is_selected: false,
          })),
        },
      }

    case CHAT.DELETE_MESSAGE: {
      const { chat_id, message_id, total_undelivered } = action.payload

      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((item) => {
            if (item.chat_id === chat_id) {
              return {
                ...item,
                total_undelivered,
              }
            }

            return item
          }),
        },
        open_dialog: {
          ...state.open_dialog,
          messages: state.open_dialog?.messages
            ?.filter((message) => message.id !== message_id)
            .map((message) => {
              if (message.reply?.id === message_id) {
                return {
                  ...message,
                  reply: null,
                }
              }

              return message
            }),
        },
        message_action: {
          ...state.message_action,
          isMessageDelete: state.message_action.id === message_id,
        },
      }
    }

    case CHAT.EDIT_MESSAGE_START: {
      const message = state.open_dialog.messages.find((message) => message.id === action.payload.id).message

      return {
        ...state,

        message_action: {
          ...state.message_action,

          isActive: true,
          id: action.payload.id,
          text: message,
          operation: operationType.edit,
        },
        message,
      }
    }

    case CHAT.REPLY_MESSAGE_START: {
      const message = state.open_dialog.messages.find((message) => message.id === action.payload.id).message
      const isPrevOperationEdit = state.message_action.operation === operationType.edit

      return {
        ...state,

        message_action: {
          ...state.message_action,

          isActive: true,
          id: action.payload.id,
          text: message,
          operation: operationType.reply,
        },
        message: isPrevOperationEdit ? '' : state.message,
      }
    }

    case CHAT.CHANGE_MESSAGE_VALUE: {
      const { value } = action.payload

      return {
        ...state,
        message: value,
      }
    }

    case CHAT.ACTION_MESSAGE_CLOSE:
      return {
        ...state,
        message_action: {
          ...initialState.message_action,
        },
      }

    case CHAT.UPDATE_MESSAGE:
      return {
        ...state,
        open_dialog: {
          ...state.open_dialog,
          messages: state.open_dialog.messages.map((message) => {
            if (message.id === action.payload.id) {
              return {
                ...message,
                edited: true,
                message: action.payload.message,
              }
            }

            if (message.reply?.id === action.payload.id) {
              return {
                ...message,
                reply: { ...message.reply, message: action.payload.message },
              }
            }

            return message
          }),
        },
      }

    case CHAT.CLEAR_ROOM_TOTAL_UNDELIVERED: {
      const { chat_id } = action.payload

      const activeChatUndelivered = state.dialogs.list.find((room) => room.chat_id === chat_id)?.total_undelivered

      return {
        ...state,
        global_undelivered: state.global_undelivered - activeChatUndelivered,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((room) => {
            if (room.chat_id === chat_id) {
              return {
                ...room,
                total_undelivered: 0,
              }
            }

            return room
          }),
        },
      }
    }

    case CHAT.LAST_MESSAGE_WAS_UPDATED: {
      const { chat_id } = action.payload

      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.map((item) => {
            if (item.chat_id === chat_id) {
              return {
                ...item,
                last_message: { ...action.payload },
              }
            }

            return item
          }),
        },
      }
    }

    case CHAT.START_PAGINATION_LOADING:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          loading: true,
        },
      }

    case CHAT.SEARCH_CHAT_MESSAGE: {
      const { messages, has_more_reverse, has_more, message_id } = action.payload

      return {
        ...state,
        open_dialog: {
          ...state.open_dialog,
          messages: messages.reverse(),
          searchMessageId: message_id,
        },
        pagination: {
          loading: false,
          has_more_up: has_more,
          has_more_down: has_more_reverse,
        },
      }
    }

    case CHAT.DELETE_ROOM: {
      const { chat_id } = action.payload

      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          list: state.dialogs.list.filter((item) => item.chat_id !== chat_id),
        },
      }
    }

    default:
      return state
  }
}
