import React from 'react'
import styles from './VideoResume.module.scss'
import VideoResumeUploader from '../VideoResumeUploader/VideoResumeUploader'
import { ReactComponent as AlertZing } from '../../assets/icons/alarm-zing.svg'

const VideoResume = () => {
  return (
    <section className={styles.videoResume}>
      <div className={styles.description_block}>
        <AlertZing className={styles.alert_icon} />
        <div className={styles.description_text}>
          <p style={{ fontWeight: 500 }}>
            Practice shows that a video resume is viewed with great interest and characterizes the applicant as a modern
            and active person. Like the usual resume in text form, the video resume has its own rules for writing.
          </p>
          <h3 className={styles.title}>Creativity</h3>
          <p>
            Perhaps the most important and important question when creating your own video presentation is "what to
            tell?" Write a short essay about yourself in free form. Give examples of interesting problems that you
            worked on and tell us how you managed to solve them outside the box. Jobseekers who are starting their
            career can voice their practical skills and academic achievements. You can also share your opinion on trends
            in the industry in general. In the essay, you can mention your hobbies and even joke on professional topics,
            but do not get too carried away: your main task is to reveal yourself as a professional.
          </p>
          <h3 className={styles.title}>Brevity</h3>
          <p style={{ fontWeight: 500 }}>Your video presentation should be from 30 sec. up to 1.30 min.</p>
          <h3 className={styles.title}>Organic</h3>
          <p>
            You need to think about - the video sequence. The easiest option is to be at your desk or while walking in
            the park. However, it all depends on your imagination and relevance. Another important point is your
            pronunciation. It is better to learn the text by heart, and not read it from a piece of paper. Do not be
            lazy and check with the dictionary, if you put the stress in specific words correctly.
          </p>
          <p style={{ paddingTop: 20 }}>
            Imagine your friends or family looking at your resume. If you are confused by this thought, do not submit
            it.
          </p>
        </div>
      </div>
      <VideoResumeUploader />
    </section>
  )
}

export default VideoResume
