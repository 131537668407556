import { CONTACTS } from '../types'

export const setAuthContacts = (payload) => {
  return {
    type: CONTACTS.SET_AUTH_CONTACTS,
    payload,
  }
}

export const addAuthContact = (contact) => {
  return {
    type: CONTACTS.ADD_AUTH_CONTACT,
    payload: contact,
  }
}

export const deleteAuthContact = (id) => {
  return {
    type: CONTACTS.DELETE_AUTH_CONTACT,
    payload: id,
  }
}

export const editAuthContact = (id, contact) => {
  return {
    type: CONTACTS.EDIT_AUTH_CONTACT,
    payload: {
      id,
      contact,
    },
  }
}

export const setMainContact = (id, type) => {
  return {
    type: CONTACTS.SET_MAIN_CONTACT,
    payload: {
      id,
      type,
    },
  }
}

export const clearAuthContacts = () => {
  return {
    type: CONTACTS.CLEAR_AUTH_CONTACTS,
  }
}

export const setAuthRelativesContacts = (payload) => {
  return {
    type: CONTACTS.SET_RELATIVES_CONTACTS,
    payload,
  }
}

export const addRelativesContact = (contact) => {
  return {
    type: CONTACTS.ADD_RELATIVES_CONTACT,
    payload: contact,
  }
}

export const deleteRelativesContact = (id) => {
  return {
    type: CONTACTS.DELETE_RELATIVES_CONTACT,
    payload: id,
  }
}

export const editRelativesContact = (id, contact) => {
  return {
    type: CONTACTS.EDIT_RELATIVES_CONTACT,
    payload: {
      id,
      contact,
    },
  }
}

export const setMainRelativesContact = (id, isActive) => {
  return {
    type: CONTACTS.SET_MAIN_RELATIVES_CONTACT,
    payload: {
      id,
      isActive,
    },
  }
}

export const clearAuthRelativesContacts = () => {
  return {
    type: CONTACTS.CLEAR_RELATIVES_CONTACTS,
  }
}
