import React from 'react'
import axios from 'axios'
import styles from './styles.module.scss'
import Button from '../../UI/Button/Button'
import videoSvg from '../../assets/icons/video-camera.svg'
import ButtonFileUploader from '../FileUploader/ButtonFileUploader'

import { getVideoResume, getVideoResumeStatus } from '../../api/getApi'
import { createFormDataForFiles } from '../../api/postApi'
import { deleteFilesByType } from '../../api/deleteApi'

// import Spinner from '../../UI/Spinner/Spinner'
import socket from '../../websocket'

import { notify } from '../../helpers/notify'
import cn from 'classnames'
import { API_BASE } from '../../api/constans'
import { getUserTimeZone } from '../../helpers/timeHelper'
// import { ReactComponent as Close } from '../../assets/icons/close-accent-color.svg'
import { confirm } from '../../UI/Confirm/Confirm'

const VideoResumeUploader = () => {
  const [videoUrl, setVideoUrl] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [isOnConvert, setIsOnConvert] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [cancelToken, setCancelToken] = React.useState(null)
  const [jobId, setJobId] = React.useState(null)
  const [isButtonCancelDisabled, setIsButtonCancelDisabled] = React.useState(false)

  React.useEffect(() => {
    getVideoResumeStatus().then(({ video_converted, video_exist, video_task }) => {
      if (!video_converted && video_exist) {
        setIsOnConvert(true)
        setProgress(99)
        setJobId(video_task)
      }
    })
    getVideoResume().then((data) => {
      if (data.count !== 0) {
        setVideoUrl(data.results)
      }
    })
  }, [])

  React.useEffect(() => {
    if (socket && jobId) {
      socket.emit('get.video.resume.status', { job_id: jobId })
    }

    if (socket) {
      socket.onmessage = (action) => {
        const { event, data } = JSON.parse(action.data)

        switch (event) {
          case 'video.resume.ready':
            setIsOnConvert(false)
            setVideoUrl(data)
            setProgress(0)
            setLoading(false)
            setCancelToken(null)
            setJobId(null)
            notify('Success', 'Video resume was successfully converted.')
            break
          case 'video.resume.status':
            setIsButtonCancelDisabled(false)
            if (data.video_status === 'Canceled') {
              setIsOnConvert(false)
              setProgress(0)
              setLoading(false)
              notify('Success', 'Video resume was successfully deleted.')
            }
            break
          default:
            return
        }
      }
    }
  }, [jobId])
  const handleUpload = ({ target }) => {
    setJobId(null)
    const token = JSON.parse(localStorage.getItem('user'))
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
      'X-User-Time-Zone': getUserTimeZone(),
      'X-User-Agent': 'web',
    }
    const cancel = axios.CancelToken.source()
    setCancelToken(cancel)

    const size = target.files[0].size > 250000000
    if (token && !size) {
      setProgress(0)
      setLoading(true)
      const url = `${API_BASE}seafarer/upload-transcoder/`
      const config = {
        headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total)
          setProgress(percentCompleted)
        },
        cancelToken: cancel.token,
      }

      const formDate = createFormDataForFiles(target.files[0])
      axios
        .post(url, formDate, config)
        .then((response) => {
          setJobId(response.data.video_task)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            err.response.data.errors.forEach(({ message }) => notify.error('Error', message[0]))
          } else {
            notify.error('Error', err.message)
          }
        })
    } else {
      notify.error('Error', 'Please do not use a video file larger than 250 MB')
    }
  }

  const handleCancelUpload = () => {
    setIsButtonCancelDisabled(true)
    if (!jobId && !!cancelToken) {
      confirm('Are you sure?', 'Do you want to cancel this upload?', () => {
        cancelToken.cancel('Upload canceled by user')
        setIsButtonCancelDisabled(false)
      })
    } else if (jobId) {
      confirm('Are you sure?', 'Do you want to cancel this upload?', () => {
        socket.emit('cancel.video.resume', { job_id: jobId })
      })
      setIsButtonCancelDisabled(false)
    }
  }

  const handleDelete = () => {
    confirm('Are you sure?', 'Do you want to delete this video?', () => {
      deleteFilesByType('video').then((data) => {
        if (data.ok) {
          setVideoUrl(null)
          notify('Success', 'video was successfully deleted.')
        } else {
          data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
        }
      })
    })
  }

  return (
    <div className={styles.block}>
      <div className={cn(styles.block_video, { [styles.uploading]: loading || isOnConvert })}>
        {videoUrl && !isOnConvert && !loading ? (
          <video controls className={cn(styles.video, { [styles.onConvert]: isOnConvert || loading })}>
            {videoUrl.map(({ id, file, file_name }) => (
              <source key={id} src={file} type={`video/${file_name.split('.')[1]}`} />
            ))}
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className={cn(styles.emptyVideo, { [styles.onConvert]: isOnConvert || loading })}>
            {/*{isOnConvert ? (*/}
            {/*  <div className={styles.convertStatus}>*/}
            {/*    <div>Video on conversion</div>*/}
            {/*    <div className={styles.convertStatusSmall}>Video conversion lasts from 1 min to 5 min</div>*/}
            {/*  </div>*/}
            {/*) : (*/}
            <div className={styles.icon}>
              <img src={videoSvg} alt='video camera' />
            </div>
            {/*)}*/}
          </div>
        )}
        {(loading || isOnConvert) && (
          <>
            <progress value={progress} max={100} className={styles.progressUpload} />
            <div className={styles.block_upload}>
              <p className={styles.uploading_info}>uploading {progress}%</p>
              <Button
                theme='text'
                disabled={isButtonCancelDisabled}
                className={styles.abort_button}
                onClick={handleCancelUpload}
              ></Button>
            </div>
          </>
        )}
      </div>
      {!videoUrl && !loading && !isOnConvert && (
        <div className={styles.formats}>
          Please use formats: <b>mp4, avi, mov, mpg, mpeg</b>
          <br />
          Please do not use a video file larger than <b>250 MB</b>
        </div>
      )}

      <div className={cn(styles.controls, { [styles.controlsDelete]: videoUrl })}>
        {videoUrl && !loading && !isOnConvert && (
          <Button
            className={styles.deleteVideo}
            disabled={loading || isOnConvert}
            onClick={handleDelete}
            size='middle'
            theme='bordered'
          >
            Delete video
          </Button>
        )}
        {!loading && !isOnConvert && (
          <ButtonFileUploader
            className={styles.uploaderVideo}
            text={videoUrl ? 'Change video' : 'Add video'}
            accept='video/mov, .mov, video/avi, .avi, video/mp4, video/mpg, .mpg, video/mpeg, .mpeg'
            onChange={handleUpload}
            disabled={loading || isOnConvert}
            name='video-resume'
          />
        )}
      </div>
    </div>
  )
}

export default VideoResumeUploader
