import React, { memo, useState } from 'react'

import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as AlarmZingIcon } from '../../assets/icons/alarm-zing.svg'
import { ReactComponent as AddFileIcon } from '../../assets/icons/add_file_icon.svg'

import Button from '../../UI/Button/Button'

import { postProcessingDocumentFile } from '../../api/postApi'
import { notify } from '../../helpers/notify'
import { isValidFile } from '../../helpers/documents.helper'
import Spinner from '../../UI/Spinner/Spinner'

const EXTENSIONS = ['.jpeg', '.png', '.pdf', '.jpg']

const BasicDropzone = memo(({ children, type, update, className }) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const onDropAccepted = async (acceptedFiles) => {
    if (acceptedFiles[0].size / 1048576 > 30) {
      notify.error('The maximum file size that can be uploaded is 30mb!')
      return
    }

    setLoading(true)

    if (isValidFile(acceptedFiles[0], EXTENSIONS) !== -1) {
      try {
        for (const file of acceptedFiles) {
          const response = await postProcessingDocumentFile(file, type)

          if (!response.ok) {
            throw response
          }

          update(dispatch, response, type)
          notify('Success', 'Your document was successfully added.')
        }
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    } else {
      notify.error('Error', `You can only add formats: ${EXTENSIONS.join(' ')}`)
      setLoading(false)
    }
  }

  const { open, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf, image/jpg',
    noClick: true,
    noKeyboard: true,
    disabled: false,
    onDropAccepted,
  })

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone disabled' })} className={cn(styles.dropzone, className)}>
        <input {...getInputProps()} />

        <AlarmZingIcon className={styles.alarm_zing} />

        <div className={styles.content}>{children}</div>

        <Button type='button' onClick={() => open()} theme='upload' size='normal' className={styles.btn}>
          {loading && <Spinner className={styles.btnSpinner} />}

          {!loading && (
            <span className={styles.btnContent}>
              <AddFileIcon /> Add photos
            </span>
          )}
        </Button>
      </div>
    </section>
  )
})

export default BasicDropzone
