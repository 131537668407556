import React, { useState } from 'react'

import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

import ServicesIcon from './ServicesIcon/ServicesIcon'

import { getServices } from './config'
import useListenEvent from '../../../hooks/useListenEvent'
import { setBillingPermissions, setServiceData } from '../../../redux/billing/billing.action'
import { events } from '../../../constants/constans'
import { serviceMap } from './constants'

const initState = {
  [serviceMap.enhancedVisibility]: false,
  [serviceMap.personalStatus]: false,
  [serviceMap.earlyAccess]: false,
  [serviceMap.activityTracking]: false,
}

const Services = () => {
  const [openPopup, setOpenPopup] = useState(initState)

  const dispatch = useDispatch()

  const { billing } = events

  const handleOpenPopup = (action, service) => {
    setOpenPopup((prev) => ({ ...initState, [service]: !prev[service] }))
  }

  const handleClosePopup = () => {
    setOpenPopup(initState)
  }

  // EVENTS

  const onBillingPermissionCallback = (data) => {
    dispatch(setBillingPermissions(data))
  }

  useListenEvent(billing.permissionsCurrent, onBillingPermissionCallback)

  const onBillingPermissionUpdateCallback = (data) => {
    dispatch(setBillingPermissions(data))
  }

  useListenEvent(billing.permissionsUpdated, onBillingPermissionUpdateCallback)

  const onServiceEventCallback = (data) => {
    const { service } = data.notification_data

    const newData = { count: 1, next: null, previous: null, results: [data] }

    dispatch(setServiceData({ service, data: newData }))
  }

  useListenEvent(billing.newEvent, onServiceEventCallback)

  // EVENTS

  const services = Object.keys(getServices())

  return (
    <div className={styles.wrapper}>
      {services.map((service) => {
        const props = getServices()[service]

        return (
          <ServicesIcon
            key={service}
            service={service}
            isOpenPopup={openPopup[service]}
            onOpenPopup={handleOpenPopup}
            onClosePopup={handleClosePopup}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default Services
