import React from 'react'

import styles from './styles.module.scss'

import ProfileNavItem from './ProfileNavItem'
import { profilePaths } from '../../constants/constans'
import useNavigationProfile from './useNavigationProfile'

const ProfileNav = () => {
  const { navigation } = useNavigationProfile()

  return (
    <div className={styles.nav}>
      {Object.keys(navigation).map((path) => {
        const { ...rest } = navigation[path]

        if (path === profilePaths.general) {
          return (
            <div key={path} className={styles.step} id='tour-step-1'>
              <ProfileNavItem link={path} {...rest} />
            </div>
          )
        }

        return <ProfileNavItem key={path} link={path} {...rest} />
      })}
    </div>
  )
}

export default ProfileNav
