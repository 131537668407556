import React from 'react'

import store from '../../../../../../redux/store'

import { getDateByUserFormat } from '../../../../../../helpers/timeHelper'
import moment from 'moment'

export const getMessage = (message) => {
  if (!message) return null

  const arr = message.split('company')
  const companyName = arr[0]
  const restStr = arr.filter((_, idx) => idx !== 0).join()

  return (
    <span>
      <b>{companyName}</b>

      {` company${restStr}`}
    </span>
  )
}

export const getDate = (date) => {
  if (!date) return null

  const { date_format: userFormat } = store.getState().user.settings

  return getDateByUserFormat(moment(date), `${userFormat} hhaa`)
}
