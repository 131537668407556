import React from 'react'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { getReviewsHelper } from '../../../helpers/apiHelper'
import { getRequests, getRequestsBySearch } from '../../../api/getApi'
import { clearRequests, setRequests } from '../../../redux/actions/reviews.action'
import Input from '../../../UI/Input/Input'
import ReviewsRequestsList from '../../../components/ReviewsRequests/ReviewsRequestsList'
import styles from './RequestForReviews.module.scss'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { ReactComponent as NoInfoImage } from '../../../assets/images/get_reviews_stub.svg'
import Spinner from '../../../UI/Spinner/Spinner'

const RequestForReviews = () => {
  const dispatch = useDispatch()
  const requests = useSelector(({ reviews }) => reviews.requests)

  const [searchValue, setSearchValue] = React.useState('')
  const [querySearchValue, setQuerySearchValue] = React.useState('')

  React.useEffect(() => {
    return () => {
      dispatch(clearRequests())
      setQuerySearchValue('')
    }
  }, [dispatch])

  const { handleScroll, dataCount, loading, nextPage } = useInfiniteScroll(
    querySearchValue ? getRequestsBySearch : getRequests,
    setRequests,
    getReviewsHelper,
    querySearchValue ? `category=all&value=${querySearchValue}` : `category=all`,
    clearRequests
  )
  const isLoading = nextPage ? loading : null

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchValue(value)
    if (value === '' || value.length > 1) {
      filterRequests(value)
    }
  }

  const filterRequests = React.useCallback(
    debounce((value) => {
      setQuerySearchValue(value)
      dispatch(clearRequests())
    }, 1000),
    [dispatch]
  )

  return (
    <section className={styles.request_section}>
      <div className={cn(styles.headerWrapper)}>
        <>
          <div className={styles.title}>Requests ({dataCount})</div>
          <div className={cn(styles.search, { [styles.isEmpty]: isEmpty(requests) })}>
            <Input
              value={searchValue}
              placeholder='Search'
              theme='bordered'
              onChange={handleInputChange}
              label='Search'
              className={styles.search_requests}
            />
          </div>
        </>
      </div>
      <div className={cn(styles.containerList, { [styles.containerList_empty]: isEmpty(requests) })}>
        {requests.length !== 0 ? (
          <ReviewsRequestsList handleScroll={handleScroll} filter='all' requests={requests} />
        ) : !requests.length && isLoading ? (
          <Spinner type='magnifier' size={160} withoutPosition />
        ) : (
          <div className={styles.noInfo}>
            <NoInfoImage />
            <p>You don't have any request for reviews</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default RequestForReviews
