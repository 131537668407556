import React from 'react'

import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'

import bgImage from './image/image.png'
import Button from '../../../../UI/Button/Button'
import { profilePaths } from '../../../../constants/constans'

const EmptyBanner = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push(profilePaths.billing_services)
  }

  return (
    <div className={styles.bannerWrapper}>
      <img src={bgImage} alt='background' />

      <p className={styles.description}>
        your payment history will appear here once you've purchased additional services
      </p>

      <Button type='button' className={styles.button} onClick={handleClick}>
        Go to Store
      </Button>
    </div>
  )
}

export default EmptyBanner
