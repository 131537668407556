import React, { useMemo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const ProfileNavItem = ({ link, icon, title, menuItems, paths, disabled = false }) => {
  const { admin } = useSelector(({ authentication }) => authentication)

  const { isAdmin = false, permissions = [] } = admin ?? {}

  const onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname)
    }
  }

  const isActiveLink = useMemo(() => {
    return permissions.some((item) => item === link)

    //eslint-disable-next-line
  }, [permissions, link])

  const isLinkInactive = (isAdmin && !isActiveLink) || disabled

  return (
    <div className={styles.link_wrapper}>
      <NavLink
        isActive={paths ? onPaths(paths) : undefined}
        className={cn(styles.link, { [styles.link_inactive]: isLinkInactive })}
        to={link}
        activeClassName={styles.active}
      >
        {icon}

        <span className={styles.title}>{title}</span>
      </NavLink>

      {menuItems && (
        <div className={styles.link_menu}>
          {menuItems.map((link, index) => (
            <NavLink
              isActive={link.paths ? onPaths(link.paths) : undefined}
              key={index}
              className={styles.link_menu_item}
              activeClassName={styles.active}
              to={link.path}
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  )
}

ProfileNavItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ProfileNavItem
