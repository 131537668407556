import React from "react";
import styles from "./CardHeaderLoader.module.scss";

const CardHeaderLoader = () => {
  const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return (
    <div className={styles.header}>
      <div className={styles.avatar} />

      <div className={styles.info}>
        {items.map((item, index) => (
          <div key={index} className={styles.infoItem}>
            <div className={styles.title} />
            <div className={styles.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardHeaderLoader;
