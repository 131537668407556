import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getAuthProfileFilters, getSeafarerVisa, getGooglePlacesCities } from '../../../../api/getApi'
import { postDocumentsFile, postSeafarerVisa } from '../../../../api/postApi'
import { putSeafarerVisa } from '../../../../api/putApi'
import { deleteSeafarerFile } from '../../../../api/deleteApi'
import { documentSubmitHelper, getDocumentHelper, isFile, isValidFile } from '../../../../helpers/documents.helper'
import { notify } from '../../../../helpers/notify'
import {
  addAuthDocument,
  editAuthDocument,
  editDocument,
  setDocument,
} from '../../../../redux/actions/documents.action'
import { setUserInfo } from '../../../../redux/actions/user.action'
import { confirm } from '../../../../UI/Confirm/Confirm'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import CheckBox from '../../../../UI/CheckBox/CheckBox'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import Input from '../../../../UI/Input/Input'
import KeyBoardDatePicker from '../../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import Button from '../../../../UI/Button/Button'
import Spinner from '../../../../UI/Spinner/Spinner'
import FileUploader from '../../../FileUploader/FileUploader'
import { ReactComponent as AttachIcon } from '../../../../assets/icons/add_file_icon.svg'
import { initialValues, getValidationSchema } from './config'
import { isCompletelyFilled } from '../../../../helpers/objectsHelper'
import { getMinDate, today } from '../../../../helpers/timeHelper'

const extensions = ['.pdf', '.jpg', '.jpeg', '.png']

const VisaForm = ({
  currentDocumentId,
  setCurrentDocumentId,
  documentFiles,
  setDocumentFiles,
  fillFields,
  setFillFields,
  authDocuments,
}) => {
  const dispatch = useDispatch()
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const [fillFullVisaInformation, setFillFullVisaInformation] = React.useState(false)
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [fetchedFilesLength, setFetchedFilesLength] = React.useState(0)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentData, setDocumentData] = React.useState()
  const [documentFilesForDeleting, setDocumentFilesForDeleting] = React.useState([])
  const [filters, setFilters] = React.useState({
    countries: [],
    visaTypes: [],
  })
  const [isUpload, setIsUpload] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: currentDocumentId && documentData ? documentData : initialValues,
    validationSchema: getValidationSchema(fillFullVisaInformation, dateFormat),
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (document) => {
      setIsLoading(true)

      if (!fillFullVisaInformation) {
        document = {
          type: values.type,
          country: { name: 'country', id: null },
          number: '',
          city: { name: '', id: null },
          date_of_issue: null,
          date_of_expire: null,
        }
      }

      if (currentDocumentId) {
        editVisa(documentSubmitHelper(document, 'visa'))
      } else {
        postVisa(documentSubmitHelper(document, 'visa'))
      }
    },
  })

  const { values, touched, errors, dirty } = formik

  const resetForm = () => {
    formik.resetForm()
    setDocumentFiles([])
  }

  React.useEffect(() => {
    const isCompletely = isCompletelyFilled(values, ['type', 'country', 'number', 'date_of_issue', 'date_of_expire'])

    if (values.type.id && !fillFullVisaInformation) {
      setIsButtonEnable(true)

      return
    }

    setIsButtonEnable((isCompletely && dirty) || (isCompletely && fetchedFilesLength !== documentFiles.length))

    // eslint-disable-next-line
  }, [values, dirty, documentFiles, fetchedFilesLength, fillFullVisaInformation])

  React.useEffect(() => {
    getAuthProfileFilters('country', 'seafarer_visas').then((filters) => {
      setFilters({
        countries: filters.country,
        visaTypes: filters.seafarer_visas,
      })
    })
  }, [])

  React.useEffect(() => {
    if (currentDocumentId) {
      setIsLoading(true)

      getSeafarerVisa(currentDocumentId).then((document) => {
        setFillFields(true)

        document.country?.id ? setFillFullVisaInformation(true) : setFillFullVisaInformation(false)

        setDocumentData(getDocumentHelper(document, 'visa'))

        formik.resetForm()

        if (document.files.length) {
          setFetchedFilesLength(document.files.length)
          setDocumentFiles(
            document.files.map((file) => {
              return {
                ...file,
                name: file.file_name,
              }
            })
          )
        }
        setIsLoading(false)
      })
    } else {
      formik.resetForm()
      setDocumentFiles([])
      setDocumentFilesForDeleting([])
      setFillFields(false)
      setFillFullVisaInformation(false)
    }
    // eslint-disable-next-line
  }, [currentDocumentId])

  const changeFiles = async (documentId, action, type = 'post') => {
    setIsUpload(true)

    const promises = []

    documentFiles.forEach((file) => {
      if (isFile(file)) {
        promises.push(postDocumentsFile(file, documentId, 'visa'))
      }
    })

    documentFilesForDeleting.forEach((file) => {
      promises.push(deleteSeafarerFile(file))
    })

    try {
      const response = await Promise.all(promises)
      const passport = await getSeafarerVisa(documentId)

      if (type === 'post') dispatch(action(passport))

      if (type === 'put') dispatch(action(passport.id, passport))

      if (response.some(({ errors }) => errors)) {
        response.forEach(({ errors }) => errors && notify.errorsList(errors))
      }
      // else {
      //   const actionText = (() => {
      //     if (type === 'post') return 'added'
      //
      //     if (type === 'put') return 'updated'
      //
      //     return ''
      //   })()
      //
      //   notify('Success', `Your document was successfully ${actionText}`)
      // }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsUpload(false)
    }
  }

  const postVisa = async (document) => {
    try {
      const response = await postSeafarerVisa(document)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length) {
        await changeFiles(response.id, addAuthDocument)
      }

      if (!documentFiles.length) {
        dispatch(setDocument(getSeafarerVisa, response.id))
      }

      if (!authDocuments.length) {
        dispatch(setUserInfo())
      }

      resetForm()
      notify('Success', `Your document was successfully added`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const editVisa = async (document) => {
    try {
      const response = await putSeafarerVisa(document, currentDocumentId)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length || documentFilesForDeleting.length) {
        await changeFiles(currentDocumentId, editAuthDocument, 'put')
      }

      if (!documentFiles.length || !documentFilesForDeleting.length) {
        dispatch(editDocument(getSeafarerVisa, currentDocumentId))
      }

      resetForm()

      setCurrentDocumentId(null)
      setDocumentFilesForDeleting([])
      notify('Success', `Your document was successfully updated`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputFileChange = ({ target }) => {
    const files = target.files

    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (documentFiles.length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          setDocumentFiles((prevState) => [...prevState, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      if (currentDocumentId) {
        if (deleteType === 'byId') {
          setDocumentFilesForDeleting((prevState) => [...prevState, id])
          setDocumentFiles((prevState) => prevState.filter((file) => file.id !== id))
        } else {
          setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
        }
      } else {
        setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
      }
    })
  }

  const handleFillFullVisaInformationCheckBox = () => {
    if (fillFullVisaInformation) {
      formik.resetForm()
    }
    setFillFullVisaInformation(!fillFullVisaInformation)
  }

  const getPlaces = (name, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      getGooglePlacesCities(target.value, values.country.iso2.toLowerCase()).then((cities) => {
        setAutocompleteItems(cities)
      })
    }
  }

  const handleChangeValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const handleChangeSelectValue = (name, value) => {
    if (name === 'country' && value.id !== values.country.id) {
      formik.setValues({
        ...values,
        city: { name: '', id: null },
      })
    }

    handleChangeValue({ target: { value, name } })
  }

  const handleChangeAutocompleteValue = (name, { target }) => {
    handleChangeValue({
      target: { value: { name: target.value, id: null }, name },
    })

    getPlaces(name, target)
  }

  const handleClickAutocomplete = (name, value) => {
    handleChangeValue({ target: { value, name } })

    setAutocompleteItems([])
  }

  const handleFocusAutoComplete = (name, { target }) => {
    getPlaces(name, target)
  }

  const isRequest = isLoading || isUpload

  const isDisabled = isLoading || !fillFields || isUpload

  return (
    <form
      className={cn(styles.form_visa, {
        [styles.disabled]: isDisabled,
      })}
      onSubmit={formik.handleSubmit}
    >
      {isRequest && (
        <div className={styles.loadingContainer}>
          <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
        </div>
      )}

      <div className={styles.formColumn}>
        <div>
          <SelectSearch
            label='Visa'
            name='type'
            id={values.type.id}
            items={filters.visaTypes}
            selectedItem={values.type.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFields}
            isInvalid={!!(errors.type?.id && touched.type)}
            required
          />

          {errors.type?.id && touched.type && <p className={styles.formItemError}>{errors.type?.id}</p>}
        </div>
        <div style={{ marginTop: '28px' }}>
          <SelectSearch
            label='Country'
            name='country'
            id={values.country.id}
            items={filters.countries}
            selectedItem={values.country.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFullVisaInformation}
            required={fillFullVisaInformation}
            isInvalid={!!(errors.country?.id && touched.country)}
          />

          {errors.country?.id && touched.country && <p className={styles.formItemError}>{errors.country?.id}</p>}
        </div>
        <div>
          <Autocomplete
            autoComplete={'off'}
            label='City'
            name='city'
            placeholder='City'
            items={autocompleteItems}
            value={values.city.name}
            onChange={handleChangeAutocompleteValue}
            onClick={handleClickAutocomplete}
            onFocus={handleFocusAutoComplete}
            disabled={!values.country.id || !fillFullVisaInformation}
            isInvalid={Boolean(errors.city?.id && touched.city)}
            errorMessage={errors.city?.id}
          />
        </div>
      </div>

      <div style={{ alignSelf: 'end' }} className={styles.formColumn}>
        <CheckBox onClick={handleFillFullVisaInformationCheckBox} checked={fillFullVisaInformation}>
          Fill in full visa information
        </CheckBox>
        <div style={{ marginTop: '40px' }}>
          <Input
            label='Number'
            name='number'
            value={values.number}
            onChange={handleChangeValue}
            disabled={!fillFullVisaInformation}
            required={fillFullVisaInformation}
            isInvalid={!!(errors.number && touched.number)}
          />

          {errors.number && touched.number && <p className={styles.formItemError}>{errors.number}</p>}
        </div>
        <div className={styles.formRow}>
          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_issue}
            nameOfValue='date_of_issue'
            keyBoardLabel='Date of issue'
            minDate={getMinDate}
            maxDate={today}
            isInvalid={!!(errors.date_of_issue || (errors.date_of_issue && touched.date_of_issue))}
            errorMessage={errors.date_of_issue}
            disabled={!fillFullVisaInformation}
            required={fillFullVisaInformation}
            today={true}
          />

          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_expire}
            nameOfValue='date_of_expire'
            keyBoardLabel='Date of expire'
            minDate={values.date_of_issue || today}
            isInvalid={!!(errors.date_of_expire || (errors.date_of_expire && touched.date_of_expire))}
            errorMessage={errors.date_of_expire}
            disabled={!fillFullVisaInformation}
            required={fillFullVisaInformation}
            today={true}
          />
        </div>
      </div>

      <div className={styles.controls}>
        <FileUploader
          name='visa'
          text='Add scan'
          theme='attachFile'
          files={documentFiles}
          onChange={handleInputFileChange}
          onDelete={handleInputFileDelete}
          icon={<AttachIcon />}
          isUpload={isUpload}
        />

        <Button className={styles.button_save} type='submit' size='middle-full' disabled={!isButtonEnable}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default VisaForm
