import React, { useEffect, useState } from 'react'

import CSSTransition from 'react-transition-group/CSSTransition'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import styles from './styles.module.scss'
import cn from 'classnames'

import Button from '../../../UI/Button/Button'
import CvForm from './CvForm'

const PopupForm = () => {
  const [isOpenForm, setIsOpenForm] = useState(false)
  const nodeRef = React.useRef(null)

  useEffect(() => {
    const body = document.documentElement

    isOpenForm ? disableBodyScroll(body) : enableBodyScroll(body)

    return () => enableBodyScroll(body)
  }, [isOpenForm])

  return (
    <>
      <Button
        className={cn(styles.send_cv_button, styles.header_button)}
        size='normal'
        theme='bordered'
        smooth='true'
        duration={500}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpenForm(true)
        }}
      >
        <span className={styles.cvContent}>
          Send CV {/*<span className={styles.limited_duration_marker}>*/}
          {/*  <LimitedDuration width='100%' height='100%' />*/}
          {/*</span>*/}
        </span>
      </Button>
      <CSSTransition
        nodeRef={nodeRef}
        classNames='fadein-top'
        appear
        mountOnEnter
        unmountOnExit
        in={isOpenForm}
        timeout={300}
      >
        <CvForm setIsOpen={setIsOpenForm} />
      </CSSTransition>
    </>
  )
}

export default PopupForm
