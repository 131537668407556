import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import Masonry from 'react-responsive-masonry'
import ReviewItem from './ReviewItem/ReviewItem'
import styles from '../UserCard.module.scss'
import { useDispatch } from 'react-redux'
import { setReviewsInfo } from '../../../redux/actions/userCard.action'
import { ReactComponent as NoInfoImage } from '../../../assets/images/reviews_sub.svg'

const Reviews = ({ userCardData }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setReviewsInfo([]))
    }
  }, [dispatch])
  if (isEmpty(userCardData.reviews)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={350} height={270} />
        <p>You don’t have any reviews yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter={'16px'}>
        {userCardData.reviews.map((review, index) => review.questions && <ReviewItem key={index} review={review} />)}
      </Masonry>
    )
  }
}

export default Reviews
