import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import JobTableMobileItem from './JobTableMobileItem'

const JobTableMobile = ({ vacancies = [] }) => {
  const [maxViewVacancies, setMaxViewVacancies] = useState(6)
  const isItTabletView = window.innerWidth > 744

  const update = () => {
    const width = window.innerWidth
    if (width >= 744) {
      setMaxViewVacancies(6)
    } else {
      setMaxViewVacancies(4)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [])

  return (
    <section className={styles.table}>
      <div className={styles.header}>
        <h2>
          Last {maxViewVacancies} vacancies <span> for </span> Seafarers
        </h2>
      </div>
      <div>
        <ul className={styles.vacanciesBlock}>
          {
            //eslint-disable-next-line
            vacancies?.map((item, index) => {
              //eslint-disable-next-line
              if (index + 1 <= maxViewVacancies) {
                // const {
                // vessel,
                // vacancy_type,
                // country
                // } = item

                // const { name: vessel_type } = vessel
                // const vesselTypeOrLand = (() => {
                //   if (vacancy_type === 'land') {
                //     return `Shore-Based Job, ${country.name}`
                //   }
                //
                //   return vessel_type ? vessel_type : vessel.vessel_type.name
                // })()

                return (
                  <li key={item.id}>{item && <JobTableMobileItem item={item} isItTabletView={isItTabletView} />}</li>
                )
              }
            })
          }
        </ul>
      </div>
      {/*<div className={styles.linksToDownloadApp} id='jobTableMobile'>*/}
      {/*  <h4>*/}
      {/*    If you want to find out more information on open vacancies and apply, we suggest that you install a mobile*/}
      {/*    app.*/}
      {/*  </h4>*/}
      {/*  <p>*/}
      {/*    The NextShip mobile app allows seafarers to keep abreast of the global work situation at sea and not miss any*/}
      {/*    urgent vacancies.*/}
      {/*  </p>*/}
      {/*  <div className={styles.links}>*/}
      {/*    <a href='https://play.google.com/store/apps/details?id=com.nextship.client'>*/}
      {/*      <img src={playMarketPng} alt='google play market link' />*/}
      {/*    </a>*/}
      {/*    <a href='https://apps.apple.com/ua/app/nextship/id1554236157'>*/}
      {/*      <img src={appStorePng} alt='apple app store link' />*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
  )
}

export default JobTableMobile
