import store from '../../../redux/store'
import { viewServiceEvent } from '../../../api/postApi'
import { notify } from '../../../helpers/notify'

export const isActiveService = (serviceName) => {
  const { permissions } = store.getState().billing

  return permissions.includes(serviceName)
}

export const viewEvent = async (action, service) => {
  try {
    await viewServiceEvent({ action, service })
  } catch (error) {
    notify.errorsList(error.errors)
  }
}
