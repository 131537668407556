import { CONTACTS } from '../types'

const initialState = {
  contactsRelatives: [],
  authContactsRelatives: [],
  count: 0,
}

export default function contactsRelativesReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACTS.SET_RELATIVES_CONTACTS: {
      return {
        ...state,
        contactsRelatives: [...state.contactsRelatives, ...action.payload.results],
        count: action.payload.count,
      }
    }

    case CONTACTS.ADD_RELATIVES_CONTACT: {
      return {
        ...state,
        contactsRelatives: [
          ...state.contactsRelatives.map((item) => {
            if (action.payload.next_of_kin === true) {
              return { ...item, next_of_kin: false }
            } else {
              return item
            }
          }),
          action.payload,
        ],
      }
    }

    case CONTACTS.DELETE_RELATIVES_CONTACT: {
      return {
        ...state,
        contactsRelatives: state.contactsRelatives.filter((contact) => contact.id !== action.payload),
      }
    }

    case CONTACTS.EDIT_RELATIVES_CONTACT: {
      return {
        ...state,
        contactsRelatives: state.contactsRelatives.map((contact) => {
          if (contact.id === action.payload.id) {
            return action.payload.contact
          } else {
            if (action.payload.contact.next_of_kin === true) {
              return { ...contact, next_of_kin: false }
            }
            return contact
          }
        }),
      }
    }

    case CONTACTS.SET_MAIN_RELATIVES_CONTACT: {
      const { id, isActive } = action.payload

      return {
        ...state,
        contactsRelatives: state.contactsRelatives.map((contact) => {
          if (contact.id === id) {
            return { ...contact, next_of_kin: isActive }
          } else {
            return { ...contact, next_of_kin: false }
          }
        }),
      }
    }

    case CONTACTS.CLEAR_RELATIVES_CONTACTS: {
      return {
        ...state,
        contactsRelatives: [],
      }
    }

    default:
      return state
  }
}
