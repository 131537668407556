import React, { useEffect, useRef, useState } from 'react'
import { StyledTooltip } from '../UI/Tooltip/Tooltip.config'
import cn from 'classnames'

const UseSmartTooltip = ({
  children,
  tagType = 'span',
  className,
  typeLink = '',
  onClick,
  update,
  amount_of_line = 1,
}) => {
  const [titleWidth, setTitleWidth] = useState(0)
  const [titleScrollWidth, setTitleScrollWidth] = useState(0)
  const [visible, setVisible] = useState(false)

  const contentRef = useRef(null)
  useEffect(() => {
    if (contentRef.current) {
      setTitleWidth(contentRef.current.offsetWidth)
      setTitleScrollWidth(contentRef.current.scrollWidth)
    }
  }, [contentRef, update])

  let Tag = tagType

  useEffect(() => {
    if (tagType === 'a') {
      contentRef.current.setAttribute(
        'href',
        `${typeLink === 'tel' ? 'tel:' : typeLink === 'email' ? 'mailto:' : ''}${children}`
      )
      if (tagType !== 'tel' && tagType !== 'email') {
        contentRef.current.setAttribute('target', '_blank')
      }
    }

    const isVisible = amount_of_line > 1 ? titleScrollWidth > titleWidth : titleScrollWidth > titleWidth
    setVisible(isVisible)
  }, [titleWidth, titleScrollWidth, children, tagType, typeLink, update, amount_of_line])
  return (
    <StyledTooltip className='use_smart_tooltip' placement={'top'} title={children} disableHoverListener={!visible}>
      <Tag className={cn(className, 'tooltip-wrapper', { is_visible: visible })} ref={contentRef} onClick={onClick}>
        {children}
      </Tag>
    </StyledTooltip>
  )
}

export default UseSmartTooltip
