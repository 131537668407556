import styles from './messageAction.module.scss'
import React from 'react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as ReplyIcon } from '../../assets/icons/reply.svg'
import { operationType } from '../../constants/constans'

const MessageAction = ({ messageAction, onClick }) => {
  const isEdit = messageAction.operation === operationType.edit
  const isReply = messageAction.operation === operationType.reply

  return (
    <div className={styles.active_content}>
      <div className={styles.content}>
        {isEdit && <span className={styles.title}>Edit message</span>}

        {isReply && (
          <div className={styles.reply_icon}>
            <ReplyIcon />
          </div>
        )}

        <span className={styles.active_text}>{!isEdit && messageAction.text}</span>
      </div>

      <button onClick={onClick} className={styles.close_button}>
        <CloseIcon className={styles.close_icon} />
      </button>
    </div>
  )
}

export default MessageAction
