import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import ContactInfoItem from './ContactInfoItem/ContactIfnoItem'
import styles from '../UserCard.module.scss'
import { setContactInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'

const ContactInfo = ({ userCardData, isActive, preloader: Preloader }) => {
  const dispatch = useDispatch()
  const contactInfoData = userCardData.contactInfo
  const { mainContacts, socialMediaContacts, relativeContacts } = contactInfoData

  useEffect(() => {
    return () => {
      dispatch(setContactInfo({}))
    }
  }, [dispatch])

  if (isEmpty(contactInfoData)) {
    return <div className={styles.noInfo}>No information</div>
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px' style={{ borderRadius: '4px' }}>
        {mainContacts.length !== 0 && <ContactInfoItem title='My contacts' items={mainContacts} />}

        {socialMediaContacts.length !== 0 && <ContactInfoItem title='Social networks' items={socialMediaContacts} />}

        {isActive ? (
          <Preloader />
        ) : (
          relativeContacts?.map((relativeContact, index) => {
            return (
              <ContactInfoItem
                key={index}
                title={`${relativeContact.type} contacts`}
                nextOfKin={relativeContact.nextOfKin}
                items={relativeContact.values}
              />
            )
          })
        )}
      </Masonry>
    )
  }
}

export default ContactInfo
