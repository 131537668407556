import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { getDate, getMessage } from './utils'
import { viewEvent } from '../../../utils'
import { action, serviceMap } from '../../../constants'
import { setServiceData } from '../../../../../../redux/billing/billing.action'

const ActivityTrackingNewProfileViews = ({ views, viewsLength, onScroll }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState(null)
  const [message, setMessage] = useState('')
  const { date_format } = useSelector(({ user }) => user.settings)

  const dispatch = useDispatch()

  useEffect(() => {
    viewEvent(action.info, serviceMap.activityTracking)

    return () => {
      // set the data: {} after checking the service event,
      // so that there is no function getServiceData call, it should be called once, when initializing the application
      dispatch(setServiceData({ service: serviceMap.activityTracking, data: {} }))
    }

    // eslint-disable-next-line
  }, [])

  const firstItem = views[0]

  useEffect(() => {
    setDate(getDate(firstItem?.date))
    setMessage(getMessage(firstItem?.message))
  }, [views, date_format, firstItem])

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div onScroll={onScroll} className={cn(styles.wrapper, { [styles.scroll]: viewsLength > 10 && isOpen })}>
      {views.length === 1 && (
        <p>
          {message}

          <span className={styles.date}>{date}</span>
        </p>
      )}

      {views.length > 1 && !isOpen && (
        <p>
          {message}

          <span className={styles.date}>{date}</span>

          <button type='button' onClick={handleClick} className={styles.showMore}>
            show more
          </button>
        </p>
      )}

      {isOpen &&
        views.map((item, idx) => {
          const message = getMessage(item.message)
          const date = getDate(item.date)

          return (
            <p key={idx} className={styles.item}>
              {message}

              <span className={styles.date}>{date}</span>
            </p>
          )
        })}
    </div>
  )
}

export default ActivityTrackingNewProfileViews
