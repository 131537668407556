import React, { useState } from 'react'

import widgetMT from './widgetMT.module.scss'

import PropTypes from 'prop-types'

import { ReactComponent as VesselIcon } from '../../../../../assets/icons/vessel.svg'

import MTButton from '../../../../../UI/Select/VesselAutocomplete/MTButton/MTButton'

import { getMtVessels } from '../../../../../api/getApi'
import useClickOutside from '../../../../../hooks/useClickOutside'
import { notify } from '../../../../../helpers/notify'

const STEP = {
  INIT: 'INIT',
  REQUEST: 'REQUEST',
  NOT_FOUND: 'NOT_FOUND',
}

const WidgetMarineTraffic = ({ value, onSuccess, onError, stepErr, setMTWidget }) => {
  const [step, setStep] = useState(stepErr ?? STEP.INIT)

  const MTWidgetRef = useClickOutside(() => {
    setMTWidget({ isOpened: false, initialStep: null })
  })

  const runNextStep = async () => {
    setStep(STEP.REQUEST)

    try {
      const response = await getMtVessels(value)

      if (response.results?.length) {
        onSuccess(response)
      } else {
        setStep(STEP.NOT_FOUND)
      }
    } catch (error) {
      notify.info('Info', 'The request isn’t available, waiting time 2 minutes.')

      setStep(STEP.INIT)

      console.error(error)
    }
  }

  const handleNotFoundClick = () => {
    onError()
  }

  return (
    <div className={widgetMT.widget} ref={MTWidgetRef}>
      {step === STEP.INIT && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>This vessel not found</span>

          <MTButton onClick={runNextStep} Icon={VesselIcon}>
            Search in
          </MTButton>
        </div>
      )}

      {step === STEP.REQUEST && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>Search in progress ...</span>

          <MTButton disabled={true} Icon={VesselIcon}>
            Search in
          </MTButton>
        </div>
      )}

      {step === STEP.NOT_FOUND && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>This vessel not found</span>

          <p>
            Please inform Customer Support Service about this problem. After your confirmation, chat with Customer
            Support will be started
          </p>

          <MTButton onClick={handleNotFoundClick}>I confirm</MTButton>
        </div>
      )}
    </div>
  )
}

WidgetMarineTraffic.propTypes = {
  value: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default WidgetMarineTraffic
