import React, { useEffect } from "react";
import StarRating from "../StarRating/StarRating";
import styles from "../../tabs/review/RequestForReviews/RequestForReviews.module.scss";

function ReviewsQuestion({ title, answer, id, handleClick }) {
  const [rating, setRating] = React.useState(answer);
  const [hoverRating, setHoverRating] = React.useState(0);

  const onMouseEnter = (index) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (index) => {
    setRating(index);
  };

  useEffect(() => {
    handleClick(rating, id);
  }, [rating, id, handleClick]);

  return (
    <li>
      <div className={styles.question}>{title}</div>
      <div>
        <StarRating
          rating={rating}
          hoverRating={hoverRating}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSaveRating={onSaveRating}
          size="21px"
        />
      </div>
    </li>
  );
}

export default ReviewsQuestion;
