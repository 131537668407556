import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StubIcon } from '../../../../assets/images/visas_stub.svg'

const Stub = () => {
  return (
    <div className={styles.wrapper}>
      <StubIcon />

      <p>You don't have any visas yet. Add a photo of your visas or fill in the fields</p>
    </div>
  )
}

export default Stub
