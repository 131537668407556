import React from "react";
import { useDispatch } from "react-redux";
import { getAppleIdTokenFromUrl } from "../helpers/apiHelper";
import ModalAlert from "../components/ModalAlert/ModalAlert";
import { Redirect } from "react-router-dom";
import { getUserAppleToken } from "../redux/actions/auth";

const AppleSignin = () => {
  const dispatch = useDispatch();
  const [isVerifySuccess, setIsVerifySuccess] = React.useState(null);

  React.useEffect(() => {
    const token = getAppleIdTokenFromUrl();
    if (token) {
      dispatch(
        getUserAppleToken({
          token: token,
          grant_type: "convert_token",
          backend: "apple-id",
        })
      );
      setIsVerifySuccess(true);
    } else {
      setIsVerifySuccess(false);
    }
  }, [dispatch]);

  if (isVerifySuccess === false) {
    return <Redirect to="/" />;
  }

  return (
    <div className="page">
      <main>
        {isVerifySuccess && (
          <ModalAlert
            isOk={true}
            title="Thank you!"
            content="You are successfully login from Apple ID."
          />
        )}
      </main>
    </div>
  );
};

export default AppleSignin;
