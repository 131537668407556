import React, { useState } from 'react'

import styles from './styles.module.scss'

import { ReactComponent as EnvelopeIcon } from '../../../assets/icons/udelivered-convert.svg'
import { ReactComponent as HeadphonesIcon } from './icons/headphones.svg'

import UserProfile from '../../UserProfile/UserProfile'
import { useSelector } from 'react-redux'
import { getLocalStorageItem } from '../../../helpers/helpers'

import Support from '../../Support/Support'
import useBillingActive from '../../../hooks/useBillingActive'
import Store from './Store/Store'
import cn from 'classnames'
import { Button, buttonClasses, Popover, popoverClasses } from '@mui/material'
import Operator from '../../Operator/Operator'

const Controls = ({ isAdmin }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { isSocketConnected } = useSelector(({ ui }) => ui)
  const { undelivered_count, loading, work } = useSelector(({ support }) => support)

  const isBillingActive = useBillingActive()

  const user = getLocalStorageItem('user')

  return (
    <div className={styles.wrapper}>
      {isBillingActive && !isAdmin && <Store />}

      <Button
        id='tour-step-5'
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        disabled={!isSocketConnected || user?.isAdmin}
        sx={{
          width: '60px',
          height: '60px',
          minWidth: '60px',
          minHeight: '60px',
          backgroundColor: open ? '#06251e' : 'transparent',
          boxShadow: 'none',
          padding: 0,
          color: '#222',
          [`&:hover`]: {
            backgroundColor: '#06251e',
          },
          [`&.${buttonClasses.disabled}`]: {
            filter: 'grayscale(1)',
            backgroundColor: 'transparent',
            cursor: 'not-allowed',
          },
        }}
      >
        <Operator isActive={open}>
          {undelivered_count && loading && work ? (
            <EnvelopeIcon height={20} width={28} className={cn('envelope', styles.envelope)} />
          ) : (
            <HeadphonesIcon />
          )}
        </Operator>
      </Button>

      {/*<NotificationStatus isActive={true} />*/}

      <UserProfile isAdmin={isAdmin} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transition='true'
        sx={{
          [`.${popoverClasses.paper}`]: {
            marginTop: '50px',
            marginLeft: '0px',
            width: '406px',
            boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Support />
      </Popover>
    </div>
  )
}

export default Controls
