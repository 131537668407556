import React, { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import styles from './styles.module.scss'

import Input from '../../../../../../UI/Input/Input'
import Spinner from '../../../../../../UI/Spinner/Spinner'
import Button from '../../../../../../UI/Button/Button'

import { ReactComponent as EditIcon } from './icons/edit.svg'

import { useNotify } from '../../../../../../hooks/useNotify'
import { getPersonalStatus } from '../../../../../../api/getApi'
import { setPersonalStatus } from '../../../../../../api/putApi'

const PersonalStatusActivated = () => {
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const maxLengthOfStatus = 200
  const phoneNumberRegex = /\+?[0-9\s\-()]{7,}/
  const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+|[^\s]+\.[^\s]{2,})/i

  const formik = useFormik({
    initialValues: { status: '' },
    validationSchema: Yup.object().shape({
      status: Yup.string()
        .max(maxLengthOfStatus, 'Too long!')
        .test('no-phone-number', 'Phone numbers are not allowed!', (value) => !phoneNumberRegex.test(value))
        .test('no-url', 'URLs are not allowed!', (value) => !urlRegex.test(value)),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      setLoading(true)

      try {
        const { status } = await setPersonalStatus(values)

        setStatus(status)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    },
  })

  const { notify } = useNotify()

  const { values, errors, handleChange, handleSubmit, setValues } = formik

  useEffect(() => {
    const getStatus = async () => {
      try {
        const { status } = await getPersonalStatus()

        setStatus(status)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getStatus()

    // eslint-disable-next-line
  }, [])

  const handleEditClick = () => {
    setValues({ status })

    setStatus('')
  }

  return (
    <div className={styles.wrapper}>
      {loading && <Spinner className={styles.spinner} />}

      {!loading && status.length === 0 && (
        <form onSubmit={handleSubmit}>
          <p>{`Share a short unique message (${values.status.length}/${maxLengthOfStatus})`}</p>

          <div className={styles.input}>
            <Input
              textarea
              name='status'
              type='text'
              rows={22}
              value={values.status}
              onChange={handleChange}
              isInvalid={Boolean(errors.status)}
              errorMessage={errors.status}
            />
          </div>

          <Button type='submit' size='middle-full' className={styles.button}>
            Save
          </Button>
        </form>
      )}

      {!loading && status.length !== 0 && (
        <>
          <p>Your message:</p>

          <div className={styles.statusWrapper}>
            <p className={styles.status}>{`"${status}"`}</p>

            <button type='button' onClick={handleEditClick} className={styles.edit}>
              <span>edit</span>

              <EditIcon />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default PersonalStatusActivated
