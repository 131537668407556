import React from 'react'

import '../scss/profile.scss'

import General from '../components/General/General'
import Address from '../tabs/personal-data/Address/Address'
import Anthropometry from '../tabs/personal-data/Anthropometry/Anthropometry'
import TabNav from '../components/TabNav/TabNav'
import MyContacts from '../tabs/contanct-info/MyContacts/MyContacts'
import RelativesContacts from '../tabs/contanct-info/RelativesContacts/RelativesContacts'
import VideoResume from '../components/VideoResume/VideoResume'
import { profilePaths } from '../constants/constans'
// import CustomJoyride from '../components/CustomJoyride/CustomJoyride'
// import { introStyles } from '../components/CustomJoyride/joyride.helper'
// import ReactJoyride from 'react-joyride'

const ProfilePersonalEditPage = () => {
  const links = [
    {
      url: profilePaths.general,
      title: 'General',
      component: General,
    },
    {
      url: profilePaths.address,
      title: 'Address',
      component: Address,
    },
    {
      url: profilePaths.biometrics,
      title: 'Biometrics',
      component: Anthropometry,
    },
    {
      url: profilePaths.my_contacts,
      title: 'My contacts',
      component: MyContacts,
    },
    {
      url: profilePaths.relatives_contacts,
      title: 'Relatives contacts',
      component: RelativesContacts,
    },
    {
      url: profilePaths.video_resume,
      title: 'Video resume',
      component: VideoResume,
    },
  ]

  return <TabNav title='Profile' links={links} redirectLink={profilePaths.general} />
}

export default ProfilePersonalEditPage
