import cn from 'classnames'
import styles from './styles.module.scss'
import React, { memo, useState } from 'react'
import ActionBlock from './ActionBlock'
import ShareBlock from '../../ShareBlock/ShareBlock'
import ImageComponent from '../../../UI/Image/ImageComponent'
import { isDefaultVesselImg } from '../../../helpers/helpers'

const MobileItemOpenCard = memo(({ item, isCardOpen, loading, setLoading }) => {
  const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)
  const [isAppPopUpOpen, setIsAppPopUpOpen] = useState(false)
  const handleShareBlockClose = () => {
    setIsShareBlockOpen(false)
  }

  const { vessel, description, title, shipowner, salary_description } = item
  const {
    mt_photo_mini_url,
    crew_mixed,
    crew_nationality,
    engine_type,
    year_of_build,
    flag,
    dwt,
    grt,
    teu,
    hp,
    kw,
  } = vessel

  const vesselPowerCreator = () => (hp ? `${hp}HP` : '') + (kw ? `/${kw}KW` : '')

  const openCardValues = [
    { label: 'Job Title', value: title, type: 'mainInfo' },
    { label: 'Description', value: description, type: 'mainInfo' },
    { label: 'Shipowner', value: shipowner.name, type: 'mainInfo' },
    { label: 'Salary description', value: salary_description, type: 'mainInfo' },
    { label: 'Crew', value: crew_mixed ? 'Mix' : crew_nationality?.name, type: 'characteristics' },
    { label: 'Main Engine', value: engine_type?.imaritime_id, type: 'characteristics' },
    { label: 'Built Year', value: year_of_build, type: 'characteristics' },
    { label: 'Vessel Flag', value: flag?.name, type: 'characteristics' },
    { label: 'DWT', value: dwt, type: 'characteristics' },
    { label: 'GRT', value: grt, type: 'characteristics' },
    { label: 'TEU', value: teu, type: 'characteristics' },
    { label: 'Power', value: vesselPowerCreator(), type: 'characteristics' },
  ]

  return (
    <div className={cn(styles.openCard, { [styles.openCard_visible]: isCardOpen })}>
      {mt_photo_mini_url && (
        <ImageComponent
          src={mt_photo_mini_url}
          alt='Ship'
          className={cn(styles.vesselImg, { [styles.vesselImgDefault]: isDefaultVesselImg(mt_photo_mini_url) })}
        />
      )}

      {openCardValues.map(
        (item, index) =>
          item.value &&
          (item.type === 'mainInfo' ? (
            <p key={index}>
              <span>{item.label}:</span> <br /> {item.value}
            </p>
          ) : (
            <div className={styles.openCard_value} key={index}>
              <span>{item.label}:</span> <p>{item.value}</p>
            </div>
          ))
      )}
      <ShareBlock id={item.id} onClose={handleShareBlockClose} isOpen={isShareBlockOpen} isMobileView />

      <ActionBlock
        loading={loading}
        setLoading={setLoading}
        item={item}
        setIsShareBlockOpen={setIsShareBlockOpen}
        isShareBlockOpen={isShareBlockOpen}
        isAppPopUpOpen={isAppPopUpOpen}
        setIsAppPopUpOpen={setIsAppPopUpOpen}
      />
    </div>
  )
})
export default MobileItemOpenCard
