import { SUPPORT } from '../types'
import { operationType } from '../../constants/constans'

const initialState = {
  undelivered_count: null,
  loading: true,
  user: null,
  messages: [],
  has_more_up: null,
  has_more_down: null,
  support_online: null,
  message_text: '',
  is_opened: false,
  work: false,
  message_action: {
    isActive: false,
    text: '',
    id: null,
    operation: '',
    isMessageDelete: false,
  },
  searchMessageId: null,
  anonymous_uuid: null,
}

export default function supportReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPORT.SET_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    case SUPPORT.GET_MESSAGES: {
      const { messages, has_more, reverse } = action.payload

      return {
        ...state,
        ...action.payload,
        messages: !messages.length ? state.messages : messages.reverse(),
        has_more_up: !reverse ? has_more : state?.has_more_up,
        has_more_down: reverse ? has_more : state?.has_more_down,
        loading: false,
      }
    }

    case SUPPORT.SET_NEXT_PAGE_SUPPORT_MESSAGES: {
      const { messages, has_more, reverse } = action.payload

      const result = !reverse ? [...state.messages, ...messages.reverse()] : [...messages.reverse(), ...state.messages]

      return {
        ...state,
        ...action.payload,
        messages: result,
        has_more_up: !reverse ? has_more : state?.has_more_up,
        has_more_down: reverse ? has_more : state?.has_more_down,
        loading: false,
        searchMessageId: null,
      }
    }

    case SUPPORT.SET_UNDELIVERED:
      return {
        ...state,
        undelivered_count: action.payload.count.support,
      }

    case SUPPORT.RESET_TOTAL_UNDELIVERED:
      return {
        ...state,
        undelivered_count: 0,
      }

    case SUPPORT.NEW_MESSAGE: {
      const { id, message, is_outgoing, date, user, total_undelivered, sender_type, reply } = action.payload

      const newMessage = {
        id,
        message,
        is_outgoing,
        date,
        reply,
      }

      const ANONYMOUS = 'anonymous'
      const SENDER_TYPE_ADMIN = 'admin'
      const isAdmin = user?.name !== ANONYMOUS && sender_type === SENDER_TYPE_ADMIN

      return {
        ...state,
        undelivered_count: total_undelivered,
        messages: [
          {
            ...newMessage,
            user,
          },
          ...state.messages,
        ],
        user: isAdmin ? { ...user } : state.user,
      }
    }

    case SUPPORT.SET_IS_OPENED: {
      const { isOpened } = action.payload

      return {
        ...state,
        is_opened: isOpened,
      }
    }

    case SUPPORT.SET_MESSAGE_TEXT: {
      const { value } = action.payload

      return {
        ...state,
        message_text: value,
      }
    }

    case SUPPORT.SET_STATUS: {
      const { value } = action.payload

      return {
        ...state,
        work: value.work,
      }
    }

    case SUPPORT.DELETE_SUPPORT_MESSAGE:
      return {
        ...state,
        messages: state.messages
          .filter((message) => message.id !== action.payload)
          .map((message) => {
            if (message.reply?.id === action.payload) {
              return {
                ...message,
                reply: null,
              }
            }

            return message
          }),
        message_action: {
          ...state.message_action,
          isMessageDelete: state.message_action.id === action.payload,
        },
      }

    case SUPPORT.REPLY_SUPPORT_MESSAGE: {
      const message_text = state.messages.find((message) => message.id === action.payload.id).message
      const isPrevOperationEdit = state.message_action.operation === operationType.edit

      return {
        ...state,

        message_action: {
          ...state.message_action,

          isActive: true,
          id: action.payload.id,
          text: message_text,
          operation: operationType.reply,
        },
        message_text: isPrevOperationEdit ? '' : state.message_text,
      }
    }

    case SUPPORT.EDIT_SUPPORT_MESSAGE: {
      const message_text = state.messages.find((message) => message.id === action.payload.id).message

      return {
        ...state,

        message_action: {
          ...state.message_action,

          isActive: true,
          id: action.payload.id,
          text: message_text,
          operation: operationType.edit,
        },
        message_text,
      }
    }

    case SUPPORT.ACTION_SUPPORT_MESSAGE_CLOSE:
      return {
        ...state,
        message_action: {
          ...initialState.message_action,
        },
      }

    case SUPPORT.UPDATE_SUPPORT_MESSAGE:
      return {
        ...state,
        messages: state.messages.map((message) => {
          if (message.id === action.payload.id) {
            return {
              ...message,
              edited: true,
              message: action.payload.message,
            }
          }

          if (message.reply?.id === action.payload.id) {
            return {
              ...message,
              reply: { ...message.reply, message: action.payload.message },
            }
          }

          return message
        }),
      }

    case SUPPORT.SEARCH_SUPPORT_MESSAGE: {
      const { messages, has_more_reverse, has_more, message_id } = action.payload

      return {
        ...state,
        ...action.payload,
        messages: messages.reverse(),
        searchMessageId: message_id,
        has_more_up: has_more,
        has_more_down: has_more_reverse,
        loading: false,
      }
    }

    case SUPPORT.SET_ANONYMOUS_UUID:
      return {
        ...state,
        anonymous_uuid: action.payload,
      }

    default:
      return state
  }
}
