import React, { useState } from 'react'
import styles from './vacancy.module.scss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import VacancyDetailsLabel from '../../../UI/VacancyDetailsLabel/VacancyDetailsLabel'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'
import AnimateHeight from 'react-animate-height'
import VacancyRightColumn from './VacancyComponents/VacancyRightColumn'
import VacancyFooter from './VacancyComponents/VacancyFooter'
import VacancyMainInfo from './VacancyComponents/VacancyMainInfo'
import ShareBlock from '../../ShareBlock/ShareBlock'

const Vacancy = ({ vacancy, appliedJobs, count, onClick, isOpen, refs, onAnimationEnd, isScrollList }) => {
  const [loading, setLoading] = useState(false)
  const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)

  const isAdmin = useSelector(({ authentication }) => authentication.user.isAdmin)

  const handleShareBlockClose = (e) => {
    e.stopPropagation()
    setIsShareBlockOpen(false)
  }

  return (
    <div
      ref={refs}
      className={cn(styles.vacancy, { [styles.row_is_scroll_list]: isScrollList })}
      onClick={() => {
        setIsShareBlockOpen(false)
        onClick(vacancy.id, isOpen)
      }}
    >
      <div className={cn(styles.shareBlockWrapper, { [styles.shareBlockWrapper_open]: isShareBlockOpen && isOpen })}>
        <ShareBlock id={vacancy.id} onClose={handleShareBlockClose} isOpen={isShareBlockOpen && isOpen} />
      </div>
      <div className={styles.vacancyInfo}>
        <div style={{ display: 'flex' }}>
          <div className={styles.vacancyFullInfo}>
            <div className={cn(styles.vacancyFullInfo, { [styles.header]: true })}>
              <div className={styles.row}>
                <UseSmartTooltip
                  tagType={'p'}
                  className={cn(
                    styles.vacancyField,
                    {
                      [styles.rank]: true,
                    },
                    styles.rankLand,
                    { [styles.rankLand__unviewedVacancy]: vacancy.early_access_flag }
                  )}
                  children={vacancy.rank_name || 'No information'}
                  update={vacancy}
                  amount_of_line={1}
                />

                {vacancy.urgent && <VacancyDetailsLabel label='urgent' urgent />}

                <VacancyDetailsLabel label='shore-based job' />
              </div>

              <div className={styles.vacancyField_short}>
                <p>{vacancy.title}</p>
              </div>
            </div>

            <AnimateHeight
              style={{ flexShrink: 0, background: 'var(--color-white)', borderRadius: '8px' }}
              height={isOpen ? 'auto' : 0}
              onAnimationEnd={onAnimationEnd}
            >
              <div className={cn(styles.vacancyFullInfo, { [styles.body]: true })}>
                <VacancyMainInfo vacancy={vacancy} />
              </div>
              <div className={cn(styles.vacancyFullInfo, { [styles.footer]: true }, styles.footerLand)}>
                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>City:</p>
                  <p>{vacancy.city || '—'}</p>
                </div>
                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Country:</p>
                  <p>{vacancy.country.name}</p>
                </div>
              </div>
            </AnimateHeight>
          </div>
          {!appliedJobs && (
            <VacancyRightColumn
              loading={loading}
              vacancy={vacancy}
              setLoading={setLoading}
              setIsShareBlockOpen={setIsShareBlockOpen}
              toOpenCard={onClick}
              isOpen={isOpen}
            />
          )}
        </div>

        <VacancyFooter
          vacancy={vacancy}
          loading={loading}
          setLoading={setLoading}
          isAdmin={isAdmin}
          appliedJobs={appliedJobs}
          count={count}
          isOpen={isOpen}
        />
      </div>
    </div>
  )
}

export default Vacancy
