import React, { memo, useEffect, useRef, useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import { motion, useAnimation } from 'framer-motion'

import cn from 'classnames'
import styles from './styles.module.scss'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'
import { ReactComponent as CalcOfFilesIcon } from '../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as DeleteFile } from '../../../assets/icons/close.svg'

import ImageComponent from '../../../UI/Image/ImageComponent'

const EducationGroupItem = memo(({ type, data, onDelete, onEdit, deleteFile, isActive }) => {
  const [visibleListOfFiles, setVisibleListOfFiles] = useState(false)
  const nodeRef = useRef(null)
  const control = useAnimation()

  useEffect(() => {
    if (visibleListOfFiles) {
      control.start((i) => ({
        opacity: 1,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    } else {
      control.start((i) => ({
        opacity: 0,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    }

    // eslint-disable-next-line
  }, [visibleListOfFiles])

  const handleFilesEnter = () => {
    setVisibleListOfFiles(true)
  }
  const handleFilesLeave = () => {
    setVisibleListOfFiles(false)
  }

  return (
    <div className={cn(styles.card, { [styles.card__active]: isActive })}>
      <div className={styles.cardInfo}>
        <div className={styles.documentType}>{data.documentName}</div>

        <div className={styles.documentBody}>
          <div className={styles.documentBody_info}>
            <div className={styles.degree}>
              Degree: <p className={styles.degreeValue}>{data.degree}</p>
            </div>

            <div className={styles.period}>
              Period: <p className={styles.periodValue}>{data.period}</p>
            </div>
          </div>

          <div className={styles.files}>
            {data?.files?.length ? (
              <div key={data.files[0].id} className={styles.file} onMouseEnter={handleFilesEnter}>
                <div key={data.files[0].id} className={styles.file}>
                  <div className={styles.calcOfFiles}>
                    <label className={styles.calcOfFilesLabel}>{data.files.length}</label>

                    <CalcOfFilesIcon />
                  </div>

                  <a rel='noopener noreferrer' target='_blank' href={data.files[0].file}>
                    <ImageComponent src={data.files[0].file_mini} alt='file' />
                  </a>
                </div>
              </div>
            ) : (
              <div className={styles.file} />
            )}
          </div>

          <CSSTransition nodeRef={nodeRef} in={visibleListOfFiles} timeout={500} unmountOnExit classNames='listOfFiles'>
            <div ref={nodeRef} className='listOfFiles' onMouseLeave={handleFilesLeave}>
              <div className={styles.files_list}>
                {data?.files?.length &&
                  data.files.map((file) => (
                    <motion.div
                      key={file.id}
                      className={styles.file}
                      initial={{ opacity: 0, x: 20 }}
                      animate={control}
                      custom={file.index}
                    >
                      <div key={file.id} className={styles.file}>
                        <div className={styles.deleteFile} onClick={() => deleteFile(file.id, data.id, type, isActive)}>
                          <DeleteFile />
                        </div>

                        <a rel='noopener noreferrer' target='_blank' href={file.file}>
                          <ImageComponent src={file.file_mini} alt='file' />
                        </a>
                      </div>
                    </motion.div>
                  ))}
              </div>
            </div>
          </CSSTransition>
        </div>

        {data.id !== 'default' && (
          <div className={styles.cardControls}>
            <button
              className={cn(styles.cardControl, styles.cardControlEdit)}
              onClick={() => onEdit(data.id, !isActive)}
            >
              {isActive ? (
                <span>
                  Close <CloseIcon />
                </span>
              ) : (
                <span>
                  Edit <EditIcon />
                </span>
              )}
            </button>

            <button
              className={cn(styles.cardControl, styles.cardControlDelete)}
              onClick={() => onDelete(data.id, isActive)}
            >
              <span>
                Delete <TrashIcon />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
})

export default EducationGroupItem
