import { TYPES } from './ui.types'

import { getUiInfo } from '../../api/getApi'
import { setUiInfo } from '../../api/patchApi'
import { notify } from '../../helpers/notify'

export const updateUiInfoThunk = () => async (dispatch) => {
  try {
    const response = await getUiInfo()

    dispatch({ type: TYPES.UPDATE_INFO, payload: response })
  } catch (error) {
    notify.errorsList(error.errors)
  }
}

export const setUiPropThunk = (data) => async (dispatch) => {
  try {
    const response = await setUiInfo(data)

    dispatch({ type: TYPES.SET_INFO, payload: response })
  } catch (error) {
    notify.errorsList(error.errors)
  }
}

export const setIsSocketConnectedAction = (status) => ({
  type: TYPES.SET_IS_SOCKET_CONNECTED,
  payload: { status },
})
