import React from 'react'

import Wrapper from '../Wrapper/Wrapper'
import PersonalStatusActivated from './PersonalStatusActivated/PersonalStatusActivated'
import PersonalStatusHasExpired from './PersonalStatusHasExpired/PersonalStatusHasExpired'
import PersonalStatusNotActivated from './PersonalStatusNotActivated/PersonalStatusNotActivated'

import { action } from '../../constants'

const PopupPersonalStatus = ({ notificationAction, isServiceActive, handleClose }) => {
  return (
    <Wrapper notificationAction={notificationAction} isServiceActive={isServiceActive} onClose={handleClose}>
      {isServiceActive && <PersonalStatusActivated />}

      {notificationAction === action.hasExpired && <PersonalStatusHasExpired />}

      {!isServiceActive && notificationAction !== action.hasExpired && <PersonalStatusNotActivated />}
    </Wrapper>
  )
}

export default PopupPersonalStatus
