import { convertDateToSubmit } from './timeHelper'
import { format } from 'date-fns'
import { vacancyType } from '../components/ProfileVacancies/VacancyForm/config'

export const vacanciesFormSearchHelper = (values) => {
  const workLocation = (() => {
    if (!values.workLocation.id) {
      return { show_sea: true, show_land: true }
    }

    return {
      show_sea: values.workLocation.id === vacancyType.sea,
      show_land: values.workLocation.id === vacancyType.land,
    }
  })()

  return {
    vessel_type: values.vesselType.id,
    rank: values.rank.id,
    join_date: convertDateToSubmit(values.joinDate),
    salary: values.salary ? Number(values.salary) : null,
    salary_rate: values.salaryRate.name === 'None' ? null : values.salaryRate.name.toLowerCase(),
    salary_currency: values.salaryCurrency.id,
    duration: values.duration ? Number(values.duration) : null,
    duration_period: values.durationPeriod.name === 'None' ? null : values.durationPeriod.name.toLowerCase(),

    ...workLocation,
  }
}

export const vacancySearchHelper = (vacancy) => {
  const { vessel_build_year, join_date, ...rest } = vacancy

  return {
    ...rest,

    vessel_build_year: vessel_build_year ? format(new Date(vessel_build_year), 'yyyy') : null,
    join_date: join_date.replaceAll('/', ' - '),
  }
}

export const vacanciesSearchHelper = (vacancies) => {
  return vacancies.map(
    ({
      vessel,
      vessel_build_year,
      join_date,

      ...vacancy
    }) => {
      const power = vessel?.hp && vessel?.kw ? `${vessel.hp}HP/${vessel.kw}KW` : '—'

      return {
        ...vacancy,

        vessel,
        power,

        vesselBuildYear: vessel_build_year ? format(new Date(vessel_build_year), 'yyyy') : null,
        joinDate: join_date.replaceAll('/', ' - '),
      }
    }
  )
}

export const vacanciesListHelper = (vacancies) => {
  return vacancies
}

export const salaryCurrenciesHelper = (salaryCurrencies) => {
  return salaryCurrencies.map((salaryCurrency) => {
    return {
      id: salaryCurrency.id,
      name: salaryCurrency.symbol || '-',
    }
  })
}

export const determinationFullnessVacancy = (vacancy) => {
  const { vessel } = vacancy
  const arr = []
  if (vessel.hasOwnProperty('engine_type', 'dwt', 'grt', 'flag', 'year_of_build', 'teu', 'kw', 'crew_nationality')) {
    const { engine_type, dwt, grt, flag, year_of_build, teu, kw, crew_nationality } = vessel
    arr.push(engine_type, kw, teu, dwt, year_of_build, crew_nationality, grt, flag)
  }
  return arr
}
