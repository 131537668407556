import React from 'react'

import { useSelector } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as VacanciesSearchIcon } from '../../assets/icons/vacanciesPageIcon.svg'
import { ReactComponent as DocumentsPageIcon } from '../../assets/icons/documentsPage.svg'
// import { ReactComponent as StoreIcon } from '../../assets/icons/store.svg'

import UserIcon from '../../UI/Icons/UserIcon'
import MessagesIcon from '../../UI/Icons/MessagesIcon'
import PortfolioSvg from '../../UI/Icons/PortfolioSvg'
import NotificationIcon from '../../UI/Icons/NotificationIcon'
import StoreIcon from '../../UI/Icons/StoreIcon'
import SettingsIcon from '../../UI/Icons/SettingsIcon'

import useBillingActive from '../../hooks/useBillingActive'
import { profilePaths } from '../../constants/constans'

const useNavigationProfile = () => {
  const messagesCount = useSelector(({ chat }) => chat.global_undelivered)
  const eventsCount = useSelector(({ notifications }) => notifications.events_counter_total)
  const eventRequiresConfirmation = useSelector(({ billing }) => billing.event_requires_confirmation)
  const isBillingActive = useBillingActive()

  const navigation = {
    [profilePaths.vacancies]: {
      icon: <VacanciesSearchIcon />,
      title: 'Vacancies',
      paths: [profilePaths.vacancies, profilePaths.applied_jobs, profilePaths.user_card],
    },
    [profilePaths.general]: {
      icon: <UserIcon />,
      title: 'Profile',
      paths: [
        profilePaths.general,
        profilePaths.address,
        profilePaths.biometrics,
        profilePaths.my_contacts,
        profilePaths.relatives_contacts,
        profilePaths.video_resume,
      ],
    },
    [profilePaths.education]: {
      icon: <DocumentsPageIcon />,
      title: 'Documents',
      paths: [
        profilePaths.education,
        profilePaths.experience,
        profilePaths.passports,
        profilePaths.visas,
        profilePaths.licenses,
        profilePaths.certificates,
      ],
    },
    [profilePaths.chat]: {
      icon: (
        <span className={cn({ [styles.notSeen]: messagesCount })}>
          <MessagesIcon />
        </span>
      ),
      title: 'Chats',
    },
    [profilePaths.reviews]: {
      icon: <PortfolioSvg />,
      title: 'Reviews',
      paths: [profilePaths.reviews, profilePaths.request],
    },
    [profilePaths.list]: {
      icon: (
        <span className={cn({ [styles.notSeen]: eventsCount })}>
          <NotificationIcon />
        </span>
      ),
      title: 'Events',
    },

    ...(isBillingActive && {
      [profilePaths.billing_services]: {
        icon: (
          <span className={cn({ [styles.notSeen]: eventRequiresConfirmation })}>
            <StoreIcon />
          </span>
        ),
        title: 'Store',
        paths: [profilePaths.billing_services, profilePaths.billing_history],
      },
    }),

    [profilePaths.settings]: {
      icon: <SettingsIcon />,
      title: 'Settings',
    },
  }

  return { navigation }
}

export default useNavigationProfile
