import React from 'react'

import mtButton from './mtButton.module.scss'

import PropTypes from 'prop-types'

import Button from '../../../Button/Button.jsx'

const MTButton = ({ onClick, disabled = false, children, Icon }) => {
  return (
    <Button type='button' size='middle-full' onClick={onClick} className={mtButton.button} disabled={disabled}>
      {children}

      {Icon && <Icon />}
    </Button>
  )
}

MTButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  Icon: PropTypes.element,
}

export default MTButton
