import React, { memo, useCallback, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import styles from './styles.module.scss'
import cn from 'classnames'
import { CardElement } from '@stripe/react-stripe-js'
import Input from '../../../../UI/Input/Input'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import Button from '../../../../UI/Button/Button'
import CardPreview from './CardPreview/CardPreview'
import Spinner from '../../../../UI/Spinner/Spinner'
import CardSystems from './CardSystems/CardSystems'

import { ReactComponent as ArrowIcon } from './icons/arrow.svg'

import { getGooglePlacesAddress, getGooglePlacesCities } from '../../../../api/getApi'
import { useNotify } from '../../../../hooks/useNotify'
import { isEveryFieldFilled } from '../../../../helpers/helpers'

import { initialValues, validationSchema, cardElementOptions, tooltipContent } from './config'
import { StyledTooltip } from '../../../../UI/Tooltip/Tooltip.config'
import ButtonPayComponent from '../ButtonPayComponent/ButtonPayComponent'

const Form = memo(
  ({
    stripe,
    elements,
    onSubmit,
    onReady,
    onCardDetach,
    countries,
    card,
    billingDetails,
    sum,
    totalPriceForButton,
    markedPaidIds,
    markedUnpaidIds,
    isLoadingSum,
    paddingBottom,
  }) => {
    const [autocompleteItems, setAutocompleteItems] = useState([])
    const [cardErrorMessage, setCardErrorMessage] = useState('')
    const [paymentSystem, setPaymentSystem] = useState(card.brand ?? '')
    const [isCardElementFocused, setIsCardElementFocused] = useState(false)
    const [isCardComplete, setIsCardComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visibility, setVisibility] = useState(false)
    const { notify } = useNotify()

    const formik = useFormik({
      validationSchema: card.id ? null : validationSchema,
      initialValues: { ...initialValues, ...billingDetails },
      enableReinitialize: true,

      onSubmit: async (values) => {
        setLoading(true)

        try {
          // if the card field is not filled in or the card is not linked
          if (!isCardComplete && !card.id) return

          if (!card.id) {
            await createPaymentMethod()

            return
          }

          await onSubmit(values)
        } catch (error) {
          notify.errorsList(error.errors)
        } finally {
          setLoading(false)
        }
      },
    })

    const { values, errors, touched, isSubmitting, setErrors } = formik

    useEffect(() => {
      const element = elements?.getElement(CardElement)

      if (!element) return

      element.on('focus', () => setIsCardElementFocused(true))
      element.on('blur', () => setIsCardElementFocused(false))

      if (!card.id) setPaymentSystem('')
      if (card.id) {
        setErrors({})
        setCardErrorMessage('')
      }
    }, [card.id, elements])

    useEffect(() => {
      if (!isCardComplete && isSubmitting && !cardErrorMessage && !card.id)
        setCardErrorMessage('This field is required')

      // eslint-disable-next-line
    }, [isSubmitting])

    useEffect(() => {
      if (totalPriceForButton > 0) {
        setVisibility(true)
      } else {
        setVisibility(false)
      }
    }, [totalPriceForButton, sum, isLoadingSum, markedPaidIds, markedUnpaidIds])

    const createPaymentMethod = async () => {
      try {
        const { error } = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })

        if (error) {
          setCardErrorMessage(error.message)

          return
        }

        await onSubmit(values)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    const clearValuesByField = (name) => {
      switch (name) {
        case 'country':
          formik.setValues({
            ...values,
            city: initialValues.city,
            street: initialValues.street,
            zipCode: initialValues.zipCode,
          })

          break

        case 'city':
          formik.setValues({
            ...values,
            street: initialValues.street,
            zipCode: initialValues.zipCode,
          })

          break

        case 'street':
          formik.setValues({
            ...values,
            zipCode: initialValues.zipCode,
          })

          break

        default:
          break
      }
    }

    const handleFormikChange = (e) => {
      const { name } = e.target

      clearValuesByField(name)

      if (!touched[name]) {
        formik.setFieldTouched(name, true)
      }

      formik.handleChange(e)
    }

    const getCities = useCallback(
      async (city) => {
        try {
          const iso2 = values.country.iso2.toLowerCase()

          const response = await getGooglePlacesCities(city, iso2)

          setAutocompleteItems(response)
        } catch (error) {
          notify.errorsList(error.errors)
        }
      },

      // eslint-disable-next-line
      [values.country.iso2]
    )

    const getAddress = useCallback(
      async (address) => {
        try {
          const id = values.city.id

          if (!id) return

          const response = await getGooglePlacesAddress(address, id)

          setAutocompleteItems(response)
        } catch (error) {
          notify.errorsList(error.errors)
        }
      },

      // eslint-disable-next-line
      [values.city.id]
    )

    const getPlacesItems = useCallback(
      (name, value) => {
        setAutocompleteItems([])

        if (value.length > 2) {
          if (name === 'city') {
            getCities(value)

            return
          }

          if (name === 'street') {
            getAddress(value)
          }
        }
      },
      [getAddress, getCities]
    )

    const handleAutocompleteChange = (name, { target }) => {
      const value = { name: target.value, id: null }

      handleFormikChange({ target: { name, value } })

      getPlacesItems(name, target.value)
    }

    const handleAutocompleteFocus = useCallback(
      (type, { target }) => {
        getPlacesItems(type, target.value)
      },
      [getPlacesItems]
    )

    const handleItemClick = (name, value) => {
      handleFormikChange({ target: { name, value } })
    }

    const handleCardChange = useCallback((e) => {
      const { error, brand, complete } = e

      setPaymentSystem(brand === 'unknown' ? '' : brand)
      setIsCardComplete(complete)

      if (error) {
        setCardErrorMessage(error.message)
        setPaymentSystem('')

        return
      }

      setCardErrorMessage('')
    }, [])

    const isFieldsComplete = isEveryFieldFilled(values, true)
    const isButtonDisabled = Boolean(cardErrorMessage || !sum || isLoadingSum || loading)

    return (
      <form onSubmit={formik.handleSubmit} className={styles.form} style={{ paddingBottom: paddingBottom }}>
        {Boolean(card.id) && (
          <StyledTooltip title={tooltipContent || 'some text'} placement='top'>
            <button
              type='button'
              theme='text'
              className={styles.editPaymentInformation}
              onClick={onCardDetach}
              disabled={isLoadingSum}
            >
              Delete payment information
            </button>
          </StyledTooltip>
        )}
        {Boolean(!card.id && markedPaidIds.length && !sum && !isLoadingSum) && (
          <Button
            type='button'
            theme='text'
            className={styles.editPaymentInformation}
            // if we have purchased goods and there are no new ones to purchase, we do not call this function
            // card replacement logic is executed
            onClick={createPaymentMethod}
            disabled={!isFieldsComplete || !isCardComplete}
          >
            Save payment information
          </Button>
        )}

        <h4 className={styles.title}>payment information</h4>

        <CardSystems paymentSystem={paymentSystem} />

        <div className={cn(styles.fieldsWrapper, { [styles.fieldsDisabled]: Boolean(card.id && isFieldsComplete) })}>
          <div className={styles.cardElementWrapper}>
            {Boolean(!card.id) && (
              <CardElement
                onChange={handleCardChange}
                className={cn(styles.cardElement, {
                  [styles.cardElement__error]: Boolean(cardErrorMessage),
                  [styles.cardElement__focus]: isCardElementFocused,
                  [styles.cardElement__hide]: Boolean(card.id),
                })}
                options={cardElementOptions}
              />
            )}

            {Boolean(card.id) && <CardPreview last4={card.last4} exp_m={card.exp_month} exp_y={card.exp_year} />}
            {Boolean(cardErrorMessage) && !card.id && (
              <span className={styles.cardErrorMessage}>{cardErrorMessage}</span>
            )}
          </div>

          <Input
            name='name'
            placeholder='Cardholder Name'
            value={values.name}
            onChange={handleFormikChange}
            isInvalid={Boolean(errors.name && touched.name)}
            errorMessage={errors.name}
          />

          <SelectSearch
            name='country'
            placeholder='country'
            id={values.country.id}
            className={cn(styles.fieldCountry, styles.field, {
              [styles.fieldCountry__invalid]: Boolean(errors.country?.id && touched.country),
            })}
            items={countries}
            onClick={handleItemClick}
            selectedItem={values.country.name}
            isInvalid={Boolean(errors.country?.id && touched.country)}
            errorMessage={errors.country?.id}
          />

          <Autocomplete
            name='city'
            placeholder='City'
            className={styles.field}
            value={values.city.name}
            items={autocompleteItems}
            onChange={handleAutocompleteChange}
            onFocus={handleAutocompleteFocus}
            onClick={handleItemClick}
            isInvalid={Boolean(errors.city?.name && touched.city && values.country.id)}
            errorMessage={errors.city?.name}
            disabled={!values.country.id}
          />

          <Autocomplete
            name='street'
            placeholder='Street'
            className={styles.field}
            value={values.street.name}
            items={autocompleteItems}
            onChange={handleAutocompleteChange}
            onFocus={handleAutocompleteFocus}
            onClick={handleItemClick}
            isInvalid={Boolean(errors.street?.name && touched.street && values.country.id)}
            errorMessage={errors.street?.name}
            disabled={!values.city.name}
          />

          <div className={styles.fieldsRow}>
            <Input
              type='phone'
              name='phone'
              placeholder='Phone number'
              value={values.phone}
              onChange={handleFormikChange}
              isInvalid={Boolean(errors.phone && touched.phone)}
              errorMessage={errors.phone}
            />

            <Input
              name='zipCode'
              value={values.zipCode}
              onChange={handleFormikChange}
              placeholder='ZIP Code'
              isInvalid={Boolean(errors.zipCode && touched.zipCode && values.street.name)}
              errorMessage={errors.zipCode}
              disabled={!values.street.name}
            />
          </div>
        </div>
        <p className={styles.withdrawMoney}>withdraw money from this card monthly</p>

        <div className={cn(styles.express_checkout, { [styles.express_checkout_visible]: visibility })}>
          <ButtonPayComponent
            onReady={onReady}
            stripe={stripe}
            elements={elements}
            totalAmount={sum}
            markedPaidIds={markedPaidIds}
            markedUnpaidIds={markedUnpaidIds}
            onConfirm={onSubmit}
          />
        </div>

        <div className={styles.bottom}>
          <div className={styles.total}>{!isLoadingSum ? `total $${sum}` : <Spinner />}</div>

          <Button type='submit' className={cn(styles.button, { [styles.button__disabled]: isButtonDisabled })}>
            <span>subscribtion now</span>

            <ArrowIcon />
          </Button>
        </div>
      </form>
    )
  }
)

export default Form
