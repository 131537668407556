import React, { memo, useCallback, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

import Input from '../../../../UI/Input/Input'
import CheckBox from '../../../../UI/CheckBox/CheckBox'
import { TrashIcon } from '../../../../UI/Icons/Icons'

import { setAllSelected, setAllUnselected } from '../../../../redux/actions/chat.action'

export const Header = memo(({ dialogs, search, setSearch, onDelete }) => {
  const dispatch = useDispatch()

  const isAllSelected = useMemo(() => dialogs.list.every((item) => item.is_selected), [dialogs.list])
  const isSomeSelected = useMemo(() => dialogs.list.some((item) => item.is_selected), [dialogs.list])

  const handleAllClick = useCallback(() => {
    if (isAllSelected) {
      dispatch(setAllUnselected())
    } else {
      dispatch(setAllSelected())
    }

    // eslint-disable-next-line
  }, [isAllSelected])

  return (
    <>
      <div className={styles.header}>
        <Input value={search} onChange={({ target }) => setSearch(target.value)} type='text' placeholder='Search' />
      </div>

      <div className={styles.controls}>
        {isSomeSelected && (
          <>
            <CheckBox
              textStyles={{ color: 'var(--color-accent-light)', fontWeight: 600, fontSize: 14 }}
              theme='bordered'
              checked={isAllSelected}
              onClick={handleAllClick}
            >
              Select all
            </CheckBox>

            <button onClick={onDelete} className={styles.delete}>
              <TrashIcon height={13} width={11} stroke='var(--color-white)' />

              <span>Delete</span>
            </button>
          </>
        )}

        {!isSomeSelected && `All messages ${dialogs.list.length}`}
      </div>
    </>
  )
})
