import React from 'react'
import styles from '../../tabs/review/RequestForReviews/RequestForReviews.module.scss'
import ReviewsRequestsListItem from './ReviewsRequestsListItem'
import Masonry from 'react-responsive-masonry'

function ReviewsRequestsList({ requests, filter, handleScroll }) {
  return (
    <>
      <div className={styles.requestCardsContainer} onScroll={handleScroll}>
        <Masonry columnsCount={2} gutter='25px' className={styles.requestCards}>
          {requests
            .filter((item) => filter === 'all' || item.category.toLowerCase() === filter.toLowerCase())
            .map((item) => (
              <ReviewsRequestsListItem data={item} key={item.id} filter={filter} />
            ))}
        </Masonry>
      </div>
    </>
  )
}

export default ReviewsRequestsList
