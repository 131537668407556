import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import styles from './vacancyList.module.scss'

import Vacancy from '../Vacancy/Vacancy'
import { vacancyType } from '../VacancyForm/config'
import VacancyLand from '../Vacancy/VacancyLand'
import { fetchVacancyView } from '../../../api/getApi'
import useHeightWithScroll from '../../../hooks/useHeightWithScroll'
import { createItemRef } from '../../../helpers/createItemRef'
import cn from 'classnames'

const VacancyList = forwardRef(({ vacancies, count, onScroll, appliedJobs }, ref) => {
  const [openItemId, setOpenItemId] = useState(null)
  const [animationEnd, setAnimationEnd] = useState(0)

  const { divRef, heightWithScroll } = useHeightWithScroll({ state: animationEnd })

  const refs = createItemRef(vacancies)

  const heightVacancyList = 415

  const HandleOpenVacancy = useCallback((id, isOpen) => {
    setOpenItemId(isOpen ? null : id)
    if (!isOpen) fetchVacancyView(id)
  }, [])

  useEffect(() => {
    if (openItemId && animationEnd) {
      const childElement = refs[openItemId].current
      const parentElement = divRef.current

      parentElement.scrollTop = childElement.offsetTop - parentElement.offsetTop
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemId, animationEnd])

  const handleAnimationEnd = ({ newHeight }) => {
    setAnimationEnd(newHeight)
  }
  return (
    <div className={styles.vacanciesListWrapper}>
      <div className={styles.vacanciesListHeader}>
        <p className={styles.vacanciesListTitle}>All results {count ? `(${count})` : ''}</p>
      </div>

      <div
        ref={divRef}
        className={cn(styles.vacanciesListBody, { [styles.vacancy_list_scroll]: heightWithScroll > heightVacancyList })}
        onScroll={onScroll}
      >
        {vacancies.length ? (
          vacancies.map((vacancy) => {
            if (vacancy.vacancy_type === vacancyType.land) {
              return (
                <VacancyLand
                  key={vacancy.id}
                  refs={refs[vacancy.id]}
                  vacancy={vacancy}
                  appliedJobs={appliedJobs}
                  count={count}
                  onClick={HandleOpenVacancy}
                  isOpen={openItemId === vacancy.id}
                  onAnimationEnd={handleAnimationEnd}
                  isScrollList={heightWithScroll > heightVacancyList}
                />
              )
            }

            return (
              <Vacancy
                key={vacancy.id}
                refs={refs[vacancy.id]}
                vacancy={vacancy}
                appliedJobs={appliedJobs}
                count={count}
                onClick={HandleOpenVacancy}
                isOpen={openItemId === vacancy.id}
                onAnimationEnd={handleAnimationEnd}
                isScrollList={heightWithScroll > heightVacancyList}
              />
            )
          })
        ) : (
          <p className={styles.emptyResult}>No such results</p>
        )}
      </div>
    </div>
  )
})

export default VacancyList
