import React from 'react'

import styles from './chatEmpty.module.scss'

import { ReactComponent as EmptyChatIcon } from './icons/chat_empty_icon.svg'

import Button from '../../../../UI/Button/Button'

import { profilePaths } from '../../../../constants/constans'

const ChatEmpty = () => {
  return (
    <div className={styles.block}>
      <div className={styles.content}>
        <EmptyChatIcon className={styles.icon} />

        <div className={styles.textContent}>
          <h3 className={styles.textContentTitle}>You have no messages</h3>

          <p className={styles.textContentCaption}>
            Send a request for vacancies of interest and wait for a message from the company manager
          </p>
        </div>

        <Button className={styles.button} link to={profilePaths.vacancies} size='middle'>
          Go to vacancies
        </Button>
      </div>
    </div>
  )
}

export default ChatEmpty
