import React from 'react'
import { useEffect } from 'react'
import { confirmUnsubscribe } from '../api/postApi'
import { useState } from 'react'
import ModalAlert from '../components/ModalAlert/ModalAlert'
import WithPreloader from '../HOC/WithPreloader'
import { notify } from '../helpers/notify'

const Unsubscribe = () => {
  const [isUnsubscribeSuccess, setIsUnsubscribeSuccess] = useState(null)
  const [error, setError] = useState('Something went wrong. Try again.')

  const getUnsubscribeToken = () => {
    const searchUrl = new URL(window.location.href)

    const decodedSearch = searchUrl.search.replace(/&amp;/g, '&')

    const decodedUrl = new URL('http://localhost:3003/seafarer-unsubscribe/' + decodedSearch)

    const token = decodeURIComponent(decodedUrl.searchParams.get('token'))
    const uid = decodeURIComponent(decodedUrl.searchParams.get('uid'))
    const notification = decodeURIComponent(decodedUrl.searchParams.get('notification'))

    return {
      uid,
      token,
      notification,
    }
  }

  useEffect(() => {
    confirmUnsubscribe(getUnsubscribeToken())
      .then((data) => {
        setIsUnsubscribeSuccess(data.status)
      })
      .catch(({ errors }) => {
        setError(errors[0].message[0])

        setIsUnsubscribeSuccess(false)
        notify.error(errors[0].message[0])
      })
  }, [])

  return (
    <div className='page' style={{ height: '100%' }}>
      <main>
        <WithPreloader
          component={ModalAlert}
          isActive={isUnsubscribeSuccess === null}
          size={120}
          type='magnifier'
          isOk={isUnsubscribeSuccess}
          title={isUnsubscribeSuccess ? 'Success!' : 'Oops..'}
          content={isUnsubscribeSuccess ? 'Your email successfully unsubscribed.' : error}
        />
      </main>
    </div>
  )
}

export default Unsubscribe
