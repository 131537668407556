import React from 'react'

import styles from '../../styles.module.scss'

const EnhancedVisibilityActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>
        VIP status activated! Now your profile will be displayed as one of the first in the general list of seafarers
      </p>
    </div>
  )
}

export default EnhancedVisibilityActivated
