import { TYPES } from './ui.types'

const initialState = {
  trial_passed: null,
  isSocketConnected: false,
}

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.UPDATE_INFO:
      return {
        ...state,
        ...action.payload,
      }

    case TYPES.SET_INFO: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case TYPES.SET_IS_SOCKET_CONNECTED: {
      const { status } = action.payload

      return {
        ...state,
        isSocketConnected: status,
      }
    }

    default:
      return state
  }
}
