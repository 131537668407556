import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const AccentButton = ({ name, title, text, icon, url, disabled = false }) => {
  const firstWordTitle = title.split(' ')[0]
  const endTitle = title
    .split(' ')
    .filter((item, index) => index !== 0)
    .join(' ')

  return (
    <Link to={url} className={cn(styles.button, { [styles.disabled]: disabled })}>
      <div className={styles.background_icon}>
        <img src={icon} alt='icon' />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.title}>
          <span>{firstWordTitle} </span>
          {endTitle}
        </div>
        <div className={styles.text}>{text}</div>
      </div>
    </Link>
  )
}

AccentButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default AccentButton
