import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserCardHelper from '../../helpers/userCard.helper'
import WithPreloader from '../../HOC/WithPreloader'
import CardHeader from './CardHeader/CardHeader'
import ContactInfo from './ContactInfo/ContactInfo'
import Education from './Education/Education'
import Experience from './Experience/Experience'
import Passports from './Passports/Passports'
import Visas from './Visas/Visas'
import Licenses from './Licenses/Licenses'
import Certificates from './Certificates/Certificates'
import SizeInformation from './SizeInformation/SizeInformation'
import Reviews from './Reviews/Reviews'
import VideoResume from './VideoResume/VideoResume'
import styles from './UserCard.module.scss'
import './slider-style.scss'
import { clearUserCardState, getUserCard } from '../../redux/actions/userCard.action'
import { UserPreloaderDoubleItem as preloaderDoubleItem } from './UserPreloaderItems/UserPreloaderDoubleItem/UserPreloaderDoubleItem'
import { UserPreloaderDoubleColumns as preloaderDoubleColumns } from './UserPreloaderItems/UserPreloaderDoubleColumns/UserPreloaderDoubleColumns.jsx'
import Colleagues from './Colleagues/Colleagues'
import { Tab, Tabs, tabsClasses } from '@mui/material'

const navigation = [
  { id: 0, title: 'Contact Info', component: ContactInfo, preloader: preloaderDoubleItem },
  { id: 1, title: 'Education', component: Education, preloader: preloaderDoubleItem },
  { id: 2, title: 'Work Experience', component: Experience, preloader: preloaderDoubleColumns },
  { id: 3, title: 'Passports', component: Passports, preloader: preloaderDoubleItem },
  { id: 4, title: 'Visas', component: Visas, preloader: preloaderDoubleItem },
  { id: 5, title: 'Licenses', component: Licenses, preloader: preloaderDoubleColumns },
  { id: 6, title: 'Certificates', component: Certificates, preloader: preloaderDoubleItem },
  { id: 7, title: 'Size information', component: SizeInformation, preloader: preloaderDoubleItem },
  { id: 8, title: 'Reviews', component: Reviews, preloader: preloaderDoubleItem },
  { id: 9, title: 'Video Resume', component: VideoResume },
  { id: 10, title: 'Colleagues', component: Colleagues },
]

export default function UserCard() {
  const dispatch = useDispatch()

  const ref = useRef()
  const [currentTab, setCurrentTab] = useState('Contact Info')
  const data = useSelector(({ userCard }) => userCard)
  const { loading, loadingGeneral } = useSelector(({ userCard }) => userCard)
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setCurrentTab(navigation.find((item) => item.id === newValue).title)
  }

  React.useEffect(() => {
    dispatch(getUserCard('general'))
    return () => {
      dispatch(clearUserCardState())
    }
  }, [dispatch])

  useEffect(() => dispatch(getUserCard(UserCardHelper.transformTitleToQueryParameter(currentTab))), [
    currentTab,
    dispatch,
  ])

  const { component, preloader } = navigation.find((r) => r.title === currentTab)

  return (
    <section className={styles.user_card}>
      <div className={styles.card} ref={ref}>
        {data && <CardHeader generalUserCardInfo={data.general} loading={loadingGeneral} />}
        <div className={styles.nav}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={'scrollable'}
            indicatorColor='#222'
            textColor='#222'
            sx={{
              alignItems: 'center',
              gap: '8px',
              [`& .MuiTab-root`]: {
                fontFamily: 'Montserrat, sans-serif',
                padding: '8px 24px',
                letterSpacing: 'normal',
                lineHeight: 'normal',
                minHeight: '17px',
                textTransform: 'capitalize',
                // color: '#222',
              },
              [`& .${tabsClasses.scroller}`]: {
                order: '1',
                borderBottom: '1px solid #DFF0EB',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                order: 2,
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: '#43927C',
                color: '#fff',
                '&.Mui-disabled': { opacity: 0.3 },
              },
              [`& .Mui-selected`]: {
                fontWeight: 600,
              },
              [`& .${tabsClasses.indicator}`]: {
                backgroundColor: '#43927C',
                height: '2px',
                zIndex: 1,
              },
            }}
          >
            {navigation.map((item, index) => {
              return <Tab key={index} label={item.title} value={item.value} />
            })}
          </Tabs>
        </div>
        <div className={styles.content}>
          <WithPreloader size={50} isActive={loading} userCardData={data} component={component} preloader={preloader} />
        </div>
      </div>
    </section>
  )
}
