import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SectionForgotPass from "../components/sections/SectionForgotPass/SectionForgotPass";

const ForgotPassPage = () => {
  return (
    <div className="page page-line-bg forgot-pass-page">
      <Header />
      <main style={{ paddingBottom: "100px" }}>
        <div className="container">
          <SectionForgotPass />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ForgotPassPage;
