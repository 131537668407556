import React, { useState } from 'react'
import { useFormik } from 'formik'
import { getHelper, generalHelper } from './general.helper'
import { isEqual } from 'lodash'
import Input from '../../UI/Input/Input'
import { convertDateFromGet, getMaxAdultDate, getMinDate, today } from '../../helpers/timeHelper'
import SelectSearch from '../../UI/Select/SelectSearch'
import Autocomplete from '../../UI/Select/Autocomplete'
import Select from '../../UI/Select/Select'
import Button from '../../UI/Button/Button'
import Radio from '../../UI/Input/Radio'
import Tooltip from '../../UI/Tooltip/Tooltip'
import { putProfileGeneral } from '../../api/putApi'
import { getProfileFetch } from '../../redux/actions/user.action'
import { useDispatch } from 'react-redux'
import { getAuthProfile, getAuthProfileFilters, getGooglePlacesCities, getLocationUser } from '../../api/getApi'
import { useNotify } from '../../hooks/useNotify'
import KeyBoardDatePicker from '../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import { description, initialValues, validationSchema } from './config'
import styles from '../Documents/form.module.scss'
import { isCompletelyFilled } from '../../helpers/objectsHelper'
import { UKRAINE_NATIONALITY } from '../../constants/constans'
import cn from 'classnames'
import { addDays } from 'date-fns'

const CONFIG = {
  NATIONALITY: 'nationality',
}

function GeneralForm() {
  const { notify } = useNotify()
  const dispatch = useDispatch()
  const [autocompleteItems, setAutocompleteItems] = useState([])
  const [filters, setFilters] = useState({})
  const [isButtonEnable, setIsButtonEnable] = useState(false)
  const [isWage, setIsWage] = useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isUpload, setIsUpload] = React.useState(false)
  const [isAvailableDate, setIsAvailableDate] = useState(false)
  const [formValues, setFormValues] = useState()
  const [locationItems, setLocationItems] = useState([])

  const formik = useFormik({
    initialValues: formValues ? formValues : initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      setIsButtonEnable(false)
      setIsUpload(true)
      putProfileGeneral(generalHelper(values))
        .then((data) => {
          if (data.ok) {
            notify('Success', 'Your changes were successfully saved.')
            dispatch(getProfileFetch())
            setFormValues({
              ...values,
            })
            setIsUpload(false)
          } else {
            data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
          }
        })
        .catch(({ response }) => {
          const errorsData = response.data
          errorsData.data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
        })
    },
  })

  const { errors, touched, values, dirty } = formik

  React.useEffect(() => {
    const isEqualFields = isEqual(formValues, values)

    const completelyFilled = isCompletelyFilled(values, ['first_name', 'last_name', 'rank'])
    const completelyFilledMinWage = isCompletelyFilled(values, ['salary', 'salary_rate', 'salary_currency'])
    const completelyFilledAvailableDate = isCompletelyFilled(values, ['availability_status', 'available_from'])

    setIsWage(!!values.salary || !!values.salary_rate.id || !!values.salary_currency.id)
    setIsAvailableDate(!!values.availability_status.id || !!values.available_from)

    // if (!dirty) return

    if (!isEqualFields) {
      if (isWage && isAvailableDate) {
        setIsButtonEnable(completelyFilled && completelyFilledMinWage && completelyFilledAvailableDate && dirty)
      } else if (isWage) {
        setIsButtonEnable(completelyFilled && completelyFilledMinWage && dirty)
      } else if (isAvailableDate) {
        setIsButtonEnable(completelyFilled && completelyFilledAvailableDate && dirty)
      }
      setIsButtonEnable(completelyFilled && dirty)
    } else {
      setIsButtonEnable(false)
    }
    //eslint-disable-next-line
  }, [formValues, values, isWage, isAvailableDate, dirty])

  React.useEffect(() => {
    setIsLoading(true)

    getAuthProfileFilters(
      'country',
      'marital_status',
      'rank',
      'currency',
      'salary_rate',
      'availability_status',
      'english_levels',
      'gender'
    ).then((data) => setFilters(data))

    getAuthProfile().then((data) => {
      setFormValues(
        getHelper({
          ...data,
          date_of_birth: convertDateFromGet(data.date_of_birth, 'start'),
          available_from:
            data.availability_status?.name.toLowerCase() === 'ready'
              ? today.toDate()
              : convertDateFromGet(data.available_from, 'start'),
        })
      )
      formik.resetForm()
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFieldChange = (type, data) => {
    if (data.name !== UKRAINE_NATIONALITY && type === CONFIG.NATIONALITY) {
      formik.setValues({ ...values, user_city_now: initialValues.user_city_now })
    }

    if (type === 'availability_status') {
      formik.setValues({
        ...values,
        available_from: data.name === 'ready' ? today.toDate() : addDays(new Date(), 1),
        availability_status: data,
      })
    } else {
      handleChangeFormikValue({
        target: {
          value:
            data?.iso2 && type !== 'nationality'
              ? { name: data.id ? data.name : '', id: data.id, iso2: data.iso2 }
              : data?.name
              ? { name: data.id ? data.name : '', id: data.id }
              : data,
          name: type,
        },
      })
    }
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }
    formik.handleChange(value)
  }

  const handleAutocompleteFocus = (type, { target }) => {
    getPlacesItems(target)
  }

  const handleAutocompleteClick = (type, data) => {
    handleChangeFormikValue({ target: { value: { ...values[type], ...data }, name: type } })
  }

  const getPlacesItems = (target) => {
    if (target.value.length > 2) {
      getGooglePlacesCities(target.value, formik.values.country_of_birth.iso2.toLowerCase()).then((data) => {
        setAutocompleteItems(data)
      })
    }
  }

  const handleAutocompleteChange = (type, { target }) => {
    handleChangeFormikValue({
      target: { value: { name: target.value, id: null }, name: type },
    })

    getPlacesItems(target)
  }

  const handleRadioChange = ({ target }) => {
    handleFieldChange(target.name, +target.id)
  }

  const getCurrentLocationUser = async (city) => {
    try {
      if (city.length > 2) {
        const response = await getLocationUser(city)

        setLocationItems(response)
      }
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const handleLocationChange = (name, { target }) => {
    handleChangeFormikValue({
      target: { value: { name: target.value, id: null }, name },
    })

    getCurrentLocationUser(target.value)
  }

  const handleLocationFocus = (_, { target }) => {
    getCurrentLocationUser(target.value)
  }

  const handleLocationClick = (name, value) => {
    handleChangeFormikValue({ target: { value, name } })
  }

  const isUkraineNationality = values.nationality.name === UKRAINE_NATIONALITY
  const isDisabled = isLoading || isUpload

  return (
    <form
      style={{
        maxWidth: '574px',
        width: '100%',
        filter: isDisabled ? 'opacity(0.4)' : 'none',
        pointerEvents: isDisabled ? 'none' : 'auto',
        cursor: isDisabled ? 'default' : 'pointer',
      }}
      onSubmit={formik.handleSubmit}
    >
      <div className='profile-form-main'>
        <div className='profile-form-fields'>
          <div className='profile-form-column'>
            <div className='field-item'>
              <Input
                name='first_name'
                label='First Name'
                value={values.first_name || ''}
                onChange={handleChangeFormikValue}
                required
                isInvalid={errors.first_name && touched.first_name}
              />

              {errors.first_name && touched.first_name && <p className={styles.formItemError}>{errors.first_name}</p>}
            </div>
            <div className='field-item'>
              <Input
                name='last_name'
                label='Last Name'
                value={values.last_name || ''}
                onChange={handleChangeFormikValue}
                required
                isInvalid={errors.last_name && touched.last_name}
              />

              {errors.last_name && touched.last_name && <p className={styles.formItemError}>{errors.last_name}</p>}
            </div>
            <div className='field-item'>
              <Input
                name='middle_name'
                onChange={handleChangeFormikValue}
                label='Middle Name'
                value={values.middle_name || ''}
                isInvalid={errors.middle_name && touched.middle_name}
              />
            </div>
            <div className='field-item'>
              <KeyBoardDatePicker
                formik={formik}
                keyBoardLabel='Date of birth'
                value={values.date_of_birth}
                nameOfValue={'date_of_birth'}
                minDate={getMinDate}
                maxDate={getMaxAdultDate}
                isInvalid={!!(errors.date_of_birth || (errors.date_of_birth && touched.date_of_birth))}
                errorMessage={errors.date_of_birth}
                viewFormat={'spacious'}
              />
            </div>
            <div className='field-item'>
              <SelectSearch
                label='Country of Birth'
                name={'country_of_birth'}
                id={values.country_of_birth.id}
                selectedItem={values.country_of_birth.name}
                onClick={(type, data) => {
                  const countryName = data.name
                  if (countryName === 'Country') {
                    formik.setValues({
                      ...formik.values,
                      city_of_birth: { name: '', id: null },
                    })
                  } else {
                    if (countryName === formik.values.country_of_birth.name) {
                      return null
                    } else {
                      formik.setValues({
                        ...formik.values,
                        city_of_birth: { name: '', id: null },
                      })
                    }
                  }
                  handleFieldChange(type, data)
                }}
                items={filters.country || []}
              />
            </div>
            <div className='field-item'>
              <Autocomplete
                autoComplete={'new-password'}
                label='City of Birth'
                name='city_of_birth'
                disabled={!values.country_of_birth.iso2}
                onFocus={handleAutocompleteFocus}
                id={values.city_of_birth?.id}
                value={values.city_of_birth?.name}
                onChange={handleAutocompleteChange}
                onClick={handleAutocompleteClick}
                isInvalid={Boolean(errors.city_of_birth?.id && touched.city_of_birth)}
                errorMessage={errors.city_of_birth?.id}
                items={autocompleteItems}
              />
            </div>

            <div className='field-item'>
              <SelectSearch
                label='Citizenship'
                name='nationality'
                id={values.nationality.id}
                selectedItem={values.nationality.name}
                onClick={handleFieldChange}
                items={filters.country || []}
              />
            </div>

            <div className='field-item'>
              <Autocomplete
                autoComplete='location_now'
                label='Location now (Ukrainians only)'
                name='user_city_now'
                onFocus={handleLocationFocus}
                placeholder='Location now (Ukrainians only)'
                value={values.user_city_now?.name}
                onChange={handleLocationChange}
                onClick={handleLocationClick}
                items={locationItems}
                isInvalid={Boolean(errors.user_city_now?.id && touched.user_city_now)}
                errorMessage={errors.user_city_now?.id}
                disabled={!isUkraineNationality}
                description={description}
              />
            </div>
          </div>

          <div className='profile-form-column'>
            <div className='sex-field'>
              <label className='sex_label' htmlFor={'sex'}>
                Sex
              </label>
              <div id={'sex'} role='group' aria-labelledby='my-radio-group' className='radio-group'>
                {filters.gender &&
                  filters.gender.map(({ name, id }) => {
                    return (
                      <label key={id}>
                        <Radio
                          onChange={handleRadioChange}
                          name='gender'
                          isChecked={values.gender === id}
                          id={id}
                          label={name}
                        />
                      </label>
                    )
                  })}
              </div>
            </div>
            <div className='field-item'>
              <Select
                label='Marital status'
                name={'marital_status'}
                selectedItem={values.marital_status.name}
                id={values.marital_status.id}
                onClick={handleFieldChange}
                items={filters.marital_status || []}
              />
            </div>
            <div className='field-item'>
              <SelectSearch
                label='Rank'
                name='rank'
                selectedItem={values.rank.name}
                id={values.rank.id}
                required
                isInvalid={errors.rank?.name && touched.rank}
                onClick={handleFieldChange}
                items={filters.rank}
              />
            </div>
            <div className='field-item'>
              <SelectSearch
                label='Additional Rank'
                name='rank_secondary'
                selectedItem={values.rank_secondary.name}
                id={values.rank_secondary.id}
                onClick={handleFieldChange}
                items={filters.rank}
              />
            </div>
            <div className='field-item-row'>
              <div className='field-item'>
                <Input
                  type='number'
                  required={values.salary_currency?.id || values.salary_rate?.id}
                  onChange={handleChangeFormikValue}
                  name='salary'
                  label='Min Wage'
                  value={values.salary !== 'null' ? values.salary : ''}
                  isInvalid={!!(errors.salary && touched.salary)}
                />
              </div>

              <div className='field-item'>
                <Select
                  required={values?.salary !== 'null' && values.salary?.length > 0}
                  label='Cur'
                  name='salary_currency'
                  selectedItem={values.salary_currency.name}
                  id={values.salary_currency.id}
                  isInvalid={!!(errors.salary_currency && touched.salary_currency)}
                  onClick={handleFieldChange}
                  items={filters.currency}
                />
                <div
                  className={cn(styles.general_form_error, {
                    [styles.view_error]:
                      errors.salary_currency?.name || (errors.salary_currency?.name && touched.salary_currency),
                  })}
                >
                  {errors.salary_currency?.name}
                </div>
              </div>

              <div className='field-item'>
                <Select
                  required={values?.salary !== 'null' && values.salary?.length > 0}
                  label='Rate'
                  name='salary_rate'
                  selectedItem={values.salary_rate.name}
                  id={values.salary_rate.id}
                  isInvalid={!!(errors.salary_rate && touched.salary_rate)}
                  onClick={handleFieldChange}
                  items={filters.salary_rate}
                />
                <div
                  className={cn(styles.general_form_error, {
                    [styles.view_error]: errors.salary_rate?.name || (errors.salary_rate?.name && touched.salary_rate),
                  })}
                >
                  {errors.salary_rate?.name}
                </div>
              </div>
            </div>
            <div className='field-item'>
              <Select
                required={isAvailableDate}
                label='Availability Status'
                name='availability_status'
                selectedItem={values.availability_status.name}
                id={values.availability_status.id}
                onClick={handleFieldChange}
                items={filters.availability_status}
                isInvalid={!!errors.availability_status}
              />
            </div>
            <div className='field-item'>
              <KeyBoardDatePicker
                formik={formik}
                keyBoardLabel='Available from'
                value={values.available_from}
                nameOfValue='available_from'
                required={values.availability_status.id && +values.availability_status.id !== 1}
                disabled={values.availability_status.name === 'ready' || values.availability_status.name === ''}
                minDate={today}
                isInvalid={!!(errors.available_from || (errors.available_from && touched.available_from))}
                errorMessage={errors.available_from}
                today={true}
              />
            </div>

            <div className='field-item'>
              <Select
                label='English Level'
                name={'english_level'}
                id={values.english_level.id}
                selectedItem={values.english_level.name}
                onClick={handleFieldChange}
                items={filters.english_levels}
              />

              <Tooltip>
                <div className='english-lvl'>
                  {filters.english_levels &&
                    filters.english_levels.map(({ name, CEFR, description }, index) => (
                      <React.Fragment key={index}>
                        <div className='english-lvl_title'>
                          {name} {CEFR ? `(${CEFR})` : null}
                        </div>
                        <div className='english-lvl_text'>{description}</div>
                      </React.Fragment>
                    ))}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        <Button disabled={!isButtonEnable} type='submit' size='middle-full' style={{ marginTop: 24 }}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default GeneralForm
