import { REVIEWS, REQUEST } from '../types'
import { helpRequest } from '../actions/reviews.action'

export const initialState = {
  requests: [],
  reviews: [],
}

export default function reviewsRequestReducer(state = initialState, action) {
  switch (action.type) {
    case REVIEWS.SET_REVIEWS:
      return {
        ...state,
        reviews: [...state.reviews, ...action.payload],
      }
    case REVIEWS.SET_REVIEW_ANSWERS:
      return {
        ...state,
        reviews: helpRequest(state.reviews, action),
      }
    case REVIEWS.CLEAR_REVIEWS:
      return {
        ...state,
        reviews: [],
      }
    case REVIEWS.CLEAR_REVIEW_ANSWERS:
      return {
        ...state,
        reviews: state.reviews.map((item) => {
          return {
            ...item,
            questions: null,
            description: null,
          }
        }),
      }
    case REQUEST.CLEAR_REQUEST_QUESTIONS:
      return {
        ...state,
        requests: state.requests.map((item) => {
          return {
            ...item,
            questions: null,
            description: null,
          }
        }),
      }
    case REQUEST.SET_REQUEST:
      return {
        ...state,
        requests: [...state.requests, ...action.payload],
      }
    case REQUEST.SET_REQUEST_QUESTIONS:
      return {
        ...state,
        requests: helpRequest(state.requests, action),
      }
    case REQUEST.SET_REQUEST_DESCRIPTION:
      return {
        ...state,
        requests: helpRequest(state.requests, action),
      }
    case REQUEST.CLEAR_REQUEST:
      return {
        ...state,
        requests: [],
      }
    default:
      return state
  }
}
