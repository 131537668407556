import React from 'react'

import styles from './styles.module.scss'

const QRCode = ({ QRCodeIcon, SocialMediaIcon }) => {
  return (
    <div className={styles.qrCodeWrapper}>
      <QRCodeIcon className={styles.qrCodeIcon} />

      <SocialMediaIcon className={styles.socialMediaIcon} />
    </div>
  )
}

export default QRCode
