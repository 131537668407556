import React, { useEffect, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import Banner from './Banner/Banner'
import Products from './Products/Products'
import Form from './Form/Form'
import UnsubscribeForm from '../Popups/UnsubscribeForm/UnsubscribeForm'
import PendingPaymentPopup from '../Popups/PendingPaymentPopup/PendingPaymentPopup'
import PopupNotify from '../Popups/PopupNotify/PopupNotify'
import Spinner from '../../../UI/Spinner/Spinner'

import useServicesStore from './useServicesStore'
import { getPublicStripeKey } from '../../../api/getApi'
import { loadStripe } from '@stripe/stripe-js'
import { notify } from '../../../helpers/notify'
import { Elements } from '@stripe/react-stripe-js'

const Services = () => {
  const [sum, setSum] = useState(0)

  const [options, setOptions] = useState({
    mode: 'subscription',
    amount: sum > 0 ? sum : (sum + 1) * 100,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    setupFutureUsage: 'off_session',
    appearance: {
      variables: {
        borderRadius: '4px',
      },
    },
  })
  const stripe = async () => {
    try {
      const { public_key } = await getPublicStripeKey()

      return loadStripe(public_key, { locale: 'en' })
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  useEffect(() => {
    if (sum > 0) setOptions({ ...options, amount: sum })
    //eslint-disable-next-line
  }, [sum])

  return (
    <Elements stripe={stripe()} options={options}>
      <ServicesWrapper setSum={setSum} />
    </Elements>
  )
}

const ServicesWrapper = ({ setSum }) => {
  const {
    stripe,
    elements,

    card,
    billingDetails,
    products,

    markedUnpaidProductsId,
    markedPaidProductsId,
    unProlongedProducts,
    unsubscribeProduct,
    setMarkedPaidProductsId,
    setUnProlongedProducts,
    updateProductsByEmailSubscription,

    popup,
    successProcessPayment,
    unSuccessProcessPayment,
    serviceEmailSubscription,

    pendingPaymentData,
    setPendingPaymentData,

    filters,
    totalPrice,
    totalPriceForButton,

    loading,
    isPriceLoading,
    isActiveUnsubscribeForm,

    handleSubmit,
    handleCheckBoxClick,
    handleCardDetach,
    handleReadyButtons,

    handlePopupClose,
    handleUnsubscribeFormClose,
    currentProductId,
    paymentRequest,
    paddingBottom,
  } = useServicesStore()

  useEffect(() => {
    if (totalPriceForButton > 0) setSum(totalPriceForButton)
  }, [setSum, totalPriceForButton, markedUnpaidProductsId, markedPaidProductsId, isPriceLoading])

  return (
    <div className={cn(styles.main, { [styles.slideIn]: !loading })}>
      {loading && <Spinner size={100} />}

      {!loading && (
        <>
          <div className={styles.leftBlock}>
            <Banner />

            <Products
              onCheckBoxClick={handleCheckBoxClick}
              serviceEmailSubscription={serviceEmailSubscription}
              updateProductsByEmailSubscription={updateProductsByEmailSubscription}
              products={products}
              markedPaidIds={markedPaidProductsId}
              markedUnpaidIds={markedUnpaidProductsId}
              unProlongedProducts={unProlongedProducts}
              currentProductId={currentProductId}
              isPriceLoading={isPriceLoading}
            />
          </div>

          <Form
            stripe={stripe}
            elements={elements}
            onSubmit={handleSubmit}
            onReady={handleReadyButtons}
            onCardDetach={handleCardDetach}
            countries={filters.countries}
            card={card}
            billingDetails={billingDetails}
            sum={totalPrice}
            markedPaidIds={markedPaidProductsId}
            markedUnpaidIds={markedUnpaidProductsId}
            isLoadingSum={isPriceLoading}
            paymentRequest={paymentRequest}
            paddingBottom={paddingBottom}
            totalPriceForButton={totalPriceForButton}
          />

          {isActiveUnsubscribeForm && (
            <UnsubscribeForm
              reasons={filters.reasons}
              product={unsubscribeProduct}
              successProcessPayment={successProcessPayment}
              unSuccessProcessPayment={unSuccessProcessPayment}
              updateMarkedPaidIds={setMarkedPaidProductsId}
              updateUnProlongedProducts={setUnProlongedProducts}
              onClose={handleUnsubscribeFormClose}
            />
          )}

          {Boolean(pendingPaymentData) && (
            <PendingPaymentPopup
              stripe={stripe}
              data={pendingPaymentData}
              updatePendingPaymentData={setPendingPaymentData}
              successProcessPayment={successProcessPayment}
              unSuccessProcessPayment={unSuccessProcessPayment}
            />
          )}

          {popup.isActive && <PopupNotify onClose={handlePopupClose} {...popup} />}
        </>
      )}
    </div>
  )
}

export default Services
