import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import {
  getAuthProfileFilters,
  getGooglePlacesCities,
  getGooglePlacesUniversity,
  getSeafarerEducation,
} from '../../../../api/getApi'
import { postDocumentsFile, postSeafarerEducation } from '../../../../api/postApi'
import { putProfileEducation } from '../../../../api/putApi'
import {
  addAuthDocument,
  editAuthDocument,
  editDocument,
  setDocument,
} from '../../../../redux/actions/documents.action'
import { setUserInfo } from '../../../../redux/actions/user.action'
import { useNotify } from '../../../../hooks/useNotify'
import { documentSubmitHelper, getDocumentHelper, isFile, isValidFile } from '../../../../helpers/documents.helper'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import KeyBoardDatePicker from '../../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import { getMinDate, today } from '../../../../helpers/timeHelper'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import Input from '../../../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import styles from './styles.module.scss'
import Spinner from '../../../../UI/Spinner/Spinner'
import { initialValues, getValidationSchema } from './config'
import FileUploader from '../../../FileUploader/FileUploader'
import { ReactComponent as AddFile } from '../../../../assets/icons/add_file_icon.svg'
import { confirm } from '../../../../UI/Confirm/Confirm'
import { deleteSeafarerFile } from '../../../../api/deleteApi'
import { isCompletelyFilled } from '../../../../helpers/objectsHelper'

const extensions = ['.pdf', '.jpg', '.jpeg', '.png']

const EducationForm = ({
  currentDocumentId,
  setCurrentDocumentId,
  documentFiles,
  setDocumentFiles,
  fillFields,
  setFillFields,
  authDocuments,
}) => {
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentData, setDocumentData] = React.useState()
  const [isUpload, setIsUpload] = useState(false)
  const [documentFilesForDeleting, setDocumentFilesForDeleting] = useState([])
  const [fetchedFilesLength, setFetchedFilesLength] = React.useState(0)

  const [filters, setFilters] = React.useState({
    countries: [],
    education_degree: [],
  })

  const formik = useFormik({
    initialValues: documentData && currentDocumentId ? documentData : initialValues,
    validationSchema: getValidationSchema(),
    validateOnChange: true,
    validateOnBlue: true,
    enableReinitialize: true,

    onSubmit: (document) => {
      setIsLoading(true)

      if (currentDocumentId) {
        const editEducation = async (document) => {
          try {
            const response = await putProfileEducation(document, currentDocumentId)

            if (response.errors) {
              notify.errorsList(response.errors)

              return
            }

            if (documentFiles.length || documentFilesForDeleting.length) {
              await changeFiles(currentDocumentId, editAuthDocument, 'put')
            }

            if (!documentFiles.length || !documentFilesForDeleting.length) {
              dispatch(editDocument(getSeafarerEducation, currentDocumentId))
            }

            resetForm()

            setCurrentDocumentId(null)
            setDocumentFilesForDeleting([])

            notify('Success', 'Your changes were successfully saved.')
          } catch (error) {
            notify.errorsList(error.errors)
          } finally {
            setIsLoading(false)
          }
        }
        editEducation(documentSubmitHelper(document, 'education'))
        return
      }

      const postDocument = async (document) => {
        try {
          const response = await postSeafarerEducation(document)

          if (response.errors) {
            notify.errorsList(response.errors)

            return
          }

          if (documentFiles.length) {
            await changeFiles(response.id, addAuthDocument)
          }

          if (!documentFiles.length) {
            dispatch(setDocument(getSeafarerEducation, response.id))
          }

          if (!authDocuments.length) {
            dispatch(setUserInfo())
          }
          notify('Success', 'Your education was successfully added.')

          resetForm()
        } catch (error) {
          console.error('An error occurred:', error)
        } finally {
          setIsLoading(false)
        }
      }

      postDocument(documentSubmitHelper(document, 'education'))
    },
  })

  const { values, errors, touched, dirty } = formik

  React.useEffect(() => {
    if (currentDocumentId) {
      setIsLoading(true)
      const fetchData = async () => {
        try {
          const response = await getSeafarerEducation(currentDocumentId)
          setFillFields(true)

          setDocumentData(getDocumentHelper(response, 'education'))
          formik.resetForm()

          if (response.files.length) {
            setFetchedFilesLength(response.files.length)
            setDocumentFiles(
              response.files.map((file) => {
                return {
                  ...file,
                  name: file.file_name,
                }
              })
            )
          } else {
            setFetchedFilesLength(0)
          }
        } catch (error) {
          console.error('Error fetching education document:', error)
        } finally {
          setIsLoading(false)
        }
      }
      fetchData()
    } else {
      resetForm()
      setDocumentFiles([])
      setDocumentFilesForDeleting([])
      setFillFields(false)
    }

    // eslint-disable-next-line
  }, [currentDocumentId])

  useEffect(() => {
    setIsButtonEnable(dirty)
  }, [dirty])

  useEffect(() => {
    getAuthProfileFilters('country', 'education_degree').then((filters) => {
      setFilters({
        countries: filters.country,
        educationDegrees: filters.education_degree,
      })
    })
  }, [])

  useEffect(() => {
    const fieldsToCheck = ['Education degree', 'country']
    const completelyFilled = isCompletelyFilled(values, fieldsToCheck)

    const isNewDocumentFilesObjects = documentFiles.every((obj) => 'id' in obj)
    const isFilesLengthChanged = fetchedFilesLength !== documentFiles.length

    if (currentDocumentId) {
      setIsButtonEnable(completelyFilled && (dirty || isFilesLengthChanged || !isNewDocumentFilesObjects))
    } else {
      setIsButtonEnable(completelyFilled)
    }
  }, [currentDocumentId, values, documentFiles, fetchedFilesLength, dirty])

  const resetForm = () => {
    formik.resetForm()
    setDocumentFiles([])
  }

  const changeFiles = async (documentId, action, type = 'post') => {
    setIsUpload(true)

    const promises = []

    documentFiles.forEach((file) => {
      if (isFile(file)) {
        promises.push(postDocumentsFile(file, documentId, 'education'))
      }
    })

    documentFilesForDeleting.forEach((file) => {
      promises.push(deleteSeafarerFile(file))
    })

    try {
      const response = await Promise.all(promises)
      const education = await getSeafarerEducation(documentId)

      if (type === 'post') dispatch(action(education))

      if (type === 'put') dispatch(action(education.id, education))

      if (response.some(({ errors }) => errors)) {
        response.forEach(({ errors }) => errors && notify.errorsList(errors))
      } else {
        const actionText = (() => {
          if (type === 'post') return 'added'

          if (type === 'put') return 'updated'

          return ''
        })()

        notify('Success', `Your document was successfully ${actionText}`)
      }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsUpload(false)
    }
  }

  const getPlaces = (name, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      if (name === 'city') {
        getGooglePlacesCities(target.value, values.country.iso2.toLowerCase()).then((cities) => {
          setAutocompleteItems(cities)
        })
      } else {
        getGooglePlacesUniversity(target.value, values.city.id, values.degree.name).then((degrees) => {
          setAutocompleteItems(degrees)
        })
      }
    }
  }

  const handleChangeValue = (event) => {
    if (!touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    if (event.target.name === 'field_of_study' && event.target.value?.length > 101) {
      return
    }

    formik.handleChange(event)
  }

  const handleChangeSelectValue = (name, value) => {
    if (name === 'country' && value.id !== values.country.id) {
      formik.setValues({
        ...values,
        city: { name: '', id: null },
        college: { name: '', id: null },
      })
    } else if (name === 'degree' && value.name !== values.degree?.name) {
      formik.setValues({
        ...values,
        college: { name: '', id: null },
      })
    }

    handleChangeValue({ target: { value, name } })
  }

  const handleChangeAutocompleteValue = (name, { target }) => {
    if (name === 'city') {
      formik.setValues({
        ...values,
        college: { name: '', id: null },
      })
    }

    handleChangeValue({
      target: { value: { name: target.value, id: null }, name },
    })

    getPlaces(name, target)
  }

  const handleClickAutocomplete = (name, value) => {
    handleChangeValue({ target: { value, name } })

    setAutocompleteItems([])
  }

  const handleFocusAutoComplete = (name, { target }) => {
    getPlaces(name, target)
  }

  const handleInputFileChange = ({ target }) => {
    const files = target.files
    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (documentFiles.length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          setDocumentFiles((prevState) => [...prevState, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      if (currentDocumentId) {
        if (deleteType === 'byId') {
          setDocumentFilesForDeleting((prevState) => [...prevState, id])
          setDocumentFiles((prevState) => prevState.filter((file) => file.id !== id))
        } else {
          setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
        }
      } else {
        setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
      }
    })
  }

  const isDisabled = isLoading || !fillFields || isUpload

  return (
    <form className={cn(styles.form, { [styles.form__disabled]: isDisabled })} onSubmit={formik.handleSubmit}>
      {isLoading && <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />}
      <div className={styles.formItem}>
        <SelectSearch
          label='Education degree'
          name='degree'
          id={values.degree.id}
          items={filters.educationDegrees}
          selectedItem={values.degree.name}
          onClick={handleChangeSelectValue}
          required
          disabled={!fillFields}
        />
      </div>

      <div className={styles.row}>
        <KeyBoardDatePicker
          formik={formik}
          value={values.date_from}
          nameOfValue='date_from'
          keyBoardLabel='From'
          minDate={getMinDate}
          maxDate={today}
          views={['year']}
          format='YYYY'
          isInvalid={!!(errors.date_from || (errors.date_from && touched.date_from))}
          errorMessage={errors.date_from}
          disabled={!fillFields}
          today={true}
        />

        <KeyBoardDatePicker
          formik={formik}
          value={values.date_to}
          nameOfValue='date_to'
          keyBoardLabel='Till'
          minDate={values.date_from || getMinDate}
          views={['year']}
          format='YYYY'
          isInvalid={!!(errors.date_to || (errors.date_to && touched.date_to))}
          errorMessage={errors.date_to}
          disabled={!fillFields}
          today={true}
        />
      </div>

      <div className={styles.formItem}>
        <SelectSearch
          label='Country'
          name='country'
          id={values.country.id}
          items={filters.countries}
          selectedItem={values.country.name}
          onClick={handleChangeSelectValue}
          required
          disabled={!fillFields}
        />
      </div>

      <div className={styles.formItem}>
        <Autocomplete
          autoComplete={'off'}
          label='City'
          name='city'
          placeholder='City'
          items={autocompleteItems}
          value={values.city.name}
          onChange={handleChangeAutocompleteValue}
          onClick={handleClickAutocomplete}
          onFocus={handleFocusAutoComplete}
          isInvalid={!!(errors?.city?.id && touched?.city)}
          disabled={!values.country.id || !fillFields}
          required={!!values.country.id}
        />

        {errors?.city?.id && touched?.city && <p className={styles.formItemError}>{errors.city.id}</p>}
      </div>

      <div className={styles.formItem}>
        <Autocomplete
          autoComplete={'off'}
          label='College name'
          name='college'
          getTitle='name'
          items={autocompleteItems}
          value={values.college.name}
          onChange={handleChangeAutocompleteValue}
          onClick={handleClickAutocomplete}
          onFocus={handleFocusAutoComplete}
          isInvalid={!!(errors?.college?.id && touched?.college)}
          disabled={!values.city.id || !values.degree.id || !fillFields}
          required={!!values.city.id}
        />

        {errors?.college?.id && touched?.college && <p className={styles.formItemError}>{errors.college.id}</p>}
      </div>

      <div className={styles.formItem}>
        <Input
          label='Field of study'
          name='field_of_study'
          value={values.field_of_study}
          onChange={handleChangeValue}
          disabled={!fillFields}
        />

        {errors?.field_of_study && touched?.field_of_study && (
          <p className={styles.formItemError}>{errors.field_of_study}</p>
        )}
      </div>

      <Input
        label='Additional info'
        name='description'
        value={values.description}
        className={styles.additionalInfo}
        onChange={handleChangeValue}
        textarea
        disabled={!fillFields}
      />
      <FileUploader
        name='education'
        text='Add scans'
        theme='attachFile'
        icon={<AddFile />}
        files={documentFiles}
        onChange={handleInputFileChange}
        onDelete={handleInputFileDelete}
        isUpload={isUpload}
        disabled={!fillFields}
      />

      <div>
        <Button type='submit' size='middle-full' disabled={!isButtonEnable}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default EducationForm
