export const getAddressesHelper = ([residence, birth]) => {
  return {
    residence_country: residence?.country || { name: '', id: null },
    residence_city: residence?.city || { name: '', id: null },
    residence_address: residence?.address || { name: '', id: null },
    residence_apartment: residence?.apartment || '',
    residence_house: residence?.house || '',
    residence_zip: residence?.zip || '',
    is_same: birth?.is_same || false,

    birth_country: birth?.country || { name: '', id: null },
    birth_city: birth?.city || { name: '', id: null },
    birth_address: birth?.address || { name: '', id: null },
    birth_apartment: birth?.apartment || '',
    birth_house: birth?.house || '',
    birth_zip: birth?.zip || '',
  }
}

export const sortObjectByKeys = (obj) => {
  // Checking if obj is an object and not an array
  if (obj === null || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj
  }

  // Getting and sorting object keys
  let sortedKeys = Object.keys(obj).sort()

  // Create a new object with sorted keys
  let sortedObj = {}
  sortedKeys.forEach((key) => {
    sortedObj[key] = sortObjectByKeys(obj[key])
  })

  return sortedObj
}

export const sortObjects = (obj) => {
  let sortedKeys = Object.keys(obj).sort()

  // Create a new object with sorted keys
  let sortedObj = {}
  sortedKeys.forEach((key) => {
    sortedObj[key] = obj[key]
  })
  return sortedObj
}
