import React, { memo, useEffect, useRef, useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import { motion, useAnimation } from 'framer-motion'

import styles from './styles.module.scss'
import '../filesGroupAnimation.scss'
import cn from 'classnames'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'
import { ReactComponent as CalcOfFilesIcon } from '../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as DeleteFileIcon } from '../../../assets/icons/close.svg'

import DateExpiredChecker from '../../../UI/DateExpiredChecker/DateExpiredChecker'
import ImageComponent from '../../../UI/Image/ImageComponent'

const DocumentGroupItem = memo(({ type, data, onDelete, onEdit, deleteFile, isActive }) => {
  const [visibleListOfFiles, setVisibleListOfFiles] = useState(false)
  const [visibleListOfEndorsementFiles, setVisibleListOfEndorsementFiles] = useState(false)
  const nodeRef = useRef(null)
  const control = useAnimation()

  useEffect(() => {
    let isMounted = true

    const animateControl = () => {
      if (visibleListOfFiles || visibleListOfEndorsementFiles) {
        control.start((i) => ({
          opacity: 1,
          x: 0,
          transition: { delay: i * 0.1, type: 'spring' },
        }))
      } else {
        control.start((i) => ({
          opacity: 0,
          x: 0,
          transition: { delay: i * 0.1, type: 'spring' },
        }))
      }
    }

    if (isMounted) animateControl()

    return () => {
      isMounted = false
    }
  }, [visibleListOfFiles, visibleListOfEndorsementFiles, control])

  const handleFilesEnter = () => {
    setVisibleListOfFiles(true)
  }
  const handleFilesLeave = () => {
    setVisibleListOfFiles(false)
  }

  const handleFilesEndorsementEnter = () => {
    setVisibleListOfEndorsementFiles(true)
  }
  const handleFilesEndorsementLeave = () => {
    setVisibleListOfEndorsementFiles(false)
  }

  return (
    <div className={cn(styles.card, { [styles.card__active]: isActive })}>
      <div className={styles.cardInfo}>
        <div className={styles.documentType}>{data.documentName}</div>

        <div className={styles.documentBody}>
          <div className={styles.documentMainData}>
            <div className={styles.documentNumbers}>
              {data?.number ? (
                <p>
                  <span className={styles.documentNumber}>{type === 'license' ? 'License number:' : 'Number:'}</span>
                  <span className={styles.numberValue}>{data.number}</span>
                </p>
              ) : (
                <p>Not specified</p>
              )}

              <div className={styles.periods}>
                <div className={styles.period}>
                  <span className={styles.period_title}>Period:</span>

                  {data.date_of_issue && (
                    <span className={styles.periodValue}>
                      {data.date_of_issue}-<DateExpiredChecker>{data.date_of_expire}</DateExpiredChecker>
                    </span>
                  )}

                  {!data.date_of_issue && !data.date_of_expire && <span className={styles.periodValue}>No info</span>}
                </div>
              </div>
            </div>

            {data?.endorsement && (
              <div className={styles.documentNumbers}>
                <p>
                  <span className={styles.documentNumber}>Endorsement number:</span>
                  <span className={styles.numberValue}>{data?.endorsement?.number}</span>
                </p>

                <div className={styles.period}>
                  <span className={styles.period_title}>Period:</span>
                  <span className={styles.periodValue}>{data.endorsement?.date_of_issue}</span>-
                  <DateExpiredChecker>{data?.endorsement?.date_of_expire}</DateExpiredChecker>
                </div>
              </div>
            )}
          </div>

          <div className={styles.files}>
            {data?.files?.length ? (
              <div key={data.files[0].id} className={styles.file} onMouseEnter={handleFilesEnter}>
                <div key={data.files[0].id} className={styles.file}>
                  <div className={styles.calcOfFiles}>
                    <label className={styles.calcOfFilesLabel}>{data.files.length}</label>

                    <CalcOfFilesIcon />
                  </div>

                  <a rel='noopener noreferrer' target='_blank' href={data.files[0].file}>
                    <ImageComponent src={data.files[0].file_mini} alt='file' />
                  </a>
                </div>
              </div>
            ) : (
              <div className={styles.file} />
            )}

            {data?.endorsement?.files?.length ? (
              <div
                key={data.endorsement.files[0].id}
                className={styles.file}
                onMouseEnter={handleFilesEndorsementEnter}
              >
                {data.endorsement.files.length && (
                  <div className={styles.calcOfFiles}>
                    <label className={styles.calcOfFilesLabel}>{data.endorsement.files.length}</label>

                    <CalcOfFilesIcon />
                  </div>
                )}

                <a rel='noopener noreferrer' target='_blank' href={data.endorsement.files[0].file}>
                  <ImageComponent src={data.endorsement.files[0].file_mini} alt='file' />
                </a>
              </div>
            ) : (
              ''
            )}
          </div>

          <CSSTransition nodeRef={nodeRef} in={visibleListOfFiles} timeout={500} unmountOnExit classNames='listOfFiles'>
            <div ref={nodeRef} className='listOfFiles' onMouseLeave={handleFilesLeave}>
              <div className={styles.files_list}>
                {data?.files?.length &&
                  data.files.map((file) => (
                    <motion.div
                      key={file.id}
                      className={styles.file}
                      initial={{ opacity: 0, x: 20 }}
                      animate={control}
                      custom={file.index}
                    >
                      <div key={file.id} className={styles.file}>
                        <div className={styles.deleteFile} onClick={() => deleteFile(file.id, data.id, type, isActive)}>
                          <DeleteFileIcon />
                        </div>

                        <a rel='noopener noreferrer' target='_blank' href={file.file}>
                          <ImageComponent src={file.file_mini} alt='file' />
                        </a>
                      </div>
                    </motion.div>
                  ))}
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            nodeRef={nodeRef}
            in={visibleListOfEndorsementFiles}
            timeout={500}
            unmountOnExit
            classNames='listOfFiles'
          >
            <div ref={nodeRef} className='listOfFiles' onMouseLeave={handleFilesEndorsementLeave}>
              <div className={styles.files_list_endorsement}>
                {data?.endorsement?.files?.length
                  ? data.endorsement.files.map((file) => (
                      <motion.div
                        key={file.id}
                        className={styles.file}
                        custom={file.index}
                        initial={{ opacity: 0, x: 20 }}
                        animate={control}
                      >
                        <div key={file.id} className={styles.file}>
                          <div
                            className={styles.deleteFile}
                            onClick={() => deleteFile(file.id, data.id, 'endorsement', isActive)}
                          >
                            <DeleteFileIcon />
                          </div>

                          <a rel='noopener noreferrer' target='_blank' href={file.file}>
                            <ImageComponent src={file.file_mini} alt='file' />
                          </a>
                        </div>
                      </motion.div>
                    ))
                  : ''}
              </div>
            </div>
          </CSSTransition>
        </div>

        {data.id !== 'default' && (
          <>
            <div className={styles.cardControls}>
              <button
                className={cn(styles.cardControl, styles.cardControlEdit)}
                onClick={() => onEdit(data.id, !isActive)}
              >
                {isActive ? (
                  <span>
                    Close <CloseIcon />
                  </span>
                ) : (
                  <span>
                    Edit <EditIcon />
                  </span>
                )}
              </button>

              <button
                className={cn(styles.cardControl, styles.cardControlDelete)}
                onClick={() => onDelete(data.id, isActive)}
              >
                <span>
                  Delete <TrashIcon />
                </span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
})

export default DocumentGroupItem
