import { serviceMap } from './constants'

import { ReactComponent as VisibilityIcon } from './icons/enhancedVisibility.svg'
import { ReactComponent as StatusIcon } from './icons/personalStatus.svg'
import { ReactComponent as AccessIcon } from './icons/earlyAccess.svg'
import { ReactComponent as TrackingIcon } from './icons/activityTracking.svg'

import PopupEnhancedVisibility from './Popups/PopupEnhancedVisibility/PopupEnhancedVisibility'
import PopupPersonalStatus from './Popups/PopupPersonalStatus/PopupPersonalStatus'
import PopupEarlyAccess from './Popups/PopupEarlyAccess/PopupEarlyAccess'
import PopupActivityTracking from './Popups/PopupActivityTracking/PopupActivityTracking'
import { isActiveService } from './utils'

export const getServices = () => {
  return {
    [serviceMap.enhancedVisibility]: {
      Icon: VisibilityIcon,
      Popup: PopupEnhancedVisibility,
      isActive: isActiveService(serviceMap.enhancedVisibility),
    },
    [serviceMap.personalStatus]: {
      Icon: StatusIcon,
      Popup: PopupPersonalStatus,
      isActive: isActiveService(serviceMap.personalStatus),
    },
    [serviceMap.earlyAccess]: {
      Icon: AccessIcon,
      Popup: PopupEarlyAccess,
      isActive: isActiveService(serviceMap.earlyAccess),
    },
    [serviceMap.activityTracking]: {
      Icon: TrackingIcon,
      Popup: PopupActivityTracking,
      isActive: isActiveService(serviceMap.activityTracking),
    },
  }
}
