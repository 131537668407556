import React, { useState, useEffect, memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'

import SelectItem from './SelectItem'
import BottomArrowSvg from '../Icons/BottomArrowSvg'
import Input from '../Input/Input'
import useClickOutside from '../../hooks/useClickOutside'
import { createItemRef } from '../../helpers/createItemRef'
import useHeightWithScroll from '../../hooks/useHeightWithScroll'

const Select = memo(
  ({
    name,
    id,
    label,
    placeholder,
    selectedItem,
    defaultName = '–',
    items = [],
    onClick,
    isInvalid = false,
    errorMessage = '',
    required = false,
    landing,
    className,
    disabled = false,
  }) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [filteredItems, setFilteredItems] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [activeIndex, setActiveIndex] = useState(-1)
    const [isFocused, setIsFocused] = useState(false)

    const selectEl = useClickOutside(() => setIsSelectOpen(false))

    const isOnTop = selectEl.current && window.innerHeight - selectEl.current.getBoundingClientRect().bottom < 250
    const newItems = defaultName ? [{ id: 'null', name: defaultName }, ...items] : items
    const list = filteredItems.length !== 0 ? filteredItems : newItems

    const refs = createItemRef(list)
    const { divRef: ulRef } = useHeightWithScroll({ state: isSelectOpen && list.length })

    useEffect(() => {
      if (!isSelectOpen) {
        setInputValue('')
        setFilteredItems([])
      }
      if (isSelectOpen && id) {
        const childElement = refs[id].current
        const parentElement = ulRef.current

        parentElement.scrollTop = childElement.offsetTop - parentElement.offsetTop
      }
      //eslint-disable-next-line
    }, [isSelectOpen, id, ulRef])

    useEffect(() => {
      if (isSelectOpen && activeIndex !== -1) {
        const childElement = refs[newItems[activeIndex]?.id]?.current
        const parentElement = ulRef.current

        if (childElement) {
          const childRect = childElement.getBoundingClientRect()
          const parentRect = parentElement.getBoundingClientRect()

          if (childRect.bottom > parentRect.bottom) {
            parentElement.scrollTop += childRect.bottom - parentRect.bottom
          } else if (childRect.top < parentRect.top) {
            parentElement.scrollTop -= parentRect.top - childRect.top
          }
        }
      }
    }, [isSelectOpen, activeIndex, newItems, refs, ulRef])

    const handleItemClick = (id, _) => {
      setIsSelectOpen(false)

      const rest = items.find((item) => item.id === Number(id))

      if (id === null) {
        onClick(name, { id, name: defaultName || label })

        return
      }

      onClick(name, { ...rest })
    }

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleKeyDown = (e) => {
      if (!isSelectOpen && e.key === 'Enter' && isFocused) {
        setIsSelectOpen(true)

        return
      }

      if (e.key === 'ArrowUp' && activeIndex > 0) {
        e.preventDefault()

        setActiveIndex(activeIndex - 1)

        return
      }

      if (e.key === 'ArrowDown' && activeIndex < list.length - 1) {
        e.preventDefault()

        setActiveIndex(activeIndex + 1)

        return
      }

      if (e.key === 'Enter' && activeIndex !== -1) {
        const id = list[activeIndex].id
        const name = list[activeIndex].name

        handleItemClick(id, name)
      }
    }

    const handleInputChange = ({ target }) => {
      const { value } = target

      setInputValue(value)

      if (!value) {
        setFilteredItems(items)

        return
      }

      if (value.length > 2) {
        setFilteredItems(
          items.filter((item) => {
            const name = item.name.toLowerCase()

            return name.includes(value.toLowerCase())
          })
        )
      }
    }

    return (
      <>
        {label && (
          <label className={cn(styles.label, { [styles.label_disabled]: disabled })}>
            {label} {required && <span className={cn('required-star', styles.required_star)}>*</span>}
          </label>
        )}

        <div
          ref={selectEl}
          className={cn(styles.wrapper, className, {
            [styles.noEvent]: disabled,
            [styles.wrapper_landing]: landing,
          })}
        >
          <div
            tabIndex='0'
            className={cn(styles.header, 'select-search', {
              [styles.focus]: isSelectOpen,
              [styles.header_disabled]: disabled,
              [styles.header_invalid]: isInvalid,
            })}
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            <div
              className={cn(styles.title, {
                [styles.title_default]: id === null,
                [styles.title_disabled]: disabled,
              })}
            >
              {selectedItem || placeholder || '–'}
            </div>
          </div>

          {isInvalid && errorMessage && !disabled && <p className='errorMessage'>{errorMessage}</p>}

          {isSelectOpen && (
            <div
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className={cn(styles.search_menu, { [styles.list_top]: isOnTop })}
            >
              <Input
                theme='search'
                placeholder='Search'
                value={inputValue}
                onChange={handleInputChange}
                clear={setInputValue}
                className={styles.search}
                xw='true'
                focus
              />

              <ul ref={ulRef} className={cn(styles.list)}>
                {list.map(({ name: item, id }, index) => {
                  const isActive = index === activeIndex || item === selectedItem

                  return (
                    <SelectItem
                      refs={refs[id]}
                      tabIndex='0'
                      key={id}
                      name={item}
                      id={id}
                      isActive={isActive}
                      onClick={handleItemClick}
                    />
                  )
                })}
              </ul>
            </div>
          )}

          <div
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            className={cn(styles.icon, {
              [styles.icon_active]: isSelectOpen,
            })}
          >
            <BottomArrowSvg fill={'var(--color-accent-light)'} />
          </div>
        </div>
      </>
    )
  }
)

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  selectedItem: PropTypes.string,
  defaultName: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  landing: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Select
