import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Input from '../../../UI/Input/Input'
import SelectSearch from '../../../UI/Select/SelectSearch'
import {
  changeJobSearchRank,
  changeJobSearchVessel,
  changeJobSearchDate,
  clearJobSearchPageSize,
} from '../../../redux/actions/mainFilter'

import styles from './styles.module.scss'
import KeyBoardDatePicker from '../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import moment from 'moment'
import { useFormik } from 'formik'
import { today } from '../../../helpers/timeHelper'

const JobSearchFilter = React.memo(({ data, rank, vessel, setSearchValue, setExcludeId, searchValue, loading }) => {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: { date: null },
  })
  const { values } = formik

  const handleSelect = (type, { name, id }) => {
    dispatch(clearJobSearchPageSize())
    if (type === 'rank') {
      dispatch(changeJobSearchRank(id === null ? { name: 'All', id: '' } : { name, id }))
    } else if (type === 'vessel_type') {
      dispatch(changeJobSearchVessel(id === null ? { name: 'All', id: '' } : { name, id }))
    }

    setExcludeId(null)
  }

  const handleInputChange = ({ target }) => {
    setSearchValue(target.value)

    setExcludeId(null)
  }

  const handleDayChange = (selectedDay) => {
    dispatch(clearJobSearchPageSize())
    dispatch(changeJobSearchDate(selectedDay))

    setExcludeId(null)
  }

  useEffect(() => {
    return () => {
      dispatch(changeJobSearchDate(null))
    }
    //eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (values.date) {
      handleDayChange(moment(values?.date).toDate())
    }
    //eslint-disable-next-line
  }, [values])

  return (
    <div className={styles.filter}>
      <div className={styles.group}>
        <Input
          focus
          label='Search'
          placeholder='Vessel, company, title, rank, desc...'
          onChange={handleInputChange}
          value={searchValue}
          className={styles.search_job}
        />
      </div>
      <div className={styles.group}>
        <SelectSearch
          className={styles.rankSelect}
          label='Rank'
          name={'rank'}
          id={rank.id}
          selectedItem={rank.name}
          onClick={handleSelect}
          items={data.rank}
          landing
          disabled={loading}
        />
      </div>
      <div className={styles.group}>
        <SelectSearch
          label='Vessel Type'
          name={'vessel_type'}
          id={vessel.id}
          selectedItem={vessel.name}
          onClick={handleSelect}
          items={data.vessel_type}
          landing
          disabled={loading}
        />
      </div>
      <div className={styles.group}>
        <p className={styles.groupLabel}>Join Date (from)</p>
        <KeyBoardDatePicker
          formik={formik}
          nameOfValue='date'
          value={values.date || today}
          className={styles.join_date}
          disabled={loading}
          today={true}
          viewFormat={'spacious'}
        />
      </div>
    </div>
  )
})

const mapStateToProps = (state) => {
  return {
    vessel: state.mainFilter.filter.vesselType,
    rank: state.mainFilter.filter.rank,
  }
}

export default connect(mapStateToProps)(JobSearchFilter)
