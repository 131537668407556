import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import styles from '../UserCard.module.scss'
import LicenseItem from './LicenseItem/LIcenseItem'
import { setLicensesInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'
import { ReactComponent as NoInfoImage } from '../../../assets/images/licences_stub.svg'

const License = ({ userCardData, showFullViewImage }) => {
  const dispatch = useDispatch()
  const { licenses } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setLicensesInfo([]))
    }
  }, [dispatch])
  if (isEmpty(licenses)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={415} height={265} />
        <p>You don't have any licenses yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={1} gutter='16px'>
        {licenses?.map((license, index) => {
          return (
            <LicenseItem
              key={index}
              title={license.name}
              licenseFiles={license.licenseFiles}
              endorsementFiles={license.endorsementFiles}
              items={license.values}
              showFullViewImage={showFullViewImage}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default License
