import React, { Component } from "react";
import PropTypes from "prop-types";
import { API_BASE } from "../api/constans";

export default class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError() {
    return { error: true };
  }

  postRequest = async (url, data) => {
    const response = await fetch(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return await json;
  };

  componentDidCatch(error, info) {
    this.postRequest(`${API_BASE}log-error/`, {
      project_name: "seafarer",
      error: `error: ${error} \n ${JSON.stringify(info)}`,
    }).then(() => {
      this.setState(error);
    });
  }

  render() {
    if (this.state.error) {
      return <p>Something broke</p>;
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
