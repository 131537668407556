import React from 'react'
export const Highlighting = ({ data, match, className }) => {
  const { message, event_keyword, event_link } = data

  const addLink = (message, event_keyword) => {
    return event_keyword ? message.replace(event_keyword, '').trim() : message
  }
  const basicString = addLink(message, event_keyword)

  const action = event_keyword?.charAt(0).toUpperCase() + event_keyword?.slice(1)

  const stringLink = <a href={event_link}>{String(action)}</a>

  const fullString = (
    <span className={className}>
      {basicString} <span style={{ fontWeight: '600' }}>{event_link && stringLink}</span>
    </span>
  )

  if (!match) return <div>{fullString}</div>
  const regexp = new RegExp(match, 'ig')
  const matchValue = message.match(regexp)
  if (matchValue) {
    return message.split(regexp).map((str, index, array) => {
      if (index < array.length - 1) {
        const match = matchValue.shift()
        return (
          <span key={index}>
            {str}
            <span style={{ background: '#1CCF9E', opacity: '.8' }}>{match}</span>
          </span>
        )
      } else {
        return str
      }
    })
  } else {
    return fullString
  }
}
