import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StatusIcon } from './icons/status.svg'
import UseSmartTooltip from '../../hooks/useSmartTooltip'

const PersonalStatus = ({ status }) => {
  return (
    <>
      <div className={styles.statusWrapper}>
        <StatusIcon />

        <UseSmartTooltip tagType={'span'} className={styles.status} children={`"${status}"`} amount_of_line={1} />
      </div>
    </>
  )
}

export default PersonalStatus
