import React, { useState } from 'react'
import styles from './styles.module.scss'
import ActionBlock from './ActionBlock'
import ShareBlock from '../../ShareBlock/ShareBlock'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-accent-color.svg'
import JobTabletCharacteristics from './JobTabletUI/JobTabletCharacteristics'
import JobTabletMainInfo from './JobTabletUI/JobTabletMainInfo'
import cn from 'classnames'

const JobSeaTabletInfoPopup = ({ item, setIsTabletCardOpen, loading, setLoading }) => {
  const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)
  const [isAppPopUpOpen, setIsAppPopUpOpen] = useState(false)

  const {
    id: item_id,
    rank_name,
    title,
    description,
    shipowner,
    vessel,
    vacancy_type,
    salary,
    salary_currency,
    salary_description,
  } = item

  const { name, vessel_id } = vessel

  const { symbol: currency_symbol } = salary_currency
  const { name: shipowner_name } = shipowner

  const toChooseVacancyType = () => {
    return vacancy_type === 'sea' ? 'Onboard Job' : 'Shore-Based Job'
  }
  const handleShareBlockClose = () => {
    setIsShareBlockOpen(false)
  }
  const vacancyLocationType = toChooseVacancyType()

  return (
    <div className={styles.popupWrapper}>
      {/*<article>*/}
      <div className={styles.popupBlock}>
        <div className={cn(styles.popupMainBlock, { [styles.popupMainBlock_disabled]: isAppPopUpOpen })}>
          <div className={styles.popupTopInfo}>
            <div className={styles.vacancyTopInfo}>
              <h3>{rank_name}</h3>
              <b>{vacancyLocationType}</b>
            </div>
            <div
              onClick={() => {
                setIsTabletCardOpen(false)
              }}
            >
              <CloseIcon stroke='#07251e' />
            </div>
          </div>
          <p>{description || title}</p>
          <p>
            {salary} {currency_symbol} {salary_description}
          </p>
          {vacancy_type === 'sea' && vessel_id && (
            <p>
              <span> Vessel Name: </span>
              <br />
              {name}
            </p>
          )}
          <p>
            <span> Shipowner: </span>
            <br />
            {shipowner_name}
          </p>

          <JobTabletCharacteristics vessel={vessel} />
          <JobTabletMainInfo item={item} isAppPopUpOpen={isAppPopUpOpen} />
          <ActionBlock
            loading={loading}
            setLoading={setLoading}
            item={item}
            isPopup={true}
            setIsShareBlockOpen={setIsShareBlockOpen}
            isShareBlockOpen={isShareBlockOpen}
            isAppPopUpOpen={isAppPopUpOpen}
            setIsAppPopUpOpen={setIsAppPopUpOpen}
          />
        </div>
        <ShareBlock id={item_id} setIsOpen={handleShareBlockClose} isOpen={isShareBlockOpen} />
      </div>
      {/*</article>*/}
    </div>
  )
}

export default JobSeaTabletInfoPopup
