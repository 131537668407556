import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import SizeInformationItem from './SizeInformationItem/SizeInformationItem'
import styles from '../UserCard.module.scss'
import { setSizeInformationInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'

const SizeInformation = ({ userCardData }) => {
  const dispatch = useDispatch()
  const { sizeInformation } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setSizeInformationInfo({}))
    }
  }, [dispatch])
  if (isEmpty(sizeInformation.values)) {
    return <div className={styles.noInfo}>No information</div>
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px' style={{ borderRadius: '4px' }}>
        <SizeInformationItem title={sizeInformation.title} items={sizeInformation.values} />
      </Masonry>
    )
  }
}

export default SizeInformation
