import React from 'react'

import styles from './operator.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'

const Operator = ({ isActive = false, children, className, ...rest }) => {
  return (
    <div className={className}>
      <div className={cn(styles.block, { [styles.active]: isActive })} {...rest}>
        {children}

        <span className={styles.title}>support</span>
      </div>
    </div>
  )
}

export default Operator

Operator.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
