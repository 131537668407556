import {
  chatLastMessageUpdate,
  clearChatRoomTotalUndelivered,
  deleteMessage,
  deleteRoomFromList,
  restoreChatRoom,
  searchChatMessage,
  setDeleteMessageError,
  setDialogs,
  setEditMessageError,
  setGlobalUndelivered,
  setMessages,
  setNewMessage,
  setNextPageMessages,
  updateMessage,
} from '../redux/actions/chat.action'
import {
  newSupportMessageAction,
  resetTotalUndelivered,
  setNextPageSupportMessages,
  setSupportStatus,
  setSupportUndelivered,
  updateSupportMessages,
  deleteSupportMessage,
  updateSupportMessage,
  setSupportDeleteMessageError,
  setSupportEditMessageError,
  searchSupportMessage,
  setAnonymousId,
} from '../redux/support/support.action'
import { notify } from '../helpers/notify'
import { userLogout } from '../redux/actions/auth'
import { addNotifications, setCounterNotifications } from '../redux/actions/notifications.action'
import { events } from '../constants/constans.js'
import { setBillingEvent, setBillingPermissionCurrent } from '../redux/billing/billing.action'
import { setLandingInfo } from '../redux/actions/landingStatisticsInfo.action'

export const socketEvent = (event, payload, chat_id) => (dispatch) => {
  console.log('%c%s', 'color: #43927C;', event, payload)

  switch (event) {
    case 'chat.rooms':
      dispatch(setDialogs(payload))
      break

    case 'chat.messages':
      // if payload has last_message_id, call setNextPageMessages
      if (payload.last_message_id) {
        dispatch(setNextPageMessages(payload))
      } else {
        dispatch(setMessages(payload))
      }

      break

    case 'new.chat.message':
      dispatch(setNewMessage(payload, chat_id))
      break

    case 'chat.message.deleted':
      dispatch(deleteMessage(payload.chat_id, payload.id, payload.total_undelivered))
      break

    case 'chat.message.updated':
      dispatch(updateMessage(payload))
      break

    case 'chat.last.message.update': {
      const { id, message, chat_id } = payload

      // if the user deleted the last message, delete the chat room with him
      if (!id && !message) {
        dispatch(deleteRoomFromList(chat_id))
      }

      dispatch(chatLastMessageUpdate(payload))
      break
    }

    case 'chat.total.undelivered':
      dispatch(setGlobalUndelivered(payload))
      dispatch(setSupportUndelivered(payload))
      break

    case 'new.chat.room':
      dispatch(restoreChatRoom(payload))
      dispatch(setGlobalUndelivered(payload))
      break

    case 'chat.room.auto.restore':
      dispatch(restoreChatRoom(payload))
      dispatch(setGlobalUndelivered(payload))
      break

    case 'error.chat.edit.message':
      dispatch(setEditMessageError(payload.message))
      break

    case 'error.chat.delete.message':
      dispatch(setDeleteMessageError(payload.message))
      break

    case 'search.chat.message':
      dispatch(searchChatMessage(payload))
      break

    //  SUPPORT

    case 'support.messages':
      if (payload.last_message_id) {
        dispatch(setNextPageSupportMessages(payload))
      } else {
        dispatch(updateSupportMessages(payload))
      }

      break

    case 'support.new.chat.message':
      dispatch(newSupportMessageAction(payload))
      break

    case 'error.message':
      notify.error('Error', payload.message)
      break

    case 'error':
      if (payload.message === 'Authorization for seafarer failed') {
        notify.error('Error', payload.message)
        userLogout()
        window.location.reload()
      }
      break

    case 'no.chat.messages':
      window.location.href = '/profile/chat'
      break

    case 'chat.message.was.delivered':
      dispatch(clearChatRoomTotalUndelivered(payload))
      break

    case 'support.chat.message.was.delivered':
      dispatch(resetTotalUndelivered(payload))
      break

    case 'support.chat.status':
      dispatch(setSupportStatus(payload))
      break

    case 'support.chat.message.deleted':
      dispatch(deleteSupportMessage(payload.id))
      break

    case 'support.chat.message.updated':
      dispatch(updateSupportMessage(payload))
      break

    case 'error.support.chat.edit.message':
      dispatch(setSupportEditMessageError(payload.message))
      break

    case 'error.support.chat.delete.message':
      dispatch(setSupportDeleteMessageError(payload.message))
      break

    case 'search.support.message':
      dispatch(searchSupportMessage(payload))
      break

    case 'anonymous.uuid':
      dispatch(setAnonymousId(payload.uuid))
      break

    //events&alerts

    case 'events.event.new':
      window.location === '/profile/notification/list' && dispatch(addNotifications(payload))
      break

    case 'events.counter.total':
      dispatch(setCounterNotifications(payload.events))
      break

    //billing
    case events.billing.permissionsCurrent:
      dispatch(setBillingPermissionCurrent(payload))
      break
    case events.billing.cardDetach:
      notify.info('Info', 'Your card has been detached!')
      break
    case events.billing.subscriptionUnPaid:
      notify.info('Info', 'The payment was not successful!')
      dispatch(setBillingEvent(false))
      break
    case events.billing.subscriptionPaid:
      notify.info('Info', 'Your payment has been successfully processed!')
      dispatch(setBillingEvent(false))
      break
    case events.billing.subscriptionCanceled:
      notify.info('Info', 'Your subscription has been canceled!')
      break

    case events.billing.paymentRequiresConfirmation:
      notify.info('Info', 'You have requires confirmation payment, please go to the billing page!')
      dispatch(setBillingEvent(true))

      break

    //landing
    case events.landing.status:
      dispatch(setLandingInfo(payload))
      break

    default:
      return
  }
}
