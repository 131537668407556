import React, { memo } from 'react'

import cn from 'classnames'

import PropTypes from 'prop-types'

const Radio = ({ theme = 'default', label, field, isInvalid, isChecked, className, ...rest }) => {
  return (
    <div className={cn(`input-radio input-radio-${theme}`, className)}>
      <input checked={isChecked} type='radio' {...field} {...rest} />
      <span className='radio'>
        <span />
      </span>
      <span className='text'>{label}</span>
    </div>
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  isInvalid: PropTypes.bool,
  isChecked: PropTypes.bool,
}

export default memo(Radio)
