import React from 'react'
import styles from '../../tabs/review/RequestForReviews/RequestForReviews.module.scss'
import Button from '../../UI/Button/Button'
import Input from '../../UI/Input/Input'
import { ReactComponent as NewReview } from '../../assets/icons/new-review.svg'
import EditIcon from '../../assets/icons/edit-no-underline.svg'
import ReviewsQuestion from './ReviewsQuestion'
import AnimateHeight from 'react-animate-height'
import { getRequestMoreInfo } from '../../api/getApi'
import { useDispatch, useSelector } from 'react-redux'
import { clearRequestQuestions, setRequestDescription, setRequestQuestion } from '../../redux/actions/reviews.action'
import { notify } from '../../helpers/notify'
import { putRequestForReview } from '../../api/putApi'
import { requestsHelper } from '../../helpers/reviews.helper'
import useClickOutside from '../../hooks/useClickOutside'
import UseSmartTooltip from '../../hooks/useSmartTooltip'
import moment from 'moment'

function ReviewsRequestsListItem({ data }) {
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const [isOpen, setIsOpen] = React.useState(false)
  const [isRatingAdded, setIsRatingAdded] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const dispatch = useDispatch()
  const isOpenRef = useClickOutside(() => setIsOpen(false))

  const dataDateToDateFormat = (originalDate) => (originalDate ? moment(originalDate).format(dateFormat) : 'current')

  const handleClick = () => {
    const id = data.id
    setIsOpen(!isOpen)

    if (!isOpen) {
      getRequestMoreInfo(id)
        .then((data) => {
          setDescription(data.description)
          dispatch(setRequestQuestion(data, id))
        })
        .catch((error) => {
          setIsOpen(false)
          if (error) notify.error('Error', 'Something went wrong')
        })
    } else {
      dispatch(clearRequestQuestions())
    }
  }

  const handleRejected = () => {
    const id = data.id

    getRequestMoreInfo(id)
      .then((data) => {
        data.category = 'rejected'
        dispatch(setRequestQuestion(data, id))
        putRequestForReview(requestsHelper(data), id)
          .then(() => notify.info('Success', 'Review successfully was rejected'))
          .catch(() => notify.error('Error', 'Something went wrong'))
      })
      .catch((error) => {
        setIsOpen(false)
        if (error) notify.error('Error', 'Something went wrong')
      })
    setIsOpen(false)
  }

  const handleSend = () => {
    const id = data.id
    data.description = description
    data.category = 'replied'
    dispatch(setRequestDescription(data, id))
    putRequestForReview(requestsHelper(data), id)
      .then((data) => {
        if (!data.ok) {
          data.errors.forEach((item) => {
            notify.error('Error', item.message)
          })
        } else {
          notify.info('Success', 'Your review was successfully added')
          setIsOpen(false)
        }
      })
      .catch(() => {
        notify.error('Error', 'Something went wrong')
      })
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleAnswer = (answer = 0, id) => {
    data.questions.map((item) => {
      if (item.id === id && answer) {
        item.answer = answer
        setIsRatingAdded(true)
      }
      return item
    })
  }

  const handleDescription = (e) => {
    const value = e.target.value
    setDescription(value)
  }

  return (
    <div ref={isOpenRef} className={styles.item}>
      <div className={styles.cardHeader}>
        <div className={styles.profilePhoto}>
          <img src={data.image} alt='user' />
        </div>
        <div className={styles.profileRankAndVessel}>
          {data.category.toLowerCase() === 'new' && <NewReview className={styles.new_star} />}
          <p style={{ fontWeight: '400', fontSize: '14px' }}>Do you want to leave a review</p>
          <UseSmartTooltip
            tagType={'p'}
            className={styles.name}
            children={data.name?.length > 3 ? `${data.name.toLowerCase()}?` : 'No name'}
            amount_of_line={1}
          />
          <UseSmartTooltip tagType={'p'} className={styles.rank} amount_of_line={1}>
            {data.rank}
            <span className={styles.reviewDate}>
              ({dataDateToDateFormat(data.start_date)} - {dataDateToDateFormat(data.end_date)})
            </span>
          </UseSmartTooltip>

          <p className={styles.vesselName}>{data?.vessel_name.toLowerCase()}</p>
        </div>
        <ReviewsRequestsListItemControls
          onClick={handleClick}
          onRejected={handleRejected}
          status={data.category?.toLowerCase()}
        />
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
        <div className={styles.reviewArea}>
          <ul className={styles.questionsWrapper}>
            {data.questions &&
              data.questions.map((item, index) => (
                <ReviewsQuestion
                  key={index}
                  id={item.id}
                  title={item.question}
                  answer={item.answer}
                  handleClick={handleAnswer}
                />
              ))}
          </ul>
          <div className={styles.message}>
            <Input
              textarea
              placeholder='Write your review...'
              onChange={(e) => handleDescription(e)}
              value={description}
            />
          </div>
          <div className={styles.sendButtons}>
            <Button theme='bordered' size='normal' onClick={handleClose}>
              Close
            </Button>
            <Button disabled={!isRatingAdded} theme='default' size='normal' onClick={handleSend}>
              Send
            </Button>
          </div>
        </div>
      </AnimateHeight>
    </div>
  )
}

export default ReviewsRequestsListItem

function ReviewsRequestsListItemControls({ status, onClick, onRejected }) {
  switch (status) {
    case 'rejected':
      return (
        <div className={styles.buttons}>
          <Button size='normal' theme='default' onClick={onClick}>
            Yes
          </Button>
        </div>
      )
    case 'new':
      return (
        <div className={styles.buttons}>
          <Button size='normal' theme='bordered' onClick={onRejected}>
            No
          </Button>
          <Button size='normal' theme='default' onClick={onClick}>
            Yes
          </Button>
        </div>
      )
    case 'replied':
      return (
        <div className={styles.buttons}>
          <Button style={{ paddingTop: '11px', paddingBottom: '11px' }} size='normal' theme='white' onClick={onClick}>
            Edit <img src={EditIcon} alt='pencil' />
          </Button>
        </div>
      )
    default:
      return null
  }
}
