import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './Anthropometry.module.scss'
import Button from '../../../UI/Button/Button'
import Select from '../../../UI/Select/Select'
import Input from '../../../UI/Input/Input'
import { getAuthBio, getAuthProfileFilters } from '../../../api/getApi'
import { putProfileAnthropometry } from '../../../api/putApi'
import { emptyStringToNull } from '../../../helpers/objectsHelper'
import { getProfileFetch } from '../../../redux/actions/user.action'
import { useDispatch } from 'react-redux'
import TableSizes from '../../../components/TableSizes/TableSizes'
import { useNotify } from '../../../hooks/useNotify'
import { getAnthropometryHelper } from '../anthropometry.helper'
import { isEqual } from 'lodash'
import { initialValuesAnthropometry, validationSchemaAnthropometry } from '../config'
import cn from 'classnames'

Yup.addMethod(Yup.string, 'integer', function () {
  return this.matches(/^\d+$/, 'The field should have digits only')
})

export default function Anthropometry() {
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [filters, setFilters] = React.useState({
    hair_color: [],
    eye_color: [],
    suit_size: [],
  })
  const [formValues, setFormValues] = useState({})

  const formik = useFormik({
    initialValues: formValues ? formValues : initialValuesAnthropometry,
    validationSchema: validationSchemaAnthropometry,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      putProfileAnthropometry({
        ...values,
        ...emptyStringToNull(values),
        hair_color: values.hair_color.id,
        eye_color: values.eye_color.id,
        suit_size: filters.suit_size.filter((item) => item.name === values.suit_size?.name)[0]?.id || null,
      }).then((data) => {
        if (data.ok) {
          notify('Success', 'Your changes were successfully saved.')
          setIsBtnDisabled(true)
          dispatch(getProfileFetch())
          getData()
        } else {
          data.errors.forEach(({ field, message }) => notify.error('Error', `${field}: ${message}`))
        }
      })
    },
  })

  React.useEffect(() => {
    getAuthProfileFilters('hair_color', 'suit_size', 'eye_color').then((data) => {
      setFilters(() => data)
    })
  }, [])

  const getData = () => {
    getAuthBio()
      .then((data) => {
        setFormValues(getAnthropometryHelper(data))
        formik.setValues(getAnthropometryHelper(data))
      })
      .then(() => {
        setLoading(false)
        setIsBtnDisabled(true)
      })
  }

  React.useEffect(() => {
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFieldChange = (type, data) => {
    handleChangeFormikValue({ target: { value: data, name: type } })
  }

  const handleChangeFormikValue = (value) => {
    setIsBtnDisabled(false)
    formik.handleChange(value)
  }

  const handleShoeSizeClick = (shoeSizeValue) => {
    setIsBtnDisabled(false)
    formik.setValues({ ...formik.values, foot_size: shoeSizeValue })
  }

  const handleSuitSizeClick = (suitSizeValue, suitSizeId) => {
    formik.setValues({
      ...formik.values,
      suit_size: { name: suitSizeValue, id: suitSizeId },
    })
  }
  const { values } = formik

  useEffect(() => {
    const heightDiff = isEqual(+values.height, +formValues.height)
    const weightDiff = isEqual(+values.weight, +formValues.weight)
    const hairDiff = isEqual(+values.hair_color?.id, +formValues.hair_color?.id)
    const eyeDiff = isEqual(+values.eye_color?.id, +formValues.eye_color?.id)
    const suitDiff = isEqual(+values.suit_size?.id, +formValues.suit_size?.id)
    const shoeDiff = isEqual(+values.foot_size, +formValues.foot_size)
    const collarDiff = isEqual(+values.collar, +formValues.collar)
    const waistDiff = isEqual(+values.waist, +formValues.waist)

    const different =
      !heightDiff || !weightDiff || !hairDiff || !eyeDiff || !suitDiff || !shoeDiff || !collarDiff || !waistDiff

    setIsBtnDisabled(!different)
  }, [values, formValues])

  if (loading) return null

  return (
    <section className={styles.anthropometry}>
      <form className={styles.anthropometry_form} onSubmit={formik.handleSubmit}>
        <div className={styles.column_anthropometry_left}>
          <div className='field-item'>
            <Input
              label='Height, cm'
              name='height'
              onChange={handleChangeFormikValue}
              isInvalid={!!formik.errors.height}
              value={formik.values.height}
            />
            <div className={cn('biometrics_error', { view_error: formik.errors.height })}>{formik.errors.height}</div>
          </div>
          <div className='field-item'>
            <Select
              label='Hair Color'
              name={'hair_color'}
              selectedItem={formik.values.hair_color.name}
              id={formik.values.hair_color.id}
              items={filters.hair_color}
              onClick={handleFieldChange}
            />
          </div>
          <div className='field-item'>
            <Select
              label='Suit Size'
              name={'suit_size'}
              selectedItem={formik.values.suit_size.name}
              id={formik.values.suit_size.id}
              items={filters.suit_size}
              onClick={handleFieldChange}
            />
          </div>
          <div className='field-item'>
            <Input
              label='Collar, cm'
              name='collar'
              onChange={handleChangeFormikValue}
              isInvalid={!!formik.errors.collar}
              value={formik.values.collar}
            />

            <div className={cn('biometrics_error', { view_error: formik.errors.collar })}>{formik.errors.collar}</div>
          </div>
        </div>
        <div className={styles.column_anthropometry_right}>
          <div className='field-item'>
            <Input
              label='Weight, kg'
              name='weight'
              onChange={handleChangeFormikValue}
              isInvalid={!!formik.errors.weight}
              value={formik.values.weight}
            />
            <div className={cn('biometrics_error', { view_error: formik.errors.weight })}>{formik.errors.weight}</div>
          </div>
          <div className='field-item'>
            <Select
              label='Eye Color'
              name={'eye_color'}
              selectedItem={formik.values.eye_color.name}
              id={formik.values.eye_color.id}
              items={filters.eye_color}
              onClick={handleFieldChange}
            />
          </div>
          <div className='field-item'>
            <Input
              label='Shoe Size (EU)'
              name='foot_size'
              onChange={handleChangeFormikValue}
              isInvalid={!!formik.errors.foot_size}
              value={formik.values.foot_size}
            />

            <div className={cn('biometrics_error', { view_error: formik.errors.foot_size })}>
              {formik.errors.foot_size}
            </div>
          </div>
          <div className='field-item'>
            <Input
              label='Waist, cm'
              name='waist'
              onChange={handleChangeFormikValue}
              isInvalid={!!formik.errors.waist}
              value={formik.values.waist}
            />

            <div className={cn('biometrics_error', { view_error: formik.errors.waist })}>{formik.errors.waist}</div>
          </div>
        </div>
        <Button className={styles.button_anthropometry} disabled={isBtnDisabled} type='submit' size='middle-full'>
          Save
        </Button>
      </form>
      <TableSizes
        className={styles.anthropometry_table}
        suitSizes={filters?.suit_size}
        handleShoeSizeClick={handleShoeSizeClick}
        handleSuitSizeClick={handleSuitSizeClick}
      />
    </section>
  )
}
