export const serviceMap = {
  enhancedVisibility: 'enhanced_visibility',
  personalStatus: 'personal_status',
  earlyAccess: 'early_access',
  activityTracking: 'activity_tracking',
}

export const action = {
  activated: 'activated',
  notActivated: 'not activated',
  hasExpired: 'has expired',
  info: 'info',
}

export const category = {
  read: 'read',
  unread: 'unread',
  all: 'all',
}
