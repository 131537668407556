import React, { useState } from 'react'

import styles from './styles.module.scss'

import { ReactComponent as LogoIcon } from './assets/icons/logo.svg'
import { ReactComponent as QRFbIcon } from './assets/icons/qrCodeFb.svg'
import { ReactComponent as QRTgIcon } from './assets/icons/qrCodeTg.svg'
import { ReactComponent as FbIcon } from './assets/icons/facebook.svg'
import { ReactComponent as TgIcon } from './assets/icons/telegram.svg'

import fbScreen from './assets/images/fbScreen.png'
import tgScreen from './assets/images/tgScreen.png'

import QRCode from './QRCode/QRCode'
import SocialMedia from './SocialMedia/SocialMedia'
import CheckBox from '../../UI/CheckBox/CheckBox'

import useListenEvent from '../../hooks/useListenEvent'
import { events } from '../../constants/constans'
import socket from '../../websocket'
import cn from 'classnames'

const bannerType = {
  withoutCheckBox: '0',
  withCheckBox: '1',
}

const banner_id = '1'

const PopupAboutSocialMedia = () => {
  const [bannerStatus, setBannerStatus] = useState(null)
  const [force_close, setForceClose] = useState(false)

  const { banner } = events

  const onBannerStatusCallback = (data) => {
    setTimeout(() => {
      setBannerStatus(data)
    }, 10000)
  }

  useListenEvent(banner.status, onBannerStatusCallback)

  const { banner_type = null } = bannerStatus ?? {}

  if (!banner_type) return null

  const handleCheckBoxClick = () => {
    setForceClose((prevState) => !prevState)
  }

  const handleCloseClick = () => {
    socket.emit(banner.close, { banner_id, force_close })

    setBannerStatus(null)
  }

  return (
    <div className={styles.popupWrapper}>
      <div className={cn(styles.popup, { [styles.popup__borderBottom]: banner_type === bannerType.withCheckBox })}>
        <div className={styles.leftBlock}>
          <LogoIcon className={styles.logo} />

          <div className={styles.qrCodesWrapper}>
            <QRCode QRCodeIcon={QRFbIcon} SocialMediaIcon={FbIcon} />

            <QRCode QRCodeIcon={QRTgIcon} SocialMediaIcon={TgIcon} />
          </div>

          <p className={styles.text}>
            Join our <b>Facebook</b> and <b>Telegram</b> channels and follow the news and updated vacancies.
          </p>

          <div className={styles.socialMediaWrapper}>
            <SocialMedia
              Icon={FbIcon}
              label='@NextShip'
              link='https://www.facebook.com/nextship.page'
              name='Facebook'
            />
            <SocialMedia Icon={TgIcon} label='@nextshipapp' link='https://t.me/nextshipapp' name='Telegram' />
          </div>

          {banner_type === bannerType.withCheckBox && (
            <CheckBox theme='bordered' checked={force_close} onClick={handleCheckBoxClick} className={styles.checkBox}>
              Don't show this message again
            </CheckBox>
          )}
        </div>

        <div className={styles.rightBlock}>
          <img src={fbScreen} alt='facebook screen' className={styles.fbScreen} />

          <img src={tgScreen} alt='telegram screen' className={styles.tgScreen} />
        </div>

        <button onClick={handleCloseClick} className={styles.closeButton}>
          close
        </button>
      </div>
    </div>
  )
}

export default PopupAboutSocialMedia
