export const profilePaths = {
  view: '/profile/view',
  personal_data: '/profile/edit/personal-data',
  documents: '/profile/edit/documents',
  chat: '/profile/chat',
  review: '/profile/review',
  notification: '/profile/notification',
  billing: '/profile/billing',
  settings: '/profile/settings',

  vacancies: '/profile/view/vacancies',
  applied_jobs: '/profile/view/applied-jobs',
  user_card: '/profile/view/user-card',
  general: '/profile/edit/personal-data/general',
  address: '/profile/edit/personal-data/address',
  biometrics: '/profile/edit/personal-data/biometrics',
  my_contacts: '/profile/edit/personal-data/my-contacts',
  relatives_contacts: '/profile/edit/personal-data/relatives-contacts',
  education: '/profile/edit/documents/education',
  experience: '/profile/edit/documents/experience',
  passports: '/profile/edit/documents/passports',
  visas: '/profile/edit/documents/visas',
  certificates: '/profile/edit/documents/certificates',
  licenses: '/profile/edit/documents/licenses',
  video_resume: '/profile/edit/personal-data/video-resume',
  reviews: '/profile/review/reviews',
  request: '/profile/review/request',
  list: '/profile/notification/list',
  billing_services: '/profile/billing/services',
  billing_history: '/profile/billing/history',
}

export const UKRAINE_NATIONALITY = 'Ukraine'

export const operationType = {
  edit: 'Edit',
  reply: 'Reply',
  delete: 'Delete',
}

export const events = {
  chat: {
    rooms: 'chat.rooms',
    messageDelivered: 'chat.message.delivered',
    messages: 'chat.messages',
    newMessage: 'new.chat.message',
    searchMessage: 'search.chat.message',
    editMessage: 'chat.edit.message',
    sendMessage: 'send.message',
    delete: 'chat.delete',
  },
  billing: {
    paymentRequiresConfirmation: 'billing.payment_intent.requires_confirmation',
    cardDetach: 'billing.card.detached',
    cardAdded: 'billing.card.added',
    subscriptionPaid: 'billing.subscription.paid',
    subscriptionUnPaid: 'billing.subscription.unpaid',
    subscriptionUpdated: 'billing.subscription.updated',
    permissionsCurrent: 'billing.permissions.current',
    permissionsUpdated: 'billing.permissions.updated',
    subscriptionCanceled: 'billing.subscription.canceled',
    newEvent: 'events.event.new',
  },
  banner: {
    status: 'banner.status',
    close: 'banner.close',
  },
  landing: {
    status: 'seafarer.statistic.landing',
  },
}

export const dateFormatForSanding = 'YYYY-MM-DD'
