import React from 'react'

import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'
import cn from 'classnames'

import Button from '../../../../../UI/Button/Button'

import { ReactComponent as XIcon } from '../../../../../assets/icons/x.svg'
import { ReactComponent as StoreIcon } from './icons/store.svg'

import { profilePaths } from '../../../../../constants/constans'
import { action } from '../../constants'

const Wrapper = ({ children, onClose, notificationAction, isServiceActive, className = '' }) => {
  const history = useHistory()

  const isButtonVisible = (() => {
    if (notificationAction === action.hasExpired) return true

    return !isServiceActive
  })()

  const handleButtonClick = () => {
    history.push(profilePaths.billing_services)
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <button onClick={onClose} className={styles.iconWrapper}>
        <XIcon />
      </button>

      {children}

      {isButtonVisible && (
        <Button type='button' size='middle-full' className={styles.button} onClick={handleButtonClick}>
          <span>Go to Store</span>

          <span>
            <StoreIcon />
          </span>
        </Button>
      )}
    </div>
  )
}

export default Wrapper
