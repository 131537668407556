import React, { useState } from 'react'

import styles from './styles.module.scss'

import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import CompletionStatus from '../CompletionStatus/CompletionStatus'
import Portal from '../Portal/Portal'
import { HelperInfo } from './information'
import informationSvg from '../../assets/icons/information.svg'
import { CSSTransition } from 'react-transition-group'
import { NavLink } from 'react-router-dom'
import Indicator from '../TabNav/Indicator'
import Services from './Services/Services'
import Controls from './Controls/Controls'
import useBillingActive from '../../hooks/useBillingActive'

// import Location from '../Location/Location'
// import OutOfUkraine from '../OutOfUkraine/OutOfUkraine'
// import { getUkrainianLocationInfo } from '../../api/getApi'
// import { putUkrainianLocationInfo } from '../../api/putApi'

const ProfileHeader = ({ title, links = [] }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(null)

  const profile = useSelector(({ user }) => user.profile)
  const { admin } = useSelector(({ authentication }) => authentication)

  const isBillingActive = useBillingActive()

  const helperInfo = HelperInfo()

  // const [isLocationOpen, setIsLocationOpen] = useState(false)
  // const [outOfResidenceData, setOutOfResidenceData] = useState({})

  // const outOfUkraineRef = useClickOutside(() => setIsLocationOpen(false))

  // const [outOfResidenceData, setOutOfResidenceData] = React.useState({})
  // const [isLocationOpen, setIsLocationOpen] = React.useState(false)

  // const outOfUkraineRef = useClickOutside(() => setIsLocationOpen(false))

  // const handleLocationOpenChange = () => {
  //   setIsLocationOpen(!isLocationOpen)
  //
  //   if (!outOfResidenceData.show_out_of_residence) {
  //     putUkrainianLocationInfo({ show_out_of_residence: true }).then((response) => {
  //       setOutOfResidenceData((prevState) => ({
  //         ...prevState,
  //         show_out_of_residence: response.show_out_of_residence,
  //       }))
  //     })
  //   }
  //
  //   dispatch(setIsSupportOpenedAction(false))
  // }

  // React.useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   if (!user?.isAdmin && user?.access_token) {
  //     getUkrainianLocationInfo().then((response) => {
  //       setOutOfResidenceData(response)
  //
  //       if (response.show_out_of_residence) {
  //         setIsLocationOpen(response.show_out_of_residence)
  //       }
  //     })
  //   }
  // }, [])

  return (
    <header id='tour-step-2' className={styles.header}>
      <div className={styles.header_wrapper}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <span>{title}</span>

            {helperInfo && (
              <img
                className={styles.helperIcon}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                src={informationSvg}
                alt='information helper'
                id='tour-step-3'
              />
            )}
          </div>

          {profile.filler.grand_total < 100 && (
            <CompletionStatus
              value={profile.filler.grand_total}
              color={profile.filler.color}
              unfilled={profile.filler.unfilled}
            />
          )}

          <div className={styles.controlsWrapper}>
            {/*<div ref={outOfUkraineRef}>*/}
            {/*  <CSSTransition*/}
            {/*    classNames='fadein-top'*/}
            {/*    appear*/}
            {/*    mountOnEnter*/}
            {/*    unmountOnExit*/}
            {/*    in={isLocationOpen}*/}
            {/*    timeout={300}*/}
            {/*  >*/}
            {/*    <OutOfUkraine*/}
            {/*      outOfResidence={outOfResidenceData.out_of_residence}*/}
            {/*      setOutOfResidenceData={setOutOfResidenceData}*/}
            {/*      setIsLocationOpen={setIsLocationOpen}*/}
            {/*    />*/}
            {/*  </CSSTransition>*/}
            {/*</div>*/}

            {/*<Location isActive={isLocationOpen} isDisable={!isSocketConnected} onClick={handleLocationOpenChange} />*/}

            {isBillingActive && !admin?.isAdmin && <Services />}

            <Controls isAdmin={admin?.isAdmin} />
          </div>
        </div>

        <Portal>
          <CSSTransition classNames='tooltip' appear mountOnEnter unmountOnExit in={isTooltipOpen} timeout={300}>
            <div className='global-tooltip'>{isTooltipOpen ? helperInfo : null}</div>
          </CSSTransition>
        </Portal>

        <div className={styles.wrapper}>
          {!!links.length && (
            <div className={styles.tabs}>
              {links.map(({ url, title, indicator }, index) => (
                <NavLink key={index} to={url} activeClassName={styles.active}>
                  {title}

                  {indicator && <Indicator value={indicator} />}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default ProfileHeader

ProfileHeader.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array,
}
