import React from 'react'

export const DownloadSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='17'
      fill='none'
      viewBox='0 0 11 17'
      stroke='black'
      strokeWidth='.3'
    >
      <path
        fill='var(--second-color)'
        d='M5.853.24a.487.487 0 00-.708 0l-4 4.117a.523.523 0 000 .729.494.494 0 00.708 0L5 1.846v10.08c0 .136.053.267.147.364a.493.493 0 00.707 0 .522.522 0 00.146-.364V1.846l3.146 3.239a.5.5 0 00.354.15c.133 0 .26-.054.354-.15a.523.523 0 000-.73l-4-4.116zM.5 16.03h10c.133 0 .26-.055.354-.151a.523.523 0 000-.728A.493.493 0 0010.5 15H.5a.493.493 0 00-.354.15.522.522 0 000 .729c.094.096.221.15.354.15z'
      />
    </svg>
  )
}

export const CalendarIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        fill='var(--color-accent-light)'
        d='M14.52 16.165c.421 0 .825-.168 1.122-.467a1.599 1.599 0 000-2.256 1.582 1.582 0 00-2.244 0 1.6 1.6 0 000 2.255c.298.3.702.468 1.122.468z'
      />
      <path
        fill='var(--color-accent-light)'
        fillRule='evenodd'
        d='M7.38 5c-.631 0-1.237.252-1.683.7A2.399 2.399 0 005 7.394v9.57c0 .634.25 1.243.697 1.691.446.45 1.052.701 1.683.701h9.52c.631 0 1.237-.252 1.683-.7a2.399 2.399 0 00.697-1.692v-9.57c0-.635-.25-1.244-.697-1.692A2.374 2.374 0 0016.9 5H7.38zm-.793 11.963V8.19h11.106v8.773a.792.792 0 01-.793.798H7.38a.791.791 0 01-.793-.798z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export const TrashIcon = ({ width, height, stroke }) => {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 17 20'>
      <path
        fill={stroke}
        stroke={stroke}
        strokeWidth='0.3'
        d='M15.411 3.744h-3.01V2.006c0-.554-.451-1.006-1.007-1.006h-5.25c-.556 0-1.008.452-1.008 1.006v1.738h-3.13C1.452 3.744 1 4.196 1 4.751v1.01c0 .556.452 1.008 1.006 1.008h.395l.397 11.898c0 .581.473 1.054 1.054 1.054h9.714a1.05 1.05 0 001.053-1.038l.398-11.914h.394c.555 0 1.007-.452 1.007-1.007V4.75c0-.555-.451-1.007-1.007-1.007zM13.66 18.668a.093.093 0 01-.094.093H3.852c-.052 0-.094-.041-.094-.11L3.361 6.77h10.696l-.397 11.899zm1.799-12.906a.049.049 0 01-.048.048H2.006a.047.047 0 01-.047-.048v-1.01c0-.026.021-.048.047-.048h3.61a.48.48 0 00.48-.48V2.007c0-.026.02-.047.047-.047h5.251c.027 0 .048.021.048.047v2.217c0 .265.214.48.48.48h3.489c.026 0 .048.022.048.047v1.011z'
      />
      <path
        fill={stroke}
        stroke={stroke}
        strokeWidth='0.3'
        d='M9.772 3.282H7.647a.48.48 0 000 .96h2.125a.48.48 0 100-.96z'
      />
    </svg>
  )
}

export const Cells = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none' viewBox='0 0 26 26'>
      <path
        style={{ fill: 'none' }}
        stroke='var(--color-accent-light)'
        strokeWidth='1.8'
        d='M20.238 6H24a1 1 0 011 1v17a1 1 0 01-1 1H6a1 1 0 01-1-1v-4.7'
      />
      <rect
        width='19.2'
        style={{ fill: 'none' }}
        height='18.2'
        x='0.9'
        y='0.9'
        stroke='var(--color-accent-light)'
        strokeWidth='1.8'
        rx='1.1'
      />
      <mask id='path-3-inside-1' fill='#fff'>
        <path
          fillRule='evenodd'
          d='M11 5a.5.5 0 01.5.5v4h4a.5.5 0 010 1h-4v4a.5.5 0 01-1 0v-4h-4a.5.5 0 010-1h4v-4A.5.5 0 0111 5z'
          clipRule='evenodd'
        />
      </mask>
      <path
        fillRule='evenodd'
        d='M11 5a.5.5 0 01.5.5v4h4a.5.5 0 010 1h-4v4a.5.5 0 01-1 0v-4h-4a.5.5 0 010-1h4v-4A.5.5 0 0111 5z'
        clipRule='evenodd'
      />
      <path
        fill='var(--color-accent-light)'
        d='M11.5 9.5h-1v1h1v-1zm0 1v-1h-1v1h1zm-1 0h1v-1h-1v1zm0-1v1h1v-1h-1zM11 6a.5.5 0 01-.5-.5h2A1.5 1.5 0 0011 4v2zm-.5-.5v4h2v-4h-2zm1 5h4v-2h-4v2zm4 0a.5.5 0 01-.5-.5h2a1.5 1.5 0 00-1.5-1.5v2zM15 10a.5.5 0 01.5-.5v2A1.5 1.5 0 0017 10h-2zm.5-.5h-4v2h4v-2zm-5 1v4h2v-4h-2zm0 4a.5.5 0 01.5-.5v2a1.5 1.5 0 001.5-1.5h-2zm.5-.5a.5.5 0 01.5.5h-2A1.5 1.5 0 0011 16v-2zm.5.5v-4h-2v4h2zm-1-5h-4v2h4v-2zm-4 0a.5.5 0 01.5.5H5a1.5 1.5 0 001.5 1.5v-2zm.5.5a.5.5 0 01-.5.5v-2A1.5 1.5 0 005 10h2zm-.5.5h4v-2h-4v2zm5-1v-4h-2v4h2zm0-4a.5.5 0 01-.5.5V4a1.5 1.5 0 00-1.5 1.5h2z'
        mask='url(#path-3-inside-1)'
      />
    </svg>
  )
}

export const StarSvg = ({ size, fill, children, fontSize = 8 }) => {
  const classes = {
    wrapper: {
      position: 'relative',
      display: 'inline-block',
    },
    children: {
      position: 'absolute',
      width: size,
      height: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: 0,
      top: 0,
      fontWeight: 600,
      fontSize: `${fontSize}px`,
      lineHeight: `${fontSize * 1.22}px`,
      color: 'var(--color-white)',
    },
  }
  return (
    <div style={classes.wrapper}>
      <div style={classes.children}>{children}</div>
      <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox={'0 0 30 30'}>
        <path
          fill={fill}
          d='M15 0l2.61 2.72 3.491-1.423 1.279 3.546 3.767.12-.274 3.76 3.393 1.642-1.78 3.323 2.432 2.88-2.977 2.312 1.05 3.62-3.66.9-.514 3.735-3.71-.665-1.988 3.202L15 27.555l-3.119 2.117-1.988-3.202-3.71.665-.513-3.734-3.66-.901 1.05-3.62-2.978-2.312 2.432-2.88-1.78-3.323 3.393-1.643-.274-3.759 3.767-.12L8.9 1.297l3.49 1.422L15 0z'
        />
      </svg>
    </div>
  )
}

export const ArrowDownSvg = ({ width, height, fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 14 7'>
      <path
        fill={fill}
        d='M6.989 6.998a.997.997 0 01-.639-.23L.36 1.77A1.001 1.001 0 011.639.231L6.99 4.71 12.339.39a.998.998 0 011.408.15 1 1 0 01-.14 1.46l-5.99 4.827a.997.997 0 01-.628.17z'
      />
    </svg>
  )
}

export const ArchiveIcon = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' fill='none' viewBox='0 0 16 18'>
      <path
        fill={fill}
        d='M4.37 10.112l1.832 1.221h3.596l1.832-1.221A.668.668 0 0112 10h2V4.667h-4A.667.667 0 019.333 4V0H2.667A.667.667 0 002 .667V10h2c.132 0 .26.039.37.112zm.963-7.445h2a.667.667 0 010 1.333h-2a.667.667 0 010-1.333zm0 2.666h5.334a.667.667 0 010 1.334H5.333a.667.667 0 010-1.334zm0 2.667h5.334a.667.667 0 010 1.333H5.333a.667.667 0 010-1.333z'
      />
      <path
        fill={fill}
        d='M10.667.39v2.943h2.943L10.667.391zM12.202 11.333l-1.832 1.222a.666.666 0 01-.37.112H6a.666.666 0 01-.37-.112l-1.832-1.222H0v5.334c0 .368.299.666.667.666h14.666a.667.667 0 00.667-.666v-5.334h-3.798zm-2.869 3.334H6.667a.667.667 0 010-1.334h2.666a.667.667 0 010 1.334z'
      />
    </svg>
  )
}

export const StarIcon = ({ fill, size }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} fill='none' viewBox='0 0 28 26'>
      <path
        fill={fill}
        d='M14.235 0l3.196 9.836h10.343l-8.367 6.08 3.196 9.836-8.368-6.08-8.367 6.08 3.196-9.836-8.367-6.08h10.342L14.235 0z'
      />
    </svg>
  )
}

export const CircleSvg = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none' viewBox='0 0 10 10'>
      <circle cx='5' cy='5' r='5' fill={fill} />
    </svg>
  )
}

export const TriangleBadge = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='28' fill='none' viewBox='0 0 27 28'>
      <path fill={fill} d='M14.5 8.5a4 4 0 014 4v15.2L0 8.5h14.5z' />
    </svg>
  )
}
