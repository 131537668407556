import * as Yup from 'yup'
import { format } from 'date-fns'

export const getValidationSchema = (dateFormat) => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Field is required')
      .trim()
      .matches(/^[A-Za-z][A-Za-z0-9, &:/'.-]*$/, 'Invalid symbol')
      .min(2, 'Too short')
      .max(60, 'Too long'),
    dateFrom: Yup.date()
      .required('Field is required')
      .min(
        new Date('1900-01-01'),
        dateFormat &&
          `Min date is ${format(new Date('1900-01-01'), dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd'))}`
      )
      .max(
        new Date(new Date().setHours(0, 0, 2)),
        dateFormat &&
          `Max date is ${format(
            new Date(new Date().setHours(0, 0, 2)),
            dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
          )}`
      )
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
    dateTo: Yup.date()
      .required('Field is required')
      .min(Yup.ref('dateFrom'), 'Date of ending of contract should be later than date of begging')
      .max(
        new Date(new Date().setHours(0, 0, 2)),
        dateFormat &&
          `Max date is ${format(
            new Date(new Date().setHours(0, 0, 2)),
            dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
          )}`
      )
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
  })
}

export const label = 'If during the period of your work on this vessel its name was different, please indicate it.'
