import React from 'react'

import styles from '../../styles.module.scss'

const EarlyAccessNotActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>
        <b>Early Access</b> service is not activated.
      </p>
      <p>
        Stay ahead of the curve by receiving notifications about new job openings several hours ahead of others,
        allowing you to react swiftly and secure the best opportunities.
      </p>
    </div>
  )
}

export default EarlyAccessNotActivated
