import React from 'react'
import Spinner from '../UI/Spinner/Spinner'

export default function WithPreloader({
  component: Component,
  preloader: PreloaderComponent,
  isActive,
  size = 30,
  type,
  ...props
}) {
  const classes = {
    wrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  }

  return isActive ? (
    <div style={classes.wrapper}>
      {PreloaderComponent ? <PreloaderComponent /> : <Spinner type={type} size={size} />}
    </div>
  ) : (
    <Component {...props} />
  )
}
