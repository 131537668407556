import React from 'react'
import styles from './offline.module.scss'
import { ReactComponent as OfflineIcon } from './icons/offline.svg'

const Offline = () => {
  return (
    <div className={styles.offline}>
      <OfflineIcon />
      <h1>No internet</h1>
    </div>
  )
}

export default Offline
