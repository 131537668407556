import PropTypes from 'prop-types'
import cn from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import Input from '../Input/Input'
import useClickOutside from '../../hooks/useClickOutside'
import { getCompleteVesselInfo } from '../../helpers/autocomplete.helper'
import Tooltip from '../Tooltip/Tooltip'
import useDebounce from '../../hooks/useDebounce'
import useInput from '../../hooks/useInput'

const Autocomplete = memo(
  ({
    name,
    value,
    label,
    placeholder,
    getTitle = 'description',
    className = '',
    items = [],
    onChange,
    onClick,
    handleScroll,
    onFocus,
    isInvalid = false,
    required,
    autoComplete = 'off',
    errorMessage = '',
    description = '',
    disabled,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)

    const selectEl = useClickOutside(() => setIsOpen(false))

    const { value: inputValue, onChange: handleChange, setValue } = useInput(value)

    const debounceValue = useDebounce(inputValue)

    useEffect(() => {
      if (inputValue !== value) {
        onChange(name, { target: { value: inputValue } })
      }

      // eslint-disable-next-line
    }, [debounceValue])

    useEffect(() => {
      setValue(value)

      // eslint-disable-next-line
    }, [value])

    const handleItemClick = (id, value) => {
      setIsOpen(false)

      onClick(name, { id, name: value })
    }

    const handleKeyDown = (e) => {
      if (!isOpen) {
        return
      }

      if (e.key === 'ArrowUp' && activeIndex > 0) {
        e.preventDefault()

        setActiveIndex(activeIndex - 1)

        return
      }

      if (e.key === 'ArrowDown' && activeIndex < items.length - 1) {
        e.preventDefault()

        setActiveIndex(activeIndex + 1)

        return
      }

      if (e.key === 'Enter' && activeIndex !== -1) {
        const id = items[activeIndex].place_id
        const name = items[activeIndex].description

        handleItemClick(id, name)
      }
    }

    return (
      <>
        {label && (
          <div className={styles.wrapperLabel}>
            <label className={cn(styles.label, { [styles.label_disabled]: disabled })}>
              {label} {required && <span className={cn('required-star', styles.required_star)}>*</span>}
            </label>

            {description && (
              <div className={styles.tooltipInfo}>
                <Tooltip>
                  <div className={styles.tooltipInfo_content}>{description}</div>
                </Tooltip>
              </div>
            )}
          </div>
        )}

        <div
          ref={selectEl}
          className={cn(styles.wrapper, className, {
            [styles.invalid]: isInvalid,
            [styles.noEvent]: disabled,
          })}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
        >
          <Input
            type='search'
            placeholder={disabled && placeholder === label ? '' : placeholder}
            name={name}
            value={inputValue ?? ''}
            onChange={handleChange}
            onFocus={(e) => onFocus(name, e)}
            autoComplete={autoComplete}
            clear={setValue}
            isInvalid={isInvalid}
            disabled={disabled}
          />

          {isInvalid && errorMessage && !disabled && <p className='errorMessage'>{errorMessage}</p>}

          {items.length && isOpen ? (
            <ul className={styles.list} onScroll={handleScroll}>
              {items.map((item, index) => {
                const { [getTitle]: name, place_id: id, id: alt_id } = item
                const isActive = index === activeIndex || name === value

                return (
                  <SelectItem
                    tabIndex='0'
                    key={id || alt_id}
                    name={name}
                    id={id || alt_id}
                    displayName={getCompleteVesselInfo(item, getTitle)}
                    isActive={isActive}
                    onClick={handleItemClick}
                  />
                )
              })}
            </ul>
          ) : null}
        </div>
      </>
    )
  }
)

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getTitle: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  handleScroll: PropTypes.func,
  onFocus: PropTypes.func,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Autocomplete
