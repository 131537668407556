import React, { useState } from 'react'
import UserProfileMore from './UserProfileMore'
import { Popover, Button, popoverClasses } from '@mui/material'
import { useSelector } from 'react-redux'

export default function UserProfile({ isAdmin }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const { picture } = useSelector(({ user }) => user.profile)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id='tour-step-6'
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        sx={{
          width: '32px',
          height: '32px',
          minWidth: '32px',
          minHeight: '32px',
          borderRadius: '50%',
          backgroundImage:
            picture?.file_mini || picture?.file
              ? `url(${picture?.file_mini || picture?.file})`
              : 'var(--color-chat-gray)',
          backgroundSize: '110%',
          backgroundPosition: '50% 0%',
          backgroundRepeat: 'no-repeat',
          marginLeft: '8px',
        }}
      >
        <span></span>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transition='true'
        sx={{
          [`.${popoverClasses.paper}`]: {
            marginTop: '30px',
            marginLeft: '0px',
            width: '327px',
            boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <UserProfileMore isAdmin={isAdmin} />
      </Popover>
    </>
  )
}
