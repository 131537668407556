import React from 'react'

import styles from '../sections/SectionAboutUs/styles.module.scss'

import Button from '../../UI/Button/Button'
import PropTypes from 'prop-types'

const LoginMobileInfo = ({ handleClose }) => {
  return (
    <div className={styles.wrapperBackground}>
      <div className={styles.loginModal}>
        <div className={styles.wrapperModal}>
          <p>In order to log in, you can use the computer version of the site or download the mobile app</p>

          <Button className={styles.loginButton} onClick={handleClose}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  )
}

LoginMobileInfo.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default LoginMobileInfo
