import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styles from './notifications.module.scss'

import CheckBox from '../../../UI/CheckBox/CheckBox'
import Spinner from '../../../UI/Spinner/Spinner'
import Button from '../../../UI/Button/Button'

import { getNotificationSettings } from '../../../api/getApi'
import { postNotificationSettings } from '../../../api/postApi'
import { useNotify } from '../../../hooks/useNotify'

const checkBoxTextStyles = {
  fontWeight: 400,
  color: 'var(--second-color)',
  fontSize: '14px',
}

const notificationType = {
  event: 'event',
  email: 'email',
}

const Notifications = () => {
  const [notifications, setNotifications] = useState([])
  const [initNotifications, setInitNotifications] = useState([])
  const [isNotificationsAll, setIsNotificationsAll] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isButtonEnable, setIsButtonEnable] = useState(false)
  const [loading, setLoading] = useState(false)

  const { notify } = useNotify()

  useEffect(() => {
    const getNotifications = async () => {
      setLoading(true)

      try {
        const { results } = await getNotificationSettings()

        setNotifications(results)
        setInitNotifications(results)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getNotifications()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const hasChanged = JSON.stringify(initNotifications) !== JSON.stringify(notifications)

    if (notifications.every(({ is_active }) => Boolean(is_active))) {
      setIsNotificationsAll(true)
    } else {
      setIsNotificationsAll(false)
    }

    if (hasChanged) {
      setIsButtonEnable(true)
    } else {
      setIsButtonEnable(false)
    }
  }, [initNotifications, notifications])

  const handleNotificationsUpdate = useCallback(async () => {
    setLoading(true)

    try {
      const inactiveNotificationIds = notifications.reduce((acc, cur) => {
        if (!cur.is_active) {
          return [...acc, cur.id]
        }

        return acc
      }, [])

      const { results } = await postNotificationSettings({
        notification: inactiveNotificationIds,
      })

      setNotifications(results)
      setInitNotifications(results)
      setIsEdit(false)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [notifications])

  const handleCheckBoxClick = useCallback(
    (id) => {
      const updatedNotifications = notifications.map((item) => {
        if (item.id === id) {
          return { ...item, is_active: !item.is_active }
        }

        return item
      })

      setNotifications(updatedNotifications)
    },
    [notifications]
  )

  const handleAllClick = useCallback(() => {
    const result = notifications.map((item) => ({ ...item, is_active: !isNotificationsAll }))

    setNotifications(result)
  }, [isNotificationsAll, notifications])

  const handleEditClose = useCallback(() => {
    setIsEdit(false)
    setNotifications(initNotifications)
  }, [initNotifications])

  const eventNotifications = useMemo(() => {
    return notifications.filter(({ carrier_type }) => carrier_type === notificationType.event)
  }, [notifications])
  const emailNotifications = useMemo(() => {
    return notifications.filter(({ carrier_type }) => carrier_type === notificationType.email)
  }, [notifications])

  return (
    <>
      <div className={styles.editBlock}>
        {isEdit && (
          <div className={styles.buttons}>
            <Button className={styles.buttonClose} onClick={handleEditClose} type='button' size='small'>
              Close
            </Button>

            <Button
              className={styles.buttonSave}
              onClick={handleNotificationsUpdate}
              type='button'
              size='small'
              disabled={!isButtonEnable || loading}
            >
              Save
            </Button>
          </div>
        )}

        {!isEdit && (
          <button className={styles.buttonEdit} onClick={() => setIsEdit(true)}>
            Edit notification list
          </button>
        )}
      </div>

      {isEdit && (
        <div className={styles.checkBoxAll}>
          <CheckBox theme='bordered' name='notificationAll' onClick={handleAllClick} checked={isNotificationsAll}>
            Select/Cancel all notifications
          </CheckBox>
        </div>
      )}

      <div className={styles.notificationsWrapper}>
        {loading && <Spinner />}

        {!loading && (
          <div className={styles.notifications_scrollBlock}>
            <div className={styles.leftBlock}>
              <span>Web application</span>

              {eventNotifications.map(({ id, is_active, short_name }) => (
                <CheckBox
                  key={id}
                  id={id}
                  theme='bordered'
                  name='notification'
                  onClick={handleCheckBoxClick}
                  checked={is_active}
                  textStyles={checkBoxTextStyles}
                  disabled={!isEdit}
                >
                  {short_name}
                </CheckBox>
              ))}
            </div>

            <div className={styles.rightBlock}>
              <span>Email</span>

              {emailNotifications.map(({ id, is_active, short_name }) => (
                <CheckBox
                  key={id}
                  id={id}
                  name='notification'
                  theme='bordered'
                  onClick={handleCheckBoxClick}
                  checked={is_active}
                  textStyles={checkBoxTextStyles}
                  disabled={!isEdit}
                >
                  {short_name}
                </CheckBox>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Notifications
