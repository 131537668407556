import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'

import './auth-form.scss'

import Separator from '../../UI/Separator/Separator'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import InputPassword from '../../UI/Input/InputPassword'
import { registerUserRequest } from '../../api/postApi'
import FacebookAuthButton from '../../UI/AuthButtons/FacebookAuthButton'
import GoogleAuthButton from '../../UI/AuthButtons/GoogleAuthButton'
import AppleAuthButton from '../../UI/AuthButtons/AppleAuthButton'
import CheckBox from '../../UI/CheckBox/CheckBox'
import { initialValuesSignUp, signUpSchema } from './sign.schemas'
import { signUpSubmitHelper } from './sign.helper'
import { useNotify } from '../../hooks/useNotify'
import { getCurrentDomain } from '../../helpers/apiHelper'
import MetaTags from '../MetaTags/MetaTags'

const SeamanSignUpForm = () => {
  const { notify } = useNotify()
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false)

  const formik = useFormik({
    initialValues: initialValuesSignUp,
    validationSchema: signUpSchema,
    validateOnChange: true,

    onSubmit: (values) => {
      setIsButtonDisabled(true)
      registerUserRequest(signUpSubmitHelper(values)).then((data) => {
        if (!data.ok) {
          setIsButtonDisabled(false)
          data.errors.forEach(({ message }) => notify.error('Error', message))
        } else {
          setIsButtonDisabled(false)
          notify('Success', 'A confirmation letter has been sent to your email!')
        }
      })
    },
  })

  const handleChangeFormikValue = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }
    formik.handleChange(event)
  }

  const { values, errors, touched } = formik

  return (
    <>
      <MetaTags
        title='NextShip webservice for seafarers Sign Up'
        keywords={
          'create account, create account NextShip, create account Next Ship, NextShip create account,' +
          ' NextShip account, NextShip personal account, create personal account NextShip, Create profile NextShip,' +
          ' create personal profile NextShip, Profile NextShip, Create CV, Fill in CV, Fill out CV, Create CV' +
          ' NextShip, Log In NextShip, NextShip Log in, Create resume seafarer, Create resume NextShip, how to fill' +
          ' out cv, fill out resume, fill out a cv, fill out a resume, attach resume/cv, fill out resume form, cv' +
          ' fill, application form NextShip, fill in application form, fill out application form, create application' +
          ' form NextShip, login NextShip'
        }
      />
      <div className='auth-form auth-form-signup'>
        <div className='auth-form-container'>
          <div className='auth-form__title'>Create Account as</div>
          <div className='auth-form__scope_selection'>
            <a className='scope_selection_link' href={`${getCurrentDomain()}employers.nextship.net/`}>
              CrewManager
            </a>
            <span className='scope_selection_active'>Seafarer</span>
          </div>
          <div className='auth-form__inform'>How do you want to Create Account?</div>

          <div className='auth-form__social-wrapper'>
            <AppleAuthButton />
            <GoogleAuthButton />
            <FacebookAuthButton />
          </div>

          <Separator text='or' />

          <form className='auth-form__signup_form' onSubmit={formik.handleSubmit}>
            <fieldset className='auth-form__fieldset'>
              <div className='form-group'>
                <Input
                  className='auth-form__input'
                  name='email'
                  onChange={handleChangeFormikValue}
                  value={values.email}
                  isInvalid={errors.email && touched.email}
                  placeholder='Email'
                  required
                />
                {errors.email && touched.email ? (
                  <div className='input-error-message-static'>{errors.email}</div>
                ) : null}
              </div>
              <div className='form-group'>
                <InputPassword
                  theme='password'
                  className='auth-form__input'
                  name='password'
                  onChange={handleChangeFormikValue}
                  value={values.password}
                  isInvalid={errors.password && touched.password}
                  placeholder='Password'
                  required
                />
                {errors.password && touched.password ? (
                  <div className='input-error-message-static'>{errors.password}</div>
                ) : null}
              </div>

              <div className='form-group'>
                <InputPassword
                  theme='password'
                  className='auth-form__input'
                  name='confirm'
                  onChange={handleChangeFormikValue}
                  value={values.confirm}
                  isInvalid={errors.confirm && touched.confirm}
                  placeholder='Confirm password'
                  required
                />
                {errors.confirm && touched.confirm ? (
                  <div className='input-error-message-static'>{errors.confirm}</div>
                ) : null}
              </div>
              <div className='form-group'>
                <CheckBox
                  type='checkbox'
                  name='agree'
                  onClick={() => formik.setFieldValue('agree', !values.agree)}
                  checked={values.agree}
                  theme='black'
                  className='auth-form__policy'
                >
                  I have read and agree to the
                  <b>
                    {' '}
                    <Link to='/terms'>Terms</Link>
                  </b>{' '}
                  &
                  <b>
                    {' '}
                    <Link to='/privacy'>Data Use Policy</Link>
                  </b>
                  .
                </CheckBox>
                {errors.agree ? <div className='input-error-message-static'>{errors.agree}</div> : null}
              </div>
            </fieldset>

            <Button theme='dark' size='large-full' type='submit' disabled={isButtonDisabled}>
              Create Account
            </Button>
          </form>
        </div>
        <div className='auth-form__footer'>
          Have account?{' '}
          <b>
            <Link to='/auth/signin'>Log In</Link>
          </b>
        </div>
      </div>
    </>
  )
}

export default SeamanSignUpForm
