import React, { useEffect, useState } from 'react'
import Input from '../../../UI/Input/Input'
import { useFormik } from 'formik'
import Button from '../../../UI/Button/Button'
import styles from './ChangeEmail.module.scss'
import { changeEmailRequest } from '../../../api/postApi'
import InputPassword from '../../../UI/Input/InputPassword'
import { validationSchema } from './config'
import { useNotify } from '../../../hooks/useNotify'
import { isCompletelyFilled } from '../../../helpers/objectsHelper'
import { isEqual } from 'lodash/lang'

const ChangePassword = () => {
  const { notify } = useNotify()

  const [isButtonEnable, setIsButtonEnable] = useState(false)
  const [data, setData] = useState(null)

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlue: true,

    onSubmit: (values) => handleSubmit(values),
  })

  const { values, errors, touched } = formik

  const handleSubmit = ({ password, email }) => {
    setData(values)

    changeEmailRequest({ password, email })
      .then((data) => {
        if (data.status) {
          notify('Success', 'A confirmation letter has been sent to your new email')
        } else {
          notify.error('Error', 'This is your current email.')
        }
      })
      .catch((error) => notify.errorsList(error.errors))
  }

  useEffect(() => {
    if (isEqual(data, values)) {
      setIsButtonEnable(false)
    } else {
      const completelyFilled = isCompletelyFilled(values)

      setIsButtonEnable(completelyFilled)
    }
  }, [values, data])

  return (
    <form onSubmit={formik.handleSubmit} autoComplete='off'>
      <div className={styles.block}>
        <div className={styles.field}>
          <div className={styles.label}>Your password</div>
          <InputPassword
            theme='password'
            autoComplete='new-password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            isInvalid={!!(errors.password && touched.password)}
          />

          {errors.password && touched.password ? <div className={styles.error}>{errors.password}</div> : null}
        </div>

        <div className={styles.field}>
          <div className={styles.label}>New Email</div>
          <Input
            autoComplete='off'
            name='email'
            type='mail'
            value={formik.values.email}
            onChange={formik.handleChange}
            isInvalid={!!(errors.email && touched.email)}
          />

          {errors.email && touched.email ? <div className={styles.error}>{errors.email}</div> : null}
        </div>

        <div className={styles.controls}>
          <Button disabled={!isButtonEnable} type='submit' size='middle'>
            Confirm
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ChangePassword
