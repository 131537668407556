import {
  authPostFormData,
  authPostRequest,
  authPostRequestV2,
  postFormData,
  postRequest,
  request,
} from './auth.service'
import { API_BASE_V2, METHOD } from './constans'

export const registerUserRequest = async (data) => {
  return await postRequest('register/', data)
}

export const signupVerify = async (data) => {
  return await request('activation/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const confirmVerify = async (data) => {
  return await request('change-email-confirm/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const confirmUnsubscribe = async (data) => {
  return await request('seafarer/seafarer-notification-unsubscribe/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const passwordRecoverRequest = async (data, action_type = 'change_password') => {
  return await request(`reset-password/?action_type=${action_type}`, {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const changePasswordConfirmRequest = async (data) => {
  return await request('reset-password-confirm/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const postEmailSubscribe = async (data) => {
  return await postRequest('anonymous/subscribe/', data)
}

export const postDeleteEmail = async (data) => {
  return await request(`delete-account-email/`, { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export async function postSeafarerEducation(data) {
  return await authPostRequest(`seafarer/educations/`, JSON.stringify(data))
}

export async function postSeafarerPassport(data) {
  return await authPostRequest(`seafarer/passports/`, JSON.stringify(data))
}

export async function postSeafarerVisa(data) {
  return await authPostRequest(`seafarer/visas/`, JSON.stringify(data))
}

export async function postRelative(data) {
  return await authPostRequest(`seafarer/relatives/`, JSON.stringify(data))
}

export const changePasswordRequest = async (data) => {
  return await request('change-password/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const changeEmailRequest = async (data) => {
  return await request('change-email/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const deleteAccount = async (data) => {
  return await request('delete-account/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export async function postProfileContacts(data) {
  return await authPostRequest(`seafarer/contacts/`, JSON.stringify(data))
}

export async function postSeafarerCertificate(data) {
  return await authPostRequest(`seafarer/certificates/`, JSON.stringify(data))
}

export async function postSeafarerExperience(data, type) {
  return await authPostRequest(`seafarer/experiences-${type}/`, JSON.stringify(data))
}

export async function postSeafarerLicense(data) {
  return await authPostRequest(`seafarer/licenses/`, JSON.stringify(data))
}

function createFormDataForVideo(file) {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

export function createFormDataForFiles(file, type, id, is_main) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  if (id) {
    formData.append('type_id', id)
  }
  if (is_main) {
    formData.append('main_page', is_main)
  }

  return formData
}

export async function postDocumentsFile(file, id, type, is_main) {
  return await authPostFormData(`seafarer/uploads/`, createFormDataForFiles(file, type, id, is_main))
}

export async function postProcessingDocumentFile(file, type) {
  return await authPostFormData(`seafarer/uploads/`, createFormDataForFiles(file, type))
}

export async function postFormDataVideo(data) {
  return await authPostFormData(`seafarer/upload-transcoder/`, createFormDataForVideo(data))
}

function createFormDataForCv(type = 'cv', file, email, is_main) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('email', email)
  formData.append('type', type)
  if (is_main) {
    formData.append('main_page', is_main)
  }
  return formData
}

export async function postAnonymousCV(type, file, email, is_main) {
  return await postFormData(`anonymous/cv/`, createFormDataForCv(type, file, email, is_main))
}

export async function postVacancyRequest(data) {
  return await authPostRequest(`seafarer/vacancy-requests/`, JSON.stringify(data))
}

export const postArchiveNotifications = async (data) => {
  return await authPostRequest(`seafarer/events/`, JSON.stringify(data))
}

export async function postNotificationSettings(data) {
  return await authPostRequestV2(`seafarer/notifications/`, JSON.stringify(data))
}

export const postEditVesselName = async (data) => {
  const response = await authPostRequestV2(`vessels/name-history/`, JSON.stringify(data))

  if (!response.ok) throw new Error(response.errors[0].message)
}

export const postCheckNotify = async (id) => {
  return await request(`seafarer/events/`, {
    method: METHOD.POST,
    body: JSON.stringify({
      events: [+id],
      is_read: true,
      is_archive: false,
    }),
  })
}

// BILLING //

export const setBillingCard = async (pm) => {
  return await request('billing/card/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      pm,
    }),
  })
}

export const setBillingSubscriptionPlans = async (products) => {
  return await request('billing/subscription-plan/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      products,
    }),
  })
}

export const setBillingSubscription = async (data) => {
  return await request('billing/subscription/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const detachBillingCard = async (id) => {
  return await request('billing/card-detach/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({ id }),
  })
}

export const unsubscribeBillingProduct = async (data) => {
  return await request('billing/unsubscribe/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const viewServiceEvent = async (data) => {
  return await request('seafarer/events/view-by-service-action/', {
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const setEmailSubscription = async (id) => {
  return await request('billing/email-subscription/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      product_id: id,
    }),
  })
}

// BILLING //

// FIREBASE

export const postFCMToken = async (token) => {
  return await request('services/mobile-devices/', {
    method: METHOD.POST,
    body: JSON.stringify({
      name: null,
      registration_id: token,
      device_id: null,
      active: true,
      type: 'web',
    }),
  })
}

// FIREBASE
