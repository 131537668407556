import React from 'react'

import GetReviews from '../tabs/review/GetReviews/GetReviews'
import RequestForReviews from '../tabs/review/RequestForReviews/RequestForReviews'
import TabNav from '../components/TabNav/TabNav'
import { profilePaths } from '../constants/constans'

const ProfileVideoResumePage = () => {
  const links = [
    {
      url: profilePaths.reviews,
      title: 'My reviews',
      component: GetReviews,
    },
    {
      url: profilePaths.request,
      title: 'Request for reviews',
      component: RequestForReviews,
    },
  ]

  return <TabNav title='Reviews' links={links} redirectLink={profilePaths.reviews} />
}

export default ProfileVideoResumePage
