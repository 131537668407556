import React from 'react'

const PortfolioSvg = () => {
  return (
    <svg width='27' height='31' viewBox='0 0 27 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.2806 1C20.2824 1 26 6.38124 26 12.9856C26 18.1529 22.3922 22.8004 17.1637 24.3903L15.268 28.7625C14.9012 29.5881 14.1368 30.0771 13.25 30.0771C12.3634 30.0771 11.599 29.5879 11.232 28.7625L9.33633 24.3903C4.13855 22.8003 0.5 18.1835 0.5 12.9856C0.560998 6.3813 6.27878 1 13.2806 1V1ZM10.0701 23.1367C10.2535 23.1979 10.4369 23.3201 10.5286 23.5342L12.5467 28.1818C12.7303 28.6401 13.1583 28.6708 13.2805 28.6708C13.4027 28.6708 13.8308 28.6403 14.0449 28.1816L16.063 23.534C16.1547 23.3506 16.3076 23.1977 16.5215 23.1365C21.322 21.8218 24.6546 17.6329 24.6546 12.9548C24.6546 7.11489 19.5485 2.37569 13.2805 2.37569C7.01253 2.37569 1.90641 7.14542 1.90641 12.9854C1.90641 17.6329 5.26983 21.8217 10.0701 23.1365L10.0701 23.1367Z'
        fill='#43927C'
        stroke='#43927C'
        strokeWidth='0.4'
      />
      <path
        d='M7.71611 10.3255L10.7737 9.8975L12.1495 7.08455C12.3636 6.65651 12.7917 6.41189 13.2502 6.41189C13.7088 6.41189 14.1368 6.68702 14.351 7.08455L15.7268 9.83624L18.8456 10.2948C19.3041 10.356 19.7017 10.6923 19.8241 11.1204C19.977 11.5789 19.8546 12.0377 19.5182 12.374L17.3168 14.5143L17.8366 17.6023C17.9283 18.0609 17.7143 18.5197 17.3473 18.7948C16.9805 19.0699 16.4607 19.1006 16.0631 18.8865L13.3419 17.4494L10.5595 18.917C10.3761 19.0087 10.1926 19.0699 9.97849 19.0699C9.73385 19.0699 9.45873 18.9782 9.24462 18.8253C8.87778 18.5502 8.66363 18.0914 8.75536 17.6329L9.30605 14.606L7.0434 12.4046C6.70706 12.0682 6.58486 11.579 6.73756 11.1509C6.8907 10.7229 7.25753 10.3867 7.71609 10.3255L7.71611 10.3255ZM10.3764 13.75C10.621 13.9946 10.7432 14.3309 10.6822 14.6673L10.1929 17.6026L12.8836 16.1961C13.0366 16.1043 13.1895 16.0738 13.3729 16.0738C13.5258 16.0738 13.7092 16.1043 13.8622 16.1961L16.4916 17.5719L15.9719 14.5755C15.9107 14.2392 16.0331 13.9029 16.2777 13.6582L18.418 11.5792L15.4216 11.1512C15.0853 11.0899 14.7794 10.876 14.6267 10.5702L13.3121 7.91015L11.9667 10.6314C11.8138 10.9372 11.5081 11.1511 11.1718 11.2124L8.23642 11.6404L10.3764 13.75Z'
        fill='#43927C'
      />
    </svg>
  )
}

export default PortfolioSvg
