import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import Spinner from '../Spinner/Spinner'

const Table = ({ head, body, onScroll = null, emptyMessage = '', colSpan = 0, loading }) => {
  return (
    <table className={styles.table}>
      <thead>{head}</thead>

      <tbody className={cn({ [styles.body]: onScroll })} onScroll={onScroll}>
        {Boolean(!body?.length && emptyMessage && !loading) && (
          <tr className={styles.emptyMessage}>
            <td colSpan={colSpan}>{emptyMessage}</td>
          </tr>
        )}

        {body?.length !== 0 && body}

        {loading && (
          <tr className={styles.scrollPreloader}>
            <td>
              <Spinner type="magnifier" size={body.length === 0 && loading ? 80 : 60} withoutPosition />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
