import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import EducationItem from './EducationItem/EducationItem'
import styles from '../UserCard.module.scss'
import { setEducationInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'
import { ReactComponent as NoInfoImage } from '../../../assets/images/education_stub.svg'

const Education = ({ userCardData }) => {
  const dispatch = useDispatch()
  const { education } = userCardData

  useEffect(() => {
    return () => {
      dispatch(setEducationInfo([]))
    }
  }, [dispatch])

  if (isEmpty(education)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={320} height={290} />
        <p>You don’t have any educations yet</p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px'>
        {education?.map((education, index) => {
          return (
            <EducationItem
              key={index}
              title={education.collegeName}
              items={education.values}
              period={education.period}
              files={education.files}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default Education
