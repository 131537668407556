import React from 'react'
import { useSelector } from 'react-redux'
import './KeyBoradDatePicker.scss'
import { CalendarIcon } from '../Icons/Icons'
import cn from 'classnames'
import { CustomDatePicker, CustomHeaderContainer, CustomPickersDay, CustomPickersTextField } from './dataPicker.configs'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

const KeyBoardDatePicker = ({
  formik,
  keyBoardLabel,
  value,
  nameOfValue,
  minDate,
  maxDate,
  className,
  errorMessage,
  isInvalid,
  required,
  viewFormat = 'dense',
  ...rest
}) => {
  const userFormat = useSelector(({ user }) => user.settings.date_format)

  const handleChangeData = (date) => {
    const value = date || null
    formik.setFieldTouched(nameOfValue, true, true)
    formik.setFieldValue(nameOfValue, value)
  }
  return (
    <div className={cn('keyBoardDatePicker', className)}>
      {keyBoardLabel && (
        <p
          className={cn('keyBoardDatePickerLabel', {
            'keyBoardDatePickerLabel-disabled': rest?.disabled,
          })}
        >
          {keyBoardLabel} {required ? <span>*</span> : ''}
        </p>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en'>
        <CustomDatePicker
          value={moment(value) || null}
          onChange={handleChangeData}
          slots={{
            openPickerIcon: CalendarIcon,
            calendarHeader: CustomHeaderContainer,
            day: CustomPickersDay,
            textField: CustomPickersTextField,
          }}
          className={className}
          slotProps={{
            textField: {
              variant: 'outlined',
              error: isInvalid,
              helperText: errorMessage,
            },
            actionBar: {
              actions: rest.today ? ['accept', 'today', 'clear', 'cancel'] : ['accept', 'clear', 'cancel'],
            },
          }}
          format={userFormat}
          inputFormat={userFormat}
          formatDensity={viewFormat}
          minDate={minDate}
          maxDate={maxDate}
          {...rest}
        />
      </LocalizationProvider>
    </div>
  )
}

export default KeyBoardDatePicker
