import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getSeafarerCertificate, getAuthProfileFilters, getGooglePlacesCities } from '../../../../api/getApi'
import { postDocumentsFile, postSeafarerCertificate } from '../../../../api/postApi'
import { putSeafarerCertificate } from '../../../../api/putApi'
import { deleteSeafarerFile } from '../../../../api/deleteApi'
import { documentSubmitHelper, getDocumentHelper, isFile, isValidFile } from '../../../../helpers/documents.helper'
import { getMinDate, today } from '../../../../helpers/timeHelper'
import { notify } from '../../../../helpers/notify'
import {
  addAuthDocument,
  editAuthDocument,
  editDocument,
  setDocument,
} from '../../../../redux/actions/documents.action'
import { setUserInfo } from '../../../../redux/actions/user.action'
import { confirm } from '../../../../UI/Confirm/Confirm'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import Button from '../../../../UI/Button/Button'
import Input from '../../../../UI/Input/Input'
import KeyBoardDatePicker from '../../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import FileUploader from '../../../FileUploader/FileUploader'
import { ReactComponent as AttachIcon } from '../../../../assets/icons/attach.svg'
import { initialValues, getValidationSchema } from './config'
import { isCompletelyFilled } from '../../../../helpers/objectsHelper'
import Spinner from '../../../../UI/Spinner/Spinner'

const extensions = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png', '.xlsx', '.xls']

const CertificateForm = ({
  currentDocumentId,
  setCurrentDocumentId,
  documentFiles,
  setDocumentFiles,
  fillFields,
  setFillFields,
  authDocuments,
}) => {
  const dispatch = useDispatch()
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [fetchedFilesLength, setFetchedFilesLength] = React.useState(0)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentFilesForDeleting, setDocumentFilesForDeleting] = React.useState([])
  const [documentData, setDocumentData] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)

  const [filters, setFilters] = React.useState({
    countries: [],
    certificateTypes: [],
  })
  const [isUpload, setIsUpload] = useState(false)

  const formik = useFormik({
    initialValues: currentDocumentId && documentData ? documentData : initialValues,
    validationSchema: getValidationSchema(dateFormat),
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (document) => {
      setIsLoading(true)

      if (currentDocumentId) {
        editCertificate(documentSubmitHelper(document, 'certificate'))
      } else {
        postCertificate(documentSubmitHelper(document, 'certificate'))
      }
    },
  })

  const { values, touched, errors, dirty } = formik

  const resetForm = () => {
    formik.resetForm()
    setDocumentFiles([])
  }

  React.useEffect(() => {
    const completelyFilled = isCompletelyFilled(values, ['type', 'country', 'date_of_issue'])

    setIsButtonEnable(completelyFilled && dirty)
    // eslint-disable-next-line
  }, [values, dirty])

  React.useEffect(() => {
    const isNewDocumentFilesObjects = documentFiles.every((obj) => 'id' in obj)
    const isFilesLengthChanged = fetchedFilesLength !== documentFiles.length

    setIsButtonEnable(!isNewDocumentFilesObjects || dirty || isFilesLengthChanged)

    // eslint-disable-next-line
  }, [documentFiles, dirty])

  React.useEffect(() => {
    getAuthProfileFilters('country', 'seafarer_certificates').then((filters) => {
      setFilters({
        countries: filters.country,
        certificateTypes: filters.seafarer_certificates,
      })
    })
  }, [])

  React.useEffect(() => {
    if (currentDocumentId) {
      setIsLoading(true)

      getSeafarerCertificate(currentDocumentId).then((document) => {
        setFillFields(true)

        setDocumentData(getDocumentHelper(document, 'certificate'))

        formik.resetForm()

        if (document.files.length) {
          setFetchedFilesLength(document.files.length)
          setDocumentFiles(
            document.files.map((file) => {
              return {
                ...file,
                name: file.file_name,
              }
            })
          )
        }
        setIsLoading(false)
      })
    } else {
      formik.resetForm()
      setDocumentFiles([])
      setDocumentFilesForDeleting([])
      setFillFields(false)
    }
    // eslint-disable-next-line
  }, [currentDocumentId])

  const changeFiles = async (documentId, action, type = 'post') => {
    setIsUpload(true)

    const promises = []

    documentFiles.forEach((file) => {
      if (isFile(file)) {
        promises.push(postDocumentsFile(file, documentId, 'certificate'))
      }
    })

    documentFilesForDeleting.forEach((file) => {
      promises.push(deleteSeafarerFile(file))
    })

    try {
      const response = await Promise.all(promises)
      const passport = await getSeafarerCertificate(documentId)

      if (type === 'post') dispatch(action(passport))

      if (type === 'put') dispatch(action(passport.id, passport))

      if (response.some(({ errors }) => errors)) {
        response.forEach(({ errors }) => errors && notify.errorsList(errors))
      }
      // else {
      //   const actionText = (() => {
      //     if (type === 'post') return 'added'
      //
      //     if (type === 'put') return 'updated'
      //
      //     return ''
      //   })()
      //
      //   notify('Success', `Your document was successfully ${actionText}`)
      // }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsUpload(false)
    }
  }

  const postCertificate = async (document) => {
    try {
      const response = await postSeafarerCertificate(document)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length) {
        await changeFiles(response.id, addAuthDocument)
      }

      if (!documentFiles.length) {
        dispatch(setDocument(getSeafarerCertificate, response.id))
      }

      if (!authDocuments.length) {
        dispatch(setUserInfo())
      }

      resetForm()
      notify('Success', `Your document was successfully added`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const editCertificate = async (document) => {
    try {
      const response = await putSeafarerCertificate(document, currentDocumentId)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length || documentFilesForDeleting.length) {
        await changeFiles(currentDocumentId, editAuthDocument, 'put')
      }

      if (!documentFiles.length || !documentFilesForDeleting.length) {
        dispatch(editDocument(getSeafarerCertificate, currentDocumentId))
      }

      resetForm()

      setCurrentDocumentId(null)
      setDocumentFilesForDeleting([])
      notify('Success', `Your document was successfully updated`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputFileChange = ({ target }) => {
    const files = target.files

    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (documentFiles.length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          setDocumentFiles((prevState) => [...prevState, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      if (currentDocumentId) {
        if (deleteType === 'byId') {
          setDocumentFilesForDeleting((prevState) => [...prevState, id])
          setDocumentFiles((prevState) => prevState.filter((file) => file.id !== id))
        } else {
          setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
        }
      } else {
        setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
      }
    })
  }

  const getPlaces = (name, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      getGooglePlacesCities(target.value, values.country.iso2.toLowerCase()).then((cities) => {
        setAutocompleteItems(cities)
      })
    }
  }

  const handleChangeValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const handleChangeSelectValue = (name, value) => {
    if (name === 'country' && value.id !== values.country.id) {
      formik.setValues({
        ...values,
        city: { name: '', id: null },
      })
    }
    if (name === 'type') {
      handleChangeValue({
        target: {
          value: {
            name: value.name,
            id: value.id,
          },
          name: name,
        },
      })
    } else {
      handleChangeValue({ target: { value, name } })
    }
  }

  const handleChangeAutocompleteValue = (name, { target }) => {
    handleChangeValue({
      target: { value: { name: target.value, id: null }, name },
    })

    getPlaces(name, target)
  }

  const handleClickAutocomplete = (name, value) => {
    handleChangeValue({ target: { value, name } })

    setAutocompleteItems([])
  }

  const handleFocusAutoComplete = (name, { target }) => {
    getPlaces(name, target)
  }

  const isRequest = isLoading || isUpload
  const isDisabled = isLoading || !fillFields || isUpload

  return (
    <form className={cn(styles.form, { [styles.form__disabled]: isDisabled })} onSubmit={formik.handleSubmit}>
      {isRequest && (
        <div className={styles.loadingWrapper}>
          <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
        </div>
      )}

      <div className={styles.column}>
        <div>
          <SelectSearch
            label='Certificate'
            name='type'
            id={values.type.id}
            items={filters.certificateTypes}
            selectedItem={values.type.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFields}
            isInvalid={!!(errors.type?.id && touched.type)}
            required
          />

          {errors.type?.id && touched.type && <p className={styles.formItemError}>{errors.type?.id}</p>}
        </div>

        <div>
          <SelectSearch
            label='Country'
            name='country'
            id={values.country.id}
            items={filters.countries}
            selectedItem={values.country.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFields}
            isInvalid={!!(errors.country?.id && touched.country)}
            required
          />

          {errors.country?.id && touched.country && <p className={styles.formItemError}>{errors.country?.id}</p>}
        </div>

        <div>
          <Autocomplete
            autoComplete={'new-password'}
            label='City'
            name='city'
            placeholder='City'
            items={autocompleteItems}
            value={values.city.name}
            onChange={handleChangeAutocompleteValue}
            onClick={handleClickAutocomplete}
            onFocus={handleFocusAutoComplete}
            disabled={!values.country.id || !fillFields}
            isInvalid={Boolean(errors.city?.id && touched.city)}
            errorMessage={errors.city?.id}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div>
          <Input
            label='Number'
            name='number'
            value={values.number}
            onChange={handleChangeValue}
            disabled={!fillFields}
          />
        </div>
        <div className={styles.row}>
          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_issue}
            nameOfValue='date_of_issue'
            keyBoardLabel='Date of issue'
            minDate={getMinDate}
            maxDate={today}
            isInvalid={!!(errors.date_of_issue || (errors.date_of_issue && touched.date_of_issue))}
            errorMessage={errors.date_of_issue}
            disabled={!fillFields}
            required
            today={true}
          />
          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_expire}
            nameOfValue='date_of_expire'
            keyBoardLabel='Date of expire'
            minDate={values.date_of_issue || today}
            isInvalid={!!(errors.date_of_expire || (errors.date_of_expire && touched.date_of_expire))}
            errorMessage={errors.date_of_expire}
            disabled={!fillFields}
            today={true}
          />
        </div>

        <FileUploader
          name='certificate'
          text='Add scan'
          theme='attachFile'
          files={documentFiles}
          onChange={handleInputFileChange}
          onDelete={handleInputFileDelete}
          icon={<AttachIcon />}
          isUpload={isUpload}
        />
      </div>

      <Button className={styles.buttonSave} type='submit' size='middle-full' disabled={!isButtonEnable}>
        Save
      </Button>
    </form>
  )
}

export default CertificateForm
