import React from 'react'
import styles from './styles.module.scss'
import ManagerPhoto from './assets/icons/manager-icon.svg'
import { ReactComponent as HandIcon } from './assets/icons/waving-hand.svg'
import cn from 'classnames'
import { RatingStarSvg } from '../../../UI/Icons/RatingStarSvg'
import { MailSvg } from '../../../UI/Icons/MailSvg'

const SupportHeader = ({ isChatStarted, name, avatar, isOnline }) => {
  return isChatStarted ? (
    <div className={styles.header}>
      <div className={cn(styles.operator_photo, { [styles.online]: isOnline })}>
        <img src={avatar || ManagerPhoto} alt='manager avatar' />
      </div>

      <div>
        <div className={styles.position}>Your chat with support manager</div>
        <div className={styles.supportName}>{name}</div>
        <div className={styles.info}>
          <RatingStarSvg width='23' height='23' fill='#43927C' />

          <span className={styles.vertical_line} />

          <a href='mailto:help@nextship.net'>
            <MailSvg width='24' height='16' />
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.headerStarted}>
      <div className={styles.title}>
        Hi there! <HandIcon />
      </div>
      <p>
        Ask us anything, or share your feedback <br /> We are always happy to chat :)
      </p>
    </div>
  )
}

export default SupportHeader
