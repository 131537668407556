export const clearJobSearchPageSize = () => {
  return {
    type: 'CLEAR_JOB_SEARCH_PAGE_SIZE',
  }
}

export const changeJobSearchRank = (obj) => {
  return {
    type: 'CHANGE_JOBSEARCH_RANK',
    payload: obj,
  }
}

export const changeJobSearchVessel = (obj) => {
  return {
    type: 'CHANGE_JOBSEARCH_VESSEL',
    payload: obj,
  }
}

export const changeJobSearchDate = (date) => {
  return {
    type: 'CHANGE_JOBSEARCH_DATE',
    payload: date,
  }
}

export const changeJobSearchSort = (name, sortState) => {
  return {
    type: 'CHANGE_JOBSEARCH_SORT',
    payload: {
      name,
      sortState,
    },
  }
}
