import React from 'react'
import ModalAlert from '../../components/ModalAlert/ModalAlert'
import WithPreloader from '../../HOC/WithPreloader'

const SeafarerNotPermission = () => {
  return (
    <div className='page' style={{ height: '100%' }}>
      <main>
        <WithPreloader
          component={ModalAlert}
          isActive={false}
          size={120}
          type='magnifier'
          isOk={false}
          title={'Oops..'}
          scope={'seafarer'}
          content={"You are haven't permissions"}
        />
      </main>
    </div>
  )
}

export default SeafarerNotPermission
