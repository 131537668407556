import React from 'react'

import styles from '../../styles.module.scss'

const PersonalStatusNotActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>
        <b>Personal Status</b> service is not activated.
      </p>
      <p>
        Share a unique message with crewing managers, providing them with valuable insights about yourself to stand out
        among candidates and increase your chances of getting noticed.
      </p>
    </div>
  )
}

export default PersonalStatusNotActivated
