import React from 'react'
import styles from '../../UserCard.module.scss'

const VideosResumeItem = ({ videoUrl }) => {
  return (
    <video controls className={styles.videoResume}>
      {videoUrl.length ? (
        videoUrl.map(({ id, file, file_name }) => (
          <source key={id} src={file} type={`video/${file_name.split('.')[1]}`} />
        ))
      ) : (
        <source key={videoUrl.id} src={videoUrl.file} type={`video/${videoUrl.file_name.split('.')[1]}`} />
      )}
      Your browser does not support the video tag.
    </video>
  )
}

export default VideosResumeItem
