const initialState = {
  pagination: {
    currentSize: 10,
    page: 1,
  },
  filter: {
    rank: {
      name: 'All',
      id: '',
    },
    vesselType: {
      name: 'All',
      id: '',
    },
    joinDate: new Date(),
  },
  sort: {
    duration: 0,
    join_date: 2,
    salary: 0,
  },
}

const filters = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_JOBSEARCH_RANK':
      return {
        ...state,
        filter: {
          ...state.filter,
          rank: {
            name: action.payload.name,
            id: action.payload.id,
          },
        },
      }
    case 'CHANGE_JOBSEARCH_VESSEL':
      return {
        ...state,
        filter: {
          ...state.filter,
          vesselType: {
            name: action.payload.name,
            id: action.payload.id,
          },
        },
      }
    case 'CHANGE_JOBSEARCH_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          joinDate: action.payload,
        },
      }

    case 'CHANGE_JOBSEARCH_SORT':
      return {
        ...state,
        sort: {
          ...initialState.sort,
          join_date: action.payload.name !== 'join_date' && 0,
          [action.payload.name]: action.payload.sortState,
        },
      }
    case 'CLEAR_JOB_SEARCH_PAGE_SIZE':
      return {
        ...state,
        pagination: {
          page: 1,
          currentSize: 10,
        },
      }
    default:
      return state
  }
}

export default filters
