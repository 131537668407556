import React, { useEffect, useRef, useState } from 'react'
import styles from './imageComponent.module.scss'
import { CSSTransition } from 'react-transition-group'
import imagePreviewError from '../../assets/images/image-prewiev-error.svg'

const ImageComponent = ({ src, alt, className = '', preloader, ...props }) => {
  const classes = `${styles.wrapper} ${className}`
  const nodeRef = useRef(null)
  const [loadedSrc, setLoadedSrc] = useState(null)

  useEffect(() => {
    if (src) {
      const handleLoad = (type = '') => {
        setLoadedSrc(type === 'error' ? imagePreviewError : src)
      }

      const image = new Image()
      image.addEventListener('load', ({ type }) => handleLoad(type))
      image.addEventListener('error', ({ type }) => handleLoad(type))
      image.src = src

      return () => {
        image.removeEventListener('load', ({ type }) => handleLoad(type))
        image.removeEventListener('error', ({ type }) => handleLoad(type))
      }
    }
  }, [src])

  return (
    <div className={classes} {...props}>
      <>
        <CSSTransition
          nodeRef={nodeRef}
          key={loadedSrc === src ? 'image' : 'preloader'}
          classNames='fadein'
          timeout={100}
        >
          {loadedSrc === src || loadedSrc === imagePreviewError ? (
            <img ref={nodeRef} src={loadedSrc} alt={alt} className={styles.image} {...props} />
          ) : (
            preloader || <div ref={nodeRef} className={styles.preloader} />
          )}
        </CSSTransition>
      </>
    </div>
  )
}

export default ImageComponent
