import React from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'
import { operationType } from '../../constants/constans'
import useClickOutside from '../../hooks/useClickOutside'
import replyIcon from '../../assets/icons/reply.svg'

const CompanionControls = ({ id, onReply, setIsOpenMenu, isOpenMenu }) => {
  const ref = useClickOutside(() => setIsOpenMenu(null))

  return (
    <div ref={ref} className={cn(styles.controls, { [styles.open_controls]: isOpenMenu })}>
      <button onClick={() => onReply(id)}>
        <img src={replyIcon} alt='reply message' />

        <span>{operationType.reply}</span>
      </button>
    </div>
  )
}

export default CompanionControls
