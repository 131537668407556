import { ReactComponent as viberSvg } from '../../../assets/icons/viber.svg'
import { ReactComponent as telegram } from '../../../assets/icons/telegram.svg'
import { ReactComponent as whatsAppSvg } from '../../../assets/icons/whatsapp.svg'

export const placeholderTexts = {
  instagram: 'Please enter instagram link',
  email: 'Please enter email',
  skype: 'Please enter skype LIVE link',
  facebook: 'Please enter facebook link',
  linkedin: 'Please enter linkedin link',
  phone: 'Please enter phone',
}

export const messengersIcons = [
  { name: 'viber', icon: viberSvg },
  { name: 'telegram', icon: telegram },
  { name: 'whatsapp', icon: whatsAppSvg },
]

export const submitHelper = (values, messengers) => {
  const result = {
    type: values.type.id,
    contact: values.contact,
    is_main: !values.id ? false : values.is_main,
  }
  if (values.type.name === 'phone') {
    result.messengers = messengers
  } else {
    result.messengers = []
  }
  return result
}

export const contactsDataPrettier = (arr) => {
  if (arr.length === 0 || !arr) return []
  return arr.map((item) => {
    return {
      id: item.id,
      header: [item.type.name],
      files: null,
      is_main: item.is_main,
      contact: item.contact,
      messengers: item.messengers,
      // content: (
      //   <div className="itemsGroupContentRow">
      //     <div className="itemsGroupContent">
      //       <b>{item.contact}</b>
      //     </div>
      //
      //     {item.messengers && (
      //       <div className="itemsGroupContent itemsGroupContentSocials">
      //         {item.messengers.map((item) => {
      //           return messengersIcons.map(
      //             ({ name, icon: Icon }) =>
      //               item === name && <Icon key={name} />
      //           );
      //         })}
      //       </div>
      //     )}
      //   </div>
      // ),
    }
  })
  // .sort((a, b) => a.header[0].localeCompare(b.header[0]));
}
