import * as Yup from 'yup'
import { getMinDate, today } from '../../../../helpers/timeHelper'
import moment from 'moment'

export const initialValues = {
  city: { name: '', id: null },
  country: { name: '', id: null, iso2: '' },
  college: { name: '', id: null },
  degree: { name: '', id: null },
  date_from: null,
  date_to: null,
  field_of_study: '',
  description: '',
}

export const getValidationSchema = () =>
  Yup.object().shape({
    degree: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    date_from: Yup.date()
      .min(getMinDate, `Min date is ${moment(getMinDate).format('YYYY')}`)
      .max(today, `Max date is ${today.format('YYYY')}`)
      .nullable(),
    date_to: Yup.date()
      .min(Yup.ref('date_from'), 'Year of end of study must be later than year of start of study')
      .nullable(),
    country: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    city: Yup.object().shape({
      id: Yup.mixed()
        .required('Field is required')
        .test('Name check', 'Select city from list', function (value) {
          const { name } = this.parent
          if (name) {
            return !!value
          } else return true
        }),
    }),
    college: Yup.object().shape({
      id: Yup.mixed()
        .required('Field is required')
        .test('Name check', 'Select college from list', function (value) {
          const { name } = this.parent
          if (name) {
            return !!value
          } else return true
        }),
    }),
    field_of_study: Yup.string().max(100, 'Too long!'),
  })
