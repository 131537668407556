export const regExp = {
  //eslint-disable-next-line
  phone: `[0-9|\\ |\\-|\\(|\\)|\\+]{10,17}[0-9|\\ |\\-|\\(|\\)]`,
  //eslint-disable-next-line
  link: `(?:https?:\\/\\/)?(?:[\\w\\.]+)\\.(?:[a-z]{2,6}\\.?)(?:\\/[\\w\\.]*)*\\/?`,
  //eslint-disable-next-line
  email: `[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b`,
  //eslint-disable-next-line
  regExpLinkInString: /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
  //eslint-disable-next-line
  regExpPhoneInString: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
};