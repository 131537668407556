import React from 'react'
import styles from './CustomJoyride.module.scss'
import Button from '../../UI/Button/Button'
import { ReactComponent as AlarmZing } from '../../assets/icons/alarm-zing.svg'

const CustomJoyride = ({ continuous, index, step, backProps, skipProps, primaryProps, tooltipProps, size }) => {
  return (
    <div className={styles.tooltip} {...tooltipProps}>
      <div className={styles.content_body}>
        <AlarmZing />
        <div>
          <h2 className={styles.heading}>
            Guide by web application ({index + 1}/{size})
          </h2>
          <p className={styles.content}>{step.content}</p>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.skip}>
          <Button theme='text' size='normal' className={styles.next} {...skipProps} id='next'>
            {skipProps.title}
          </Button>
        </div>
        {index > 0 && (
          <Button theme='bordered' size='normal' className={styles.prev} {...backProps} id='back'>
            {backProps.title}
          </Button>
        )}
        {continuous && (
          <Button size='normal' className={styles.next} {...primaryProps} id='next'>
            {primaryProps.title}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CustomJoyride
