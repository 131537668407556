import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg'
import { ReactComponent as UnuccessIcon } from './assets/icons/unsuccess.svg'
import { ReactComponent as GreenGear } from './assets/icons/green-gear.svg'
import { ReactComponent as LightgreenGear } from './assets/icons/lightgreen-gear.svg'
import { ReactComponent as LightgreenGearCircle } from './assets/icons/lightgreen-gear-circle.svg'
import { ReactComponent as YellowGear } from './assets/icons/yellow-gear.svg'
import { ReactComponent as TransparentGear } from './assets/icons/transparent-gear.svg'

import { steps } from '../../Services/constants'

import Button from '../../../../UI/Button/Button'
import Wrapper from '../Wrapper/Wrapper'

const PopupNotify = ({ step, message, onClose }) => {
  const notifyMessage = (() => {
    let result = ''

    const getNotifyMessage = (messages) => {
      if (Array.isArray(messages) && messages.length !== 0) {
        messages.forEach((error) => {
          if (error.message) {
            getNotifyMessage(error.message)

            return
          }

          result = error
        })
      } else {
        result = messages
      }
    }

    getNotifyMessage(message)

    return result
  })()

  return (
    <Wrapper>
      {step === steps.process && (
        <>
          <div className={styles.canvasForGears}>
            <GreenGear className={styles.greenGear} />
            <YellowGear className={styles.yellowGear} />
            <LightgreenGear className={styles.lightgreenGear} />
            <LightgreenGearCircle className={styles.lightgreenGearMini} />
            <LightgreenGearCircle className={styles.lightgreenGearMini2} />
            <LightgreenGearCircle className={styles.lightgreenGearMini3} />
            <LightgreenGearCircle className={styles.lightgreenGearMini4} />
            <LightgreenGearCircle className={styles.lightgreenGearMini5} />
            <TransparentGear className={styles.transparentBigGear} />
            <TransparentGear className={styles.transparentBigGear2} />
            <TransparentGear className={styles.transparentMiniGear} />
          </div>

          <p className={styles.message}>{notifyMessage}</p>
        </>
      )}

      {step === steps.success && (
        <>
          <SuccessIcon />

          <p className={styles.message}>{notifyMessage}</p>

          <Button type='button' className={styles.button} onClick={onClose}>
            Ok
          </Button>
        </>
      )}

      {step === steps.unsuccess && (
        <>
          <UnuccessIcon />

          <p className={styles.message}>{notifyMessage}</p>

          <Button type='button' className={styles.button} onClick={onClose}>
            Ok
          </Button>
        </>
      )}
    </Wrapper>
  )
}

export default PopupNotify
