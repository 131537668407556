import React from 'react'
import styles from './settingsPage.module.scss'
import { useSelector } from 'react-redux'
import ChangePassword from '../../components/Settings/ChangePassword/ChangePassword'
import CreatePassword from '../../components/Settings/CreatePassword/CreatePassword'
import ChangeEmail from '../../components/Settings/ChangeEmail/ChangeEmail'

const SettingsSecurity = () => {
  const has_password = useSelector(({ user }) => user.settings.has_password)

  return (
    <div className={styles.screen}>
      <div className={styles.wrapper}>
        <div className={styles.editBlockWrapper}>
          <h2 className={styles.title}>{has_password ? 'Change password' : 'Create password'}</h2>
          {has_password ? <ChangePassword /> : <CreatePassword />}
        </div>

        {has_password && (
          <div className={styles.editBlockWrapper}>
            <h2 className={styles.title}>Change email</h2>
            <ChangeEmail />
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsSecurity
