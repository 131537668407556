import React from 'react'
import styles from './deleteAccount.module.scss'
import { deleteAccount } from '../../../api/postApi'
import { notify } from '../../../helpers/notify'
import { confirm } from '../../../UI/Confirm/Confirm'

const DeleteAccount = () => {
  const handleSubmit = () => {
    confirm('Are you sure?', 'Do you want to delete your account?', () => {
      deleteAccount('nothing')
        .then(() => notify('Success', 'A confirmation letter has been sent to your email!', { autoClose: false }))
        .catch((error) => notify.errorsList(error.errors))
    })
  }

  return (
    <div className={styles.wrapper}>
      <button className={styles.delete} onClick={handleSubmit}>
        Delete account
      </button>
    </div>
  )
}

export default DeleteAccount
