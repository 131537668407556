import React from 'react'

import NotificationList from '../tabs/notification/NotificationList'
// import NotificationHistory from './NotificationHistory'
import TabNav from '../components/TabNav/TabNav'
import { profilePaths } from '../constants/constans'

const EventsAlertsPage = () => {
  const links = [
    {
      url: profilePaths.list,
      title: 'Notifications',
      component: NotificationList,
    },
    // {
    //   url: '/profile/notification/history',
    //   title: 'History',
    //   component: NotificationHistory,
    // },
  ]

  return <TabNav title='Events&Alerts' links={links} redirectLink={profilePaths.list} />
}

export default EventsAlertsPage
