import { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useNotify } from './useNotify'
import { getVacancy } from '../api/getApi'
import { vacancySearchHelper } from '../helpers/vacanciesSearchHelper'
import { profilePaths } from '../constants/constans'

const useViewVacancy = (updateAction) => {
  const { isAuth } = useSelector(({ authentication }) => authentication)

  const { notify } = useNotify()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const params = new URLSearchParams(window.location.search)

  const vacancyId = params.get('id')

  useEffect(() => {
    const fetchVacancy = async () => {
      if (!vacancyId) return

      const clearUrl = location.pathname.slice(0, -1)

      if (isAuth && clearUrl !== profilePaths.vacancies) {
        history.push(`${profilePaths.vacancies}/?id=${vacancyId}`)
      }

      try {
        const vacancy = await getVacancy(vacancyId)

        if (vacancy.error) throw new Error(vacancy.error)

        dispatch(updateAction(vacancySearchHelper(vacancy)))
      } catch (error) {
        notify.error('Error', error.message)
      }
    }

    fetchVacancy()

    // eslint-disable-next-line
  }, [isAuth, vacancyId])

  return { vacancyId }
}

export default useViewVacancy
