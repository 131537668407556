import React from 'react'

import PropTypes from 'prop-types'

import MTButton from '../MTButton/MTButton'

import { getMtVessels } from '../../../../api/getApi'
import { notify } from '../../../../helpers/notify'

const MTButtonWithSearch = ({ value, onError, onSuccess, Icon }) => {
  const runNextStep = async () => {
    try {
      const response = await getMtVessels(value)

      if (response.results?.length) {
        onSuccess(response)
      } else {
        onError('NOT_FOUND')
      }
    } catch (error) {
      notify.info('Info', 'The request isn’t available, waiting time 2 minutes.')

      console.error(error)
    }
  }

  return (
    <MTButton onClick={runNextStep} Icon={Icon}>
      Search in
    </MTButton>
  )
}

MTButtonWithSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  Icon: PropTypes.element,
}

export default MTButtonWithSearch
