import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-accent-color.svg'
import playMarketPng from '../../../../assets/images/play-market.svg'
import appStorePng from '../../../../assets/images/app-store.svg'
import { useSelector } from 'react-redux'
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg'
import { ReactComponent as SignUpIcon } from '../../../../assets/icons/sign_up.svg'
const PopUp = ({ textRender, onClose }) => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.iconWrapper}>
          <button onClick={onClose} className={styles.iconWrapper_button}>
            <CloseIcon stroke='#07251e' />
          </button>
        </div>
        <div className={styles.info}>
          <div>{isAuth ? <SuccessIcon /> : <SignUpIcon />}</div>
          {textRender()}
          <div className={styles.links}>
            <a
              className={styles.linkApp}
              href='https://play.google.com/store/apps/details?id=com.nextship.client'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img src={playMarketPng} alt='play market' className={styles.play_market_svg} />
            </a>
            <a
              className={styles.linkApp}
              href='https://apps.apple.com/ua/app/nextship/id1554236157'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img src={appStorePng} alt='app store' className={styles.app_store_svg} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopUp
