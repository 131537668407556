import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './switcher.module.scss'
// import { ReactComponent as UkraineFlagIcon } from '../../assets/icons/ukraineFlag.svg'

const Switcher = ({ isActive = false, onClick, id, theme, className }) => {
  const handleClick = () => onClick(id, isActive)
  return (
    <form>
      <label
        htmlFor='switcher'
        onClick={handleClick}
        className={cn(styles.switcher, className, {
          [styles[theme]]: theme,
        })}
      >
        <input readOnly type='checkbox' checked={isActive} id={id} />
        <span className={cn(styles.slider, styles.round)}></span>
      </label>
    </form>
  )
}
export default Switcher

Switcher.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.string,
  className: PropTypes.string,
}
