import { useDispatch } from 'react-redux'
import { setAdmin, setToken } from '../redux/actions/user.action'
import { useEffect } from 'react'

const useAdminQuery = () => {
  const getExpires_date = (date) => {
    if (isNaN(date)) return
    return new Date().getTime() + date * 1000
  }
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('access_token')
  const expires_in = params.get('expires_in')
  const refresh_token = params.get('refresh_token')

  useEffect(() => {
    if (token && expires_in && refresh_token) {
      dispatch(
        setToken({
          access_token: token,
          expires_date: getExpires_date(expires_in),
          expires_in: expires_in,
          ok: true,
          refresh_token: refresh_token,
          scope: 'seafarer',
          token_type: 'Bearer',
          isAdmin: true,
        })
      )
      dispatch(setAdmin({ token }))
    }
  }, [token, expires_in, refresh_token, dispatch])
}
export default useAdminQuery
