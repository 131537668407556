export const CHAT = {
  SET_IS_DIALOG_CHECKED: 'SET_DIALOG_CHECKED',
  DELETE_SELECTED: 'DELETE_SELECTED',
  SET_ALL_SELECTED: 'SET_ALL_SELECTED',
  SET_ALL_UNSELECTED: 'SET_ALL_UNSELECTED',
  SET_DIALOGS: 'SET_DIALOGS',
  SET_MESSAGES: 'SET_MESSAGES',
  NEW_MESSAGE: 'NEW_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  EDIT_MESSAGE_START: 'EDIT_MESSAGE_START',
  REPLY_MESSAGE_START: 'REPLY_MESSAGE_START',
  CHANGE_MESSAGE_VALUE: 'CHANGE_MESSAGE_VALUE',
  ACTION_MESSAGE_CLOSE: 'ACTION_MESSAGE_CLOSE',
  UPDATE_MESSAGE: 'CHAT_UPDATE_MESSAGE',
  SET_GLOBAL_UNDELIVERED: 'CHAT_SET_GLOBAL_UNDELIVERED',
  RESTORE_ROOM: 'CHAT_RESTORE_ROOM',
  CLEAR_ROOM_TOTAL_UNDELIVERED: 'CHAT_CLEAR_ROOM_TOTAL_UNDELIVERED',
  LAST_MESSAGE_WAS_UPDATED: 'CHAT_LAST_MESSAGE_WAS_UPDATED',
  START_PAGINATION_LOADING: 'CHAT_START_PAGINATION_LOADING',
  SET_NEXT_PAGE_MESSAGES: 'CHAT_SET_NEXT_PAGE_MESSAGES',
  CREATE_NEW_CHAT_ROOM: 'CHAT_CREATE_NEW_CHAT_ROOM',
  SET_EDIT_MESSAGE_ERROR: 'SET_EDIT_MESSAGE_ERROR',
  SET_DELETE_MESSAGE_ERROR: 'SET_EDIT_MESSAGE_ERROR',
  SEARCH_CHAT_MESSAGE: 'SEARCH_CHAT_REPLY_MESSAGE',
  DELETE_ROOM: 'DELETE_ROOM_FROM_LIST',
}

export const SUPPORT = {
  GET_MESSAGES: 'SUPPORT_GET_MESSAGES',
  NEW_MESSAGE: 'SUPPORT_NEW_MESSAGE',
  SET_LOADING: 'SUPPORT_SET_LOADING',
  SET_UNDELIVERED: 'SUPPORT_SET_UNDELIVERED',
  RESET_TOTAL_UNDELIVERED: 'RESET_TOTAL_UNDELIVERED',
  SET_IS_OPENED: 'SET_IS_OPENED',
  SET_MESSAGE_TEXT: 'SET_MESSAGE_TEXT',
  SET_STATUS: 'SET_STATUS',
  SET_NEXT_PAGE_SUPPORT_MESSAGES: 'SET_NEXT_PAGE_SUPPORT_MESSAGES',
  DELETE_SUPPORT_MESSAGE: 'DELETE_SUPPORT_MESSAGE',
  REPLY_SUPPORT_MESSAGE: 'REPLY_SUPPORT_MESSAGE',
  EDIT_SUPPORT_MESSAGE: 'EDIT_SUPPORT_MESSAGE',
  ACTION_SUPPORT_MESSAGE_CLOSE: 'ACTION_SUPPORT_MESSAGE_CLOSE',
  UPDATE_SUPPORT_MESSAGE: 'UPDATE_SUPPORT_MESSAGE',
  SET_SUPPORT_EDIT_MESSAGE_ERROR: 'SET_SUPPORT_EDIT_MESSAGE_ERROR',
  SET_SUPPORT_DELETE_MESSAGE_ERROR: 'SET_SUPPORT_DELETE_MESSAGE_ERROR',
  SEARCH_SUPPORT_MESSAGE: 'SEARCH_SUPPORT_REPLY_MESSAGE',
  SET_ANONYMOUS_UUID: 'SET_ANONYMOUS_UUID',
}

export const DOCUMENTS = {
  SET_PROCESSING_DOCUMENTS: 'SET_PROCESSING_DOCUMENTS',
  CLEAR_PROCESSING_DOCUMENTS: 'CLEAR_PROCESSING_DOCUMENTS',
  SET_AUTH_DOCUMENTS: 'SET_AUTH_DOCUMENTS',
  CLEAR_AUTH_DOCUMENTS: 'CLEAR_AUTH_DOCUMENTS',
  ADD_PROCESSING_DOCUMENT: 'ADD_PROCESSING_DOCUMENT',
  ADD_AUTH_DOCUMENT: 'ADD_AUTH_DOCUMENT',
  DELETE_AUTH_DOCUMENT: 'DELETE_AUTH_DOCUMENT',
  DELETE_PROCESSING_DOCUMENT: 'DELETE_PROCESSING_DOCUMENT',
  EDIT_AUTH_DOCUMENT: 'EDIT_AUTH_DOCUMENT',
  DELETE_FILE: 'DELETE_FILE',
}

export const CONTACTS = {
  SET_AUTH_CONTACTS: 'SET_AUTH_CONTACTS',
  CLEAR_AUTH_CONTACTS: 'CLEAR_AUTH_CONTACTS',
  ADD_AUTH_CONTACT: 'ADD_AUTH_CONTACT',
  DELETE_AUTH_CONTACT: 'DELETE_AUTH_CONTACT',
  EDIT_AUTH_CONTACT: 'EDIT_AUTH_CONTACT',
  SET_MAIN_CONTACT: 'SET_MAIN_CONTACT',
  SET_RELATIVES_CONTACTS: 'SET_RELATIVES_CONTACTS',
  ADD_RELATIVES_CONTACT: 'ADD_RELATIVES_CONTACT',
  DELETE_RELATIVES_CONTACT: 'DELETE_RELATIVES_CONTACT',
  EDIT_RELATIVES_CONTACT: 'EDIT_RELATIVES_CONTACT',
  SET_MAIN_RELATIVES_CONTACT: 'SET_MAIN_RELATIVES_CONTACT',
  CLEAR_RELATIVES_CONTACTS: 'CLEAR_RELATIVES_CONTACTS',
}

export const CONTRACTS = {
  SET_AUTH_CONTRACTS: 'SET_AUTH_CONTRACTS',
  ADD_AUTH_CONTRACT: 'ADD_AUTH_CONTRACT',
  DELETE_AUTH_CONTRACT: 'DELETE_AUTH_CONTRACT',
  EDIT_AUTH_CONTRACT: 'EDIT_AUTH_CONTRACT',
  CLEAR_AUTH_CONTRACTS: 'CLEAR_AUTH_CONTRACTS',
}

export const EDUCATIONS = {
  SET_AUTH_EDUCATIONS: 'SET_AUTH_EDUCATIONS',
  ADD_AUTH_EDUCATION: 'ADD_AUTH_EDUCATION',
  DELETE_AUTH_EDUCATION: 'DELETE_AUTH_EDUCATION',
  EDIT_AUTH_EDUCATION: 'EDIT_AUTH_EDUCATION',
  CLEAR_AUTH_EDUCATIONS: 'CLEAR_AUTH_EDUCATIONS',
}

export const REVIEWS = {
  SET_REVIEWS: 'SET_REVIEWS',
  SET_REVIEW_ANSWERS: 'SET_REVIEW_ANSWERS',
  CLEAR_REVIEW_ANSWERS: 'CLEAR_REVIEW_ANSWERS',
  CLEAR_REVIEWS: 'CLEAR_REVIEWS',
}

export const REQUEST = {
  SET_REQUEST: 'SET_REQUEST',
  SET_REQUEST_QUESTIONS: 'SET_REQUEST_QUESTIONS',
  SET_REQUEST_DESCRIPTION: 'SET_REQUEST_DESCRIPTION',
  CLEAR_REQUEST: 'CLEAR_REQUEST',
  CLEAR_REQUEST_QUESTIONS: 'CLEAR_REQUEST_QUESTIONS',
}

export const VACANCIES = {
  SET_FILTERS: 'SET_FILTERS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  SET_VACANCIES: 'SET_VACANCIES',
  ADD_VACANCY: 'ADD_VACANCY',
  ADD_VACANCIES_LANDING: 'ADD_VACANCIES_LANDING',
  ADD_VACANCY_LANDING: 'ADD_VACANCY_LANDING',
  CLEAR_VACANCIES: 'CLEAR_VACANCIES',
  APPLY_VACANCY: 'APPLY_VACANCY',
  SET_VACANCIES_LANDING: 'SET_VACANCIES_LANDING',
  CLEAR_VACANCIES_LANDING: 'CLEAR_VACANCIES_LANDING',
  SET_APPLIED_JOBS: 'SET_APPLIED_JOBS',
  DELETE_APPLIED_JOB: 'DELETE_APPLIED_JOB',
  SET_JOBS_COUNT: 'SET_JOBS_COUNT',
  CLEAR_APPLIED_JOBS: 'CLEAR_APPLIED_JOBS',
}

export const USER_CARD = {
  SET_GENERAL_INFO: 'SET_GENERAL_INFO',
  SET_CONTACT_INFO: 'CONTACT_INFO',
  SET_EDUCATION: 'SET_EDUCATION',
  SET_EXPERIENCE: 'SET_EXPERIENCE',
  SET_PASSPORTS: 'SET_PASSPORTS',
  SET_VISAS: 'SET_VISAS',
  SET_LICENSES: 'SET_LICENSES',
  SET_CERTIFICATES: 'SET_CERTIFICATES',
  SET_SIZE_INFORMATION: 'SET_SIZE_INFORMATION',
  SET_REVIEWS_INFO: 'SET_REVIEWS_INFO',
  SET_VIDEO_URL: 'SET_VIDEO_URL',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_LOADING_GENERAL_STATE: 'SET_LOADING_GENERAL_STATE',
  CLEAR_USER_CARD_STATE: 'CLEAR_USER_CARD_STATE',
  SET_COLLEAGUES: 'SET_COLLEAGUES',
}

export const LANDING_STATISTICS_INFO = {
  SET_LANDING_STATISTICS: 'SET_LANDING_STATISTICS',
  CLEAR_LANDING_STATISTICS: 'CLEAR_LANDING_STATISTICS',
}
