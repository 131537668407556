import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import ExperienceItem from './ExperienceItem/ExperienceItem'
import styles from '../UserCard.module.scss'
import { useDispatch } from 'react-redux'
import { setExperienceInfo } from '../../../redux/actions/userCard.action'
import { ReactComponent as NoInfoImage } from '../../../assets/images/experience_stub.svg'

const Experience = ({ userCardData, showFullViewImage }) => {
  const dispatch = useDispatch()
  const { experience } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setExperienceInfo([]))
    }
  }, [dispatch])
  if (isEmpty(experience)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={475} height={290} />
        <p>You don't have any work experience yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={1} gutter='16px'>
        {experience.map((experience, index) => (
          <ExperienceItem
            key={index}
            experience_type={experience.experience_type}
            title={experience.vesselName || experience.company}
            period={experience.period}
            files={experience.files}
            items={experience.values}
          />
        ))}
      </Masonry>
    )
  }
}

export default Experience
