import React from 'react'
import TabNav from '../../components/TabNav/TabNav'
import SettingsProfile from './SettingsProfile'
import SettingsSecurity from './SettingsSecurity'

const SettingsPage = () => {
  const links = [
    {
      url: '/profile/settings/profile',
      title: 'Profile',
      component: SettingsProfile,
    },
    {
      url: '/profile/settings/security',
      title: 'Security',
      component: SettingsSecurity,
    },
  ]

  return <TabNav links={links} title='Settings' redirectLink='/profile/settings/profile' />
}

export default SettingsPage
