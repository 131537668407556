import React from 'react'
import styles from './spinner.module.scss'
import useSpinnerElement from './useSpinnerElement'
import { number, string } from 'prop-types'
import cn from 'classnames'

const propTypes = {
  strokeWidth: number,
  borderColor: string,
  color: string,
  size: number,
  type: string,
  className: string,
}

const Spinner = (props) => {
  const spinner = useSpinnerElement(props)

  return (
    <div style={props.style} className={cn(styles.wrapper, props.className)}>
      {spinner}
    </div>
  )
}

Spinner.propTypes = propTypes

export default Spinner
