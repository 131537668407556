import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getAuthProfileFilters, getSettings } from '../../api/getApi'
import { setSettings } from '../../api/patchApi'
import { updateSettings } from '../../redux/actions/user.action'
import format from 'date-fns/format'
import styles from './settingsPage.module.scss'
import Select from '../../UI/Select/Select'
import Spinner from '../../UI/Spinner/Spinner'
import DeleteAccount from '../../components/Settings/DeleteAccount/DeleteAccount'
import Notification from '../../components/Settings/Notifications/Notifications'
import CheckBox from '../../UI/CheckBox/CheckBox'
import { useNotify } from '../../hooks/useNotify'

const SettingsProfile = () => {
  const { notify } = useNotify()
  const dispatch = useDispatch()
  const scope = useSelector(({ authentication }) => authentication.user.scope)
  const admin_allow = JSON.parse(localStorage.getItem('settings')).admin_permission
  const [adminPermission, setAdminPermission] = useState(admin_allow)
  const [selectValues, setSelectValues] = useState({
    expired_document_days: { name: '', id: null },
    date_format: { name: '', id: null },
  })
  const [filters, setFilters] = useState({
    notify_expired_documents: [],
    date_formats: [],
  })
  const [loading, setLoading] = useState(false)
  // const [LoadingNotifications, setLoadingNotifications] = useState(false)

  const checkBoxTextStyles = {
    fontSize: '14px',
    fontWeight: 400,
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getAuthProfileFilters('date_formats', 'notify_expired_documents').then(
        ({ date_formats, notify_expired_documents }) => {
          setFilters({
            date_formats: date_formats.map(({ name }) => ({
              name: name.toLowerCase(),
              id: name,
            })),
            notify_expired_documents: notify_expired_documents.map(({ days }) => ({
              name: `${days} days`,
              id: days,
            })),
          })
        }
      ),

      getSettings('seafarer').then(({ date_format, expired_document_days }) => {
        setSelectValues({
          expired_document_days: {
            name: `${expired_document_days} days`,
            id: expired_document_days,
          },
          date_format: { name: date_format, id: date_format },
        })
      }),
    ])
      .catch(({ errors }) => notify.errorsList(errors))
      .finally(() => setLoading(false))
    //eslint-disable-next-line
  }, [])

  const handleSelectChange = (name, target) => {
    if (target.id !== selectValues[name].id) {
      setSettings({ [name]: target.id }, 'seafarer').then(() => {
        dispatch(updateSettings('seafarer'))
        notify('Success', 'Your settings were updated')
        setSelectValues((state) => ({
          ...state,
          [name]: target,
        }))
      })
    }
  }

  const dateExample = format(
    new Date(),
    selectValues.date_format.name.replaceAll('Y', 'y').replaceAll('D', 'd') || 'yyyy:MM:dd'
  )

  const handleClick = (flag) => {
    setSettings({ admin_permission: flag }, scope).then(({ admin_permission }) => {
      dispatch(updateSettings(scope))
      notify('Success', 'Your settings were updated')
      setAdminPermission(admin_permission)
    })
  }

  return (
    <div className={styles.screen}>
      <div className={styles.wrapper}>
        <div className={styles.leftBlock}>
          <div className={styles.dateFormat}>
            <div className={styles.dateFormatLabel}>Select date format in your account</div>
            <div className={styles.dateFormatSelect}>
              <Select
                onClick={handleSelectChange}
                name='date_format'
                defaultName=''
                items={filters.date_formats}
                selectedItem={loading ? <Spinner size={20} /> : selectValues.date_format.name}
                id={selectValues.date_format.id}
                className={styles.select}
              />
            </div>
          </div>

          <div className={styles.dateFormatExample}>Example - {dateExample}</div>

          <div className={styles.expNotificationWrapper}>
            <div className={styles.expiredNotification}>
              <div className={styles.expiredNotificationLabel}>Notify about expired documents for</div>
              <div className={styles.expiredNotificationSelect}>
                <Select
                  onClick={handleSelectChange}
                  name='expired_document_days'
                  defaultName=''
                  items={filters.notify_expired_documents}
                  selectedItem={loading ? <Spinner size={20} /> : selectValues.expired_document_days.name}
                  id={selectValues.expired_document_days.id}
                  className={styles.select}
                />
              </div>
            </div>
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.preventEditing}>
              <CheckBox
                onClick={() => handleClick(!adminPermission)}
                checked={!adminPermission}
                theme='bordered'
                textStyles={checkBoxTextStyles}
              >
                prevent the administrator from editing my data
              </CheckBox>
            </div>
            <p className={styles.support_temporary_information}>
              Support gets temporary access to your account so we can <br /> troubleshoot problems or editing content on
              your behalf. You can <br /> revoke access at any time.
            </p>
          </div>

          <div className={styles.deleteWrapper}>
            <DeleteAccount />
          </div>
        </div>

        <div className={styles.rightBlock}>
          {/*<div className={styles.rightBlockWrapper}>*/}
          <h2 className={styles.title}>Notifications</h2>

          <Notification />
        </div>
      </div>
    </div>
  )
}

export default SettingsProfile
