import * as Yup from 'yup'
import { getDateByUserFormat, getMinDate, today } from '../../../../helpers/timeHelper'

export const initialValues = {
  number: '',
  date_of_issue: null,
  date_of_expire: null,
  type: { name: '', id: null },
  country: { name: '', id: null },
  city: { name: '', id: null },
  endorsement: {
    date_of_issue: null,
    date_of_expire: null,
    country: { name: '', id: null },
    city: { name: '', id: null },
    number: '',
  },
}

export const getValidationSchema = (dateFormat, endorsementPresent) => {
  return Yup.object().shape({
    type: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    number: Yup.string().trim().required('Field is required'),
    country: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    city: Yup.object().shape({
      id: Yup.mixed().test('Check city', 'Select city from list', function (value) {
        const { name } = this.parent

        return name ? Boolean(value) : true
      }),
    }),
    date_of_issue: Yup.date()
      .min(getMinDate, `Min date is ${getDateByUserFormat(getMinDate)}`)
      .max(today, `Max date is ${getDateByUserFormat(today)}`)
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
    date_of_expire: Yup.date()
      .min(Yup.ref('date_of_issue'), 'Date of expire must be later than date of issue')
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .nullable(),
    endorsement: Yup.object().shape({
      date_of_issue: Yup.date()
        .min(getMinDate, `Min date is ${getDateByUserFormat(getMinDate)}`)
        .max(today, `Max date is ${getDateByUserFormat(today)}`)
        .typeError(`Invalid date format, valid - ${dateFormat}`)
        .nullable(),
      date_of_expire: Yup.date()
        .min(Yup.ref('date_of_issue'), 'Date of expire must be later than date of issue')
        .typeError(`Invalid date format, valid - ${dateFormat}`)
        .nullable(),
      city: Yup.object().shape({
        id: Yup.mixed().test('Check city', 'Select city from list', function (value) {
          const { name } = this.parent

          return name ? Boolean(value) : true
        }),
      }),
    }),
  })
}
