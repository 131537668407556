export const setNotifications = (payload) => ({
  type: 'SET_NOTIFICATIONS',
  payload,
})
export const addNotifications = (payload) => ({
  type: 'ADD_NOTIFICATION',
  payload,
})
export const setCounterNotifications = (payload) => ({
  type: 'SET_COUNTER_NOTIFICATION',
  payload,
})

export const setNotificationChecked = (payload) => ({
  type: 'SET_NOTIFICATION_CHECKED',
  payload,
})

export const setAllNotificationsChecked = () => ({
  type: 'SET_ALL_NOTIFICATIONS_CHECKED',
})

export const setAllNotificationsUnchecked = () => ({
  type: 'SET_ALL_NOTIFICATIONS_UNCHECKED',
})

export const removeCheckedNotifications = () => ({
  type: 'REMOVE_CHECKED_NOTIFICATIONS',
})

export const filterNotifications = (payload) => ({
  type: 'FILTER_NOTIFICATIONS',
  payload,
})

export const clearNotifications = () => ({
  type: 'CLEAR_NOTIFICATIONS',
})

export const deleteNotifications = (payload) => ({
  type: 'DELETE_NOTIFICATIONS',
  payload,
})
export const updateNotifyState = (payload) => ({
  type: 'UPDATE_NOTIFY_STATE',
  payload,
})
