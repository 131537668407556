import React, { memo } from 'react'
import styles from './styles.module.scss'
import '../../scss/swiper-slider-processing.scss'
import ProcessingItemsGroupItem from './ProcessingItemsGroupItem'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/mousewheel'

const ProcessingItemsGroup = memo(
  ({ type, title = 'Processing', items, onDelete, currentId, count = 0, isLoading = false }) => {
    return (
      <>
        <p className={styles.processing_count}>
          {title} ({count})
        </p>

        <div className={styles.processing_wrapper}>
          <p className={styles.processing_status}>
            Status: <span>Photos pending...</span>
          </p>

          <div className={styles.processing_list}>
            <Swiper
              modules={[Mousewheel]}
              spaceBetween={-35}
              slidesPerView={4.5}
              mousewheel={true}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {items.length !== 0 &&
                items.map((item) => (
                  <SwiperSlide key={item.id}>
                    <ProcessingItemsGroupItem
                      onDelete={onDelete}
                      type={type}
                      data={item}
                      isActive={currentId === item.id}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>

            {isLoading && <p>Loading...</p>}
          </div>
        </div>
      </>
    )
  }
)

export default ProcessingItemsGroup
