import { DOCUMENTS } from '../types'

const initialState = {
  processingDocuments: [],
  authDocuments: [],
}

export default function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case DOCUMENTS.SET_PROCESSING_DOCUMENTS: {
      return {
        ...state,
        processingDocuments: [...state.processingDocuments, ...action.payload],
      }
    }

    case DOCUMENTS.ADD_PROCESSING_DOCUMENT: {
      return {
        ...state,
        processingDocuments: [action.payload, ...state.processingDocuments],
      }
    }

    case DOCUMENTS.CLEAR_PROCESSING_DOCUMENTS: {
      return {
        ...state,
        processingDocuments: [],
      }
    }

    case DOCUMENTS.SET_AUTH_DOCUMENTS: {
      return {
        ...state,
        authDocuments: [...state.authDocuments, ...action.payload],
      }
    }

    case DOCUMENTS.ADD_AUTH_DOCUMENT: {
      return {
        ...state,
        authDocuments: [action.payload, ...state.authDocuments],
      }
    }

    case DOCUMENTS.DELETE_AUTH_DOCUMENT: {
      return {
        ...state,
        authDocuments: state.authDocuments.filter((document) => document.id !== action.payload),
      }
    }

    case DOCUMENTS.DELETE_PROCESSING_DOCUMENT: {
      return {
        ...state,
        processingDocuments: state.processingDocuments.filter((document) => document.id !== action.payload),
      }
    }

    case DOCUMENTS.EDIT_AUTH_DOCUMENT: {
      return {
        ...state,
        authDocuments: state.authDocuments.map((document) => {
          if (document.id === action.payload.id) {
            return action.payload.data
          } else {
            return document
          }
        }),
      }
    }

    case DOCUMENTS.DELETE_FILE: {
      return {
        ...state,
        authDocuments: state.authDocuments.map((document) => {
          if (
            ['license', 'visa', 'passport', 'certificate', 'experience', 'education'].includes(
              action.payload.documentType
            )
          ) {
            if (document.id === action.payload.documentId) {
              return {
                ...document,
                files: document.files.filter((file) => file.id !== action.payload.fileId),
              }
            } else {
              return document
            }
          } else {
            if (document.id === action.payload.documentId) {
              return {
                ...document,
                endorsement: {
                  ...document.endorsement,
                  files: document.endorsement.files.filter((file) => file.id !== action.payload.fileId),
                },
              }
            } else {
              return document
            }
          }
        }),
      }
    }

    case DOCUMENTS.CLEAR_AUTH_DOCUMENTS: {
      return {
        ...state,
        authDocuments: [],
      }
    }

    default:
      return state
  }
}
