import React, { useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from '../components/Header/Header'
import SeamanSignUpForm from '../components/Form/SeamanSignUpForm'
import SeamanSignInForm from '../components/Form/SeamanSignInForm'
import '../scss/pages.scss'
import useClickOutside from '../hooks/useClickOutside'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import Support from '../components/Support/Support'
import Fab from '../UI/Fab/Fab'
import { isMobileDevice } from '../helpers/mobileDevice.helper'

const loginBgVideoMp4 = 'https://d1cv9t5paq7g8b.cloudfront.net/output/cover.mp4'
const loginBgVideoWebM = 'https://d1cv9t5paq7g8b.cloudfront.net/output/cover.webm'

const SeafarerAuthPage = () => {
  const [isSupportOpen, setIsSupportOpen] = React.useState(false)
  const supportRef = useClickOutside(() => setIsSupportOpen(false))
  const user = JSON.parse(localStorage.getItem('user'))
  const isSocketConnected = useSelector((state) => state.ui.isSocketConnected)

  const ref = useRef(null)
  const nodeRef = useRef(null)

  const video = ref.current

  const playPromise = video?.play()

  if (playPromise !== undefined) {
    playPromise
      .then(() => {
        // Autoplay permission granted
      })
      .catch(() => {
        // Autoplay permission not received
      })
  }

  const isDevEnvironment = () => window.location.href.includes('dev') || window.location.href.includes('localhost')

  if (isMobileDevice() && !isDevEnvironment()) return <Redirect to='/' />

  return (
    <div className='page video-container'>
      <video ref={ref} autoPlay='autoplay' loop='loop' muted className='video-bg'>
        <source src={loginBgVideoWebM} type='video/webm' />
        <source src={loginBgVideoMp4} type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <div className='video-container-content'>
        <Header />
        <main className='auth-section'>
          <div className='container auth-section-container'>
            <div className='auth-section-wrapper'>
              <Switch>
                <Route exact path='/auth/signin' component={SeamanSignInForm} />
                <Route exact path='/auth/signup' component={SeamanSignUpForm} />
              </Switch>
            </div>
          </div>
          {/* Support section start */}
          <div ref={supportRef}>
            <CSSTransition
              nodeRef={nodeRef}
              classNames='fadein-bottom'
              appear
              mountOnEnter
              unmountOnExit
              in={isSupportOpen}
              timeout={300}
            >
              <div ref={nodeRef}>
                <Support className='support-main-chat' />
              </div>
            </CSSTransition>
          </div>
          <div className='support-main-fab'>
            {/*<NewYearHat width={52} height={62} className='newYearHat' />*/}
            <Fab
              disabled={!isSocketConnected || user?.isAdmin}
              isOpen={isSupportOpen}
              handleClick={(e) => {
                e.stopPropagation()
                setIsSupportOpen(!isSupportOpen)
              }}
            />
          </div>
          {/* Support section end */}
        </main>
      </div>
    </div>
  )
}

export default SeafarerAuthPage
