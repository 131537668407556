import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { fetchFilterData, fetchFilterInfiniteScrollData } from '../../api/getApi'
import JobSearchFilter from './JobSearchFilter/JobSearchFilter'
import JobSearchTable from './JobSearchTable/JobSearchTable'
import JobTableMobile from './JobTableMobile/JobTableMobile'
import './JobSearch.scss'
import { convertDateToSubmit } from '../../helpers/timeHelper'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { addVacancyLanding, clearVacanciesLanding, setVacanciesLanding } from '../../redux/actions/vacancies.action'
import { vacanciesListHelper } from '../../helpers/vacanciesSearchHelper'
import { objToQueryConverter, sortOptionsPrettier } from '../../helpers/apiHelper'
import useDebounce from '../../hooks/useDebounce'
import useViewVacancy from '../../hooks/useViewVacancy'

const JobSearch = React.memo(() => {
  const { vacancyId } = useViewVacancy(addVacancyLanding)

  const [excludeId, setExcludeId] = useState(vacancyId)

  const [searchValue, setSearchValue] = useState('')
  const [filterData, setFilterData] = useState([])
  const debounceValue = useDebounce(searchValue)

  const { vacanciesLanding: vacancies } = useSelector(({ vacancies }) => vacancies)
  const { currentSize } = useSelector(({ mainFilter }) => mainFilter.pagination)
  const sort = useSelector(({ mainFilter }) => mainFilter.sort)
  const { vesselType: vessel, rank, joinDate } = useSelector(({ mainFilter }) => mainFilter.filter)

  useEffect(() => {
    fetchFilterData().then((data) => {
      setFilterData(data)
    })
  }, [])

  const exclude_id = (() => {
    if (!excludeId) return ''
    if (isNaN(Number(excludeId))) return ''

    return excludeId
  })()

  const { handleScroll, loading } = useInfiniteScroll(
    fetchFilterInfiniteScrollData,
    setVacanciesLanding,
    vacanciesListHelper,
    objToQueryConverter({
      exclude_id,

      vessel_type: vessel.id,
      rank: rank.id,
      search: debounceValue,
      join_date: convertDateToSubmit(joinDate),
      ordering: sortOptionsPrettier(sort),
      show_land: true,
    }),
    clearVacanciesLanding
  )

  return (
    <>
      <div className='filter-wrapper'>
        <div className='container'>
          <JobSearchFilter
            data={filterData}
            setSearchValue={setSearchValue}
            setExcludeId={setExcludeId}
            searchValue={searchValue}
            loading={loading}
          />
        </div>
      </div>
      <div className='table-wrapper'>
        <div className='container job-search'>
          <div className='job-search__pagination'>
            <h2 className='job-search__title'>
              Vacancies <span className='job-search__title--accent'>FOR</span> Seafarers
            </h2>
          </div>
          <JobSearchTable
            tableSize={currentSize}
            loading={loading}
            emptyTitle='Sorry, there are no results for this search.'
            data={vacancies}
            handleScroll={handleScroll}
          />
          <JobTableMobile
            emptyTitle='Sorry, there are no results for this search.'
            loading={loading}
            vacancies={vacancies}
          />
        </div>
      </div>
    </>
  )
})

export default JobSearch
