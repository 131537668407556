import store from '../redux/store'

import { profilePaths } from './constans'

import UserPage from '../pages/UserPage'
import ProfilePersonalEditPage from '../pages/ProfilePersonalEditPage'
import DocumentsEditPage from '../pages/DocumentsEditPage'
import MessagesPage from '../pages/MessagesPage'
import ReviewPage from '../pages/ReviewPage'
import EventsAlertsPage from '../pages/Events&AlertsPage'
import BillingPage from '../pages/BillingPage'
import SettingsPage from '../pages/SettingsPage/SettingsPage'

export const getProfileRoutes = () => {
  const { isBillingActive } = store.getState().billing

  return {
    [profilePaths.view]: {
      Component: UserPage,
    },
    [profilePaths.personal_data]: {
      Component: ProfilePersonalEditPage,
    },
    [profilePaths.documents]: {
      Component: DocumentsEditPage,
    },
    [profilePaths.chat]: {
      Component: MessagesPage,
      exact: true,
    },
    [`${profilePaths.chat}/:id`]: {
      Component: MessagesPage,
    },
    [profilePaths.review]: {
      Component: ReviewPage,
    },
    [profilePaths.notification]: {
      Component: EventsAlertsPage,
    },

    ...(isBillingActive && {
      [profilePaths.billing]: {
        Component: BillingPage,
      },
    }),

    [profilePaths.settings]: {
      Component: SettingsPage,
    },
  }
}
