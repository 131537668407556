import { confirm } from '../../UI/Confirm/Confirm'

import { deleteSeafarerFile } from '../../api/deleteApi'
import { getProcessingDocuments } from '../../api/getApi'
import {
  clearAuthDocuments,
  clearProcessingDocuments,
  deleteProcessingDocument,
  setAuthDocuments,
  setProcessingDocuments,
} from '../../redux/actions/documents.action'
import { getProcessingDocumentsHelper, objToQueryConverter } from '../../helpers/apiHelper'
import { notify } from '../../helpers/notify'

export const handleDeleteProcessingDocument = (id, dispatch) => {
  confirm('Are you sure?', 'You want to delete this document?', async () => {
    try {
      await deleteSeafarerFile(id)

      dispatch(deleteProcessingDocument(id))

      notify('Success', 'Your record was successfully deleted')
    } catch ({ response }) {
      const { errors } = response.data.data

      notify.errorsList(errors)
    }
  })
}

export const fetchData = async (dispatch, setIsFetching, getList, helper, processingType) => {
  dispatch(clearAuthDocuments())
  dispatch(clearProcessingDocuments())
  setIsFetching(true)

  try {
    const [processingDocuments, list] = await Promise.all([
      getProcessingDocuments(1, objToQueryConverter({ type: `processing_${processingType}`, page_size: 1000 })),
      getList(1, objToQueryConverter({ page_size: 1000 })),
    ])

    const handleData = (data, setFunc, helper) => {
      if (!data.ok && data.errors) {
        notify.errorsList(data.errors)

        return
      }

      dispatch(setFunc(helper(data.results, data.count, data)))
    }

    handleData(processingDocuments, setProcessingDocuments, getProcessingDocumentsHelper)
    handleData(list, setAuthDocuments, helper)
  } catch (error) {
    notify.errorsList(error.errors)
  } finally {
    setIsFetching(false)
  }
}
