import React from 'react'
import cn from 'classnames'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import FileList from '../../FileList/FileList'
import styles from '../../UserCard.module.scss'
import { ReactComponent as CalcOfFiles } from '../../../../assets/icons/calcOfFiles.svg'
import { uniqueId } from 'lodash'

const CertificateItem = ({ title, files, items }) => {
  const [showFileList, setShowFileList] = React.useState(false)

  return (
    <div className={styles.contentItem}>
      <div className={cn(styles.header, { [styles.passport]: true })}>
        <UserCardSmartTooltip
          id={uniqueId('certificate-item-')}
          type={'simple'}
          title={title}
          titleClass={styles.contentItemTitle}
        />

        <div
          className={cn(styles.calcOfFilesContainer, { [styles.calcOfActive]: showFileList })}
          onClick={() => {
            files.length && setShowFileList(!showFileList)
          }}
        >
          <label className={styles.calcOfFilesContainerLabel}>Photo</label>
          <div className={styles.calcOfFiles}>
            <CalcOfFiles />
            <label className={styles.calcOfFilesLabel}>{files.length}</label>
          </div>
        </div>
      </div>

      <div className={cn(styles.body, { [styles.passport]: true })}>
        {items?.map((item, index) => (
          <UserCardSmartTooltip
            key={index}
            title={item.title}
            value={item.value}
            id={uniqueId('certificate-item-')}
            containerClass={styles.item}
            titleClass={styles.itemTitle}
            valueClass={styles.itemValue}
          />
        ))}

        {files.length ? <FileList files={files} showFiles={showFileList} /> : ''}
      </div>
    </div>
  )
}

export default CertificateItem
