import { convertDateToSubmit } from '../../helpers/timeHelper'

export const generalHelper = (obj) => {
  return {
    first_name: obj.first_name,
    last_name: obj.last_name,
    middle_name: obj.middle_name,
    salary: obj.salary || null,
    available_from: convertDateToSubmit(obj.available_from),
    date_of_birth: convertDateToSubmit(obj.date_of_birth),
    gender: obj.gender,
    country_of_birth: obj.country_of_birth?.id || null,

    nationality: obj.nationality?.id || null,
    marital_status: obj.marital_status.id,
    rank: obj.rank.id,
    rank_secondary: obj.rank_secondary.id,
    salary_currency: obj.salary_currency.id,
    salary_rate: obj.salary_rate.id,
    availability_status: obj.availability_status.id,
    english_level: obj.english_level.id,
    city_of_birth: obj.city_of_birth,
    geolocation: {
      city: {
        id: obj.user_city_now.id,
        name: obj.user_city_now.name,
      },
    },
  }
}

export const getHelper = ({
  first_name,
  last_name,
  middle_name,
  salary,
  available_from,
  date_of_birth,
  gender,
  geolocation,
  ...rest
}) => ({
  first_name,
  last_name,
  middle_name,
  available_from,
  date_of_birth,
  gender: gender?.id,
  salary: salary === null ? '' : salary,
  country_of_birth: rest.country_of_birth
    ? { name: rest.country_of_birth.name || '', id: rest.country_of_birth.id, iso2: rest.country_of_birth.iso2 || null }
    : { name: '', id: null },
  nationality: rest.nationality
    ? { name: rest.nationality.name || '', id: rest.nationality.id }
    : { name: '', id: null },
  marital_status: rest.marital_status
    ? { name: rest.marital_status.name || '', id: rest.marital_status.id }
    : { name: '', id: null },
  rank: rest.rank ? { name: rest.rank.name || '', id: rest.rank.id } : { name: '', id: null },
  rank_secondary: rest.rank_secondary
    ? { name: rest.rank_secondary.name || '', id: rest.rank_secondary.id }
    : { name: '', id: null },
  salary_currency: rest.salary_currency
    ? { name: rest.salary_currency.name || '', id: rest.salary_currency.id }
    : { name: '', id: null },
  salary_rate: rest.salary_rate
    ? { name: rest.salary_rate.name || '', id: rest.salary_rate.id }
    : { name: '', id: null },
  availability_status: rest.availability_status
    ? { name: rest.availability_status.name || '', id: rest.availability_status.id }
    : { name: '', id: null },
  english_level: rest.english_level
    ? { name: rest.english_level.name || '', id: rest.english_level.id }
    : { name: '', id: null },
  city_of_birth: rest.city_of_birth
    ? { name: rest.city_of_birth.name || '', id: rest.city_of_birth.id }
    : { name: '', id: null },
  user_city_now: { name: geolocation?.city?.name ?? '', id: geolocation?.city?.id ?? null },
})
