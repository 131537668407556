import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import PassportItem from './PassportItem/PassportItem'
import styles from '../UserCard.module.scss'
import { setPassportsInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'
import { ReactComponent as NoInfoImage } from '../../../assets/images/passports_stub.svg'

const Passports = ({ userCardData }) => {
  const dispatch = useDispatch()
  const { passports } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setPassportsInfo([]))
    }
  }, [dispatch])
  if (isEmpty(passports)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={480} height={270} />
        <p>You don't have any passports yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px' style={{ borderRadius: '4px' }}>
        {passports?.map((passport, index) => {
          return <PassportItem key={index} title={passport.name} files={passport.files} items={passport.values} />
        })}
      </Masonry>
    )
  }
}

export default Passports
