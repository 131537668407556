import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import MobileItemOpenCard from './MobileItemOpenCard'
import { ReactComponent as JoinDateIcon } from '../../../assets/icons/vacancyJoinDate.svg'
import { ReactComponent as DurationIcon } from '../../../assets/icons/vacancyDuration.svg'
import { ReactComponent as SalaryIcon } from '../../../assets/icons/vacancySalary.svg'
import { ReactComponent as FireIcon } from '../../../assets/icons/fire.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import cn from 'classnames'
import JobSeaTabletInfoPopup from './JobSeaTabletInfoPopup'

const JobSearchTableMobileItem = memo(({ item, isItTabletView }) => {
  const [isMobileCardOpen, setIsMobileCardOpen] = useState(false)
  const [isTabletCardOpen, setIsTabletCardOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    rank_name,
    vessel,
    salary,
    join_date,
    salary_currency,
    duration,
    duration_period,
    vacancy_type,
    country,
    published_at,
  } = item

  const { name } = vessel
  const { symbol: currency_symbol = null } = salary_currency ?? {}
  const vesselTypeOrLand = (() => {
    if (vacancy_type === 'land') {
      return `Shore-Based Job, ${country.name}`
    }

    return name ? name : 'Vessel is not named'
  })()

  const toOpenCard = () => {
    window.innerWidth > 744 && setIsTabletCardOpen(true)
  }

  const renderDurationPeriod = (duration_period) => {
    if (duration_period === 'month') return 'm.'
    if (duration_period === 'week') return 'w.'
    if (duration_period === 'day') return 'd.'
    if (duration_period === 'year') return 'y.'
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={cn(styles.vacancy, { [styles.vacancy_open]: isMobileCardOpen })}
        onClick={() => {
          toOpenCard()
        }}
      >
        <article>
          <div className={styles.main_info}>
            <div className={styles.main_info_block}>
              <p>
                <JoinDateIcon className={styles.join_date_icon} />
                {join_date}
              </p>
              <div className={styles.dot}></div>
            </div>
            <div className={styles.main_info_block}>
              <p>
                <DurationIcon />
                {duration} {renderDurationPeriod(duration_period)}
              </p>
              <div className={styles.dot}></div>
            </div>
            <div className={styles.main_info_block}>
              <p>
                <SalaryIcon />
                {currency_symbol} {salary}
              </p>
            </div>
          </div>
          <p className={styles.rank_name}>
            {item.urgent && <FireIcon />}
            {rank_name}
          </p>
          <p className={styles.vessel_type}>{vesselTypeOrLand}</p>

          <div className={styles.publicationDate}>
            <span>Publ. date:{published_at}</span>
            {item.request_sent_date && <div className={styles.applied}>APPLIED</div>}
          </div>
          {!isItTabletView && (
            <div
              className={styles.toOpenBlock}
              onClick={() => {
                setIsMobileCardOpen(!isMobileCardOpen)
              }}
            >
              <ArrowDownIcon className={cn(styles.ArrowIcon, { [styles.flippedArrowIcon]: isMobileCardOpen })} />
            </div>
          )}
        </article>
      </div>
      <MobileItemOpenCard
        item={item}
        isCardOpen={isMobileCardOpen}
        setIsCardOpen={setIsMobileCardOpen}
        setLoading={setLoading}
        loading={loading}
      />
      {isTabletCardOpen && item && (
        <JobSeaTabletInfoPopup
          item={item}
          setIsTabletCardOpen={setIsTabletCardOpen}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  )
})
JobSearchTableMobileItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default JobSearchTableMobileItem
