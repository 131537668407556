const initialState = {
  list: [],
  tabs: [],
  favourites: [],
  favouritesFilter: {
    search: "",
    ordering: "",
  },
  vacancy_requests: [],
  requestFilter: {
    ordering: "",
    vacancy_id: null,
  },
  filter: {
    rank: [],
    vessel_type: [],
    engine: [],
    nationality: [],
    documents: [],
    teu: "",
    dwt: "",
    grt: "",
    hp: "",
    kw: "",
    ordering: "",
  },
};

export default function seafarersSearchReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SEAFARERS_LIST":
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };

    case "SET_SEAFARERS_REQUESTS_LIST":
      return {
        ...state,
        vacancy_requests: [...state.vacancy_requests, ...action.payload],
      };

    case "ADD_TABS_PANEL_ITEM":
      return {
        ...state,
        tabs: [action.payload, ...state.tabs],
      };

    case "DELETE_TABS_PANEL_ITEM":
      return {
        ...state,
        tabs: state.tabs.filter((tab) => tab.id !== action.payload),
      };

    case "SET_FAVOURITE_STATUS":
      return {
        ...state,
        list: state.list.map((item) => ({
          ...item,
          is_favourite:
            item.user_id === action.payload.id
              ? !action.payload.status
              : item.is_favourite,
        })),
        vacancy_requests: state.vacancy_requests.map((item) => ({
          ...item,
          is_favourite:
            item.user_id === action.payload.id
              ? !action.payload.status
              : item.is_favourite,
        })),
      };

    case "SET_SEAFARERS_FAVOURITE_LIST":
      return {
        ...state,
        favourites: [...state.favourites, ...action.payload],
      };

    case "SET_FAVOURITE_FILTER":
      return {
        ...state,
        favouritesFilter: {
          ...state.favouritesFilter,
          ...action.payload,
        },
      };

    case "REMOVE_SEAFARER_FROM_FAVOURITES":
      return {
        ...state,
        favourites: state.favourites.filter(
          (item) => item.user_id !== action.payload
        ),
        list: state.list.map((item) => ({
          ...item,
          is_favourite:
            item.user_id === action.payload.id ? false : item.is_favourite,
        })),
        vacancy_requests: state.vacancy_requests.map((item) => ({
          ...item,
          is_favourite:
            item.user_id === action.payload.id ? !action.payload.status : false,
        })),
      };

    case "CLEAR_SEAFARERS_LIST":
      return {
        ...state,
        list: [],
      };

    case "CLEAR_SEAFARERS_REQUESTS_LIST":
      return {
        ...state,
        vacancy_requests: [],
      };

    case "CLEAR_SEAFARERS_FAVOURITES_LIST":
      return {
        ...state,
        favourites: [],
      };

    case "SET_SEARCH_FILTER_OPTIONS":
      return {
        ...state,
        filter: {
          ...action.payload,
        },
      };

    case "SET_REQUESTS_FILTER":
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          ...action.payload,
        },
      };

    case "REMOVE_REQUEST":
      return {
        ...state,
        vacancy_requests: state.vacancy_requests.filter(
          (item) => item.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
