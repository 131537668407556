import React from 'react'
import styles from './UserPreloaderDoubleItem.module.scss'

export const UserPreloaderDoubleItem = ({ numberOfCards }) => {
  const items = [1, 2, 3, 4, 5, 6, 7]
  return (
    <div className={styles.content_item_wrapper}>
      <div className={styles.contentItem}>
        <div className={styles.header}></div>
        <div className={styles.body}>
          <div className={styles.info}>
            {items.map((item, index) => (
              <div key={index} className={styles.infoItem}></div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.contentItem}>
        <div className={styles.header}></div>
        <div className={styles.body}>
          <div className={styles.info}>
            {items.map((item, index) => (
              <div key={index} className={styles.infoItem}></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
