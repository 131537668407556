import React from 'react'

import styles from '../../styles.module.scss'

const EnhancedVisibilityNotActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>
        <b>Enhanced Visibility</b> service is not activated.
      </p>
      <p>
        Elevate your sailor profile to <b>VIP status</b>, ensuring it appears at the top of employer search lists,
        garnering maximum attention to your skills and experience.
      </p>
    </div>
  )
}

export default EnhancedVisibilityNotActivated
