import { DOCUMENTS } from '../types'
import { notify } from '../../helpers/notify'

export const setProcessingDocuments = (documents) => {
  return {
    type: DOCUMENTS.SET_PROCESSING_DOCUMENTS,
    payload: documents,
  }
}

export const clearProcessingDocuments = () => {
  return {
    type: DOCUMENTS.CLEAR_PROCESSING_DOCUMENTS,
  }
}

export const setAuthDocuments = (payload) => {
  return {
    type: DOCUMENTS.SET_AUTH_DOCUMENTS,
    payload,
  }
}

export const clearAuthDocuments = () => {
  return {
    type: DOCUMENTS.CLEAR_AUTH_DOCUMENTS,
  }
}

export const addProcessingDocument = (data) => {
  return {
    type: DOCUMENTS.ADD_PROCESSING_DOCUMENT,
    payload: data,
  }
}

export const addAuthDocument = (data) => {
  return {
    type: DOCUMENTS.ADD_AUTH_DOCUMENT,
    payload: data,
  }
}

export const deleteAuthDocument = (id) => {
  return {
    type: DOCUMENTS.DELETE_AUTH_DOCUMENT,
    payload: id,
  }
}

export const deleteProcessingDocument = (id) => {
  return {
    type: DOCUMENTS.DELETE_PROCESSING_DOCUMENT,
    payload: id,
  }
}

export const editAuthDocument = (id, data) => {
  return {
    type: DOCUMENTS.EDIT_AUTH_DOCUMENT,
    payload: {
      id,
      data,
    },
  }
}

export const deleteFileInDocument = (fileId, documentId, documentType) => {
  return {
    type: DOCUMENTS.DELETE_FILE,
    payload: {
      fileId,
      documentId,
      documentType,
    },
  }
}

export const setDocument = (request, id) => async (dispatch) => {
  try {
    const document = await request(id)

    dispatch(addAuthDocument(document))
  } catch (error) {
    notify.errorsList(error.errors)
  }
}

export const editDocument = (request, id) => async (dispatch) => {
  try {
    const document = await request(id)

    dispatch(editAuthDocument(document.id, document))
  } catch (error) {
    notify.errorsList(error.errors)
  }
}
