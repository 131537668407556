import React, { memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { RatingStarSvg } from '../../../../UI/Icons/RatingStarSvg'
import { MailSvg } from '../../../../UI/Icons/MailSvg'
import noAvatar from '../../../../assets/images/no-avatar.svg'

import ImageComponent from '../../../../UI/Image/ImageComponent'
import UseSmartTooltip from '../../../../hooks/useSmartTooltip'

export const Header = memo(({ interlocutor, isOnline, companyName, email }) => {
  const firstName = interlocutor?.name?.split(' ')[1] || null

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <ImageComponent
          className={cn(styles.avatar, { [styles.avatar__online]: isOnline })}
          src={interlocutor.avatar ?? noAvatar}
          alt='user avatar'
        />
        <div className={styles.userInfoBlock}>
          <span className={styles.yourChat}>Your chat with:</span>
          <UseSmartTooltip className={styles.userInfo} tagType={'span'} update={companyName}>
            <b>{companyName ?? 'no name'}</b>
          </UseSmartTooltip>
          {firstName && (
            <span className={styles.userNameInfo}>
              <b>(manager {firstName})</b>
            </span>
          )}
        </div>

        <div className={styles.interlocutor}>
          <RatingStarSvg fill='var(--color-accent-second-light)' />

          <span className={styles.verticalLine} />

          <a href={`mailto:${email}`}>
            <MailSvg height={15} width={23} />
          </a>
        </div>
      </div>
    </div>
  )
})
