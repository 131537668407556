import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StubIcon } from '../../../../assets/images/experience_stub.svg'

const Stub = () => {
  return (
    <div className={styles.wrapper}>
      <StubIcon />

      <p>
        You don't have any work experience yet. <br /> Add the any document confirming your experience or fill in the
        fields
      </p>
    </div>
  )
}

export default Stub
