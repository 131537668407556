import { addProcessingDocument } from '../redux/actions/documents.action'
import { convertDateFromGet, convertDateToSubmit } from './timeHelper'

export const isFile = (object) => {
  const isFile = Object.prototype.toString
    .call(object)
    .replace(/^\[object (.+)\]$/, '$1')
    .toLowerCase()
  return isFile === 'file'
}

export const isValidFile = (file, extensions) => {
  const extension = `.${file.name.match(/\.([a-z\d]+)$/i)[1]}`.toLowerCase()
  return extensions.findIndex((item) => item === extension)
}

export const postProcessingDocument = (dispatch, data, type) => {
  const location = window.location.pathname
  const splitLocation = location.split('/').filter((path) => path !== '')
  const locationPath = splitLocation[splitLocation.length - 1]
  const locationType =
    locationPath[locationPath.length - 1] === 's'
      ? 'processing_' + locationPath.substring(0, locationPath.length - 1)
      : 'processing_' + locationPath

  if (locationType === type) {
    dispatch(addProcessingDocument(data))
  }
}

export const documentSubmitHelper = (document, documentType) => {
  switch (documentType) {
    case 'education':
      return {
        ...document,
        date_from: convertDateToSubmit(document.date_from),
        date_to: convertDateToSubmit(document.date_to),
        country: document.country.id,
        degree: document.degree.id,
      }

    case 'experience-sea':
      return {
        rank: document.rank.id,
        vessel: document.vessel,
        shipowner: document.shipowner,
        crewing: document.crewing,
        country: document.country.id,
        salary_currency: document.currency.id,
        reason_eoc: document.reasonEOC.id,
        description: document.description,
        imo: parseInt(document.imo),
        salary: parseInt(document.wage),
        date_from: convertDateToSubmit(document.date_from),
        date_to: convertDateToSubmit(document.date_to),
      }

    case 'experience-land':
      return {
        rank: document.rank.id,
        company: document.company,
        crewing: document.crewing,
        country: document.country.id,
        city: document.city,
        salary_currency: document.currency.id,
        reason_eoc: document.reasonEOC.id,
        description: document.description,
        imo: parseInt(document.imo),
        salary: parseInt(document.wage),
        date_from: convertDateToSubmit(document.date_from),
        date_to: convertDateToSubmit(document.date_to),
      }

    case 'passport':
      return {
        type: document.type.id,
        country: document.country.id,
        first_name: document.firstName,
        last_name: document.lastName,
        number: document.number,
        middle_name: document.middleName,
        date_of_issue: convertDateToSubmit(document.date_of_issue),
        date_of_expire: convertDateToSubmit(document.date_of_expire),
        address: document.street,
        city: document.city,
        issued_by: document.issuedBy,
      }

    case 'visa':
      return {
        type: document.type.id,
        country: document.country.id,
        number: document.number,
        city: document.city,
        date_of_issue: convertDateToSubmit(document.date_of_issue),
        date_of_expire: convertDateToSubmit(document.date_of_expire),
      }

    case 'license':
      return {
        type: document.type.id,
        country: document.country.id,
        number: document.number,
        city: document.city,
        date_of_issue: convertDateToSubmit(document.date_of_issue),
        date_of_expire: convertDateToSubmit(document.date_of_expire),
        endorsement: document.endorsement?.country?.id
          ? {
              date_of_issue: convertDateToSubmit(document.endorsement.date_of_issue),
              date_of_expire: convertDateToSubmit(document.endorsement.date_of_expire),
              country: document.endorsement.country.id,
              city: document.endorsement.city,
              number: document.endorsement.number,
            }
          : {},
      }

    case 'certificate':
      return {
        type: document.type,
        country: document.country.id,
        number: document.number,
        city: document.city,
        date_of_issue: convertDateToSubmit(document.date_of_issue),
        date_of_expire: convertDateToSubmit(document.date_of_expire),
      }

    default:
      return
  }
}

export const getDocumentHelper = (document, documentType) => {
  switch (documentType) {
    case 'education':
      return {
        college: document.college,
        city: document.city,
        field_of_study: document.field_of_study || '',
        date_from: convertDateFromGet(document.date_from, 'startEducation'),
        date_to: convertDateFromGet(document.date_to, 'endEducation'),
        description: document.description || '',
        country: document.country,
        degree: document.degree,
      }

    case 'experience-sea':
      return {
        rank: document.rank || { name: '', id: null },
        vessel: { name: document.vessel.name || '', id: document.vessel.id },
        shipowner: {
          name: document.shipowner.name || '',
          id: document.shipowner.id,
        },
        crewing: { name: document.crewing.name || '', id: document.crewing.id },
        imo: document.imo,
        imo_search: {
          name: document.imo,
          id: null,
        },
        country: document.country || { name: '', id: null },
        wage: (document.salary ? document.salary.toString() : '') || '',

        currency: document.salary_currency
          ? {
              id: document.salary_currency.id,
              name: document.salary_currency.short_name,
            }
          : { name: '', id: null },
        date_from: convertDateFromGet(document.date_from, 'start'),
        date_to: convertDateFromGet(document.date_to, 'end'),
        reasonEOC: document.reason_eoc || { name: 'None', id: null },
        engine_type: document.engine_type,
        location: { id: 1, name: 'sea' },
        experience_type: document.experience_type,
        vesselType: document.vessel_type || '',
        description: document.description,
        year_of_build: document.vessel.year_of_build,
      }

    case 'experience-land':
      return {
        rank: document.rank || { name: '', id: null },
        company: {
          name: document.company.name || '',
          id: document.company.id,
        },
        city: {
          name: document.city.name || '',
          id: document.city.id,
        },
        crewing: { name: document.crewing.name || '', id: document.crewing.id },
        country: document.country || { name: '', id: null },
        wage: (document.salary ? document.salary.toString() : '') || '',
        currency: document.salary_currency
          ? {
              id: document.salary_currency.id,
              name: document.salary_currency.short_name,
            }
          : { name: '', id: null },
        date_from: convertDateFromGet(document.date_from, 'start'),
        date_to: convertDateFromGet(document.date_to, 'end'),
        reasonEOC: document.reason_eoc
          ? { name: document.reason_eoc.name, id: document.reason_eoc.id }
          : { name: 'None', id: null },
        location: { id: 2, name: 'land' },
        experience_type: document.experience_type,
        description: document.description,
      }

    case 'passport':
      return {
        type: document.type,
        lastName: document.last_name,
        firstName: document.first_name,
        middleName: document.middle_name || '',
        issuedBy: document.issued_by || '',
        number: document.number,
        date_of_issue: convertDateFromGet(document.date_of_issue, 'start'),
        date_of_expire: convertDateFromGet(document.date_of_expire, 'end'),
        country: document.country,
        city: document.city,
        street: document.address,
      }

    case 'visa':
      return {
        id: document.id,
        number: document.number,
        date_of_issue: convertDateFromGet(document.date_of_issue, 'start'),
        date_of_expire: convertDateFromGet(document.date_of_expire, 'end'),
        type: document.type,
        country: document.country ? document.country : { name: '', id: null },
        city: document.city,
        files: document.files,
      }

    case 'license':
      return {
        type: document.type,
        country: document.country,
        number: document.number,
        city: document.city,
        date_of_issue: convertDateFromGet(document.date_of_issue, 'start'),
        date_of_expire: convertDateFromGet(document.date_of_expire, 'end'),
        endorsement: document.endorsement?.country?.id
          ? {
              date_of_issue: convertDateFromGet(document.endorsement.date_of_issue, 'start'),
              date_of_expire: convertDateFromGet(document.endorsement.date_of_expire, 'end'),
              country: document.endorsement.country,
              city: document.endorsement.city,
              number: document.endorsement.number,
            }
          : {
              date_of_issue: null,
              date_of_expire: null,
              country: { name: '', id: null },
              city: { name: '', id: null },
              number: '',
            },
      }

    case 'certificate':
      return {
        id: document.id,
        date_of_issue: convertDateFromGet(document.date_of_issue, 'start'),
        date_of_expire: convertDateFromGet(document.date_of_expire, 'end'),
        country: document.country,
        city: document.city,
        number: document.number,
        type: document?.type ? document.type : { name: '', id: null },
        files: document.files,
      }

    default:
      return
  }
}
export const handleChangeDate = (name, value, handleChangeValue) => {
  if (name === 'dateFrom') {
    if (value) {
      const fromDate = new Date(new Date(value).setHours(0, 0, 1))
      handleChangeValue({ target: { value: fromDate, name } })
    } else {
      handleChangeValue({ target: { value: value, name } })
    }
  } else {
    if (value) {
      const toDate = new Date(new Date(value).setHours(0, 0, 0))
      handleChangeValue({ target: { value: toDate, name } })
    } else {
      handleChangeValue({ target: { value: value, name } })
    }
  }
}
