import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import BottomArrowSvg from '../Icons/BottomArrowSvg'
import useClickOutside from '../../hooks/useClickOutside'
import { createItemRef } from '../../helpers/createItemRef'
import useHeightWithScroll from '../../hooks/useHeightWithScroll'

const Select = memo(
  ({
    name,
    id,
    label,
    selectedItem,
    defaultName = 'None',
    items = [],
    className = '',
    onClick,
    isInvalid = false,
    required = false,
    disabled = false,
  }) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)
    const [isFocused, setIsFocused] = useState(false)

    const selectEl = useClickOutside(() => setIsSelectOpen(false))

    const isOnTop = selectEl.current && window.innerHeight - selectEl.current.getBoundingClientRect().bottom < 250
    const newItems =
      defaultName && name !== 'availability_status' ? [{ id: 'null', name: defaultName }, ...items] : items

    const refs = createItemRef(newItems)
    const { divRef: ulRef } = useHeightWithScroll({ state: isSelectOpen })

    useEffect(() => {
      if (isSelectOpen && activeIndex !== -1) {
        const childElement = refs[newItems[activeIndex]?.id]?.current
        const parentElement = ulRef.current

        if (childElement) {
          const childRect = childElement.getBoundingClientRect()
          const parentRect = parentElement.getBoundingClientRect()

          if (childRect.bottom > parentRect.bottom) {
            parentElement.scrollTop += childRect.bottom - parentRect.bottom
          } else if (childRect.top < parentRect.top) {
            parentElement.scrollTop -= parentRect.top - childRect.top
          }
        }
      }
    }, [isSelectOpen, activeIndex, newItems, refs, ulRef])

    useEffect(() => {
      if (isSelectOpen) {
        const childElement = refs[id]?.current
        const parentElement = ulRef?.current

        parentElement.scrollTop = childElement?.offsetTop - parentElement?.offsetTop
      }
      //eslint-disable-next-line
    }, [isSelectOpen, id, ulRef])

    const handleClick = (id, value) => {
      setIsSelectOpen(false)

      if (id === 'null') {
        onClick(name, { id: null, name: defaultName || label })

        return
      }

      onClick(name, { id, name: value })
    }

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleKeyDown = (e) => {
      if (!isSelectOpen && e.key === 'Enter' && isFocused) {
        setIsSelectOpen(true)

        return
      }

      if (e.key === 'ArrowUp' && activeIndex > 0) {
        e.preventDefault()

        setActiveIndex(activeIndex - 1)

        return
      }

      if (e.key === 'ArrowDown' && activeIndex < newItems.length - 1) {
        e.preventDefault()

        setActiveIndex(activeIndex + 1)

        return
      }

      if (e.key === 'Enter' && activeIndex !== -1) {
        const id = newItems[activeIndex].id
        const name = newItems[activeIndex].name

        handleClick(id, name)
      }
    }

    return (
      <>
        {label && (
          <label className={styles.label}>
            {label} {required && <span className='required-star'>*</span>}
          </label>
        )}

        <div
          tabIndex='0'
          ref={selectEl}
          className={cn(styles.wrapper, className, {
            [styles.wrapper_invalid]: isInvalid,
            [styles.noEvent]: disabled,
          })}
          onClick={() => setIsSelectOpen(!isSelectOpen)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        >
          <div
            className={cn(styles.header, {
              [styles.focus]: isSelectOpen,
              [styles.header_disabled]: disabled,
              [styles.header_invalid]: isInvalid,
            })}
          >
            <div
              className={cn(styles.title, {
                [styles.title_default]: id === null,
              })}
            >
              {selectedItem || '–'}
            </div>
          </div>

          {isSelectOpen && (
            <div className={cn(styles.search_menu, { [styles.list_top]: isOnTop })}>
              <ul ref={ulRef} className={cn(styles.list, { [styles.list_top]: isOnTop })}>
                {newItems.map(({ name: item, id }, index) => {
                  const isActive = index === activeIndex || item === selectedItem

                  return (
                    <SelectItem
                      refs={refs[id]}
                      tabIndex='0'
                      key={id}
                      name={item}
                      id={id}
                      isActive={isActive}
                      onClick={handleClick}
                    />
                  )
                })}
              </ul>
            </div>
          )}

          <div
            className={cn(styles.icon, {
              [styles.icon_active]: isSelectOpen,
            })}
          >
            <BottomArrowSvg />
          </div>
        </div>
      </>
    )
  }
)

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultName: PropTypes.string,
  items: PropTypes.array,
  className: PropTypes.string,
  // onClick: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Select
