import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import documentStyles from '../document.module.scss'

import { confirm } from '../../../UI/Confirm/Confirm'
import CheckBox from '../../../UI/CheckBox/CheckBox'
import BasicDropzone from '../../BasicDropzone/BasicDropzone'
import ProcessingItemsGroup from '../../ProcessingItemsGroup/ProcessingItemsGroup'
import ItemsGroup from '../../ItemsGroup/ItemsGroup'
import VisaForm from './VisaForm/VisaForm'
import Stub from './Stub/Stub'

import { getAuthMe, getListAuthVisas } from '../../../api/getApi'
import { deleteSeafarerFile, deleteProfileVisa } from '../../../api/deleteApi'
import { getAuthDocumentsHelper } from '../../../helpers/apiHelper'
import { getDocumentsItemGroupHelper } from '../../../helpers/itemsGroupHelper'
import { postProcessingDocument } from '../../../helpers/documents.helper'
import { deleteAuthDocument, deleteFileInDocument } from '../../../redux/actions/documents.action'
import { getUserInfo } from '../../../redux/actions/user.action'
import { useNotify } from '../../../hooks/useNotify'
import { fetchData, handleDeleteProcessingDocument } from '../utils'
import { documentType } from '../constants'

const Visas = () => {
  const [documentFiles, setDocumentFiles] = useState([])
  const [currentDocumentId, setCurrentDocumentId] = useState(null)

  const [isFetching, setIsFetching] = useState(false)
  const [fillFields, setFillFields] = useState(false)

  const { processingDocuments, authDocuments } = useSelector(({ documents }) => documents)

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const items = useMemo(() => {
    return getDocumentsItemGroupHelper(authDocuments, documentType.visa)
  }, [authDocuments])

  useEffect(() => {
    fetchData(dispatch, setIsFetching, getListAuthVisas, getAuthDocumentsHelper, documentType.visa)

    // eslint-disable-next-line
  }, [])

  const handleFillFieldsClick = useCallback(() => {
    setFillFields((prev) => !prev)
  }, [])

  const handleEditDocument = useCallback((id, currentDocument) => {
    if (currentDocument) {
      setCurrentDocumentId(id)
    } else {
      setCurrentDocumentId(null)
    }
  }, [])

  const handleDeleteDocument = useCallback(
    (id, currentDocument) => {
      confirm('Are you sure?', 'You want to delete this file?', async () => {
        try {
          await deleteProfileVisa(id)

          // reset form
          if (currentDocument) {
            setCurrentDocumentId(null)
          }

          if (authDocuments.length === 1) {
            const profile = await getAuthMe()

            dispatch(getUserInfo(profile))
          }

          dispatch(deleteAuthDocument(id))

          notify('Success', 'Your document was successfully deleted')
        } catch (error) {
          notify.errorsList(error.errors)
        }
      })
    },

    // eslint-disable-next-line
    [authDocuments.length]
  )

  const deleteDocumentFile = useCallback((fileId, documentId, type, currentDocument) => {
    confirm('Are you sure?', 'You want to delete this file?', async () => {
      setIsFetching(true)

      try {
        if (currentDocument) {
          await deleteSeafarerFile(fileId)

          dispatch(deleteFileInDocument(fileId, documentId, type))

          setDocumentFiles((prevState) => prevState.filter((file) => file.id !== fileId))

          notify('Your file was deleted from this document')

          return
        }

        await deleteSeafarerFile(fileId)

        dispatch(deleteFileInDocument(fileId, documentId, type))

        notify('Your file was deleted from this document')
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setIsFetching(false)
      }
    })

    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.documents_container}>
      <div className={styles.wrapper}>
        {!fillFields && (
          <BasicDropzone type='processing_visa' update={postProcessingDocument}>
            <p>
              Here you can add a photo of experience documents you have. Next Ship will fill in the input fields
              themselves according to the data
            </p>
          </BasicDropzone>
        )}

        <CheckBox onClick={handleFillFieldsClick} checked={fillFields}>
          Fill in the fields yourself
        </CheckBox>

        <VisaForm
          currentDocumentId={currentDocumentId}
          setCurrentDocumentId={setCurrentDocumentId}
          documentFiles={documentFiles}
          setDocumentFiles={setDocumentFiles}
          fillFields={fillFields}
          setFillFields={setFillFields}
          authDocuments={authDocuments}
        />
      </div>

      <div className={documentStyles.documents}>
        {processingDocuments.length !== 0 && (
          <ProcessingItemsGroup
            type={documentType.visa}
            items={processingDocuments}
            onDelete={handleDeleteProcessingDocument}
            currentId={currentDocumentId}
            count={processingDocuments.length}
            isLoading={isFetching}
          />
        )}

        {items.length !== 0 && (
          <ItemsGroup
            type={documentType.passport}
            title='All visas'
            items={items}
            onDelete={handleDeleteDocument}
            onEdit={handleEditDocument}
            deleteFile={deleteDocumentFile}
            currentId={currentDocumentId}
            count={authDocuments.length}
            halfHeight={Boolean(processingDocuments.length)}
            isLoading={isFetching}
          />
        )}

        {items.length === 0 && processingDocuments.length === 0 && <Stub />}
      </div>
    </div>
  )
}

export default Visas
