import React from 'react'

import styles from './NotificationList.module.scss'
import cn from 'classnames'

import CheckBox from '../../UI/CheckBox/CheckBox'

import { ReactComponent as GreenAlertIcon } from '../../assets/icons/green_alert.svg'
import { ReactComponent as YellowAlertIcon } from '../../assets/icons/yellow_alert.svg'
import { ReactComponent as RedAlertIcon } from '../../assets/icons/red_alert.svg'

import { Highlighting } from '../../helpers/textSearch.helper'
import { getDateByFormat, getDateByUserFormat, getParsedDateToISO } from '../../helpers/timeHelper'
import UseSmartTooltip from '../../hooks/useSmartTooltip'

const NotificationStatusIcon = ({ status }) => {
  switch (status) {
    case 'general':
      return <GreenAlertIcon />
    case 'warning':
      return <YellowAlertIcon />
    case 'danger':
      return <RedAlertIcon />
    default:
      return null
  }
}

const NotificationListItem = ({ data, isRead, onClick, search, active_delete, onCheck }) => {
  const parsedDate = getParsedDateToISO(data.date)

  const date = getDateByUserFormat(parsedDate)
  const time = getDateByFormat(parsedDate, 'hh:mm A')

  const handleToRead = (event) => {
    onCheck(data.id)
  }

  return (
    <li
      data-id={data.id}
      onClick={(e) => !isRead && handleToRead(e)}
      className={cn(styles.listItem, {
        [styles.active_delete]: active_delete,
        [styles.checked]: data.is_checked,
      })}
    >
      <div className={styles.checkboxWrapper}>
        <CheckBox id={data.id} checked={data.is_checked} onClick={onClick} theme='bordered' />
      </div>
      <div className={styles.statusWrapper}>
        <NotificationStatusIcon status={data.type} />
      </div>
      <div className={cn(styles.event, { [styles.event_new]: !data.is_read })}>
        <Highlighting data={data} match={search} className={styles.event_content} />
      </div>

      <p>{date}</p>
      <p>{time}</p>
    </li>
  )
}

export default NotificationListItem
