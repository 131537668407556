import React, { useEffect, useState } from 'react'
import styles from './FilterTabs.module.scss'
import PropTypes from 'prop-types'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import cn from 'classnames'
import useClickOutside from '../../hooks/useClickOutside'
import { Button } from 'antd'

function FilterTabs({ tabs, active, onChange, onClick }) {
  const [isFilterHide, setIsFilterHide] = useState(true)
  const filterRef = useClickOutside(() => setIsFilterHide(true))
  const filterBy = ({ target }) => {
    onChange(target.dataset.category)
    onClick(target.dataset.category)
  }

  const handleVisibleFilter = () => {
    setIsFilterHide(!isFilterHide)
  }

  useEffect(() => {
    return () => setIsFilterHide(true)
  }, [])

  return (
    <ul ref={filterRef} className={styles.filter}>
      <Button
        theme={'text'}
        className={cn(styles.filter__button, { [styles.active_filter]: !isFilterHide })}
        onClick={handleVisibleFilter}
      >
        Filter <Filter />
      </Button>
      <ul
        onMouseLeave={() => setIsFilterHide(true)}
        className={cn(styles.filter__items, { [styles.filter_hide]: isFilterHide })}
      >
        {tabs.map((item, index) => (
          <li data-category={item.category} className={styles.filter__item} key={index} onClick={filterBy}>
            <div className={cn(styles.circle, { [styles.circle_checked]: item.category === active })}></div>
            <button className={cn(styles.filter__item_button, { [styles.active]: item.category === active })}>
              {item.title}
              {item.svg.length
                ? item.svg.map((item, index) => {
                    return <span key={index}>{item}</span>
                  })
                : item.svg}
            </button>
          </li>
        ))}
      </ul>
    </ul>
  )
}

FilterTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FilterTabs
