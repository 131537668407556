import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const TableField = ({ children, className, style }) => {
  return (
    <td className={cn(styles.tableField, className)} style={style}>
      {children}
    </td>
  )
}

export default TableField
