import React from 'react'
import { getFullYear } from './timeHelper'

export const getDocumentsItemGroupHelper = (documents, documentType) => {
  if (documents.length) {
    switch (documentType) {
      case 'education':
        return documents.map((document) => {
          const incompleteDate = !getFullYear(document.date_from) && !getFullYear(document?.date_to)
          const dateFrom = getFullYear(document.date_from) || 'no date'
          const dateTo = getFullYear(document?.date_to) || 'current'

          const period = incompleteDate ? 'current' : `${dateFrom}  - ${dateTo}`

          return {
            id: document.id,
            documentName: [document?.college?.name],
            files: document.files,
            degree: document?.degree?.name,
            period,
          }
        })

      case 'experience':
        return documents.map((document) => {
          const dateTo = document?.date_to || 'current'
          return {
            id: document.id,
            vessel: {
              id: document?.vessel?.id,
              name: document?.vessel?.name || '–',
              mt_photo_mini_url: document?.vessel?.mt_photo_mini_url,
            },
            country: document.country,
            city: document.city,
            files: document.files
              ?.map((file, index) => {
                return {
                  ...file,
                  index,
                }
              })
              .sort(function (a, b) {
                return a.index - b.index
              }),
            rank: document?.rank?.name || '–',
            period: document.date_from + '–' + dateTo,
            vesselType: document.vessel_type || '–',
            engineType: document?.vessel?.engine_type?.name || '–',
            experience_type: document?.experience_type,
          }
        })

      case 'passport':
        return documents.map((document) => {
          return {
            id: document?.id,
            documentName: `${document.country?.name || ''} ${document.type?.name}`,
            files: document.files
              ?.map((file, index) => {
                return {
                  ...file,
                  index,
                }
              })
              .sort(function (a, b) {
                return a.index - b.index
              }),
            number: `${document?.number}`,
            date_of_issue: document.date_of_issue,
            date_of_expire: document.date_of_expire,
          }
        })

      case 'visa':
        return documents.map((document) => {
          return {
            id: document?.id,
            documentName: `${document.country?.name || ''} ${document.type?.name}`,
            files: document.files
              ?.map((file, index) => {
                return {
                  ...file,
                  index,
                }
              })
              .sort(function (a, b) {
                return a.index - b.index
              }),
            number: `${document?.number}`,
            date_of_issue: document.date_of_issue,
            date_of_expire: document.date_of_expire,
          }
        })

      case 'license':
        return documents.map((document) => {
          return {
            id: document?.id,
            documentName: `${document.country?.name} ${document.type?.name} ${documentType}`,
            files: document.files
              ?.map((file, index) => {
                return {
                  ...file,
                  index,
                }
              })
              .sort(function (a, b) {
                return a.index - b.index
              }),
            number: `${document?.number}`,
            date_of_issue: document.date_of_issue,
            date_of_expire: document.date_of_expire,
            endorsement: document.endorsement
              ? {
                  number: `${document.endorsement?.number}`,
                  date_of_issue: document.endorsement?.date_of_issue,
                  date_of_expire: document.endorsement?.date_of_expire,
                  files: document.endorsement?.files
                    ?.map((file, index) => {
                      return {
                        ...file,
                        index,
                      }
                    })
                    .sort(function (a, b) {
                      return a.index - b.index
                    }),
                }
              : null,
          }
        })

      case 'certificate':
        return documents.map((document) => {
          return {
            id: document?.id,
            documentName: `${document.country?.name || ''} ${document.type?.name}`,
            files: document?.files
              ?.map((file, index) => {
                return {
                  ...file,
                  index,
                }
              })
              .sort(function (a, b) {
                return a.index - b.index
              }),
            number: `${document?.number}`,
            date_of_issue: document.date_of_issue,
            date_of_expire: document.date_of_expire,
          }
        })

      default:
        return []
    }
  } else {
    return []
  }
}

export const simpleDataPrettier = (arr) => {
  if (arr.length === 0 || !arr) return []
  return arr.map((item) => {
    return {
      id: item?.id,
      documentName: `${item.country?.name || ''} ${item.type?.name}`,
      files: item?.files,
      number: `${item?.number}`,
      date_of_issue: item.date_of_issue,
      date_of_expire: item.date_of_expire,
    }
  })
}

export const licenseDataPrettier = (arr, title) => {
  if (arr.length === 0 || !arr) return []
  return arr.map((item) => {
    return {
      id: item?.id,
      documentName: `${item.country?.name} ${item.type?.name} ${title}`,
      files: item.files,
      number: `${item?.number}`,
      date_of_issue: item.date_of_issue,
      date_of_expire: item.date_of_expire,
      endorsement: item.endorsement
        ? {
            number: `${item?.endorsement?.number}`,
            date_of_issue: item?.endorsement?.date_of_issue,
            date_of_expire: item?.endorsement?.date_of_expire,
            files: item?.endorsement?.files,
          }
        : null,
    }
  })
}

export const diffDays = (date1, date2) => {
  return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24))
}

export const educationDataPrettier = (arr) => {
  return arr.map((item) => {
    return {
      id: item.id,
      header: [item?.college?.name],
      files: null,
      content: (
        <div className='itemsGroupContentRow'>
          <div className='itemsGroupContent'>
            Degree: <b>{item?.degree?.name}</b>
          </div>
          <div className='itemsGroupContent'>
            Period:{' '}
            <b>
              {getFullYear(item.date_from)} - {getFullYear(item?.date_to) || 'current'}
            </b>
          </div>
        </div>
      ),
    }
  })
}
