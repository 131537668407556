import { groupType } from './constants'

import MyContactGroupItem from './MyContactGroupItem/MyContactGroupItem'
import DocumentGroupItem from './DocumentGroupItem/DocumentGroupItem'
import RelativeContactsGroupItem from './RelativeContactsGroupItem/RelativeContactsGroupItem'
import EducationGroupItem from './EducationGroupItem/EducationGroupItem'
import ExperienceGroupItem from './ExperienceGroupItem/ExperienceGroupItem'

export const getGroupItem = (type) => {
  switch (type) {
    case groupType.myContacts:
      return MyContactGroupItem

    case groupType.relativeContacts:
      return RelativeContactsGroupItem

    case groupType.education:
      return EducationGroupItem

    case groupType.experience:
      return ExperienceGroupItem

    default:
      return DocumentGroupItem
  }
}
