import React from 'react'

import styles from './styles.module.scss'

import ProductItem from './ProductItem/ProductItem'

const Products = ({ products, ...rest }) => {
  return (
    <div className={styles.grid}>
      {products.map((product) => (
        <ProductItem key={product.id} data={product} {...rest} />
      ))}
    </div>
  )
}

export default Products
