import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as SupportOfflineIcon } from './assets/icons/support-offline.svg'

const SupportOffline = () => {
  return (
    <>
      <div className={styles.headerOffline}>
        <div className={styles.offlineTitle}>NextShip support</div>
      </div>

      <div className={styles.body}>
        <div className={styles.image}>
          <SupportOfflineIcon />
          <p className={styles.messageOffline}>
            We apologize but all our agents are currently busy. You can email your question to
          </p>
        </div>

        <a className={styles.mailClient} href='mailto:help@nextship.net'>
          help@nextship.net
        </a>
      </div>
    </>
  )
}

export default SupportOffline
