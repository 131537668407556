export const tHead = ['description', 'services', 'total', 'status', 'Expiration date', 'to PDF']

export const colorsMapStatus = {
  paid: '#06251E',
  open: '#E7B92A',
  failed: '#C20000',
}

export const colorExpireMap = {
  paid: '#819F97',
}

export const colorExpirationDateMap = {
  paid: '#1CCF9E',
}
