export const requestsHelper = (values) => {
  return {
    category: values.category,
    questions: values.questions.map((item) => {
      return {
        id: item.id,
        answer: item.answer,
      };
    }),
    description: values.description,
  };
};
