import React from 'react'
import { useFormik } from 'formik'
import styles from './Address.module.scss'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import {
  getAuthAddresses,
  getAuthProfileFilters,
  getGooglePlacesCities,
  getGooglePlacesAddress,
} from '../../../api/getApi'
import { deleteProfileAddressByType } from '../../../api/deleteApi'
import { putProfileAddress } from '../../../api/putApi'
import { getProfileFetch } from '../../../redux/actions/user.action'
import { useNotify } from '../../../hooks/useNotify'
import { getAddressesHelper, sortObjectByKeys, sortObjects } from '../../../helpers/addressesHelper'
import Button from '../../../UI/Button/Button'
import SelectSearch from '../../../UI/Select/SelectSearch'
import Autocomplete from '../../../UI/Select/Autocomplete'
import Input from '../../../UI/Input/Input'
import { confirm } from '../../../UI/Confirm/Confirm'
import { TrashIcon } from '../../../UI/Icons/Icons'
import CheckBox from '../../../UI/CheckBox/CheckBox'
import { initialValuesAddress, isSameValues, validationSchemaAddress } from '../config'
import { isCompletelyFilled } from '../../../helpers/objectsHelper'

const Address = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentData, setDocumentData] = React.useState()

  const [filters, setFilters] = React.useState({
    country: [],
    address_type: [],
  })
  const formik = useFormik({
    initialValues: documentData ? documentData : sortObjectByKeys(sortObjects(initialValuesAddress)),
    validationSchema: validationSchemaAddress,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      setIsButtonEnable(false)

      const birth = values.birth_country.id
        ? {
            type: 1,
            country: values.birth_country.id,
            city: values.birth_city,
            address: values.birth_address,
            apartment: values.birth_apartment || null,
            zip: values.birth_zip,
            is_same: isSame,
          }
        : {
            type: 1,
            country: null,
            city: {
              id: null,
              name: null,
            },
            address: '',
            apartment: null,
            zip: null,
            is_same: isSame,
          }

      const residence = values.residence_country.id
        ? {
            type: 2,
            country: values.residence_country.id,
            city: values.residence_city,
            address: values.residence_address,
            apartment: values.residence_apartment || null,
            zip: values.residence_zip,
            house: values.residence_house,
          }
        : {
            type: 2,
            country: null,
            city: {
              id: null,
              name: null,
            },
            address: '',
            apartment: null,
            zip: null,
            house: '',
          }

      const dataForSubmit = []

      if (residence && birth) {
        dataForSubmit.push(residence, birth)
      } else if (residence) {
        dataForSubmit.push(residence)
      } else if (birth) {
        dataForSubmit.push(birth)
      }

      putProfileAddress(dataForSubmit)
        .then((data) => {
          if (data.ok) {
            notify('Success', 'Your changes were successfully saved.')
            dispatch(getProfileFetch())
          } else {
            data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
          }
        })
        .finally(() => setIsButtonEnable(false))
    },
  })

  const { values, errors, dirty } = formik
  const isSame = React.useMemo(() => isSameValues(values), [values])

  React.useEffect(() => {
    const completelyFilledResidenceAddress = isCompletelyFilled(values, ['residence_country', 'residence_city'])
    const completelyFilledBirthAddress = isCompletelyFilled(values, ['birth_country', 'birth_city'])

    if (!!values.residence_country.id && !!values.birth_country.id) {
      setIsButtonEnable(completelyFilledResidenceAddress && completelyFilledBirthAddress && dirty)
    } else if (!!values.residence_country.id) {
      setIsButtonEnable(completelyFilledResidenceAddress && dirty)
    } else {
      setIsButtonEnable(completelyFilledBirthAddress && completelyFilledResidenceAddress && dirty)
    }
  }, [dirty, values])

  React.useEffect(() => {
    getAuthProfileFilters('country', 'address_type')
      .then((data) => {
        setFilters(() => data)
      })
      .then(() => {
        updateAddresses()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAddresses = () => {
    getAuthAddresses()
      .then((data) => {
        if (data.length) {
          const residence = data.find((item) => item.type === 'residence')
          const birth = data.find((item) => item.type === 'birth')
          setDocumentData(sortObjectByKeys(sortObjects(getAddressesHelper([residence, birth]))))
          formik.resetForm()
        }
        setLoading(false)
      })
      .catch(({ errors }) => notify.errorsList(errors))
  }

  const handleInputChange = (value) => {
    formik.handleChange(value)
  }

  const handleAutocompleteChange = (type, { target }) => {
    handleChangeFormikValue(type, { id: null, name: target.value })
    getPlacesItems(type, target)
  }

  const handleAutocompleteFocus = (type, { target }) => {
    getPlacesItems(type, target)
  }

  const getPlacesItems = (type, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      switch (type) {
        case 'residence_city':
          getGooglePlacesCities(target.value, values.residence_country.iso2.toLowerCase()).then((data) => {
            setAutocompleteItems(data)
          })
          break
        case 'birth_city':
          getGooglePlacesCities(target.value, values.birth_country.iso2.toLowerCase()).then((data) => {
            setAutocompleteItems(data)
          })
          break
        case 'residence_address':
          getGooglePlacesAddress(target.value, values.residence_city.id).then((data) => {
            setAutocompleteItems(data)
          })
          break
        case 'birth_address':
          getGooglePlacesAddress(target.value, values.birth_city.id).then((data) => {
            setAutocompleteItems(data)
          })
          break
        default:
          break
      }
    }
  }

  const handleAutocompleteClick = (type, { name, id }) => {
    handleChangeFormikValue(type, { id, name })
    setAutocompleteItems([])
  }

  const handleSelect = (type, data) => {
    handleChangeFormikValue(type, data)
  }

  const handleChangeFormikValue = (type, data) => {
    formik.handleChange({ target: { value: data, name: type } })
  }

  const deleteAddress = (type, typeName) => {
    confirm('Are you sure?', `You want to delete your ${typeName}?`, () => {
      deleteProfileAddressByType(type).then(() => {
        notify('Success', 'Your changes were successfully saved.')
        if (isSame) {
          deleteProfileAddressByType(1).then(() => {
            formik.resetForm()
          })
        }

        if (type === 1) {
          formik.resetForm({
            values: {
              ...values,
              birth_address: { id: null, name: '' },
              birth_apartment: '',
              birth_city: { id: null, name: '' },
              birth_country: { id: null, name: '' },
              birth_house: '',
              birth_zip: '',
            },
          })
        } else {
          formik.resetForm({
            values: {
              ...values,
              residence_address: { id: null, name: '' },
              residence_apartment: '',
              residence_city: { id: null, name: '' },
              residence_country: { id: null, name: '' },
              residence_house: '',
              residence_zip: '',
            },
          })
        }
        dispatch(getProfileFetch())
      })
    })
  }

  const toggleCheckbox = () => {
    if (isSame) {
      formik.setValues({
        ...values,
        birth_address: { id: null, name: '' },
        birth_apartment: '',
        birth_city: { id: null, name: '' },
        birth_country: { id: null, name: '' },
        birth_house: '',
        birth_zip: '',
      })
    } else {
      setSameAddresses()
    }
  }

  const setSameAddresses = () => {
    formik.setValues({
      ...values,
      birth_country: values.residence_country,
      birth_city: values.residence_city,
      birth_address: values.residence_address,
      birth_apartment: values.residence_apartment,
      birth_house: values.residence_house,
      birth_zip: values.residence_zip,
    })
  }

  if (loading) return null

  return (
    <section className={styles.address}>
      <form className={styles.form_address} onSubmit={formik.handleSubmit}>
        <div className={styles.column_address_residence}>
          <div className='profile-form-title'>
            <span>Residence address</span>
            <button
              type='button'
              title='Delete'
              className={cn('remove-btn', styles.remove_btn_res, {
                'disabled-field': !values.residence_country?.id,
              })}
              onClick={() => deleteAddress(2, 'residence address')}
            >
              <span>Clear</span> <TrashIcon width={9} height={11} stroke='#C20000' />
            </button>
          </div>
          <div className='field-item'>
            <SelectSearch
              defaultName=''
              label='Country'
              required={!!values.residence_country?.id}
              name='residence_country'
              id={values.residence_country?.id}
              selectedItem={values.residence_country?.name}
              items={filters.country || []}
              onClick={(type, data) => {
                if (data.name !== values.residence_country.name) {
                  formik.setValues({
                    ...values,
                    residence_address: { name: '', id: null },
                    residence_city: { name: '', id: null },
                    residence_apartment: '',
                    residence_house: '',
                    residence_zip: '',
                  })
                }
                handleSelect(type, data)
              }}
            />
          </div>
          <div className='field-item'>
            <Autocomplete
              autoComplete={'new-password'}
              label='City'
              name='residence_city'
              required={!!values.residence_country?.id}
              disabled={!values.residence_country?.iso2}
              isInvalid={!!errors?.residence_city?.id}
              value={values.residence_city?.name}
              onFocus={handleAutocompleteFocus}
              onChange={(type, event) => {
                formik.setValues({
                  ...values,
                  residence_address: { name: '', id: null },
                  residence_apartment: '',
                  residence_house: '',
                  residence_zip: '',
                })
                handleAutocompleteChange(type, event)
              }}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
            />

            {errors?.residence_city?.id && (
              <p className={'address_error'} style={{ maxWidth: 'none' }}>
                {errors.residence_city.id}
              </p>
            )}
          </div>
          <div className='field-item'>
            <Autocomplete
              autoComplete={'new-password'}
              label='Street and house'
              name='residence_address'
              disabled={!values.residence_city?.id}
              onFocus={handleAutocompleteFocus}
              getTitle='description'
              value={values.residence_address?.name}
              isInvalid={!!errors?.residence_address?.id}
              onChange={(type, event) => {
                formik.setValues({
                  ...values,
                  residence_apartment: '',
                  residence_house: '',
                  residence_zip: '',
                })
                handleAutocompleteChange(type, event)
              }}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
            />

            {errors?.residence_address?.id && (
              <p className={'address_error'} style={{ maxWidth: 'none' }}>
                {errors.residence_address.id}
              </p>
            )}
          </div>
          <div className={styles.address_details}>
            <div className='field-item'>
              <Input
                onChange={handleInputChange}
                name='residence_apartment'
                label='Apt'
                disabled={!values.residence_address?.id}
                value={values.residence_apartment}
                isInvalid={!!errors.residence_apartment}
              />
              {errors.residence_apartment && <div className='address_error'>{errors.residence_apartment}</div>}
            </div>
            {/*<div className='field-item'>*/}
            {/*  <Input*/}
            {/*    onChange={handleInputChange}*/}
            {/*    name='residence_house'*/}
            {/*    label='House'*/}
            {/*    disabled={!values.residence_address?.id}*/}
            {/*    value={values.residence_house}*/}
            {/*    isInvalid={!!errors.residence_house}*/}
            {/*  />*/}
            {/*  {errors.residence_house && <div className='address_error'>{errors.residence_house}</div>}*/}
            {/*</div>*/}
            <div className='field-item'>
              <Input
                onChange={handleInputChange}
                value={values.residence_zip}
                name='residence_zip'
                label='ZIP'
                disabled={!values.residence_address?.id}
                isInvalid={!!errors.residence_zip}
              />
              {errors.residence_zip && <div className='address_error'>{errors.residence_zip}</div>}
            </div>
          </div>
          <div
            className={cn('field-item', {
              'disabled-field': !values.residence_city?.id,
            })}
          >
            <CheckBox onClick={toggleCheckbox} checked={isSame}>
              Residence and registration addresses coincide
            </CheckBox>
          </div>
        </div>
        <div className={cn(styles.column_address_registration, { 'disabled-field': isSame })}>
          <div className='profile-form-title'>
            <span>Registration address</span>
            <button
              title='Delete'
              className={cn('remove-btn', styles.remove_btn_reg, {
                'disabled-field': !values.birth_country?.id,
              })}
              type='button'
              onClick={() => deleteAddress(1, 'registration address')}
            >
              <span>Clear</span> <TrashIcon width={9} height={11} stroke='#C20000' />
            </button>
          </div>
          <div className='field-item'>
            <SelectSearch
              defaultName=''
              label='Country'
              name='birth_country'
              id={values.birth_country?.id}
              selectedItem={values.birth_country?.name}
              items={filters.country}
              disabled={isSame}
              onClick={(type, data) => {
                if (data.name !== values.residence_country.name) {
                  formik.setValues({
                    ...values,
                    birth_address: { name: '', id: null },
                    birth_city: { name: '', id: null },
                    birth_apartment: '',
                    birth_house: '',
                    birth_zip: '',
                  })
                }
                handleSelect(type, data)
              }}
            />
          </div>
          <div className={'field-item'}>
            <Autocomplete
              autoComplete={'new-password'}
              label='City'
              name='birth_city'
              onFocus={handleAutocompleteFocus}
              required={!!values.birth_country?.id}
              isInvalid={!!errors?.birth_city?.id}
              disabled={!values.birth_country?.id || isSame}
              column='birth'
              value={values.birth_city?.name || initialValuesAddress.birth_city.name}
              onChange={(type, event) => {
                formik.setValues({
                  ...values,
                  residence_address: values.residence_address,
                  birth_address: { name: '', id: null },
                  birth_apartment: '',
                  birth_house: '',
                  birth_zip: '',
                })
                handleAutocompleteChange(type, event)
              }}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
            />

            {errors?.birth_city?.id && (
              <p className={'address_error'} style={{ maxWidth: 'none' }}>
                {errors.birth_city.id}
              </p>
            )}
          </div>
          <div className={'field-item'}>
            <Autocomplete
              autoComplete={'new-password'}
              label='Street and house'
              name='birth_address'
              disabled={!values.birth_city?.id || isSame}
              onFocus={handleAutocompleteFocus}
              getTitle='description'
              column='birth'
              value={values.birth_address?.name || initialValuesAddress.birth_address.name}
              isInvalid={!!errors?.birth_address?.id}
              onChange={(type, event) => {
                formik.setValues({
                  ...values,
                  birth_apartment: '',
                  birth_house: '',
                  birth_zip: '',
                })
                handleAutocompleteChange(type, event)
              }}
              onClick={handleAutocompleteClick}
              items={autocompleteItems}
            />

            {errors?.birth_address?.id && (
              <p className={'address_error'} style={{ maxWidth: 'none' }}>
                {errors.birth_address.id}
              </p>
            )}
          </div>
          <div className={styles.address_details}>
            <div className='field-item'>
              <Input
                onChange={handleInputChange}
                name='birth_apartment'
                label='Apt'
                disabled={!values.birth_address?.id || isSame}
                value={values.birth_apartment}
                isInvalid={!!errors.birth_apartment}
              />
              {errors.birth_apartment && <div className='address_error'>{errors.birth_apartment}</div>}
            </div>
            {/*<div className='field-item'>*/}
            {/*  <Input*/}
            {/*    onChange={handleInputChange}*/}
            {/*    name='birth_house'*/}
            {/*    disabled={!values.birth_address?.id}*/}
            {/*    value={values.birth_house}*/}
            {/*    label='House'*/}
            {/*    isInvalid={!!errors.birth_house}*/}
            {/*  />*/}
            {/*  {errors.birth_house && <div className='address_error'>{errors.birth_house}</div>}*/}
            {/*</div>*/}
            <div className='field-item'>
              <Input
                onChange={handleInputChange}
                name='birth_zip'
                disabled={!values.birth_address?.id || isSame}
                value={values.birth_zip}
                label='ZIP'
                isInvalid={!!errors.birth_zip}
              />
              {errors.birth_zip && <div className='address_error'>{errors.birth_zip}</div>}
            </div>
          </div>
        </div>
        <Button className={styles.button_save} disabled={!isButtonEnable} type='submit' size='middle-full'>
          Save
        </Button>
      </form>
    </section>
  )
}

export default Address
