import React from 'react'
import styles from './styles.module.scss'
import mobileApps_webp from '../../../assets/images/mobile_apps.webp'
import mobileApps_png from '../../../assets/images/mobile_apps.png'
import qrCode from '../../../assets/icons/qr-code.svg'
import playMarketPng from '../../../assets/images/play-market.svg'
import appStorePng from '../../../assets/images/app-store.svg'

const SectionMobApp = () => {
  return (
    <section className={styles.section}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.heading}>
          <h4 className={styles.title}>
            Mobile <span className={styles.accent}>App</span>
          </h4>
          <p className={styles.description}>
            NextShip mobile app allows seafarers to keep abreast of the global work situation at sea and not miss any
            urgent vacancies.
          </p>
        </div>
        <picture className={styles.mobileApps}>
          <source srcSet={mobileApps_webp} type='image/webp' />
          <img src={mobileApps_png} alt='mobile apps' />
        </picture>
        <div className={styles.mobile_app_banner}></div>
        <div className={styles.section_links}>
          <div className={styles.qrCode_block}>
            <img src={qrCode} className={styles.qrCode} alt='qrCode' />
          </div>
          <div className={styles.links}>
            <a
              className={styles.linkApp}
              href='https://play.google.com/store/apps/details?id=com.nextship.client'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img src={playMarketPng} alt='play market' className={styles.play_market_svg} />
            </a>
            <a
              className={styles.linkApp}
              href='https://apps.apple.com/ua/app/nextship/id1554236157'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img src={appStorePng} alt='app store' className={styles.app_store_svg} />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionMobApp
