import React from 'react'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import styles from './SmartSolution.module.scss'
import cn from 'classnames'

import { ReactComponent as Goal } from '../../../assets/icons/goal.svg'
import { ReactComponent as Sort } from '../../../assets/icons/sort.svg'
import { ReactComponent as Search } from '../../../assets/icons/search-white.svg'
import { ReactComponent as CheckIcon } from './icons/check.svg'

import smartSolution from '../../../assets/images/smart-solution.png'
// import smartSolutionMin from '../../../assets/images/smart-solution-min.png'

import Button from '../../../UI/Button/Button'

import { profilePaths } from '../../../constants/constans'

const SmartSolution = () => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)

  return (
    <section className={styles.section}>
      <div className='container'>
        <div className={styles.services}>
          <h2 className={styles.heading}>
            Our smart solutions to <span>find</span> the Job
          </h2>

          <div className={styles.block_banner}>
            <picture>
              <img className={styles.smart_solution_banner} src={smartSolution} alt='smart solution team' />
            </picture>
          </div>

          <div className={styles.services_block}>
            <h2 className={styles.services_block_title}>Services for seafarers are free:</h2>

            <div className={styles.services_block_table}>
              <div className={styles.markers_table}>
                <div className={styles.marker}>
                  <CheckIcon />
                </div>
                <div className={styles.vertical_line_marker}></div>
                <div className={styles.marker}>
                  <CheckIcon />
                </div>
                <div className={styles.vertical_line_marker}></div>
                <div className={styles.marker}>
                  <CheckIcon />
                </div>
                <div className={styles.vertical_line_marker}></div>
                <div className={styles.marker}>
                  <CheckIcon />
                </div>
              </div>

              <div className={styles.texts_table}>
                <p className={styles.text}>Register and fill out your CV</p>
                <p className={styles.text}>Access information about companies that posted vacancies</p>
                <p className={styles.text}>Apply for an unlimited number of vacancies</p>
                <p className={styles.text}>Receive notifications about new vacancies</p>
              </div>
            </div>
          </div>

          <div className={styles.solutions}>
            <ul className={styles.solutions_list}>
              <li className={styles.solutions_list_item}>
                <article>
                  <div className={cn(styles.img_wrapper_search, styles.img_wrapper)}>
                    <Search />
                  </div>

                  <p>
                    You can safely grant access to your maritime profile on <b>NextShip.</b> All crewing agencies and
                    shipowners have passed the verification process before registering.
                  </p>
                </article>
              </li>

              <li className={styles.solutions_list_item}>
                <article>
                  <div className={cn(styles.img_wrapper_goal, styles.img_wrapper)}>
                    <Goal />
                  </div>

                  <p>
                    Goal <b>NextShip</b> is to help any person find a job, regardless of his or her place of residence,
                    language, religion, skin color or beliefs.
                  </p>
                </article>
              </li>

              <li className={styles.solutions_list_item}>
                <article>
                  <div className={cn(styles.img_wrapper_sort, styles.img_wrapper)}>
                    <Sort />
                  </div>
                  <p>
                    Take a look at our current job openings. You can search, filter, and sort jobs to find the right one
                    for you. <b>NextShip</b> finds relevant jobs in few seconds and saves your time.
                  </p>
                </article>
              </li>
            </ul>
          </div>

          <div className={styles.account_control}>
            <div className={styles.download_mobile_app}>
              <Link to='/mobile-store-redirect'>Download mobile app</Link>
            </div>

            <Button
              className={styles.create_account_to_profile}
              size='normal'
              link
              to={isAuth ? profilePaths.personal_data : '/auth/signup'}
            >
              {isAuth ? 'Go to profile' : 'Create account'}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SmartSolution
