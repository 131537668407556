import React from 'react'

import styles from '../../styles.module.scss'

const ActivityTrackingNotActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>
        <b>Activity Tracking</b> service is not activated.
      </p>
      <p>
        Keep tabs on the interest in your profile by monitoring how often it's viewed and which companies are showing
        interest, allowing you to make informed career decisions.
      </p>
    </div>
  )
}

export default ActivityTrackingNotActivated
