import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'
import AnimateHeight from 'react-animate-height'
// import ReactTooltip from 'react-tooltip'
import VacancyDetailsLabel from '../../../UI/VacancyDetailsLabel/VacancyDetailsLabel'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'
import ImageComponent from '../../../UI/Image/ImageComponent'
import VacancyDesktopActionBlock from './JobItemUI/VacancyDesktopActionBlock'
import ShareBlock from '../../ShareBlock/ShareBlock'
import { isDefaultVesselImg } from '../../../helpers/helpers'
import { StyledTooltip } from '../../../UI/Tooltip/Tooltip.config'

const JobSearchTableItem = memo(
  ({ data, isOpen, onClick, isCanAccept, onAccept, acceptLoading, refs, onAnimationEnd, isScrollList }) => {
    const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)

    const { picture, name: company_name } = data.company
    const { description: vacancy_description, title: jobTitle, id } = data

    const vesselBuildYear = new Date(data?.vessel_build_year).getFullYear()

    const handleShareBlockClose = () => {
      setIsShareBlockOpen(false)
    }
    return (
      <div
        ref={refs}
        className={cn(
          styles.item,
          { [styles.item_open]: isOpen },
          { [styles.item_sent]: isCanAccept && data.request_sent_date }
        )}
        key={data.id}
      >
        <div className={cn(styles.shareBlockWrapper, { [styles.shareBlockWrapper_open]: isShareBlockOpen && isOpen })}>
          <ShareBlock id={data.id} onClose={handleShareBlockClose} isOpen={isShareBlockOpen && isOpen} />
        </div>
        <div
          className={cn(styles.row, {
            [styles.row_open]: isOpen,
            [styles.row_is_scroll_list]: isScrollList,
          })}
          onClick={() => onClick(data.id, isOpen)}
        >
          <div className={cn(styles.field, styles.fieldTitle)}>
            <UseSmartTooltip
              tagType={'span'}
              className={cn(styles.fieldTitle_rank)}
              id={`${data.id}-${data.rank?.name}`}
              children={data.rank?.name || 'No information'}
              update={isOpen}
              amount_of_line={1}
            />
            {/*<span className={styles.fieldTitle_rank}>{data.rank?.name}</span>*/}
            {data.urgent && <VacancyDetailsLabel label='urgent' className={styles.urgent} urgent />}
          </div>
          <div className={cn(styles.field, styles.fieldTitle)}>
            <UseSmartTooltip
              tagType={'span'}
              className={cn(styles.field_vessel)}
              id={`${data.id}-${data.vessel?.vessel_type?.name || data.vessel_type?.name}`}
              children={data.vessel?.vessel_type?.name || data.vessel_type?.name || 'No information'}
              update={isOpen}
              amount_of_line={1}
            />
          </div>
          <UseSmartTooltip
            tagType={'p'}
            className={cn(styles.field, styles.salary)}
            id={`salary${id}`}
            children={`${data.salary_currency?.symbol} ${data.salary}/${data.salary_rate}`}
            update={isOpen}
            amount_of_line={1}
          />
          <div className={styles.field}>
            {data.duration} {data.duration_period}
            {Boolean(data.duration_range) && ` (+/- ${data.duration_range}m)`}
          </div>
          <div className={styles.field}>{data.join_date}</div>
        </div>
        <AnimateHeight
          style={{ flexShrink: 0, background: 'var(--color-white)', borderRadius: '8px' }}
          height={isOpen ? 'auto' : 0}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.columns}>
            <div className={styles.columns_more_info}>
              <div className={styles.vacancy_description}>
                <h2>vacancy description:</h2>
                <UseSmartTooltip tagType={'p'} className={styles.job_title} update={isOpen} amount_of_line={2}>
                  {vacancy_description || jobTitle || 'No information'}
                </UseSmartTooltip>
              </div>
              <div className={styles.vessel_characteristics}>
                <div className={styles.col}>
                  {/*<p*/}
                  {/*  className={cn(styles.vessel_characteristics_item, styles.vessel_name, {*/}
                  {/*    [styles.empty_item]: !data.vessel_type.name,*/}
                  {/*  })}*/}
                  {/*>*/}
                  {/*  <b>Vessel type:</b> {data.vessel_type.name || '------'}*/}
                  {/*</p>*/}
                  <UseSmartTooltip
                    update={isOpen}
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data.vessel?.engine_type?.name,
                    })}
                  >
                    <b>Engine type:</b> {data.vessel?.engine_type?.name || '------'}
                  </UseSmartTooltip>
                  <p className={cn(styles.vessel_characteristics_item, { [styles.empty_item]: !vesselBuildYear })}>
                    <b>Built Year:</b> {vesselBuildYear || '------'}
                  </p>
                  <UseSmartTooltip
                    update={isOpen}
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.flag?.name,
                    })}
                  >
                    <b>Vessel Flag:</b> {data?.vessel?.flag?.name || '------'}
                  </UseSmartTooltip>
                  <p
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.dwt,
                    })}
                  >
                    <b>DWT:</b> {data?.vessel?.dwt || '------'}
                  </p>
                </div>
                <div className={styles.col}>
                  <p
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.grt,
                    })}
                  >
                    <b>GRT:</b> {data?.vessel?.grt || '------'}
                  </p>
                  <p
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.teu,
                    })}
                  >
                    <b>TEU:</b> {data?.vessel?.teu || '------'}
                  </p>
                  <p
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.hp && !data?.vessel?.kw,
                    })}
                  >
                    <b>Power:</b> {data?.vessel?.hp || '------'}HP/{data?.vessel?.kw || '------'}KW
                  </p>
                  <UseSmartTooltip
                    update={isOpen}
                    tagType={'span'}
                    className={cn(styles.vessel_characteristics_item, {
                      [styles.empty_item]: !data?.vessel?.crew_mixed && !data?.vessel?.crew_nationality?.name,
                    })}
                  >
                    <b>Crew:</b> {!data?.vessel?.crew_mixed ? data?.vessel?.crew_nationality?.name : 'Mix' || '------'}
                  </UseSmartTooltip>
                </div>
              </div>
            </div>
            <div className={styles.columns_more_vessel_info}>
              <div className={styles.column_start}>
                <div
                  style={{ marginBottom: '7px' }}
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.vessel.name,
                  })}
                >
                  <b>vessel name: </b>
                  {data.vessel.name ? (
                    <u>
                      <StyledTooltip
                        arrow
                        placement='right'
                        title={
                          <span>
                            <ImageComponent
                              src={data.vessel?.mt_photo_mini_url}
                              alt='vassel'
                              className={cn({
                                [styles.vesselImg]: isDefaultVesselImg(data.vessel?.mt_photo_mini_url),
                              })}
                            />
                          </span>
                        }
                      >
                        <p>{data.vessel.name}</p>
                      </StyledTooltip>
                    </u>
                  ) : (
                    '------'
                  )}
                </div>
                <p
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.shipowner.name,
                  })}
                >
                  <b>shipowner:</b>
                </p>
                <span
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.shipowner.name,
                  })}
                >
                  {data.shipowner.name || '------'}
                </span>
                <p
                  className={cn(styles.vessel_characteristics_item, styles.mt16, {
                    [styles.empty_item]: !data.salary_description,
                  })}
                >
                  <b>salary description:</b>
                </p>
                <UseSmartTooltip
                  tagType={'span'}
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.salary_description,
                  })}
                  id={'salary_description'}
                  children={data.salary_description || '-------'}
                  update={isOpen}
                  amount_of_line={1}
                />
              </div>
              <div className={styles.column_end}>
                <div className={styles.shipowner_info}>
                  <img className={styles.shipowner_avatar} src={picture.file_mini} alt='company' />
                  <UseSmartTooltip
                    tagType={'p'}
                    className={styles.shipowner_name}
                    id={'shipowner_name'}
                    children={company_name}
                    update={isOpen}
                    amount_of_line={1}
                  />
                </div>

                <VacancyDesktopActionBlock
                  onAccept={onAccept}
                  acceptLoading={acceptLoading}
                  data={data}
                  isCanAccept={isCanAccept}
                  setIsShareBlockOpen={setIsShareBlockOpen}
                  isShareBlockOpen={isShareBlockOpen}
                />
              </div>
            </div>
          </div>
        </AnimateHeight>
        {isOpen && (
          <p className={styles.publication_date}>
            Publication date: <span>{data.published_at}</span>
          </p>
        )}
      </div>
    )
  }
)

JobSearchTableItem.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isCanAccept: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
}

export default JobSearchTableItem
