import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import styles from '../../styles.module.scss'

import { viewEvent } from '../../../utils'
import { action, serviceMap } from '../../../constants'
import { setServiceData } from '../../../../../../redux/billing/billing.action'

const EarlyAccessHasExpired = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    viewEvent(action.hasExpired, serviceMap.earlyAccess)

    return () => {
      // set the data: {} after checking the service event,
      // so that there is no function getServiceData call, it should be called once, when initializing the application
      dispatch(setServiceData({ service: serviceMap.earlyAccess, data: {} }))
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.description}>
      <p className={styles.description_item}>Attention! Early access service subscription has expired.</p>
      <p>If you want to continue to be the first to receive new vacancies please renew your subscription</p>
    </div>
  )
}

export default EarlyAccessHasExpired
