import React, { memo } from 'react'

import styles from '../styles.module.scss'
import cn from 'classnames'

import { paymentSystemsMap } from '../config'

const CardSystems = memo(({ paymentSystem }) => {
  return (
    <div className={styles.cardSystems}>
      {Object.keys(paymentSystemsMap).map((system) => {
        const image = paymentSystemsMap[system]

        return (
          <div
            key={system}
            className={cn(styles.systemIconItem, {
              [styles.systemIconItem__active]: paymentSystem === system,
            })}
          >
            <img src={image} alt={system} />
          </div>
        )
      })}
    </div>
  )
})

export default CardSystems
