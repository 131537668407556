import React from "react";
import { useDispatch } from "react-redux";
import { getUserSocialToken } from "../redux/actions/auth";
import { getCodeFromUrl } from "../helpers/apiHelper";
import ModalAlert from "../components/ModalAlert/ModalAlert";

const GoogleSignin = () => {
  const dispatch = useDispatch();
  const [isVerifySuccess, setIsVerifySuccess] = React.useState(null);

  React.useEffect(() => {
    const code = getCodeFromUrl();
    if (code) {
      dispatch(
        getUserSocialToken({
          code: code,
          grant_type: "convert_token",
          backend: "google-oauth2",
        })
      );
      setIsVerifySuccess(true);
    } else {
      setIsVerifySuccess(false);
    }
  }, [dispatch]);

  return (
    <div className="page">
      <main>
        {isVerifySuccess && (
          <ModalAlert
            isOk={true}
            title="Thank you!"
            content="You are successfully login from google."
          />
        )}
        {isVerifySuccess === false && (
          <ModalAlert
            isOk={false}
            title="Oops.."
            content="Something went wrong."
          />
        )}
      </main>
    </div>
  );
};

export default GoogleSignin;
