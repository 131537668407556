import React, { useState } from 'react'
import styles from './styles.module.scss'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import PopupForm from './PopupForm'
import LoginMobileInfo from '../../UserProfile/LoginMobileInfo'
import { isMobileDevice } from '../../../helpers/mobileDevice.helper'
import { profilePaths } from '../../../constants/constans'
import { Box, Modal } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const SectionAboutUs = () => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <section className={styles.section}>
      <div className='container'>
        <div className={styles.wrapper}>
          <div className={styles.header_content}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h1 className={styles.title}>
                Is Not Another <span>Typical Job Site</span>
              </h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={styles.description}>
                NextShip is different and makes it easier, safer and faster to find the right job <br /> on the right
                ship <br /> for the right seafarer
              </div>
            </div>
            <div className={styles.header_button_group}>
              {!isMobileDevice() ? (
                <Button
                  className={cn(styles.create_account_header, styles.header_button)}
                  size='normal'
                  theme='white'
                  smooth='true'
                  duration={500}
                  link
                  to={isAuth ? profilePaths.personal_data : '/auth/signup'}
                >
                  {isAuth ? 'Go to profile' : 'Create account'}
                </Button>
              ) : (
                <Button
                  className={cn(styles.create_account_header, styles.header_button)}
                  size='normal'
                  theme='white'
                  smooth='true'
                  duration={500}
                  onClick={handleOpen}
                >
                  {isAuth ? 'Go to profile' : 'Create account'}
                </Button>
              )}
              <PopupForm />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <LoginMobileInfo handleClose={handleClose} />
        </Box>
      </Modal>
    </section>
  )
}

export default SectionAboutUs
