import React from 'react'
import styles from '../../UserCard.module.scss'
import { daysToYearsMonthsDays } from '../../../../helpers/timeHelper'

const ColleagueItem = ({ colleague }) => {
  const { colleagues, vessel_name, date_from, date_to } = colleague
  return (
    <article>
      <div className={styles.colleague}>
        <p>
          <span className={styles.colleague_vessel_name}>{vessel_name}</span> (Period{' '}
          <span className={styles.colleague_vessel_name}>
            {date_from} - {date_to || 'currently'}
          </span>
          )
        </p>
      </div>
      <ul className={styles.colleague_list}>
        {colleagues &&
          colleagues.map((item, index) => {
            const { date_from, date_to, duration, first_name, last_name, middle_name, rank } = item
            const { years, months, remainingDays } = daysToYearsMonthsDays(duration)

            return (
              <li className={styles.colleague_list_item} key={index}>
                <span>
                  {first_name || last_name || middle_name ? `${first_name} ${last_name} ${middle_name}` : 'No name'}
                </span>
                <span>{rank}</span>
                <span>
                  {years > 0 && `${years}y `}
                  {months > 0 && `${months}m `}
                  {remainingDays > 0 && `${remainingDays}d `}
                  {`(${date_from} - ${date_to || 'currently'})`}
                </span>
              </li>
            )
          })}
      </ul>
    </article>
  )
}

export default ColleagueItem
