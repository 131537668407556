import React from 'react'
import styles from './CircleTextButton.module.scss'
import imageSvg from '../../assets/icons/how-it-works.svg'
import { ReactComponent as PlaySvg } from '../../assets/icons/triangle-radius.svg'
import Modal from '../../UI/Modal/Modal'
import useClickOutside from '../../hooks/useClickOutside'

function CircleTextButton({ ...rest }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const modalRef = useClickOutside(() => setIsModalOpen(false))
  return (
    <>
      <button onClick={() => setIsModalOpen(true)} className={styles.button} {...rest}>
        <img className={styles.text} src={imageSvg} alt='how it work' />
        <PlaySvg className={styles.icon} />
      </button>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <video ref={modalRef} className={styles.video} autoPlay controls>
            <source src='https://publicnextship.s3.eu-west-1.amazonaws.com/NEXTSHIP.webm' type={`video/webm`} />
            <source src='https://publicnextship.s3.eu-west-1.amazonaws.com/NEXTSHIP.mp4' type={`video/mp4`} />
            ))
          </video>
        </Modal>
      )}
    </>
  )
}

export default CircleTextButton
