import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import styles from '../UserCard.module.scss'
import VisaItem from './VisaItem/VisaItem'
import { setVisasInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'
import { ReactComponent as NoInfoImage } from '../../../assets/images/visas_stub.svg'

const Visas = ({ userCardData }) => {
  const dispatch = useDispatch()
  const { visas } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setVisasInfo([]))
    }
  }, [dispatch])
  if (isEmpty(visas)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={385} height={260} />
        <p>You don’t have any visas yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px'>
        {visas?.map((visa, index) => {
          return <VisaItem key={index} title={visa.name} files={visa.files} items={visa.values} />
        })}
      </Masonry>
    )
  }
}

export default Visas
