import React, { memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import defaultAvatar from '../../../../assets/images/default_avatar_for_chat.svg'

import ImageComponent from '../../../../UI/Image/ImageComponent'
import OutgoingControls from '../../../ContextMenuControls/OutgoingControls'
import CompanionControls from '../../../ContextMenuControls/CompanionControls'
import ReplyMessage from '../../../ReplyMessage/ReplyMessage'

import { dateToTwelveHourFormat } from '../../../../helpers/timeHelper'
import { messageDefinition } from '../../../../helpers/support.helper'

export const Message = memo(
  ({
    id,
    userName,
    avatar,
    message,
    replyMessage,
    editMessage,
    isOutgoing,
    date,
    onReply,
    onEdit,
    onDelete,
    onContextMenu,
    isOpenMenu,
    setIsOpenMenu,
    onReplyClick,
    searchMessageId,
    refs,
  }) => {
    const onClick = (id) => {
      if (!id) return

      if (id === replyMessage?.id) {
        onReplyClick(id)
      }
    }

    const src = avatar.includes('empty-profile-photo') ? defaultAvatar : avatar
    const name = !isOutgoing ? userName?.toLowerCase() : 'You'

    return (
      <div
        className={cn(styles.message, {
          [styles.message__outgoing]: isOutgoing,
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <ImageComponent className={styles.avatar} src={src} alt='user avatar' />

              <p className={styles.name}>{name}</p>
            </div>

            <div
              ref={refs}
              className={cn(styles.messageText, { [styles.messageText__active]: searchMessageId === id })}
              onContextMenu={(e) => onContextMenu(e, id)}
              onClick={() => onClick(replyMessage?.id)}
            >
              {replyMessage && <ReplyMessage message={replyMessage} />}

              {messageDefinition(message, styles)}

              {isOutgoing && (
                <OutgoingControls
                  id={id}
                  onReply={onReply}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  setIsOpenMenu={setIsOpenMenu}
                  isOpenMenu={isOpenMenu}
                />
              )}

              {!isOutgoing && (
                <CompanionControls id={id} onReply={onReply} setIsOpenMenu={setIsOpenMenu} isOpenMenu={isOpenMenu} />
              )}
            </div>
          </div>

          <div className={styles.footer}>
            {editMessage && <span className={styles.edited}>edited</span>}

            {dateToTwelveHourFormat(date)}
          </div>
        </div>
      </div>
    )
  }
)
