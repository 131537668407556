import React, { memo } from 'react'
import { ExpressCheckoutElement } from '@stripe/react-stripe-js'
import { expressCheckoutOptions } from './configs'

const ButtonPayComponent = memo(({ onReady, onConfirm }) => {
  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    }
    resolve(options)
  }

  return (
    <ExpressCheckoutElement
      style={{ display: 'flex' }}
      onReady={onReady}
      onClick={onClick}
      onConfirm={onConfirm}
      options={expressCheckoutOptions}
    />
  )
})

export default ButtonPayComponent
