const initialState = {
  profile: {
    id: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    last_login: null,
    type: null,
    type_id: null,
    file: null,
    file_name: null,
    email: null,
    filler: {
      grand_total: 0,
    },
  },
  settings: {
    date_format: 'YYYY/MM/DD',
    expired_document_days: null,
    is_refresh: false,
    has_password: null,
  },
}

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case 'GET_USER_INFO':
      return {
        ...state,
        profile: action.payload,
      }
    case 'CHANGE_PROFILE_PICTURE':
      return {
        ...state,
        profile: {
          ...state.profile,
          picture: action.payload,
        },
      }
    case 'USER_LOGOUT':
      return {
        ...initialState,
      }
    case 'GET_USER_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
          is_refresh: true,
        },
      }
    case 'SET_LOGIN_EMAIL':
      return {
        ...state,
        profile: {
          ...state.profile,
          email: action.payload,
        },
      }

    default:
      return state
  }
}
