import React from 'react'
import styles from '../../tabs/review/GetReviews/GetReviews.module.scss'
import ReviewsListItem from './ReviewsListItem'
import Masonry from 'react-responsive-masonry'

function ReviewsList({ reviews, handleScroll }) {
  return (
    <div onScroll={handleScroll}>
      <Masonry columnsCount={2} gutter='25px' className={styles.reviewCards}>
        {reviews.map((item, index) => (
          <ReviewsListItem data={item} key={index} />
        ))}
      </Masonry>
    </div>
  )
}

export default ReviewsList
