import React, { useState } from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { batch, useDispatch } from 'react-redux'
import { Slider } from 'antd'
import { putProfilePicture } from '../../../api/putApi'
import { changeProfilePicture, getProfilePictureFetch } from '../../../redux/actions/user.action'
import styles from './styles.module.scss'
import '../../../scss/antd.scss'
import { useNotify } from '../../../hooks/useNotify'
import Button from '../../../UI/Button/Button'
import Spinner from '../../../UI/Spinner/Spinner'

const PICTURE_TYPE = 'profile'

const AvatarCropper = ({ picture, handleHideCropper, setEditorLoading, id }) => {
  const [avatarEditor, setAvatarEditor] = useState({
    image: picture,
    allowZoomOut: true,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 50,
    preview: null,
    width: 143,
    height: 143,
  })
  const [editor, setEditor] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const handleScale = (scale) => {
    if (isNaN(scale)) {
      return
    }

    setAvatarEditor({ ...avatarEditor, scale })
  }

  const setEditorRef = (editor) => {
    setEditor(editor)
  }

  const handleNewImage = (e) => {
    if (e.target.files[0]) {
      setAvatarEditor({ ...avatarEditor, image: e.target.files[0] })
    }
  }

  const handlePositionChange = (position) => {
    setAvatarEditor({ ...avatarEditor, position })
  }

  const handleSave = () => {
    const croppedImage = editor.getImage().toDataURL(editor.props.image.type)

    const arr = []

    let blobBin = atob(croppedImage.split(',')[1])

    for (let i = 0; i < blobBin.length; i++) {
      arr.push(blobBin.charCodeAt(i))
    }

    const pictureForPut = editor.props.image
    const file = new File([new Uint8Array(arr)], pictureForPut.name, {
      type: pictureForPut.type,
    })

    setLoading(true)

    putProfilePicture(id, file, PICTURE_TYPE)
      .then(() => {
        batch(() => {
          dispatch(getProfilePictureFetch(id))
          dispatch(changeProfilePicture(null))
        })

        setEditorLoading(true)
        handleHideCropper(false)

        notify('Success', 'You are successfully uploaded new user avatar!')
      })
      .catch((error) => {
        setEditorLoading(false)

        notify.errorsList(error.errors)
      })
      .finally(() => setLoading(false))
  }
  const maxSize = 30 * 1024 * 1024

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {loading && <Spinner />}

        <div className={styles.logoContainer} style={loading ? { filter: 'blur(3px)' } : {}}>
          <ReactAvatarEditor
            ref={setEditorRef}
            scale={parseFloat(avatarEditor.scale)}
            width={avatarEditor.width}
            height={avatarEditor.height}
            position={avatarEditor.position}
            onPositionChange={handlePositionChange}
            rotate={parseFloat(avatarEditor.rotate)}
            borderRadius={avatarEditor.width / (100 / avatarEditor.borderRadius)}
            image={avatarEditor.image}
            className='editor-canvas'
          />
        </div>
      </div>

      <div className={styles.body}>
        {typeof avatarEditor.image === 'object' && (
          <Slider
            style={{ width: '100%', margin: 0 }}
            min={0}
            max={2}
            step={0.01}
            tooltipVisible={false}
            onChange={handleScale}
            value={typeof avatarEditor.scale === 'number' ? avatarEditor.scale : 0}
          />
        )}

        <input
          className={styles.fileInput}
          onChange={handleNewImage}
          accept='image/png, .jpeg, .jpg'
          type='file'
          id='avatar-editor'
        />

        <div className={styles.controls}>
          <label className={styles.chooseLogoControl} htmlFor='avatar-editor'>
            Choose photo
          </label>

          {typeof avatarEditor.image === 'object' && (
            <label className={styles.closeControl} onClick={handleHideCropper}>
              Close
            </label>
          )}
        </div>

        <div className={styles.fileInfo}>
          <div className={styles.file}>
            <p className={styles.fileName}>{avatarEditor.image.name}</p>

            {avatarEditor.image.name.length >= 25 && (
              <p className={styles.fileFormat}>.{avatarEditor.image.name.split('.')[1]}</p>
            )}
          </div>

          <p className={styles.formats}>
            <b className={styles.formatsOfImages}>(jpg, jpeg, png)</b>
          </p>
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          size='middle-full'
          onClick={handleSave}
          disabled={loading || picture.size > maxSize}
          className={styles.upload_file}
        >
          Upload now
        </Button>
        {picture.size > maxSize && (
          <p className={styles.errorMessage}>The maximum file size that can be uploaded is (30, 'megabytes')</p>
        )}
      </div>
    </div>
  )
}

export default AvatarCropper
