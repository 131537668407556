import React from 'react'
import { StarIcon } from '../../UI/Icons/Icons'

function RatingIcon({
  fillColor = '#1CCF9E',
  size,
  index,
  rating,
  hoverRating,
  onMouseEnter,
  onMouseLeave,
  onSaveRating,
}) {
  const fill = React.useMemo(() => {
    if (hoverRating >= index) {
      return fillColor
    } else if (!hoverRating && rating >= index) {
      return fillColor
    }
    return '#c7dfd8'
  }, [fillColor, rating, hoverRating, index])

  if (onSaveRating) {
    return (
      <div
        style={{ display: 'flex', alignSelf: 'center' }}
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={() => onMouseLeave()}
        onClick={() => onSaveRating(index)}
      >
        <StarIcon fill={fill} size={size} />
      </div>
    )
  } else {
    return <StarIcon size={size} fill={fill} />
  }
}
export default RatingIcon
