import React, { useState } from "react";
import styles from "./VideoAd.module.scss";
import cn from "classnames";
import { ReactComponent as PlayButton } from "../../assets/icons/play-button.svg";

function VideoAd() {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = React.useRef();

  React.useEffect(() => {
    videoRef.current.onplay = () => {
      setIsPlaying(true);
    };
    videoRef.current.onpause = () => {
      setIsPlaying(false);
    };
  }, []);

  return (
    <div
      className={cn(styles.videoWrapper, { [styles.videoPlaying]: isPlaying })}
    >
      {!isPlaying && (
        <PlayButton
          onClick={() => {
            videoRef.current.play();
          }}
        />
      )}
      <video
        controls
        id="myvideo"
        className={cn(styles.video, { [styles.videoPlaying]: isPlaying })}
        ref={videoRef}
      >
        <source
          src="https://d1cv9t5paq7g8b.cloudfront.net/output/NEXTSHIP.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoAd;
