import { VACANCIES } from '../types'
import { createAction } from '../store'

export const setFilters = (payload) => {
  return {
    type: VACANCIES.SET_FILTERS,
    payload,
  }
}

export const clearFilters = createAction(VACANCIES.CLEAR_FILTERS)

export const setVacancies = (vacancies) => {
  return {
    type: VACANCIES.SET_VACANCIES,
    payload: vacancies,
  }
}
export const addVacancy = (vacancy) => {
  return {
    type: VACANCIES.ADD_VACANCY,
    payload: { vacancy },
  }
}
export const setVacanciesLanding = (vacancies) => {
  return {
    type: VACANCIES.SET_VACANCIES_LANDING,
    payload: vacancies,
  }
}
export const addVacanciesLanding = (vacancy, request_sent_date) => {
  return {
    type: VACANCIES.ADD_VACANCIES_LANDING,
    payload: {
      vacancy,
      request_sent_date,
    },
  }
}
export const addVacancyLanding = (vacancy) => {
  return {
    type: VACANCIES.ADD_VACANCY_LANDING,
    payload: { vacancy },
  }
}

export const applyVacancyRecord = (id, requestSentDate) => {
  return {
    type: VACANCIES.APPLY_VACANCY,
    payload: {
      id,
      requestSentDate,
    },
  }
}

export const clearVacancies = () => {
  return {
    type: VACANCIES.CLEAR_VACANCIES,
  }
}
export const clearVacanciesLanding = () => {
  return {
    type: VACANCIES.CLEAR_VACANCIES_LANDING,
  }
}

export const setAppliedJobs = (jobs) => ({
  type: VACANCIES.SET_APPLIED_JOBS,
  payload: { jobs },
})

export const deleteAppliedJobs = (id) => ({
  type: VACANCIES.DELETE_APPLIED_JOB,
  payload: { id },
})

export const setJobsCount = (count) => ({
  type: VACANCIES.SET_JOBS_COUNT,
  payload: { count },
})

export const clearAppliedJobs = () => ({
  type: VACANCIES.CLEAR_APPLIED_JOBS,
})
