import React, { useState } from 'react'
import cn from 'classnames'
import styles from './supportFooter.module.scss'
import MessageAction from '../../MessageAction/MessageAction'
import ResizableTextarea from '../../../UI/ResizableTextarea/ResizableTextarea'
import { actionSupportMessageClose, setSupportMessageTextAction } from '../../../redux/support/support.action'
import socket from '../../../websocket'
import { notify } from '../../../helpers/notify'
import { operationType } from '../../../constants/constans'
import { ReactComponent as SendIcon } from '../../../assets/icons/send-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Buffer } from 'buffer'

const SupportFooter = ({ setErrorLengthText }) => {
  const [isButtonEnable, setIsButtonEnable] = useState(true)

  const { message_text, message_action } = useSelector(({ support }) => support)

  const dispatch = useDispatch()

  const isEdit = message_action.operation === operationType.edit
  const isReply = message_action.operation === operationType.reply

  const handleMessageChange = ({ target }) => {
    dispatch(setSupportMessageTextAction(target.value))

    const volume = Buffer.from(target.value).length

    if (volume >= 4096) {
      setErrorLengthText(() => true)
      setIsButtonEnable(() => false)
    } else {
      setIsButtonEnable(() => true)
      setErrorLengthText(() => false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!message_text.trim()) return

    const volume = Buffer.from(message_text).length

    if (volume <= 4096) {
      const isInactiveAction = !isReply && !isEdit

      if (isInactiveAction || message_action.isMessageDelete) {
        socket.emit('send.support.message', { message: message_text })
      }

      isReply && socket.emit('send.support.message', { reply: message_action.id, message: message_text })

      isEdit &&
        socket.emit('support.chat.edit.message', {
          message: message_text,
          message_id: message_action.id,
        })

      dispatch(actionSupportMessageClose())
      dispatch(setSupportMessageTextAction(''))
    } else {
      notify.error('Error', 'This message is out of size')
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && isButtonEnable) {
      handleSubmit(event)
    }
  }

  const handleClose = () => {
    dispatch(actionSupportMessageClose())
  }
  return (
    <div className={styles.room_footer}>
      {message_action.isActive && <MessageAction messageAction={message_action} onClick={handleClose} />}

      <div className={styles.room_footer__input}>
        <ResizableTextarea
          onKeyDown={handleKeyDown}
          onChange={handleMessageChange}
          value={message_text}
          lineHeight={18}
          placeholder='Write your message...'
        />

        <button
          onClick={handleSubmit}
          className={cn(styles.send, {
            [styles.sendDisable]: !isButtonEnable,
          })}
          disabled={!isButtonEnable}
        >
          <SendIcon />
        </button>
      </div>
    </div>
  )
}

export default SupportFooter
