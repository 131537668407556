import React from 'react'

const UserIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' fill='none' viewBox='0 0 27 27'>
      <path
        fill='var(--color-accent-light)'
        stroke='var(--color-accent-light)'
        strokeWidth='0.2'
        d='M13.5 26C20.393 26 26 20.393 26 13.5S20.393 1 13.5 1 1 6.607 1 13.5 6.607 26 13.5 26zm-6.322-3.394c.165-3.441 2.444-5.409 6.322-5.409s6.157 1.968 6.322 5.409a11.026 11.026 0 01-6.322 1.985c-2.348 0-4.527-.735-6.322-1.985zM13.5 2.408c6.116 0 11.091 4.976 11.091 11.092 0 3.164-1.333 6.02-3.465 8.043-.59-3.585-3.397-5.754-7.626-5.754-4.229 0-7.036 2.17-7.626 5.754A11.058 11.058 0 012.408 13.5c0-6.116 4.976-11.092 11.092-11.092z'
      ></path>
      <path
        fill='var(--color-accent-light)'
        stroke='var(--color-accent-light)'
        strokeWidth='0.2'
        d='M13.499 14.909a4.23 4.23 0 004.225-4.226A4.23 4.23 0 0013.5 6.458a4.23 4.23 0 00-4.226 4.225A4.23 4.23 0 0013.5 14.91zm0-7.043a2.82 2.82 0 012.817 2.817 2.82 2.82 0 01-2.817 2.817 2.82 2.82 0 01-2.817-2.817 2.82 2.82 0 012.817-2.817z'
      ></path>
    </svg>
  )
}

export default UserIcon
