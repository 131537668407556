import React from 'react'

import TabNav from '../components/TabNav/TabNav'
import UserCard from '../components/UserCard/UserCard'
import ProfileVacancies from '../components/ProfileVacancies/ProfileVacancies'
import AppliedJobs from '../components/ProfileVacancies/AppliedJobs'
import { profilePaths } from '../constants/constans'

const title = window.location.pathname === profilePaths.vacancies ? 'Job search' : 'Vacanсies'

const links = [
  {
    url: profilePaths.vacancies,
    title,
    component: ProfileVacancies,
  },
  {
    url: profilePaths.applied_jobs,
    title: 'Applied jobs',
    component: AppliedJobs,
  },
  {
    url: profilePaths.user_card,
    title: 'User card',
    component: UserCard,
  },
]

const UserPage = () => {
  return <TabNav title='Vacancies' links={links} redirectLink={profilePaths.vacancies} />
}

export default UserPage
