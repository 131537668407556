import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { getReviewsHelper } from '../../../helpers/apiHelper'
import { getReviews, getReviewsBySearch } from '../../../api/getApi'
import { clearReviews, setReviews } from '../../../redux/actions/reviews.action'
import Input from '../../../UI/Input/Input'
import ReviewsList from '../../../components/ReviewsList/ReviewsList'
import styles from './GetReviews.module.scss'

const GetReviews = () => {
  const dispatch = useDispatch()
  const reviews = useSelector(({ reviews }) => reviews.reviews)

  const [searchValue, setSearchValue] = React.useState('')
  const [querySearchValue, setQuerySearchValue] = React.useState('')

  React.useEffect(() => {
    return () => {
      dispatch(clearReviews())
      setSearchValue('')
      setQuerySearchValue('')
    }
  }, [dispatch, setSearchValue])

  const { handleScroll, dataCount } = useInfiniteScroll(
    querySearchValue ? getReviewsBySearch : getReviews,
    setReviews,
    getReviewsHelper,
    querySearchValue ? `category=all&value=${querySearchValue}` : `category=all`,
    clearReviews
  )

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchValue(value)
    if (value === '' || value.length > 1) {
      filterRequests(value)
    }
  }

  const filterRequests = React.useCallback(
    debounce((value) => {
      dispatch(clearReviews())
      setQuerySearchValue(value)
    }, 1000),
    [dispatch]
  )

  return (
    <section className={styles.reviews_section}>
      <div>
        <div className={styles.header}>
          <span className={styles.count_reviews}>Reviews ({dataCount})</span>
          <Input
            label='Search'
            value={searchValue}
            theme='bordered'
            placeholder='Search'
            onChange={handleInputChange}
            className={styles.search_reviews}
          />
        </div>
      </div>
      {reviews.length ? (
        <ReviewsList reviews={reviews} handleScroll={handleScroll} />
      ) : (
        <div className={styles.noInfo}>You don't have any reviews </div>
      )}
    </section>
  )
}

export default GetReviews
