import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import styles from './styles.module.scss'

import { ChatAside } from './ChatAside/ChatAside'
import { ChatRoom } from './ChatRoom/ChatRoom'

import socket from '../../websocket'
import { events } from '../../constants/constans'

const Chat = () => {
  const { id } = useParams()

  const { chat } = events

  useEffect(() => {
    socket.emit(chat.rooms)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (id) {
      socket.emit(chat.messages, { chat_id: id })
      socket.emit(chat.messageDelivered, { chat_id: id })
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <div className={styles.wrapper}>
      <ChatAside chat_id={id} />
      <ChatRoom chat_id={id} />
    </div>
  )
}

export default Chat
