import styles from '../styles.module.scss'
import React, { memo } from 'react'

const JobTabletCharacteristics = memo(({ vessel }) => {
  const { crew_mixed, crew_nationality, year_of_build, flag } = vessel
  const { engine_type, dwt, grt, teu, hp, kw } = vessel

  const vesselPowerCreator = () => (hp ? `${hp}HP` : '') + (kw ? `/${kw}KW` : '')

  const openCardValues = [
    { label: 'Crew', value: crew_mixed ? 'Mix' : crew_nationality?.name, type: 'characteristics' },
    { label: 'Main Engine', value: engine_type?.imaritime_id, type: 'characteristics' },
    { label: 'Built Year', value: year_of_build, type: 'characteristics' },
    { label: 'Vessel Flag', value: flag?.name, type: 'characteristics' },
    { label: 'DWT', value: dwt, type: 'characteristics' },
    { label: 'GRT', value: grt, type: 'characteristics' },
    { label: 'TEU', value: teu, type: 'characteristics' },
    { label: 'Power', value: vesselPowerCreator(), type: 'characteristics' },
  ]
  return (
    <div className={styles.characteristics}>
      {openCardValues.map(
        (item, index) =>
          item.value && (
            <div className={styles.characteristics_item} key={index}>
              <span>{item.label}: </span> <p> {item.value}</p>
            </div>
          )
      )}
    </div>
  )
})
export default JobTabletCharacteristics
