import { useEffect } from 'react'

import socket from '../websocket'

const useListenEvent = (eventName, callback, deps = []) => {
  useEffect(() => {
    const _callback = (socketEvent) => {
      const { event, data } = JSON.parse(socketEvent.data)

      if (eventName === event) {
        callback(data)
      }
    }

    socket.addEvent(_callback)

    return () => {
      socket.clearEvent(_callback)
    }

    // eslint-disable-next-line
  }, deps)
}

export default useListenEvent
