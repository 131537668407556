import React from 'react'

import { profilePaths } from '../../constants/constans'
import { useLocation } from 'react-router-dom'

export const HelperInfo = () => {
  const location = useLocation()

  switch (location.pathname) {
    case profilePaths.vacancies:
      return (
        <>
          <p>
            Keep in mind, <b>your personal information</b> is presented to a <b>shipowner</b> or a <b>crewing agency</b>{' '}
            in this format.
          </p>
        </>
      )

    case profilePaths.user_card:
      return (
        <>
          <p>
            Keep in mind, <b>your personal information</b> is presented to a <b>shipowner</b> or a <b>crewing agency</b>{' '}
            in this format.
          </p>
        </>
      )

    case profilePaths.general:
      return (
        <>
          <p>
            Gender and marital status are required <b>for any visa or immigration application submission</b>. The
            vacancy may be indicative of gender (e.g. on-board cabins availability)
          </p>
          <p>
            It is highly recommended to indicate the citizenship and any entry visa availability at the initial stage of
            the job search as the awareness of immigration formalities and possession of necessary visas{' '}
            <b>may speed up the recruitment process</b>. (e.g. the Seafarers from the Philippines need Schengen Visa to
            board the vessel in EU ports).
          </p>
          <p>
            An applicant’s photograph is required for visa formalities and registration of flag documents.
            Personalization of an applicant’s profile <b>is important for the CrewManager.</b> High profile completion
            status increases the chances of placing the CV at the <b>top of search results.</b>
          </p>
        </>
      )

    case profilePaths.address:
      return (
        <>
          <p>
            Registration address <b>is required</b> for obtaining any visa or passport and <b>should be the same</b> as
            indicated in the travel passport – COUNTRY or COUNTRY/CITY. Place of birth is optional for filling out prior
            to receiving a job offer. However, some CrewManager may check it.
          </p>

          <p>
            Residence address (COUNTRY/CITY) <b>is often required</b> in the initial selection process for urgent
            vacancies. Logistics requirements may be high. More detailed information is to be provided upon signing of
            contract.
          </p>
        </>
      )

    case profilePaths.biometrics:
      return (
        <>
          <p>
            Eye / Hair color <b>is a common question in the visa</b> application and other forms. Basic information
            provided to the CrewManager also includes clothes and shoe size.
          </p>
          <p>
            Some companies may set height and weight as one of the criteria of the search process. They are also
            <b> critical </b>
            characteristics in the <b>medical certificate.</b>
          </p>
        </>
      )

    case profilePaths.my_contacts:
      return (
        <p>
          Contact information of the seafarer and his relatives <b>is needed for processing</b> visa applications,
          contract drafting and money transfer to a bank account. <b>It can be submitted</b> after the candidate for the
          vacancy has been <b>approved.</b>
        </p>
      )

    case profilePaths.relatives_contacts:
      return (
        <p>
          Contact information of the seafarer and his relatives <b>is needed for processing</b> visa applications,
          contract drafting and money transfer to a bank account. <b>It can be submitted</b> after the candidate for the
          vacancy has been <b>approved.</b>
        </p>
      )

    case profilePaths.education:
      return (
        <>
          <p>
            <b>An CrewManager welcomes</b> career growth of a candidate. Special vocational training enables junior
            officers and ratings to grow. <b>Second degree</b> is of interest to an CrewManager.
          </p>
          <p>
            The faculty name may be specified if a seaman changed it in the course of studies. (e.g. a seaman started to
            study electrical engineering but changed the field of study to navigation)
          </p>
          <p>
            <b>“Additional Info”</b> is for any information <b>you consider important.</b>
          </p>
        </>
      )

    case profilePaths.experience:
      return (
        <>
          <p>
            The main parameters for the initial search of a candidate{' '}
            <b>are vessel type, tonnage, cargo handling equipment, engine power</b> and manufacturer of the main and
            auxiliary engines as well as the <b>rank and experience</b> in the required rank onboard. Moreover, a crew
            manager may consider the applicant’s stability (frequency of company change), end-of-contract reasons,
            contract duration and number of months spent on vacation. Experience completion status increases the chances
            of placing the CV <b>at the top</b> of search results.
          </p>
        </>
      )

    case profilePaths.passports:
      return (
        <>
          <p>
            Passport information <b>should be inserted</b> as soon as possible so that a crew manager could assess an
            applicant at an early stage of the recruitment process. Pay attention to the spelling of the{' '}
            <b>first and last names</b> in the <b>documents.</b> It is highly recommended to attach scanned images of
            passports to the profile.
          </p>
        </>
      )

    case profilePaths.visas:
      return (
        <>
          <p>
            <b>Possession of certain visas</b> is one of the main criteria at the initial search of the candidate.{' '}
            <b>Having a visa affects</b> the choice of a candidate for an <b>urgent vacancy.</b> It is possible{' '}
            <b>just to indicate</b> the visa type in the personal profile. Further details <b>should be entered</b> and
            scanned visa images should be attached to the profile once the candidate has been approved.
          </p>
        </>
      )

    case profilePaths.licenses:
      return (
        <>
          <p>
            The indication of licenses and certificates <b>enables</b> a crew manager to consider an applicant for{' '}
            <b>future vacancies.</b> The time necessary to obtain these documents may be a <b>deciding factor.</b>
          </p>
        </>
      )

    case profilePaths.certificates:
      return (
        <>
          <p>
            The indication of licenses and certificates <b>enables</b> a crew manager to consider an applicant for{' '}
            <b>future vacancies.</b> The time necessary to obtain these documents may be a <b>deciding factor.</b>
          </p>
        </>
      )

    default:
      return null
  }
}
