import React from 'react'
import { debounce } from 'lodash'
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg'
import { ReactComponent as ViberIcon } from '../../../assets/icons/viber.svg'
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { UKRAINE_NATIONALITY } from '../../../constants/constans'
import styles from './userCardSmartTooltip.module.scss'
import { StyledTooltip } from '../../../UI/Tooltip/Tooltip.config'

export const messengersIcons = [
  { name: 'WhatsApp', icon: WhatsAppIcon },
  { name: 'Viber', icon: ViberIcon },
  { name: 'Telegram', icon: TelegramIcon },
]

const UserCardSmartTooltip = ({
  type = 'default',
  title,
  value,
  messengers,
  id = '',
  isMain,
  isGeneral = false,
  containerClass = '',
  titleClass = '',
  valueClass = '',
  tooltipClass = '',
  mainContactIcon = '',
  generalMailIcon = '',
  ...rest
}) => {
  const [visible, setVisible] = React.useState(false)
  const [titleWidth, setTitleWidth] = React.useState(0)
  const [titleScrollWidth, setTitleScrollWidth] = React.useState(0)
  const [titleHeight, setTitleHeight] = React.useState(0)
  const [titleScrollHeight, setTitleScrollHeight] = React.useState(0)
  const [windowSize, setWindowSize] = React.useState(window.innerWidth)
  const titleRef = React.useRef(null)
  const labelRef = React.useRef(null)
  const contentRef = React.useRef(null)
  const [maxWidth, setMaxWidth] = React.useState('370')
  const handleWindowResize = debounce(() => {
    setWindowSize(window.innerWidth)
  }, 250)

  React.useEffect(() => {
    if (titleRef) {
      setTitleWidth(titleRef.current.offsetWidth)
      setTitleScrollWidth(titleRef.current.scrollWidth)
      setTitleHeight(titleRef.current.offsetHeight)
      setTitleScrollHeight(titleRef.current.scrollHeight)
    }
  }, [windowSize, maxWidth])

  React.useEffect(() => {
    if (!titleScrollWidth || !titleWidth || !titleScrollHeight || !titleHeight) return

    if (titleScrollWidth > titleWidth || titleScrollHeight > titleHeight + 3) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [titleWidth, titleScrollWidth, titleHeight, titleScrollHeight])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }

    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (contentRef?.current?.offsetWidth && labelRef?.current?.offsetWidth) {
      setMaxWidth(contentRef?.current?.offsetWidth - labelRef?.current?.offsetWidth)
    }
  }, [windowSize, titleWidth, titleScrollWidth])

  switch (type) {
    case 'link':
      return (
        <div ref={contentRef} className={containerClass}>
          <div ref={labelRef} className={titleClass}>
            {title}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              maxWidth: `${maxWidth}px`,
            }}
          >
            <StyledTooltip title={value} disableHoverListener={!visible} placement='top' className={tooltipClass}>
              <a ref={titleRef} href={value} className={valueClass}>
                {value}
              </a>
            </StyledTooltip>

            <span style={{ paddingLeft: 7, display: 'flex', alignItems: 'center' }}>
              {isMain && <span className={mainContactIcon}>Main</span>}
            </span>
          </div>
        </div>
      )

    case 'simple':
      return (
        <StyledTooltip title={title} disableHoverListener={!visible} placement='top' className={tooltipClass}>
          <span ref={titleRef} {...rest} className={titleClass}>
            {title}
          </span>
        </StyledTooltip>
      )

    default:
      return (
        <div ref={contentRef} className={containerClass}>
          <b ref={labelRef} className={titleClass}>
            {title && `${title}:`}
          </b>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              maxWidth: `${maxWidth}px`,
            }}
          >
            <StyledTooltip title={value} disableHoverListener={!visible} placement='top'>
              <span ref={titleRef} className={valueClass}>
                {value}
              </span>
            </StyledTooltip>

            {isMain ||
              (isGeneral && (
                <span style={{ paddingLeft: 7, display: 'flex', alignItems: 'center' }}>
                  {isMain && <span className={mainContactIcon}>Main</span>}
                  {isGeneral && <span className={generalMailIcon}>General</span>}
                </span>
              ))}

            {value === UKRAINE_NATIONALITY && title === 'Citizenship' && (
              <StyledTooltip title={value} placement='right'>
                <span className={styles.location}>
                  <LocationIcon />
                </span>
              </StyledTooltip>
            )}

            <div style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
              {messengersIcons.map(
                ({ name, icon: Icon }) =>
                  messengers?.includes(name.toLowerCase()) && (
                    <span
                      style={{
                        paddingRight: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      key={name}
                    >
                      <Icon width={15} height={15} key={name} />
                    </span>
                  )
              )}
            </div>
          </div>
        </div>
      )
  }
}

export default UserCardSmartTooltip
