import React from "react";
import SocialButton from "../../components/SocialButton/SocialButton";
import { ReactComponent as GoogleLogo } from "../../assets/icons/Google-auth-logo.svg";

const FacebookAuthButton = () => {
  const setDomain = () => {
    if (
      window.location.href.includes("dev") ||
      window.location.href.includes("localhost")
    ) {
      return `dev.nextship.net`;
    } else if (window.location.href.includes("stage")) {
      return `stage.nextship.net`;
    } else {
      return `nextship.net`;
    }
  };

  const href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&include_granted_scopes=true&response_type=code&redirect_uri=https%3A%2F%2F${setDomain()}%2Fseafarer%2Fsignin%2Fgoogle&client_id=184026403640-q7cqot3hvp0hbcohp6rkj06hsg54lme0.apps.googleusercontent.com`;

  const styles = {
    cursor: "auto",
    display: "block",
  };
  return (
    <a style={styles} href={href}>
      <SocialButton style={{ backgroundColor: "#FFF" }}>
        <GoogleLogo />
      </SocialButton>
    </a>
  );
};

export default FacebookAuthButton;
