import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './Button.scss'
import { Link } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'

const Button = memo(
  ({
    children,
    theme = 'default',
    /**
      String: You can choose one of the following themes:
      - default (default)
      - white
      - bordered
      - dark
      - text
      - upload
      */
    size = 'regular',
    /**
      String: You can choose one of the following sizes:
      - regular (default)
      - small
      - normal
      - middle
      - middle-full
      - large-full
      */
    link,
    className = '',
    loading,
    ...rest
  }) => {
    const Tag = link ? Link : 'button'

    return (
      <Tag
        className={`button button-${size} button-${theme} ${className}`}
        disabled={rest?.disabled || loading}
        {...rest}
      >
        {loading ? <Spinner size={20} strokeWith={3} /> : children}
      </Tag>
    )
  }
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  size: PropTypes.string,
  link: PropTypes.bool,
  className: PropTypes.string,
}

export default Button
