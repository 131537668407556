import { getUserTimeZone } from '../helpers/timeHelper'
import { API_BASE, API_BASE_V2, initHeaders, METHOD } from './constans'
import { setToken } from '../redux/actions/user.action'
import { userLogout } from '../redux/actions/auth'
import { getRefreshToken, isExpired } from './getApi'

//============ START GET ====================

export const customHeaders = {
  'X-User-Time-Zone': getUserTimeZone(),
  'X-User-Agent': 'web',
}

export const fetchData = async (url) => {
  const token = JSON.parse(localStorage.getItem('user')) || null

  const headers = {
    headers: {
      ...customHeaders,
      'Content-Type': 'application/json',
    },
  }
  if (token) {
    headers.headers['Authorization'] = `Bearer ${token.access_token}`
  }

  const response = await fetch(url, headers)
  return await response.json()
}

export const fetchHealthData = async (url) => {
  const headers = {
    headers: {
      ...customHeaders,
      'Content-Type': 'application/json',
    },
  }

  return await fetch(url, headers)
}

let controller = new AbortController()

export const getAuthRequestBase = async (url, access_token, signal = controller.signal) => {
  const token = JSON.parse(localStorage.getItem('user'))

  const res = await fetch(`${API_BASE}${url}`, {
    signal: signal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      ...customHeaders,
    },
  })

  if (res.status === 403 && token.isAdmin) {
    userLogout()
    window.location.href = '/seafarer-permission'
  }

  if (res.status === 401) {
    userLogout()
    window.location.reload()
  }

  if (!res.ok) {
    controller.abort()
  } else {
    return res
  }
}

export const getAuthRequestBaseV2 = async (url, access_token, signal) => {
  let abortController = new AbortController()
  const abortSignal = signal ? signal : abortController.signal

  const res = await fetch(`${API_BASE_V2}${url}`, {
    signal: abortSignal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      ...customHeaders,
    },
  })

  if (res.status === 401) {
    userLogout()
    window.location.reload()
  }

  if (!res?.ok) {
    abortController.abort()
  } else {
    return res
  }
}

export async function authGetRequest(url, signal) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    if (isExpired(token.expires_date)) {
      getRefreshToken(token.refresh_token)
        .then(async (data) => {
          if (data && data.ok) {
            setToken(data)
            window.location.reload()
          } else {
            userLogout()

            window.location.reload()
          }
        })
        .catch((error) => console.log(error))
    } else {
      return await getAuthRequestBase(url, token.access_token, signal)
        .then((data) => {
          if (data.ok) {
            return data
          } else {
            userLogout()
            window.location.reload()
          }
        })
        .catch((error) => console.log(error))
    }
  } else {
    userLogout()

    window.location.reload()
  }
}

export async function authGetRequestV2(url, signal) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    if (isExpired(token.expires_date)) {
      getRefreshToken(token.refresh_token)
        .then(async (data) => {
          if (data && data.ok) {
            setToken(data)
            window.location.reload()
          } else {
            userLogout()

            window.location.reload()
          }
        })
        .catch((error) => console.log(error))
    } else {
      return await getAuthRequestBaseV2(url, token.access_token, signal)
        .then((data) => {
          if (data.ok) {
            return data
          } else {
            userLogout()
            window.location.reload()
          }
        })
        .catch((error) => console.log(error))
    }
  } else {
    userLogout()

    window.location.reload()
  }
}

// END GET

// START POST

export const postRequest = async (url, data) => {
  const response = await fetch(`${API_BASE}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...customHeaders,
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  json.ok = response.ok

  return await json
}

export async function authPostRequest(url, data = {}) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(API_BASE + url, {
      method: 'POST',
      headers: {
        ...customHeaders,
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: data,
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export async function authPostRequestV2(url, data = {}) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(API_BASE_V2 + url, {
      method: 'POST',
      headers: {
        ...customHeaders,
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: data,
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export async function authPostFormData(url, data = {}) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(API_BASE + url, {
      method: 'POST',
      headers: {
        ...customHeaders,
        Authorization: `Bearer ${token.access_token}`,
      },
      body: data,
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export async function postFormData(url, data = {}) {
  const response = await fetch(API_BASE + url, {
    method: 'POST',
    body: data,
    headers: customHeaders,
  })
  const result = await response.json()

  result.ok = response.ok
  return result
}

// END POST

const getToken = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export const request = async (endpoint, options) => {
  const headers = options?.headers ?? initHeaders

  const token = getToken()

  if (token && !options?.withoutToken) {
    if (isExpired(token.expires_date)) {
      const response = await getRefreshToken(token.refresh_token)

      if (response.ok) {
        setToken(response)

        return await request(endpoint, options)
      } else {
        userLogout()

        window.location.href = '/'

        return
      }
    } else {
      headers.set('Authorization', `Bearer ${token.access_token}`)
    }
  }

  const init = {
    body: options?.body,
    method: options?.method || METHOD.GET,
    headers,
  }

  const domain = options?.api ? options.api : API_BASE

  try {
    const response = await fetch(`${domain}${endpoint}`, init)

    if (response.status === 401) {
      userLogout()

      window.location.href = '/'

      return
    }

    const data = await response.json()

    if (!response.ok) {
      return Promise.reject(data)
    }

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const upload = async (url, options) => {
  const formData = new FormData()

  formData.append('file', options?.file)

  if (options?.type) formData.append('type', options.type)

  return request(url, {
    body: formData,
    headers: new Headers(),
    method: options?.method || METHOD.POST,
  })
}
