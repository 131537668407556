import isArray from 'lodash/isArray'

export const sortOptionsPrettier = (obj) => {
  return Object.keys(obj)
    .filter((item) => obj[item] !== 0)
    .map((item) => {
      return obj[item] === 2 ? item : `-${item}`
    })
    .join(',')
}

export const reversDate = (date) => {
  if (date) return date.split('/').reverse().join('/')
}

export function getPageFromUrl(page) {
  return !isNaN(page) ? page : new URL(page).searchParams.get('page') || 1
}

export const getCodeFromUrl = () => {
  return new URL(window.location).searchParams.get('code')
}

export const getAppleIdTokenFromUrl = () => {
  if (window.location.href.includes('dev.nextship.net')) {
    const resultUrl = window.location.href.substr(0, 46) + '?' + window.location.href.substr(47)
    return new URL(resultUrl).searchParams.get('id_token')
  } else if (window.location.href.includes('stage.nextship.net')) {
    const resultUrl = window.location.href.substr(0, 48) + '?' + window.location.href.substr(49)
    return new URL(resultUrl).searchParams.get('id_token')
  } else {
    const resultUrl = window.location.href.substr(0, 42) + '?' + window.location.href.substr(43)
    return new URL(resultUrl).searchParams.get('id_token')
  }
}

export const getTokenFromUrl = () => {
  const searchUrl = new URL(window.location).searchParams
  return {
    uid: searchUrl.get('uid'),
    token: searchUrl.get('token'),
  }
}

export const getQuery = (query) => {
  const searchUrl = new URL(window.location).searchParams
  return searchUrl.get(query)
}

export const getUniqueCursorHelper = (url) => {
  if (!url) return null

  const query = new URLSearchParams(url.replaceAll('?', '&'))
  return query.get('cursor')
}

export const getUniquePageHelper = (url) => {
  if (!url) return null

  const query = new URLSearchParams(url.replaceAll('?', '&'))
  return query.get('page')
}

export const objToQueryConverter = (obj) => {
  return Object.keys(obj)
    .map((key) => {
      const title = key
      if (title === 'search') {
        return new URLSearchParams({ [title]: obj[key] })
      } else {
        const value = isArray(obj[key]) ? obj[key].join(',') : obj[key]
        return value ? title + '=' + value : 'empty'
      }
    })
    .filter((el) => el !== 'empty')
    .join('&')
}

export const queryToObjectConverter = (queryString) => {
  const params = new URLSearchParams(queryString)
  const result = {}

  for (const [key, value] of params.entries()) {
    if (key === 'search') {
      result[key] = value
    } else {
      result[key] = value.includes(',') ? value.split(',') : value
    }
  }

  return result
}

export function getAccessToken() {
  const token = JSON.parse(localStorage.getItem('user') || 'null')

  if (!token) return null

  return token?.access_token
}

export const getContactsHelper = (list) => {
  return list.map((item) => ({
    id: item.id,
    contact: item.contact,
    type: item.type,
    is_main: item.is_main,
    messengers: item.type.name === 'phone' ? item.messengers : [],
  }))
}

export const getContractsHelper = (list) => {
  return list.map((item) => ({
    country: item.country,
    city: item.city,
    crewing: item.crewing,
    date_from: item.date_from,
    date_to: item.date_to,
    description: item.description,
    engine_type: item.engine_type,
    experience_type: item.experience_type,
    files: item.files,
    id: item.id,
    imo: item.imo,
    rank: item.rank,
    reason_eoc: item.reason_eoc,
    salary: item.salary,
    salary_currency: item.salary_currency,
    shipowner: item.shipowner,
    vessel: item.vessel,
    vessel_name: item.vessel_name,
    vessel_type: item.vessel_type,
  }))
}

export const getListAuthEducationHelper = (list) => {
  return list.map((item) => ({
    city: item.city,
    city_alternative: item.city_alternative,
    city_place_id: item.city_place_id,
    college: item.college,
    college_place: item.college_place,
    country: item.country,
    created_at: item.created_at,
    date_from: item.date_from,
    date_to: item.date_to,
    files: item.files,
    degree: item.degree,
    description: item.description,
    field_of_study: item.field_of_study,
    id: item.id,
    updated_at: item.updated_at,
  }))
}

export const getRelativesContactsHelper = (list, count) => {
  return {
    count: count,
    results: list.map((item) => ({
      address: item.address,
      city: item.city,
      count: count,
      country: item.country,
      contacts: item.contacts,
      created_at: item.created_at,
      first_name: item.first_name,
      id: item.id,
      last_name: item.last_name,
      middle_name: item.middle_name,
      next_of_kin: item.next_of_kin,
      type: item.type,
      updated_at: item.updated_at,
      year_of_birth: item.year_of_birth,
      apartment: item.apartment,
      zip: item.zip,
    })),
  }
}

export const getNotificationsHelper = (list) => {
  return list.map((item) => ({
    date: item.date,
    event_keyword: item.event_keyword,
    event_link: item.event_link,
    id: item.id,
    is_archive: item.is_archive,
    is_read: item.is_read,
    message: item.message,
    notification: item.notification,
    is_checked: item.is_checked,
    notification_data: item.notification_data,
    notification_id: item.notification_id,
    notification_subscribe_status: item.notification_subscribe_status,
    type: item.type,
  }))
}

export const getProcessingDocumentsHelper = (list) => {
  return list.map((item) => ({
    file: item.file,
    file_mini: item.file_mini,
    file_name: item.file_name,
    id: item.id,
    is_accepted: item.is_accepted,
    is_rejected: item.is_rejected,
    quality_message: item.quality_message,
    quality_status: item.quality_status,
    rejected_reason: item.rejected_reason,
    type: item.type,
    type_id: item.type_id,
  }))
}

export const getAuthDocumentsHelper = (list) => {
  return list.map((item) => ({
    address: item.address,
    city: item.city,
    country: item.country,
    date_of_expire: item.date_of_expire,
    date_of_issue: item.date_of_issue,
    files: item.files.map((item) => ({
      file: item.file,
      file_mini: item.file_mini,
      file_name: item.file_name,
      id: item.id,
      is_accepted: item.is_accepted,
      is_rejected: item.is_rejected,
      quality_message: item.quality_message,
      quality_status: item.quality_status,
      rejected_reason: item.rejected_reason,
      type: item.type,
      type_id: item.type_id,
    })),
    first_name: item.first_name,
    id: item.id,
    issued_by: item.issued_by,
    last_name: item.last_name,
    middle_name: item.middle_name,
    number: item.number,
    type: item.type,
  }))
}

export const getAuthVisasHelper = (list) => {
  return list.map((item) => ({
    city: item.city,
    country: item.country,
    date_of_expire: item.date_of_expire,
    date_of_issue: item.date_of_issue,
    files: item.files.map((item) => ({
      file: item.file,
      file_mini: item.file_mini,
      file_name: item.file_name,
      id: item.id,
      is_accepted: item.is_accepted,
      is_rejected: item.is_rejected,
      quality_message: item.quality_message,
      quality_status: item.quality_status,
      rejected_reason: item.rejected_reason,
      type: item.type,
      type_id: item.type_id,
    })),
    id: item.id,
    number: item.number,
    type: item.type,
  }))
}

export const getAuthLicensesHelper = (list) => {
  return list.map((item) => ({
    city: item.city,
    country: item.country,
    date_of_expire: item.date_of_expire,
    date_of_issue: item.date_of_issue,
    endorsement: item.endorsement,
    files: item.files.map((item) => ({
      file: item.file,
      file_mini: item.file_mini,
      file_name: item.file_name,
      id: item.id,
      is_accepted: item.is_accepted,
      is_rejected: item.is_rejected,
      quality_message: item.quality_message,
      quality_status: item.quality_status,
      rejected_reason: item.rejected_reason,
      type: item.type,
      type_id: item.type_id,
    })),
    id: item.id,
    number: item.number,
    type: item.type,
  }))
}

export const getAuthCertificatesHelper = (list) => {
  return list.map((item) => ({
    city: item.city,
    country: item.country,
    date_of_expire: item.date_of_expire,
    date_of_issue: item.date_of_issue,
    files: item.files.map((item) => ({
      file: item.file,
      file_mini: item.file_mini,
      file_name: item.file_name,
      id: item.id,
      is_accepted: item.is_accepted,
      is_rejected: item.is_rejected,
      quality_message: item.quality_message,
      quality_status: item.quality_status,
      rejected_reason: item.rejected_reason,
      type: item.type,
      type_id: item.type_id,
    })),
    id: item.id,
    number: item.number,
    type: item.type,
  }))
}

export const getReviewsHelper = (list) => {
  return list.map((item) => ({
    image: item.image,
    id: item.review_id,
    name: item.name,
    rank: item.rank,
    vessel_name: item.vessel_name,
    date: item.date,
    category: item.category,
    created_time: item.created_time,
    start_date: item.start_date,
    end_date: item.end_date,
  }))
}

export const isImo = (str) => {
  const IMO_LENGTH = 5

  // imo should be 5 numbers
  return str.length === IMO_LENGTH && !isNaN(Number(str))
}

export const getCurrentDomain = () => {
  if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
    return `https://dev.`
  } else if (window.location.href.includes('stage')) {
    return `https://stage-`
  } else {
    return `https://`
  }
}

export const searchQueryConverter = (obj) => {
  const { search, type } = obj
  if (search.length > 2) {
    const clearValue = search.trim()
    return new URLSearchParams({ [type]: clearValue }).toString()
  } else {
    return ''
  }
}
