import React from 'react'
import { useDispatch } from 'react-redux'
import GeneralForm from './GeneralForm'
import Joyride from 'react-joyride'
import CustomJoyride from '../CustomJoyride/CustomJoyride'
import { intro, introStyles } from '../CustomJoyride/joyride.helper'
import { setUiPropThunk } from '../../redux/ui/ui.action'
import UserAvatarEditor from '../AvatarEditor/UserAvatarEditor/UserAvatarEditor'
import { getUiInfo } from '../../api/getApi'

export default function General() {
  const dispatch = useDispatch()
  const [joyride, setJoyride] = React.useState({
    run: false,
    steps: intro,
  })
  React.useEffect(() => {
    getUiInfo().then((data) => {
      setJoyride({ ...joyride, run: !data.trial_passed })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function joyrideCallback(event) {
    if (event.status === 'finished') {
      dispatch(setUiPropThunk({ trial_passed: true }))
    }
  }

  return (
    <>
      <Joyride
        callback={joyrideCallback}
        steps={joyride.steps}
        run={joyride.run}
        continuous
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        tooltipComponent={CustomJoyride}
        styles={introStyles}
      />
      <div className='general_forms profile-content'>
        <UserAvatarEditor />
        <GeneralForm />
      </div>
    </>
  )
}
