import React from 'react'

import styles from '../../styles.module.scss'

const ActivityTrackingActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>Activity Tracking service activated!</p>
      <p className={styles.description_item}>
        Now you can see the names of companies that have shown interest in your profile, viewed it or added it to
        favorites
      </p>
    </div>
  )
}

export default ActivityTrackingActivated
