import { useState, useEffect } from 'react'

function useOnline(options) {
  const [online, setOnline] = useState(navigator.onLine)

  function offlineHandler() {
    setOnline(false)
    options?.offlineSideEffect && options.offlineSideEffect()
  }

  function onlineHandler() {
    setOnline(true)
    options?.onlineSideEffect && options.onlineSideEffect()
  }

  useEffect(() => {
    setOnline(navigator.onLine)
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }

    // eslint-disable-next-line
  }, [])

  return online
}

export default useOnline
