import React, { useState } from 'react'
import styles from './vacancy.module.scss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import VacancyDetailsLabel from '../../../UI/VacancyDetailsLabel/VacancyDetailsLabel'
import AnimateHeight from 'react-animate-height'
import VacancyRightColumn from './VacancyComponents/VacancyRightColumn'
import VacancyFooter from './VacancyComponents/VacancyFooter'
import VacancyMainInfo from './VacancyComponents/VacancyMainInfo'
import ShareBlock from '../../ShareBlock/ShareBlock'
import { isDefaultVesselImg } from '../../../helpers/helpers'
import ImageComponent from '../../../UI/Image/ImageComponent'
import { StyledTooltip } from '../../../UI/Tooltip/Tooltip.config'

const Vacancy = ({ vacancy, appliedJobs, count, onClick, isOpen, refs, onAnimationEnd, isScrollList }) => {
  const [loading, setLoading] = useState(false)
  const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)

  const isAdmin = useSelector(({ authentication }) => authentication.user.isAdmin)

  const handleShareBlockClose = (e) => {
    e.stopPropagation()
    setIsShareBlockOpen(false)
  }
  return (
    <div
      ref={refs}
      className={cn(styles.vacancy, {
        [styles.row_is_scroll_list]: isScrollList,
      })}
      onClick={() => {
        if (isShareBlockOpen && isOpen) return
        setIsShareBlockOpen(false)
        onClick(vacancy.id, isOpen)
      }}
    >
      <div className={cn(styles.shareBlockWrapper, { [styles.shareBlockWrapper_open]: isShareBlockOpen && isOpen })}>
        <ShareBlock id={vacancy.id} onClose={handleShareBlockClose} isOpen={isShareBlockOpen && isOpen} />
      </div>
      <div className={styles.vacancyInfo}>
        <div
          className={cn(styles.vacancyInfo_block, { [styles.vacancyInfo_block_disabled]: isShareBlockOpen && isOpen })}
        >
          <div className={styles.vacancyFullInfo}>
            <div
              className={cn(styles.vacancyFullInfo, {
                [styles.header]: true,
              })}
            >
              <div
                className={cn(styles.vacancyField, {
                  [styles.rank]: true,
                })}
              >
                <p className={cn({ [styles.unviewedVacancy]: vacancy.early_access_flag })}>{vacancy.rank?.name}</p>

                {vacancy.urgent && <VacancyDetailsLabel label='urgent' urgent />}

                <VacancyDetailsLabel label='onboard job' />
              </div>

              <div className={styles.vacancyField_short}>
                <p>{vacancy.title}</p>
              </div>
            </div>

            <AnimateHeight
              style={{ flexShrink: 0, background: 'var(--color-white)', borderRadius: '8px' }}
              height={isOpen ? 'auto' : 0}
              onAnimationEnd={onAnimationEnd}
            >
              <div
                className={cn(styles.vacancyFullInfo, {
                  [styles.body]: true,
                })}
              >
                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Vessel name:</p>

                  <StyledTooltip
                    className={styles.tooltip}
                    placement={'bottom'}
                    // open={vacancy?.vessel?.name ?? false}
                    title={
                      <span>
                        <ImageComponent
                          src={vacancy.vessel?.mt_photo_mini_url}
                          alt='vassel'
                          className={cn({ [styles.vesselImg]: isDefaultVesselImg(vacancy.vessel?.mt_photo_mini_url) })}
                        />
                      </span>
                    }
                  >
                    <p className={cn({ [styles.vesselName]: vacancy.vessel.name })}>{vacancy?.vessel?.name || '—'}</p>
                  </StyledTooltip>
                </div>

                <VacancyMainInfo vacancy={vacancy} />
              </div>
              <div
                className={cn(styles.vacancyFullInfo, {
                  [styles.footer]: true,
                })}
              >
                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Crew:</p>
                  <p>{vacancy.vessel.crew_mixed ? 'Mix' : vacancy.vessel.crew_nationality?.name || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>DWT:</p>
                  <p>{vacancy.vessel.dwt || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Engine type:</p>
                  <p>{vacancy.vessel.engine_type?.name || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>GRT:</p>
                  <p>{vacancy.vessel.grt || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Build year:</p>
                  <p>{vacancy.vesselBuildYear || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>TEU:</p>
                  <p>{vacancy.vessel.teu || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Vessel flag:</p>
                  <p>{vacancy.vessel.flag?.name || '—'}</p>
                </div>

                <div className={styles.vacancyField}>
                  <p className={styles.vacancyFieldName}>Power:</p>
                  <p>{vacancy.power}</p>
                </div>
              </div>
            </AnimateHeight>
          </div>
          {!appliedJobs && (
            <VacancyRightColumn
              loading={loading}
              vacancy={vacancy}
              setLoading={setLoading}
              setIsShareBlockOpen={setIsShareBlockOpen}
              toOpenCard={onClick}
              isOpen={isOpen}
            />
          )}
        </div>
        <VacancyFooter
          vacancy={vacancy}
          loading={loading}
          setLoading={setLoading}
          isAdmin={isAdmin}
          appliedJobs={appliedJobs}
          count={count}
          isOpen={isOpen}
        />
      </div>
    </div>
  )
}

export default Vacancy
