import React, { useState } from 'react'

import styles from '../HeaderNav/HeaderNav.module.scss'

import { NavLink } from 'react-router-dom'
import LoginMobileInfo from './LoginMobileInfo'
import { Box, Modal } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const LogInMobile = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <div className={styles.listMobile}>
      <NavLink className={styles.item} to='/' onClick={handleOpen}>
        Log in
      </NavLink>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <LoginMobileInfo handleClose={handleClose} />
        </Box>
      </Modal>
    </div>
  )
}

export default LogInMobile
