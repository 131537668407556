const initialState = {
  list: [],
  notification_type: '',
  is_archive: false,
  events_counter_total: 0,
}

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        list: [...state.list, ...action.payload.map((item) => ({ ...item, is_checked: false }))],
      }

    case 'ADD_NOTIFICATION':
      return {
        ...state,
        list: [{ ...action.payload, is_checked: false }, ...state.list],
      }

    case 'SET_COUNTER_NOTIFICATION':
      return {
        ...state,
        events_counter_total: action.payload,
      }

    case 'SET_NOTIFICATION_CHECKED':
      return {
        ...state,
        list: state.list.map((item) => ({
          ...item,
          is_checked: item.id === action.payload ? !item.is_checked : item.is_checked,
        })),
      }

    case 'SET_ALL_NOTIFICATIONS_CHECKED':
      return {
        ...state,
        list: state.list.map((item) => ({
          ...item,
          is_checked: true,
        })),
      }

    case 'SET_ALL_NOTIFICATIONS_UNCHECKED':
      return {
        ...state,
        list: state.list.map((item) => ({
          ...item,
          is_checked: false,
        })),
      }

    case 'REMOVE_CHECKED_NOTIFICATIONS':
      return {
        ...state,
        list: state.list.filter((item) => !item.is_checked),
      }

    case 'FILTER_NOTIFICATIONS':
      if (action.payload === 'all') {
        return {
          ...state,
          notification_type: '',
          is_archive: false,
        }
      }
      return {
        ...state,
        notification_type: action.payload,
        is_archive: false,
      }

    case 'UPDATE_NOTIFY_STATE':
      const modifiedArray = state.list.reduce((acc, element) => {
        if (element.id === +action.payload) {
          const modifiedElement = {
            ...element,
            is_read: true,
          }
          acc.push(modifiedElement)
        } else {
          acc.push(element)
        }
        return acc
      }, [])
      return {
        ...state,
        list: modifiedArray,
      }

    case 'DELETE_NOTIFICATIONS':
      const newList = state.list.filter(({ id }) => !action.payload.includes(id))

      return {
        ...state,
        list: newList,
      }

    case 'CLEAR_NOTIFICATIONS':
      return {
        ...state,
        list: [],
      }

    default:
      return state
  }
}
