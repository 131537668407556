import React from 'react'

import styles from './fab.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'

import { ArrowDownSvg } from '../Icons/Icons'
import { ReactComponent as HeadphonesIcon } from './icons/headphones.svg'
import { ReactComponent as EnvelopeIcon } from '../../assets/icons/udelivered-convert.svg'

import { useSelector } from 'react-redux'

const Fab = ({ handleClick, isOpen, className, ...rest }) => {
  const { undelivered_count, work } = useSelector((state) => state.support)

  return (
    <button
      onClick={handleClick}
      className={cn(styles.button, { [styles.stopAnimation]: isOpen }, className)}
      {...rest}
    >
      {isOpen && <ArrowDownSvg height='11px' width='19px' fill='#fff' />}

      {Boolean(!isOpen && undelivered_count && work) ? (
        <div className={styles.envelope}>
          <EnvelopeIcon height={20} width={28} />
        </div>
      ) : (
        !isOpen && <HeadphonesIcon height={29} width={23} />
      )}
    </button>
  )
}

export default Fab

Fab.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
