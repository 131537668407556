import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from '../../../../helpers/notify'
import {
  getSeafarerExperience,
  getAuthProfileFilters,
  getAutocompleteImo,
  getAutocompleteShipCrew,
  getAutocompleteShipowner,
  getAutocompleteVessels,
  getAutocompleteCompanies,
  getGooglePlacesCities,
  getGooglePlacesUniversity,
} from '../../../../api/getApi'
import { postDocumentsFile, postSeafarerExperience } from '../../../../api/postApi'
import { putSeafarerExperience } from '../../../../api/putApi'
import { deleteSeafarerFile } from '../../../../api/deleteApi'
import { documentSubmitHelper, getDocumentHelper, isFile, isValidFile } from '../../../../helpers/documents.helper'
import { getMinDate, today } from '../../../../helpers/timeHelper'
import { setUserInfo } from '../../../../redux/actions/user.action'
import {
  addAuthDocument,
  editAuthDocument,
  editDocument,
  setDocument,
} from '../../../../redux/actions/documents.action'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import Input from '../../../../UI/Input/Input'
import Select from '../../../../UI/Select/Select'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import FileUploader from '../../../FileUploader/FileUploader'
import Button from '../../../../UI/Button/Button'
import KeyBoardDatePicker from '../../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import { confirm } from '../../../../UI/Confirm/Confirm'
import Spinner from '../../../../UI/Spinner/Spinner'
import { ReactComponent as AddFile } from '../../../../assets/icons/add_file_icon.svg'
import WidgetMarineTraffic from './WidgetMarineTraffic/WidgetMarineTraffic'
import { setIsSupportOpenedAction, setSupportMessageTextAction } from '../../../../redux/support/support.action'
import VesselAutocomplete from '../../../../UI/Select/VesselAutocomplete/VesselAutocomplete'
import useInfiniteScrollVessel from '../../../../hooks/useInfiniteScrollVessel'
import { getUniqueCursorHelper, getUniquePageHelper } from '../../../../helpers/apiHelper'
import { initialValuesForSea, initialValuesForLand, getValidationSchemaForSea } from './config'
import { isCompletelyFilled } from '../../../../helpers/objectsHelper'
import { isEmpty } from 'lodash'
import useInfiniteScrollAutocomplete from '../../../../hooks/useInfiniteScrollAutocomplete'
import moment from 'moment'

const extensions = ['.pdf', '.jpg', '.jpeg', '.png']

const ExperienceForm = ({
  currentDocumentId,
  setCurrentDocumentId,
  documentFiles,
  setDocumentFiles,
  fillFields,
  setFillFields,
  authDocuments,
}) => {
  const dispatch = useDispatch()
  const { date_format: dateFormat } = useSelector(({ user }) => user.settings)
  const [isButtonEnable, setIsButtonEnable] = useState(false)
  const [fetchedFilesLength, setFetchedFilesLength] = React.useState(0)
  const [autocompleteItems, setAutocompleteItems] = useState([])
  const [documentFilesForDeleting, setDocumentFilesForDeleting] = useState([])
  const [filters, setFilters] = useState({
    currencies: [],
    reasonsEOC: [],
    countries: [],
    ranks: [],
    locations: [
      { id: 1, name: 'sea' },
      { id: 2, name: 'land' },
    ],
  })
  const [MTWidget, setMTWidget] = useState({
    isOpened: false,
    initialStep: null,
  })
  const [initialCursor, setInitialCursor] = useState(null)
  const [initialPage, setInitialPage] = useState(null)
  const [initialPageShipowners, setInitialPageShipowners] = useState(null)
  const [initialPageCompanies, setInitialPageCompanies] = useState(null)
  const [initialPageShipCrew, setInitialPageShipCrew] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const [fromMinDate, setFromMinDate] = useState(getMinDate)
  const [isUpload, setIsUpload] = useState(false)
  const [initialValues, setInitialValues] = useState(initialValuesForSea)
  const [experienceType, setExperienceType] = useState('sea')
  const [isLoading, setIsLoading] = React.useState(false)

  const formik = useFormik({
    initialValues:
      currentDocumentId && initialValues
        ? initialValues
        : experienceType === 'sea'
        ? initialValuesForSea
        : initialValuesForLand,
    validationSchema: getValidationSchemaForSea(dateFormat, fromMinDate),
    validateOnChange: true,
    validateOnBlue: true,
    enableReinitialize: true,

    onSubmit: (document) => {
      setIsLoading(true)
      if (currentDocumentId) {
        editExperience(documentSubmitHelper(document, `experience-${experienceType}`), experienceType)
      } else {
        postExperience(documentSubmitHelper(document, `experience-${experienceType}`), experienceType)
      }
    },
  })

  const { values, touched, errors, dirty } = formik

  const resetForm = () => {
    formik.resetForm()
    setDocumentFiles([])
  }

  useEffect(() => {
    const fieldsToCheck = experienceType === 'sea' ? ['rank', 'date_from', 'vessel'] : ['rank', 'date_from']
    const completelyFilled = isCompletelyFilled(values, fieldsToCheck)
    if (values.year_of_build) {
      setFromMinDate(moment().set('year', values.year_of_build))
    }

    const isNewDocumentFilesObjects = documentFiles.every((obj) => 'id' in obj)
    const isFilesLengthChanged = fetchedFilesLength !== documentFiles.length

    if (currentDocumentId) {
      setIsButtonEnable(completelyFilled && (dirty || isFilesLengthChanged || !isNewDocumentFilesObjects))
    } else {
      setIsButtonEnable(completelyFilled)
    }

    // eslint-disable-next-line
  }, [experienceType, currentDocumentId, values, documentFiles, fetchedFilesLength])

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await getAuthProfileFilters('currency', 'reason_eoc', 'country', 'rank')

        const { currency, reason_eoc, country, rank } = response

        setFilters({
          ...filters,
          currencies: currency,
          reasonsEOC: reason_eoc,
          countries: country,
          ranks: rank,
        })
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getFilters()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentDocumentId) {
      const getExperience = async () => {
        try {
          setIsLoading(true)

          const resposne = await getSeafarerExperience(currentDocumentId)
          setFillFields(true)

          setExperienceType(resposne.experience_type)
          setInitialValues(getDocumentHelper(resposne, `experience-${resposne.experience_type}`))
          formik.resetForm()

          if (resposne.files.length) {
            setFetchedFilesLength(resposne.files.length)
            setDocumentFiles(
              resposne.files.map((file) => {
                return {
                  ...file,
                  name: file.file_name,
                }
              })
            )
          } else {
            setFetchedFilesLength(0)
          }
        } catch (error) {
          notify.errorsList(error.errors)
        } finally {
          setIsLoading(false)
        }
      }
      getExperience()
    } else {
      resetForm()
      setDocumentFilesForDeleting([])
      setFillFields(false)
    }

    // eslint-disable-next-line
  }, [currentDocumentId])

  const changeFiles = async (documentId, action, type = 'post') => {
    setIsUpload(true)

    const promises = []

    documentFiles.forEach((file) => {
      if (isFile(file)) {
        promises.push(postDocumentsFile(file, documentId, 'experience'))
      }
    })

    documentFilesForDeleting.forEach((file) => {
      promises.push(deleteSeafarerFile(file))
    })

    try {
      const response = await Promise.all(promises)
      const experience = await getSeafarerExperience(documentId)

      if (type === 'post') dispatch(action(experience))

      if (type === 'put') dispatch(action(experience.id, experience))

      if (response.some(({ errors }) => errors)) {
        response.forEach(({ errors }) => errors && notify.errorsList(errors))
      }
      //   const actionText = (() => {
      //     if (type === 'post') return 'added'
      //
      //     if (type === 'put') return 'updated'
      //
      //     return ''
      //   })()
      //
      //   notify('Success', `Your document was successfully ${actionText}`)
      // }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsUpload(false)
    }
  }

  const postExperience = async (document, type) => {
    try {
      const response = await postSeafarerExperience(document, type)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length) {
        await changeFiles(response.id, addAuthDocument)
      }

      if (!documentFiles.length) {
        dispatch(setDocument(getSeafarerExperience, response.id))
      }

      if (!authDocuments.length) {
        dispatch(setUserInfo())
      }

      resetForm()
      notify('Success', `Your document was successfully added`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const editExperience = async (document, type) => {
    try {
      const response = await putSeafarerExperience(document, currentDocumentId, type)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.length || documentFilesForDeleting.length) {
        await changeFiles(currentDocumentId, editAuthDocument, 'put')
      }

      if (!documentFiles.length || !documentFilesForDeleting.length) {
        dispatch(editDocument(getSeafarerExperience, currentDocumentId))
      }

      resetForm()

      setCurrentDocumentId(null)
      setDocumentFilesForDeleting([])
      notify('Success', `Your document was successfully updated`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputFileChange = ({ target }) => {
    const files = target.files
    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (documentFiles.length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          setDocumentFiles((prevState) => [...prevState, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      if (currentDocumentId) {
        if (deleteType === 'byId') {
          setDocumentFilesForDeleting((prevState) => [...prevState, id])
          setDocumentFiles((prevState) => prevState.filter((file) => file.id !== id))
        } else {
          setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
        }
      } else {
        setDocumentFiles((prevState) => prevState.filter((file, index) => index !== id))
      }
    })
  }

  const { handleScroll, nextCursor } = useInfiniteScrollAutocomplete({
    requestFunction: getAutocompleteVessels,
    setItems: setAutocompleteItems,
    search: formik?.values?.vessel?.name,
    initialPage,
  })

  const { handleScroll: handleScrollShipowners } = useInfiniteScrollAutocomplete({
    requestFunction: getAutocompleteShipowner,
    setItems: setAutocompleteItems,
    search: values?.shipowner?.name,
    initialPage: initialPageShipowners,
  })

  const { handleScroll: handleScrollCompanies } = useInfiniteScrollAutocomplete({
    requestFunction: getAutocompleteCompanies,
    setItems: setAutocompleteItems,
    search: values?.company?.name,
    initialPage: initialPageCompanies,
  })

  const { handleScroll: handleScrollShipCrew } = useInfiniteScrollAutocomplete({
    requestFunction: getAutocompleteShipCrew,
    setItems: setAutocompleteItems,
    search: values?.crewing?.name,
    initialPage: initialPageShipCrew,
  })

  const { handleScroll: handleScrollImo, nextCursor: nextCursorImo } = useInfiniteScrollVessel({
    setItems: setAutocompleteItems,
    name: values?.imo_search?.name,
    requestFunction: getAutocompleteImo,
    initialCursor,
  })

  const getDataForAutocomplete = (name, target) => {
    setInitialCursor(null)
    setInitialPage(null)
    setInitialPageShipowners(null)
    setInitialPageShipCrew(null)
    setAutocompleteItems([])

    if (!target.value && name === 'vessel') {
      setMTWidget({ isOpened: false, initialStep: null })
    }

    if (target.value.length > 2) {
      if (name === 'vessel') {
        getVessels(target.value)

        return
      }

      if (name === 'imo_search') {
        getVesselsByImo(target.value)

        return
      }

      if (name === 'shipowner') {
        getShipowner(target.value)

        return
      }

      if (name === 'crewing') {
        getShipCrew(target.value)
      }

      if (name === 'company') {
        getCompanies(target.value)
      }
    }
  }

  const getVessels = async (value) => {
    try {
      const response = await getAutocompleteVessels(1, value)

      setInitialPage(getUniquePageHelper(response?.next))

      setAutocompleteItems(response.results)

      openWidgetMt(response, value)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getShipowner = async (value) => {
    try {
      const response = await getAutocompleteShipowner(1, value)
      setInitialPageShipowners(getUniquePageHelper(response?.next))
      setAutocompleteItems(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getCompanies = async (value) => {
    try {
      const response = await getAutocompleteCompanies(1, value)
      setInitialPageCompanies(getUniquePageHelper(response?.next))
      setAutocompleteItems(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getShipCrew = async (value) => {
    try {
      const response = await getAutocompleteShipCrew(1, value)
      setInitialPageShipCrew(getUniquePageHelper(response?.next))
      setAutocompleteItems(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getVesselsByImo = async (value) => {
    try {
      const response = await getAutocompleteImo(value)

      setInitialCursor(getUniqueCursorHelper(response?.next))

      setAutocompleteItems(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getPlaces = (name, target) => {
    setAutocompleteItems([])
    if (target.value.length > 2) {
      if (name === 'city') {
        getGooglePlacesCities(target.value, values.country.iso2.toLowerCase()).then((cities) => {
          setAutocompleteItems(cities)
        })
      } else {
        getGooglePlacesUniversity(target.value, values.city.id, values.degree.name).then((degrees) => {
          setAutocompleteItems(degrees)
        })
      }
    }
  }

  const openWidgetMt = (data, value) => {
    if (data.results.length === 0 && value.length > 2) {
      setMTWidget({ isOpened: true, initialStep: null })

      return
    }

    setMTWidget({ isOpened: false, initialStep: null })
  }

  const handleChangeSelectValue = (name, value) => {
    if (name === 'location') {
      formik.resetForm()
      setExperienceType(value.name)
      if (currentDocumentId) setCurrentDocumentId(null)
    }

    if (name === 'country' && experienceType === 'land' && value.id !== values.country.id) {
      formik.setValues({
        ...values,
        city: { name: '', id: null },
      })
    }

    handleChangeValue({ target: { value, name } })
  }

  const handleChangeAutocompleteValue = (name, { target }) => {
    if (name === 'city') {
      getPlaces(name, target)
    }

    if (name === 'vessel') {
      formik.setValues({
        ...values,
        vesselType: '',
        engine_type: '',
        imo: '',
        year_of_build: null,
        shipowner: { name: '', id: null },
        country: { name: '', id: null },
      })
    }

    handleChangeValue(
      name === 'imo_search'
        ? {
            target: { value: { name: parseInt(target.value), id: null }, name },
          }
        : {
            target: { value: { name: target.value, id: null }, name },
          }
    )

    getDataForAutocomplete(name, target)
  }

  const handleClickAutocomplete = (type, data) => {
    if (type === 'vessel') {
      const vessel = autocompleteItems.filter((autocompleteItem) => autocompleteItem.id === parseInt(data.id))[0]
      formik.setValues({
        ...values,
        vesselType: vessel.vessel_type?.name,
        engine_type: vessel.engine_type?.name,
        imo: parseInt(vessel?.imo),
        imo_search: {
          ...values.imo_search,
          name: vessel?.imo,
        },
        shipowner: {
          name: vessel.owner?.name || '',
          id: vessel.owner?.id || null,
        },
        country: {
          name: vessel.owner?.country?.name,
          id: vessel.owner?.country?.id,
        },
        year_of_build: vessel.year_of_build,
      })
    }

    handleChangeValue({ target: { value: { ...values[type], ...data }, name: type } })

    if (type === 'imo_search') {
      const imo = autocompleteItems.filter((autocompleteItems) => autocompleteItems.id === parseInt(data.id))[0]
      formik.setValues({
        ...values,
        vesselType: imo?.vessel_type?.name,
        engine_type: imo?.engine_type?.name,
        imo_search: {
          name: imo?.imo,
          id: imo?.imo?.id || null,
        },
        imo: imo?.imo,
        shipowner: {
          name: imo?.owner?.name || '',
          id: imo?.owner?.id || null,
        },
        country: {
          name: imo?.owner?.country?.name || '',
          id: imo?.owner?.country?.id || null,
        },
        vessel: {
          name: imo?.name || '',
          id: imo?.id || null,
        },
        crewing: {
          name: imo?.crewing?.name || '',
          id: imo?.crewing?.id || null,
        },
      })
    }

    setAutocompleteItems([])
  }

  const handleFocusAutoComplete = (name, { target }) => {
    getDataForAutocomplete(name, target)
  }

  const handleMtError = (step) => {
    if (step) {
      setMTWidget({ isOpened: true, initialStep: step })
      setAutocompleteItems([])
      setInitialCursor(null)
    } else {
      dispatch(setIsSupportOpenedAction(true))
      dispatch(
        setSupportMessageTextAction(
          `Hi!\nI need help to finding a vessel name "${values.vessel?.name}" when I try to add my experience.\nThanks!`
        )
      )
      setMTWidget({ isOpened: false, initialStep: null })
    }
  }

  const handleMtSuccess = (mtData) => {
    setInitialCursor(null)
    setAutocompleteItems([])

    setAutocompleteItems(mtData.results)

    setIsOpen(true)
    setMTWidget({ isOpened: false, initialStep: null })
  }

  const handleChangeValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const isRequest = isLoading || isUpload
  const isDisabled = isLoading || !fillFields || isUpload

  return (
    <form
      className={cn(styles.form, {
        [styles.disabled]: isDisabled,
      })}
      onSubmit={formik.handleSubmit}
    >
      {isRequest && (
        <div className={styles.loadingContainer}>
          <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
        </div>
      )}

      <div className={styles.form_fields}>
        <div>
          <SelectSearch
            label='Location'
            name='location'
            id={values.location.id}
            items={filters.locations}
            selectedItem={values.location.name}
            onClick={handleChangeSelectValue}
            defaultName={null}
            disabled={!fillFields || !!currentDocumentId}
            required
          />

          {/*{false && <p className={styles.formItemError}>{errors.rank?.id}</p>}*/}
        </div>
        <div>
          <SelectSearch
            label='Rank'
            name='rank'
            id={values.rank.id}
            items={filters.ranks}
            selectedItem={values.rank.name}
            onClick={handleChangeSelectValue}
            isInvalid={!!(errors.rank?.id && touched.rank)}
            disabled={!fillFields}
            required
          />

          {errors.rank?.id && touched.rank && <p className={styles.formItemError}>{errors.rank?.id}</p>}
        </div>

        <div className={styles.formRow}>
          <KeyBoardDatePicker
            formik={formik}
            keyBoardLabel='From'
            value={values.date_from ?? null}
            nameOfValue='date_from'
            minDate={fromMinDate}
            maxDate={today}
            isInvalid={!!(errors.date_from || (errors.date_from && touched.date_from))}
            errorMessage={errors.date_from}
            today={true}
            disabled={!fillFields}
            required
          />
          <KeyBoardDatePicker
            formik={formik}
            keyBoardLabel='Till'
            value={values.date_to}
            nameOfValue='date_to'
            minDate={moment(values.date_from) || today}
            isInvalid={!!(errors.date_to || (errors.date_to && touched.date_to))}
            errorMessage={errors.date_to}
            disabled={!fillFields}
            today={true}
          />
        </div>

        {values.location.name === 'sea' && (
          <div className={styles.vesselField}>
            <VesselAutocomplete
              label='Vessel name'
              name='vessel'
              getTitle='name'
              items={autocompleteItems}
              value={values.vessel?.name || ''}
              vesselId={values.vessel?.id}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              handleScroll={handleScroll}
              onSuccess={handleMtSuccess}
              onError={handleMtError}
              cursor={nextCursor}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              dateRange={{ from: values.date_from, to: values.date_to }}
              isInvalid={!!(errors?.vessel?.id && touched?.vessel)}
              errorMessage={errors.vessel?.id}
              disabled={!fillFields}
              required
            />

            {errors?.vessel?.id && touched?.vessel && <p className={styles.formItemError}>{errors.vessel.id}</p>}

            {MTWidget.isOpened && (
              <WidgetMarineTraffic
                onError={handleMtError}
                onSuccess={handleMtSuccess}
                value={values.vessel?.name}
                stepErr={MTWidget.initialStep}
                setMTWidget={setMTWidget}
              />
            )}
          </div>
        )}

        {values.location.name === 'sea' && (
          <div>
            <Autocomplete
              onClick={handleClickAutocomplete}
              onChange={handleChangeAutocompleteValue}
              onFocus={handleFocusAutoComplete}
              label='IMO'
              name='imo_search'
              handleScroll={handleScrollImo}
              cursor={nextCursorImo}
              value={values?.imo_search?.name || ''}
              items={autocompleteItems}
              getTitle='name'
              disabled={!fillFields}
            />
            {errors.imo_search?.name && <p className={styles.formItemError}>{errors.imo_search?.name}</p>}
          </div>
        )}

        {values.location.name === 'sea' && (
          <div>
            <Input label='Vessel type' name='vesselType' value={values.vesselType || ''} onChange={() => {}} disabled />
          </div>
        )}

        {values.location.name === 'sea' && (
          <div>
            <Input
              label='Engine type'
              name='engine_type'
              value={values.engine_type || ''}
              onChange={() => {}}
              disabled
            />
          </div>
        )}

        {values.location.name === 'land' && (
          <div>
            <Autocomplete
              label='Company'
              name='company'
              getTitle='name'
              items={autocompleteItems}
              value={values.company?.name || ''}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              handleScroll={handleScrollCompanies}
              isInvalid={!!(errors?.company?.id && touched?.company)}
              disabled={!fillFields}
            />

            {errors?.company?.id && touched?.company && <p className={styles.formItemError}>{errors.company.id}</p>}
          </div>
        )}

        {values.location.name === 'land' && (
          <div>
            <Autocomplete
              autoComplete={'off'}
              label='City'
              name='city'
              placeholder='City'
              items={autocompleteItems}
              value={values?.city?.name || ''}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              isInvalid={!!(errors?.city?.id && touched?.city)}
              disabled={!fillFields || !values?.country?.id}
            />

            {errors?.city?.id && touched?.city && <p className={styles.formItemError}>{errors.city.id}</p>}
          </div>
        )}

        {values.location.name === 'sea' ? (
          <div>
            <Autocomplete
              label='Ship/Crew Manager'
              name='crewing'
              getTitle='name'
              items={autocompleteItems}
              value={values.crewing.name}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              handleScroll={handleScrollShipCrew}
              isInvalid={!!(errors?.crewing?.id && touched?.crewing)}
              disabled={!fillFields}
            />

            {errors?.crewing?.id && touched?.crewing && <p className={styles.formItemError}>{errors.crewing.id}</p>}
          </div>
        ) : (
          <div>
            <SelectSearch
              label='Country'
              name='country'
              id={values.country.id}
              items={filters.countries}
              selectedItem={values.country.name}
              onClick={handleChangeSelectValue}
              disabled={!fillFields}
            />
          </div>
        )}
        <Input name='imo' value={values.imo || ''} type='hidden' />
      </div>
      <div className={styles.form_fields}>
        {values.location.name === 'sea' ? (
          <div>
            <Autocomplete
              label='Shipowner'
              name='shipowner'
              getTitle='name'
              items={autocompleteItems}
              value={values.shipowner?.name || ''}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              handleScroll={handleScrollShipowners}
              isInvalid={!!(errors?.shipowner?.id && touched?.shipowner)}
              disabled={!fillFields}
            />

            {errors?.shipowner?.id && touched?.shipowner && (
              <p className={styles.formItemError}>{errors.shipowner.id}</p>
            )}
          </div>
        ) : (
          <div>
            <Autocomplete
              label='Ship/Crew Manager'
              name='crewing'
              getTitle='name'
              items={autocompleteItems}
              value={values.crewing.name}
              onChange={handleChangeAutocompleteValue}
              onClick={handleClickAutocomplete}
              onFocus={handleFocusAutoComplete}
              handleScroll={handleScrollShipCrew}
              isInvalid={!!(errors?.crewing?.id && touched?.crewing)}
              disabled={!fillFields}
            />

            {errors?.crewing?.id && touched?.crewing && <p className={styles.formItemError}>{errors.crewing.id}</p>}
          </div>
        )}

        {values.location.name === 'sea' && (
          <div>
            <SelectSearch
              label='Shipowner`s country'
              name='country'
              id={values.country.id}
              items={filters.countries}
              selectedItem={values.country.name}
              onClick={handleChangeSelectValue}
              disabled={!fillFields}
            />
          </div>
        )}

        <div className={styles.formRow}>
          <div>
            <Input
              label='Wage'
              name='wage'
              value={values.wage || ''}
              onChange={handleChangeValue}
              isInvalid={!!(errors.wage && touched.wage)}
              disabled={!fillFields}
              required={values.currency?.id}
            />

            {errors.wage && touched.wage && <p className={styles.formItemError}>{errors.wage}</p>}
          </div>

          <div>
            <Select
              label='Currency'
              name='currency'
              id={values.currency?.id}
              items={filters.currencies}
              selectedItem={values.currency.name}
              onClick={handleChangeSelectValue}
              isInvalid={!!(errors.currency?.id && touched.currency)}
              disabled={!fillFields}
              required={!isEmpty(values.wage)}
            />

            {errors.currency?.id && touched.currency && <p className={styles.formItemError}>{errors.currency?.id}</p>}
          </div>
        </div>

        <div>
          <Select
            label='Reason EOC'
            name='reasonEOC'
            id={values.reasonEOC.id}
            items={filters.reasonsEOC}
            selectedItem={values.reasonEOC.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFields}
          />
        </div>

        <div
          className={cn(styles.additional_info_wrapper, {
            [styles.additional_info_wrapper_land]: values.location.name === 'land',
          })}
        >
          <Input
            label='Additional info'
            name='description'
            value={values.description || ''}
            onChange={handleChangeValue}
            disabled={!fillFields}
            className={cn(styles.additional_info, {
              [styles.additional_info_land]: values.location.name === 'land',
            })}
            textarea
          />
        </div>
        <div className={styles.experience_file_uploader}>
          <FileUploader
            name='experience'
            text='Add scan'
            theme='attachFile'
            icon={<AddFile />}
            files={documentFiles}
            onChange={handleInputFileChange}
            onDelete={handleInputFileDelete}
            isUpload={isUpload}
          />
        </div>
      </div>

      <Button className={styles.work_experience_save} type='submit' size='middle-full' disabled={!isButtonEnable}>
        Save
      </Button>
    </form>
  )
}

export default ExperienceForm
