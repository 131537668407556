import React from 'react'
import styles from './replyMessage.module.scss'
import cn from 'classnames'

const ReplyMessage = ({ message, isSupport, isOutgoing }) => {
  const name = message.user?.name?.split(' ')[1] || 'No name'

  return (
    <p className={cn(styles.reply_message, { [styles.reply_message__support]: isSupport && isOutgoing })}>
      <b>{name}</b>

      <span>{message.message}</span>
    </p>
  )
}

export default ReplyMessage
