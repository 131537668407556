import React from "react";
import PropTypes from "prop-types";

const EnvelopeSvg = ({ color }) => {
  return (
    <svg width="132" height="157" fill="none" viewBox="0 0 132 157">
      <path
        fill="#fff"
        d="M23 2v65.5L57.5 88l8-2 9 2L114 65l1-42.379L93.885 2H23z"
      />
      <path
        fill={color}
        d="M1.015 131.576h129.94c.406 0 1.015-.405 1.015-1.012V55.059c0-.404-.203-.81-.406-.81l-16.243-9.31V22.266c0-.203 0-.405-.203-.608L93.8.202C93.597 0 93.394 0 92.988 0H28.424c-3.857 0-7.106 3.239-7.106 7.085v35.02L.406 54.25c-.203.202-.406.404-.406.81v75.504c0 .607.61 1.012 1.015 1.012zm55.427-43.117L2.03 128.54V56.881L56.442 88.46zM4.061 129.552l55.224-40.688c4.06-2.834 9.34-2.834 13.197 0 22.536 16.599 32.891 24.291 55.224 40.688H4.061zm125.878-1.013l-54.412-40.08 54.412-31.578v71.658zm-1.015-73.48l-13.603 7.895v-15.79l13.603 7.895zM94.003 3.441l17.867 17.814H94.003V3.44zM28.424 2.024h63.549v20.243c0 .607.406 1.012 1.015 1.012h20.303V64.37L73.7 87.245c-4.67-3.441-10.76-3.441-15.43 0L23.348 67.002V7.085c0-2.834 2.234-5.06 5.076-5.06zm-7.106 63.764L3.045 55.059l18.273-10.728v21.457z"
      />
      <path
        fill={color}
        d="M42.636 32.388h51.367c.609 0 1.015-.405 1.015-1.013 0-.607-.406-1.012-1.015-1.012H42.636c-.609 0-1.015.405-1.015 1.012 0 .608.406 1.013 1.015 1.013zM42.636 43.521h51.367c.609 0 1.015-.404 1.015-1.012 0-.607-.609-1.012-1.015-1.012H42.636c-.609 0-1.015.405-1.015 1.012 0 .608.406 1.012 1.015 1.012zM42.636 54.654h51.367c.609 0 1.015-.405 1.015-1.012s-.406-1.012-1.015-1.012H42.636c-.609 0-1.015.405-1.015 1.012s.406 1.012 1.015 1.012zM42.636 65.788h51.367c.609 0 1.015-.405 1.015-1.012 0-.608-.609-1.012-1.015-1.012H42.636c-.609 0-1.015.404-1.015 1.012 0 .607.406 1.012 1.015 1.012zM89.539 117.406h13.806c.609 0 1.015-.405 1.015-1.012s-.406-1.012-1.015-1.012H89.539c-.61 0-1.016.405-1.016 1.012s.61 1.012 1.016 1.012zM89.539 122.061h17.866c.609 0 1.015-.405 1.015-1.012s-.406-1.012-1.015-1.012H89.539c-.61 0-1.016.405-1.016 1.012s.61 1.012 1.016 1.012zM103.544 124.693H89.738c-.61 0-1.015.405-1.015 1.012 0 .608.406 1.013 1.015 1.013h13.806c.609 0 1.015-.405 1.015-1.013 0-.607-.406-1.012-1.015-1.012z"
      />
      <ellipse cx="65.5" cy="151" fill={color} opacity="0.1" rx="47.5" ry="6" />
    </svg>
  );
};

EnvelopeSvg.propTypes = {
  color: PropTypes.string.isRequired,
};

export default EnvelopeSvg;
