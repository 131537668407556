import { getAuthMe, getProfilePicture, getSettings, isExpired, getRefreshToken } from '../../api/getApi'
import { userLogout } from './auth'
import { updateUiInfoThunk } from '../ui/ui.action'
import { notify } from '../../helpers/notify'
import { getBillingSettingsData } from '../billing/billing.action'

export const getProfileFetch = (scope = 'seafarer') => (dispatch) => {
  const token = JSON.parse(localStorage.getItem('user'))

  dispatch(getBillingSettingsData())

  if (token) {
    if (isExpired(token.expires_date)) {
      getRefreshToken(token.refresh_token).then((data) => {
        if (data.ok) {
          dispatch(setToken(data))
          getAuthMe(scope).then((profile) => dispatch(getUserInfo(profile)))
        } else {
          dispatch(userLogout())
        }
      })
    } else {
      getAuthMe(scope).then((data) => {
        dispatch(getUserInfo(data))
      })
      dispatch(updateSettings(scope))
      dispatch(updateUiInfoThunk())
    }
  } else {
    dispatch(userLogout())
  }
}

export const setToken = (data) => {
  data.expires_date = new Date().getTime() + data.expires_in * 1000
  localStorage.setItem('user', JSON.stringify(data))

  return {
    type: 'SET_TOKEN',
    payload: data,
  }
}

export const setAdmin = (data) => {
  return {
    type: 'SET_ADMIN',
    payload: { data },
  }
}

export const getProfilePictureFetch = (id, type) => {
  return (dispatch) => {
    getProfilePicture(id, type)
      .then((data) => {
        return data.json()
      })
      .then((data) => {
        dispatch(changeProfilePicture(data))
      })
  }
}

export const updateSettings = (scope) => {
  return (dispatch) =>
    getSettings(scope).then((data) => {
      localStorage.setItem('settings', JSON.stringify(data))
      dispatch(getUserSettings(data))
    })
}

export const changeProfilePicture = (payload) => {
  return {
    type: 'CHANGE_PROFILE_PICTURE',
    payload,
  }
}

export const getUserInfo = (payload) => {
  return {
    type: 'GET_USER_INFO',
    payload,
  }
}

export const getUserSettings = (payload) => {
  return {
    type: 'GET_USER_SETTINGS',
    payload,
  }
}

export const setLoginEmail = (payload) => {
  return {
    type: 'SET_LOGIN_EMAIL',
    payload,
  }
}

export const setUserInfo = (scope = 'seafarer') => async (dispatch) => {
  try {
    const response = await getAuthMe(scope)

    dispatch(getUserInfo(response))
  } catch (error) {
    notify.errorsList(error.errors)
  }
}
