import React from 'react'
import cn from 'classnames'
import checkSvg from '../../assets/icons/check.svg'
import dangerSvg from '../../assets/icons/danger.svg'
import infoSvg from '../../assets/icons/info.svg'
import styles from './styles.module.scss'
import closeSvg from '../../assets/icons/close.svg'
import { notify } from '../../helpers/notify'

export const showAlert = (text, alert, data) => {
  if (data.ok) {
    notify('Success', text)
  } else {
    data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
  }
}

const AlertTemplate = ({ style, options, message, close }) => {
  let statusIcon
  if (options.type === 'error') statusIcon = checkSvg
  if (options.type === 'success') statusIcon = dangerSvg
  if (options.type === 'info') statusIcon = infoSvg

  return (
    <div
      className={cn(
        styles.alert,
        { [styles.error]: options.type === 'error' },
        { [styles.info]: options.type === 'info' },
        { [styles.success]: options.type === 'success' }
      )}
      style={style}
    >
      <div className={styles.wrapper}>
        <div className={styles.statusIcon}>
          <img src={statusIcon} alt='success' />
        </div>
        <div>
          <div className={styles.title}> {options.type} </div>
          <div className={styles.message}> {message} </div>
        </div>
        <button className={styles.button} onClick={close}>
          <img src={closeSvg} alt='close button' />
        </button>
      </div>
    </div>
  )
}

export default AlertTemplate
