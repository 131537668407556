import * as Yup from 'yup'
import { getMinDate, today } from '../../../helpers/timeHelper'

export const initialValues = {
  type: { name: '', id: null },
  first_name: '',
  last_name: '',
  middle_name: '',
  country: { name: '', id: null },
  city: { name: '', id: null },
  next_of_kin: false,
  address: { name: '', id: null },
  year_of_birth: null,
  contact_type: { name: '', id: null },
  contact: '',
  sec_contact_type: { name: '', id: null },
  sec_contact: '',
  zip: '',
  apartment: '',
}

export const getValidationSchema = (dateFormat) => {
  return Yup.object().shape({
    type: Yup.object().shape({
      name: Yup.string().required(),
      id: Yup.number().required().positive().integer(),
    }),
    country: Yup.object().shape({
      name: Yup.string().required(),
      id: Yup.number().required().positive().integer(),
    }),
    first_name: Yup.string().trim().required('Field is required'),
    last_name: Yup.string().trim().required('Field is required'),
    contact: Yup.string().trim().required('Field is required'),
    year_of_birth: Yup.date()
      .nullable()
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .max(today, 'Year of birth cannot be more than today')
      .min(getMinDate, 'Your relative cannot be adult more than 100 years old'),
  })
}
