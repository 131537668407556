import React from 'react'
import styles from '../UserCard.module.scss'
import VideosResumeItem from './VideoResumeItem/VideosResumeItem'
import { ReactComponent as NoInfoImage } from '../../../assets/images/video_resume_stub.svg'

const VideoResume = ({ userCardData }) => {
  const { videoUrl } = userCardData

  if (!videoUrl || !Object.keys(videoUrl).length) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={460} height={260} />
        <p>Video resume has not been uploaded yet.</p>
      </div>
    )
  } else {
    return (
      <div className={styles.videoResumeContainer}>
        <VideosResumeItem videoUrl={videoUrl} />
      </div>
    )
  }
}

export default VideoResume
