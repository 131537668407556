import React from 'react'

import PopupRenameVesselName from './PopupRenameVesselName/PopupRenameVesselName'

export const getSteps = (onClick, dateRange, vesselId) => {
  return [
    {
      target: '#viewJoyride',
      content: <PopupRenameVesselName onCloseClick={onClick} dateRange={dateRange} vesselId={vesselId} />,
      placement: 'right',
      disableBeacon: true,
      hideFooter: true,
      spotlightPadding: 0,
    },
  ]
}

export const joyrideStyles = {
  options: {
    backgroundColor: 'none',
    arrowColor: 'none',
  },

  buttonClose: {
    display: 'none',
  },

  tooltipContent: {
    textAlign: 'initial',
  },
}
