import React, { useRef, useEffect } from 'react'

import './styles.scss'
import stylesForm from '../../components/Documents/form.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'

import closeSvg from '../../assets/icons/close.svg'

const Input = ({
  field,
  textarea,
  autoHeight,
  focus,
  clear,
  isInvalid,
  form,
  theme = 'default',
  label,
  required,
  disabled,
  errorMessage = '',
  className = '',
  ...rest
}) => {
  const Tag = textarea ? 'textarea' : 'input'

  const inputRef = useRef(null)

  useEffect(() => {
    focus && inputRef.current.focus()
  }, [focus])

  return (
    <div className={cn('field-wrapper', `field-wrapper-${theme}`, { disabled: disabled })}>
      {label && (
        <label
          className={cn(`input-label input-label-${theme}`, className, className?.label, {
            input_label_invalid: isInvalid,
            input_label_disabled: disabled,
          })}
        >
          {label} {required && <span className='required-star'>*</span>}
        </label>
      )}

      <div className='input_wrapper'>
        <Tag
          ref={inputRef}
          className={cn(`input input-${theme}`, className?.input, className, { input_invalid: isInvalid })}
          disabled={disabled}
          autoComplete={'off'}
          {...field}
          {...rest}
          value={rest.value ?? ''}
        />

        {isInvalid && errorMessage && !disabled && <p className={stylesForm.formItemError}>{errorMessage}</p>}

        {clear && (
          <button
            type='button'
            className={cn('input__close-button', { 'input__close-hidden': !rest.value })}
            onClick={() => clear('')}
          >
            <img src={closeSvg} alt='clear input value' />
          </button>
        )}
      </div>
    </div>
  )
}

Input.propTypes = {
  theme: PropTypes.string,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Input
