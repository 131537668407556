export const expressCheckoutOptions = {
  buttonTheme: {
    applePay: 'white-outline',
    googlePay: 'white',
  },
  buttonType: {
    googlePay: 'subscribe',
    applePay: 'subscribe',
  },
  layout: {
    overflow: 'never',
  },
  paymentMethods: {
    applePay: 'always',
    googlePay: 'always',
    link: 'never',
  },
  // Height in pixels. Defaults to 44. The width is always '100%'.
  buttonHeight: 40,
}
