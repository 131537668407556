import * as Yup from 'yup'
import { getDateByUserFormat, getMinDate, today } from '../../../../helpers/timeHelper'

export const initialValues = {
  type: { name: '', id: null },
  country: { name: '', id: null },
  firstName: '',
  lastName: '',
  middleName: '',
  date_of_issue: null,
  date_of_expire: null,
  number: '',
  street: { name: '', id: null },
  city: { name: '', id: null },
  issuedBy: '',
}

export const getValidationSchema = (dateFormat, passportFields) => {
  return Yup.object().shape({
    type: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    firstName: Yup.string().trim().required('Field is required'),
    lastName: Yup.string().trim().required('Field is required'),
    number: Yup.string().trim().required('Field is required'),
    country: Yup.object().shape({
      id: Yup.number().required('Field is required').nullable(),
    }),
    city: Yup.object().shape({
      id: Yup.mixed().test('Check city', 'Select city from list', function (value) {
        const { name } = this.parent

        return name ? Boolean(value) : true
      }),
    }),
    issuedBy: Yup.string().when('country', (value) => {
      if (passportFields.includes(value.id)) {
        return Yup.string().trim().required('Field is required')
      } else {
        return Yup.string()
      }
    }),
    date_of_issue: Yup.date()
      .min(getMinDate, `Min date is ${getDateByUserFormat(getMinDate)}`)
      .max(today, `Max date is ${getDateByUserFormat(today)}`)
      .typeError(`Invalid date format, valid - ${dateFormat}`)
      .required('Field is required'),
    date_of_expire: Yup.date().typeError(`Invalid date format, valid - ${dateFormat}`).nullable(),
  })
}
