import React, { useCallback, useState, memo, useMemo } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Flipper, Flipped } from 'react-flip-toolkit'
import styles from './styles.module.scss'

import { confirm } from '../../../UI/Confirm/Confirm'
import { Header } from './Header/Header'
import AsideItem from './AsideItem/AsideItem'
import Spinner from '../../../UI/Spinner/Spinner'

import { deleteSelected, setIsDialogChecked } from '../../../redux/actions/chat.action'
import socket from '../../../websocket'
import { events, profilePaths } from '../../../constants/constans'

export const ChatAside = memo(({ chat_id }) => {
  const [search, setSearch] = useState('')

  const dialogs = useSelector(({ chat }) => chat.dialogs)

  const dispatch = useDispatch()

  const location = useLocation()
  const history = useHistory()

  const { chat } = events

  const allCheckedIds = useMemo(() => dialogs.list.filter((el) => el.is_selected).map((el) => el.chat_id), [
    dialogs.list,
  ])
  const list = useMemo(() => {
    return dialogs.list.filter(
      (el) =>
        el.company_name?.toLowerCase().includes(search.toLowerCase()) ||
        el.user_name?.toLowerCase().includes(search.toLowerCase())
    )
  }, [dialogs.list, search])

  const handleCheckboxClick = useCallback((id, e) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(setIsDialogChecked(id))

    // eslint-disable-next-line
  }, [])

  const onDelete = useCallback(() => {
    if (allCheckedIds.length) {
      confirm('Are you sure?', `Do you really want to delete selected chats?`, () => {
        socket.emit(chat.delete, { chat_ids: allCheckedIds })

        dispatch(deleteSelected())

        if (allCheckedIds.includes(Number(chat_id))) {
          history.push(profilePaths.chat)
        }
      })
    }

    // eslint-disable-next-line
  }, [history, allCheckedIds, chat_id])

  return (
    <div className={styles.aside}>
      <Header dialogs={dialogs} onDelete={onDelete} search={search} setSearch={setSearch} />

      <div className={styles.list}>
        {dialogs.loading && <Spinner />}

        {!dialogs.loading && (
          <Flipper>
            {list.map((item) => {
              const url = `${profilePaths.chat}/${item.chat_id}`

              return (
                <Flipped key={item.chat_id} flipId={item.chat_id} stagger>
                  <AsideItem
                    id={item.chat_id}
                    onCheck={handleCheckboxClick}
                    isActive={url === location.pathname}
                    isOnline={item.is_online}
                    isSelected={item.is_selected}
                    picture={item.picture}
                    user_name={item.user_name}
                    company_name={item.company_name}
                    unread={item.total_undelivered}
                    lastMessage={item.last_message}
                    url={url}
                  />
                </Flipped>
              )
            })}
          </Flipper>
        )}
      </div>
    </div>
  )
})
