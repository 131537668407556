import { ReactComponent as FaceBookIcon } from './icons/facebook_circle_logo.svg'
import { ReactComponent as WhatsappIcon } from './icons/whatsapp_circle_logo.svg'
import { ReactComponent as TelegramIcon } from './icons/telegram_circle_logo.svg'
import { ReactComponent as ViberIcon } from './icons/viber_circle_logo.svg'
import { ReactComponent as GmailIcon } from './icons/gmail_circle_logo.svg'

export const getLinks = (id) => {
  const link = encodeURIComponent(`${window.location.origin}/vacancy/?id=${id}`)

  return [
    { Icon: FaceBookIcon, link: `https://www.facebook.com/sharer/sharer.php?display=popup&sdk=joey&u=${link}` },
    { Icon: WhatsappIcon, link: `https://api.whatsapp.com/send/?text=${link}&type=${link}&app_absent=0` },
    { Icon: TelegramIcon, link: `https://t.me/share/url?url=${link}` },
    { Icon: ViberIcon, link: `viber://forward?text=${link}` },
    { Icon: GmailIcon, link: `mailto:?subject=Тема&body=${link}` },
  ]
}
