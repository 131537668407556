import { VACANCIES } from '../types'

const initialState = {
  vacancies: [],
  filters: {
    vessel_type: null,
    rank: null,
    joinDate: null,
    salary: null,
    salary_rate: null,
    salary_currency: null,
    duration: null,
    duration_period: null,
    show_sea: true,
    show_land: true,
  },
  vacanciesLanding: [],
  appliedJobs: [],
  jobsCount: null,
}

export default function vacanciesReducer(state = initialState, action) {
  switch (action.type) {
    case VACANCIES.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      }
    }

    case VACANCIES.CLEAR_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      }
    }

    case VACANCIES.SET_VACANCIES: {
      return {
        ...state,
        vacancies: [...state.vacancies, ...action.payload],
      }
    }

    case VACANCIES.ADD_VACANCY: {
      const { vacancy } = action.payload

      return {
        ...state,
        vacancies: [vacancy, ...state.vacancies],
      }
    }

    case VACANCIES.SET_VACANCIES_LANDING: {
      return {
        ...state,
        vacanciesLanding: [...state.vacanciesLanding, ...action.payload],
      }
    }

    case VACANCIES.ADD_VACANCIES_LANDING: {
      const { vacancy, request_sent_date } = action.payload

      return {
        ...state,
        vacanciesLanding: state.vacanciesLanding.map((item) =>
          vacancy === item.id ? { ...item, request_sent_date } : item
        ),
      }
    }

    case VACANCIES.ADD_VACANCY_LANDING: {
      const { vacancy } = action.payload

      return {
        ...state,
        vacanciesLanding: [vacancy, ...state.vacanciesLanding],
      }
    }

    case VACANCIES.APPLY_VACANCY: {
      const { id, requestSentDate } = action.payload
      return {
        ...state,
        vacancies: state.vacancies.map((item) => {
          return item.id === id
            ? {
                ...item,
                request_sent_date: requestSentDate,
              }
            : item
        }),
        vacanciesLanding: state.vacanciesLanding.map((item) => {
          return item.id === id
            ? {
                ...item,
                request_sent_date: requestSentDate,
              }
            : item
        }),
      }
    }

    case VACANCIES.CLEAR_VACANCIES: {
      return {
        ...state,
        vacancies: [],
      }
    }

    case VACANCIES.CLEAR_VACANCIES_LANDING: {
      return {
        ...state,
        vacanciesLanding: [],
      }
    }

    case VACANCIES.SET_APPLIED_JOBS: {
      const { jobs } = action.payload

      return {
        ...state,
        appliedJobs: [...state.appliedJobs, ...jobs],
      }
    }

    case VACANCIES.DELETE_APPLIED_JOB: {
      const { id } = action.payload

      return {
        ...state,
        appliedJobs: state.appliedJobs.filter((jobs) => jobs.id !== id),
      }
    }

    case VACANCIES.SET_JOBS_COUNT: {
      const { count } = action.payload

      return {
        ...state,
        jobsCount: count,
      }
    }

    case VACANCIES.CLEAR_APPLIED_JOBS:
      return {
        ...state,
        appliedJobs: [],
      }

    default:
      return state
  }
}
