import React from "react";
import styles from "./styles.module.scss";
import ForgotPassForm from "../../ForgotPassForm/ForgotPassForm";
const SectionForgotPass = () => {
  return (
    <section className={styles.section}>
      <div className={styles.formWrapper}>
        <ForgotPassForm />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Forgot password?</h2>
      </div>
    </section>
  );
};

export default SectionForgotPass;
