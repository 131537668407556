import styles from './styles.module.scss'
import Button from '../../../UI/Button/Button'
import cn from 'classnames'
import Spinner from '../../../UI/Spinner/Spinner'
import React from 'react'
import { postVacancyRequest } from '../../../api/postApi'
import { applyVacancyRecord } from '../../../redux/actions/vacancies.action'
import { notify } from '../../../helpers/notify'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as LargeShareIcon } from '../../../assets/icons/large-share-icon.svg'
import { ReactComponent as SmallSuccessIcon } from '../../../assets/icons/success_small.svg'
import PopUp from './PopUp/PopUp'

const ActionBlock = ({
  isAppPopUpOpen,
  setIsAppPopUpOpen,
  loading,
  setLoading,
  item,
  isPopup,
  setIsShareBlockOpen,
  isShareBlockOpen,
}) => {
  const isAdmin = useSelector(({ authentication }) => authentication.user.isAdmin)
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)

  const dispatch = useDispatch()

  const isMobileView = window.innerWidth < 744

  const { id, company_id } = item

  const applyVacancy = async (company, vacancy, e) => {
    try {
      e.stopPropagation()
      setLoading(true)
      const data = await postVacancyRequest({ company, vacancy })

      if (data?.ok) {
        dispatch(applyVacancyRecord(vacancy, data.request_sent_date))
        handleAppPopUpOpen()
      } else {
        notify.errorsList(data?.errors)
      }
    } catch (error) {
      console.error('Произошла ошибка при отправке запроса:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAppPopUpOpen = () => {
    isShareBlockOpen && setIsShareBlockOpen(false)

    setIsAppPopUpOpen(true)
  }
  const handleAppPopUpClose = () => {
    isShareBlockOpen && setIsShareBlockOpen(false)

    setIsAppPopUpOpen(false)
  }

  const popupTextRender = () => {
    return isAuth ? (
      <div className={styles.popup_info}>
        <b>Successful!</b>
        <p>
          To continue, download the <b>NextShip</b> mobile app for the best experience.
        </p>
      </div>
    ) : (
      <div>
        <p>
          <b>To submit an application, please register with the NextShip service.</b>
        </p>
        <br />
        <p>For the best experience, download our mobile app.</p>
      </div>
    )
  }

  return (
    <>
      {isAppPopUpOpen && (
        <div>
          <PopUp textRender={popupTextRender} onClose={handleAppPopUpClose} />
        </div>
      )}
      <div
        className={cn(styles.actionBlock, {
          [styles.actionBlock_requested]: item.request_sent_date,
          [styles.actionBlock_requested_tablet]: !isMobileView && item.request_sent_date,
        })}
      >
        {item.request_sent_date ? (
          <div className={styles.request_date}>
            <SmallSuccessIcon />
            <div>
              <span>Request date: </span>
              <span>{item.request_sent_date}</span>
            </div>
          </div>
        ) : (
          <Button
            className={cn(styles.applyBtn, {
              [styles.controlBtnDisabled]: isAdmin,
              [styles.applyBtn_popup]: isPopup,
            })}
            onClick={(e) => (isAuth ? applyVacancy(company_id, id, e) : handleAppPopUpOpen())}
            disabled={isAdmin || loading}
            size={'middle-full'}
            type='button'
          >
            {loading ? (
              <div className={styles.loading}>
                <Spinner size={30} borderColor='rgba(189, 189, 189, 0.47)' />
              </div>
            ) : (
              'Apply'
            )}
          </Button>
        )}
        <Button
          className={cn(styles.shareBtn, {
            [styles.controlBtnDisabled]: isAdmin,
            [styles.shareBtn_popup]: isPopup,
          })}
          onClick={() => setIsShareBlockOpen(!isShareBlockOpen)}
          disabled={isAdmin || loading}
          size={'middle-full'}
        >
          <LargeShareIcon />
        </Button>
      </div>
    </>
  )
}
export default ActionBlock
