import React, { memo } from 'react'

// import ReactTooltip from 'react-tooltip'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'

import Switcher from '../../../UI/Switcher/Switcher'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'

const RelativeContactsGroupItem = memo(({ data, setMain, isActive, onDelete, onEdit }) => {
  return (
    <div className={cn(styles.card, { [styles.active]: isActive })}>
      <div className={styles.cardInfo}>
        <div className={styles.contactType}>
          {data?.header?.map((type) => {
            if (!type) return null
            return type
          })}

          {data.age !== null && (
            <div className={styles.age}>
              <span> ({data.age} years old)</span>
            </div>
          )}
        </div>

        {setMain && (
          <div className={styles.nextOfKin}>
            <span>Next of kin</span>

            <Switcher onClick={setMain} id={data.id} isActive={data.is_main} />
          </div>
        )}

        <div className={styles.fieldName}>
          <b>Full name: </b>

          <UseSmartTooltip
            tagType={'span'}
            className={cn(styles.smartFieldTooltip)}
            children={data.fullName}
            amount_of_line={1}
          />
        </div>

        <div className={styles.fieldName}>
          <b>Address: </b>
          <UseSmartTooltip
            tagType={'span'}
            className={cn(styles.smartFieldTooltip)}
            children={data.address}
            amount_of_line={1}
          />
        </div>

        <div className={styles.fieldName} style={{ gridColumn: '1/3' }}>
          <b>Contact: </b>

          <UseSmartTooltip
            tagType={'span'}
            className={cn(styles.smartFieldTooltip)}
            children={data?.contacts}
            amount_of_line={1}
          />
        </div>

        {data.id !== 'default' && (
          <>
            <div className={styles.cardControls}>
              <button
                className={cn(styles.cardControl, styles.cardControlEdit)}
                onClick={() => onEdit(data.id, isActive)}
              >
                {isActive ? (
                  <span>
                    Close <CloseIcon />
                  </span>
                ) : (
                  <span>
                    Edit <EditIcon />
                  </span>
                )}
              </button>

              <button
                className={cn(styles.cardControl, styles.cardControlDelete)}
                onClick={() => onDelete(data.id, isActive)}
              >
                <span>
                  Delete <TrashIcon />
                </span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
})

export default RelativeContactsGroupItem
