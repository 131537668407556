import * as Yup from 'yup'

export const initialValues = {
  rank: { name: 'All', id: null },
  vesselType: { name: 'All', id: null },
  salary: '',
  salaryRate: { name: 'Period', id: null },
  salaryCurrency: { name: '–', id: null },
  duration: '',
  durationPeriod: { name: 'Period', id: null },
  joinDate: null,
  workLocation: { name: 'All', id: null },
}

export const getValidationSchema = (dateFormat) => {
  const onlyPositiveDigits = /^\+?[1-9]\d*$/

  return Yup.object().shape({
    joinDate: Yup.date().nullable().typeError(`Invalid data, valid - ${dateFormat}`),
    salary: Yup.string()
      .max(6, 'Limit - 6 digits')
      .matches(onlyPositiveDigits, 'Only positive digits')
      .test('Salary check', 'Salary is required', function (value) {
        const { salaryRate, salaryCurrency } = this.parent
        if (salaryRate.id || salaryCurrency.id) {
          return !!value
        } else return true
      }),
    salaryRate: Yup.object().when('salary', (field) => {
      if (field) {
        return Yup.object().shape({
          id: Yup.number().typeError('Rate is required'),
        })
      }
    }),
    salaryCurrency: Yup.object().when('salary', (field) => {
      if (field) {
        return Yup.object().shape({
          id: Yup.number().typeError('Currency is required'),
        })
      }
    }),
    duration: Yup.string()
      .max(3, 'Limit - 3 digits')
      .matches(onlyPositiveDigits, 'Only positive digits')
      .test('Duration check', 'Duration is required', function (value) {
        const { durationPeriod } = this.parent
        if (durationPeriod.id) {
          return !!value
        } else return true
      }),
    durationPeriod: Yup.object().when('duration', (field) => {
      if (field) {
        return Yup.object().shape({
          id: Yup.number().typeError('Rate is required'),
        })
      }
    }),
  })
}

export const vacancyType = {
  sea: 'sea',
  land: 'land',
}
