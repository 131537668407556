import { combineReducers } from 'redux'

import mainFilter from './mainFilter'
import authentication from './auth'
import user from './user.reducer'
import uiReducer from '../ui/ui.reducer'
import seafarersSearchReducer from './seafarersSearch'
import chatReducer from './chat.reducer'
import supportReducer from '../support/support.reducer'
import notificationsReducer from './notifications.reducer'
import documentsReducer from './documents.reducer'
import contactsReducer from './contacts.reducers'
import contactsRelativesReducer from './contactsRelatives.reducer'
import reviewsRequestReducer from './reviews.reducer'
import vacanciesReducer from './vacancies.reducer'
import userCardReducer from './userCard.reducer'
import billingReducer from '../billing/billing.reducer'
import landingReducer from './landingStatisticsInfo'

const rootReducer = combineReducers({
  mainFilter,
  authentication,
  user,
  userCard: userCardReducer,
  ui: uiReducer,
  support: supportReducer,
  seafarersSearch: seafarersSearchReducer,
  chat: chatReducer,
  notifications: notificationsReducer,
  documents: documentsReducer,
  contacts: contactsReducer,
  contactsRelatives: contactsRelativesReducer,
  reviews: reviewsRequestReducer,
  vacancies: vacanciesReducer,
  billing: billingReducer,
  landingReducer,
})

export default rootReducer
