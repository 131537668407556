import React, { memo, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useNotify } from '../../../../hooks/useNotify'
import { getServiceDataByAction } from '../../../../api/getApi'
import { setServiceData } from '../../../../redux/billing/billing.action'
import { action } from '../constants'

const isEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

const ServicesIcon = memo(({ service, Icon, Popup, isOpenPopup, isActive, onOpenPopup, onClosePopup }) => {
  const [loading, setLoading] = useState(false)

  const serviceData = useSelector(({ billing }) => billing.services[service])

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const { notification_data = null } = !serviceData?.results?.length ? {} : serviceData.results[0]

  const notificationAction = notification_data?.action || null
  const isNotify = notificationAction === action.info
  const isError = notificationAction === action.hasExpired

  useEffect(() => {
    const getServiceData = async () => {
      setLoading(true)

      try {
        const data = await getServiceDataByAction(action.hasExpired, service)

        if (!data.results.length) {
          const data = await getServiceDataByAction(action.info, service)

          dispatch(setServiceData({ service, data }))

          return
        }

        dispatch(setServiceData({ service, data }))
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    if (!serviceData) getServiceData()

    // eslint-disable-next-line
  }, [])

  return (
    <div className={cn(styles.service, { [styles.skeleton]: loading })}>
      <button
        type='button'
        onClick={() => onOpenPopup(notificationAction, service)}
        className={cn(
          styles.button,
          { [styles.button__active]: isActive },
          { [styles.button__active_dark]: isOpenPopup },
          { [styles.button__notify]: isNotify },
          { [styles.button__error]: isError }
        )}
      >
        <Icon />
      </button>

      {isOpenPopup && (
        <Popup notificationAction={notificationAction} isServiceActive={isActive} handleClose={onClosePopup} />
      )}
    </div>
  )
}, isEqual)

export default ServicesIcon
