import { profilePaths } from '../../constants/constans'
import { getLocalStorageItem } from '../../helpers/helpers'

let user = getLocalStorageItem('user')

const initialState = user
  ? {
      isAuth: true,
      user,

      admin: {
        isAdmin: user.isAdmin ?? false,
        name: '',
        permissions: [profilePaths.vacancies, profilePaths.general, profilePaths.education],
      },
    }
  : {
      user: {
        scope: null,
      },

      admin: {
        isAdmin: false,
        name: '',
        token: '',
        seafarerId: null,
        permissions: [profilePaths.vacancies, profilePaths.general, profilePaths.education],
      },
    }

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        isAuth: true,
        user: action.payload,
        errors: [],
      }

    case 'SET_ADMIN': {
      return {
        ...state,
        admin: {
          ...state.admin,
          isAdmin: true,
        },
      }
    }
    case 'LOGIN_FAIL':
      return {
        ...state,
        isAuth: false,
        errors: action.payload.errors,
      }
    case 'USER_LOGOUT':
      return {
        isAuth: false,
        user: {
          scope: null,
        },
      }

    default:
      return state
  }
}
