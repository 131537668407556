import React, { useEffect } from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import CertificateItem from './CertificatesItem/CertificateItem'
import styles from '../UserCard.module.scss'
import { setCertificatesInfo } from '../../../redux/actions/userCard.action'
import { useDispatch } from 'react-redux'
import { ReactComponent as NoInfoImage } from '../../../assets/images/certificates_stub.svg'

const Certificates = ({ userCardData }) => {
  const dispatch = useDispatch()
  const { certificates } = userCardData
  useEffect(() => {
    return () => {
      dispatch(setCertificatesInfo([]))
    }
  }, [dispatch])
  if (isEmpty(certificates)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={425} height={265} />
        <p>You don't have any certificates yet. </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter='16px'>
        {certificates?.map((certificate, index) => {
          return (
            <CertificateItem
              key={index}
              title={certificate.name}
              files={certificate.files}
              items={certificate.values}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default Certificates
