import { formatISO } from 'date-fns/esm'
import { parseISO } from 'date-fns'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import store from '../redux/store'
import moment from 'moment'
import { dateFormatForSanding } from '../constants/constans'

export const stringToISO = (value) => {
  const user = JSON.parse(localStorage.getItem('user'))

  const userFormat = JSON.parse(localStorage.getItem('settings'))?.date_format

  if (value && userFormat) {
    if (user?.isAdmin) {
      value = moment(value).format(userFormat)
    }

    return formatISO(parse(value, userFormat.replace('YYYY', 'yyyy').replace('DD', 'dd'), new Date()), {
      representation: 'date',
    })
  } else {
    return null
  }
}

export const userTimeZoneDateToISO = (dateString) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const userFormat = JSON.parse(localStorage.getItem('settings'))?.date_format

  if (user?.isAdmin) {
    dateString = moment(dateString).format(userFormat)
  }

  const date = parse(dateString, userFormat.replace('YYYY', 'yyyy').replace('DD', 'dd'), new Date())

  return formatISO(date, { representation: 'date' })
}

export const getUserTimeZone = () => new Date().getTimezoneOffset() * -1

export const getMaxAdultDate = moment().subtract(18, 'years')
export const getMinDate = moment().subtract(100, 'years')
export const today = moment().startOf('day')

export const getAllDates = (arr) => {
  if (!arr) return null

  return arr.reverse().reduce((acc, cur) => {
    const newFormatDate = getDateByFormat(new Date(moment(cur.date).format()), 'MMMM DD')
    const publicationYear = moment(cur.date).year()
    const currentYear = moment().year()
    const displayDate = publicationYear < currentYear ? `${newFormatDate}, ${publicationYear}` : `${newFormatDate}`

    if (!acc.some((el) => el.value === newFormatDate)) {
      return [...acc, { value: displayDate, id: cur.id }]
    }

    return acc
  }, [])
}

export const getFullYear = (str) => {
  if (!str) {
    return null
  }

  return str.split('/').reduce((acc, cur) => (acc.length > cur.length ? acc : cur))
}

export const UTCToLocalDate = (utcDate) => {
  const date = new Date(utcDate)

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

  return date.toString()
}

export const dateToTwelveHourFormat = (utcDate) => {
  return format(new Date(UTCToLocalDate(utcDate)), "hh':'mm aaa")
}

export const convertDateToSubmit = (date) => {
  const userFormat = JSON.parse(localStorage.getItem('settings'))?.date_format
  if (date) {
    if (date) {
      return moment(date, userFormat).format(dateFormatForSanding)
    } else {
      return date
    }
  } else {
    return null
  }
}

export const convertDateFromGet = (date, begin) => {
  const userFormat = JSON.parse(localStorage.getItem('settings'))?.date_format

  if (date) {
    switch (begin) {
      case 'start':
        return moment(date, userFormat).startOf('day')

      case 'startEducation':
        return moment(date, userFormat).set('month', 1).set('day', 1)

      case 'end':
        return moment(date, userFormat).startOf('day')

      case 'endEducation':
        return moment(date, userFormat).set('months', 1).set('day', 2)

      default:
        return moment(date, userFormat)
    }
  } else {
    return null
  }
}

export const getPeriodInYears = (dateFrom, dateTo) => {
  const incompleteDate = !getFullYear(dateFrom) && !getFullYear(dateTo)
  const yearFrom = getFullYear(dateFrom) || 'no date'
  const yearTo = getFullYear(dateTo) || 'current'

  return incompleteDate ? 'current' : `${yearFrom}-${yearTo}`
}

export const getDateByFormat = (date, newFormat) => moment(date, newFormat).format(newFormat)

export const daysToYearsMonthsDays = (days = 0) => {
  const years = Math.floor(days / 365)
  const months = Math.floor((days % 365) / 30)
  const remainingDays = days % 30

  return { years, months, remainingDays }
}

export const getParsedDateToISO = (dateString) => {
  return parseISO(dateString)
}

export const getDateByUserFormat = (dateString, newFormat = null) => {
  const { date_format: userFormat = newFormat } = store.getState().user.settings

  return getDateByFormat(dateString, userFormat)
}
