import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { notify } from '../../../helpers/notify'
import { deleteSeafarerFile } from '../../../api/deleteApi'
import { changeProfilePicture } from '../../../redux/actions/user.action'
import { confirm } from '../../../UI/Confirm/Confirm'
import Spinner from '../../../UI/Spinner/Spinner'
import AvatarCropper from '../AvatarCropper/AvatarCropper'
import styles from './styles.module.scss'
import warningSvg from '../../../assets/icons/warning.svg'
import acceptSvg from '../../../assets/icons/acceptQuality.svg'

const UserAvatarEditor = () => {
  const [loading, setLoading] = useState(true)
  const [showCropper, setShowCropper] = useState(false)
  const [userAvatar, setUserAvatar] = useState('')

  const picture = useSelector(({ user }) => user.profile.picture)

  const dispatch = useDispatch()

  const loaded = () => {
    setLoading(false)
  }

  const handleHideCropper = () => {
    setShowCropper(false)
  }

  const handleNewImage = (e) => {
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0].type)) {
      if (e.target.files[0]) {
        setUserAvatar(e.target.files[0])
        setShowCropper(true)
      }
    } else {
      notify.error('Error', 'Type of your image not supported. Supported: jpg, png, jpeg')
    }
  }

  const handleDelete = () => {
    confirm('Are you sure?', 'You want to delete profile photo?', () => {
      deleteSeafarerFile(picture.id)
        .then((data) => data.json())
        .then((data) => {
          dispatch(changeProfilePicture(data))
          notify('Success', 'You are successfully deleted user avatar!')
        })
    })
  }

  return (
    <form className={styles.form}>
      {picture &&
        (showCropper && userAvatar ? (
          <AvatarCropper
            picture={userAvatar}
            id={picture.id}
            handleHideCropper={handleHideCropper}
            setEditorLoading={setLoading}
            setUserAvatar={setUserAvatar}
          />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              {loading && <Spinner />}

              <div
                className={styles.logoContainer}
                style={loading ? { backgroundColor: '#E0E0E0', filter: 'blur(3px)' } : {}}
              >
                <input
                  className={styles.fileInput}
                  onChange={handleNewImage}
                  accept='image/png, .jpeg, .jpg,'
                  type='file'
                  id='avatar-editor'
                />

                {picture.quality_status === null ? null : picture.quality_status ? (
                  <div
                    className={cn(styles.qualityStatus, {
                      [styles.accept]: picture.quality_status,
                    })}
                  >
                    <div className={styles.qualityStatusIconContainer}>
                      <img className={styles.qualityStatusIcon} src={acceptSvg} alt='accept' />
                    </div>
                  </div>
                ) : (
                  <div className={styles.qualityStatus}>
                    <div className={styles.qualityStatusIconContainer}>
                      <img className={styles.qualityStatusIcon} src={warningSvg} alt='warning' />
                    </div>
                    <div className={styles.qualityStatusToolTip}>{picture.quality_message}</div>
                  </div>
                )}

                {picture.file_name === 'empty-profile-photo.png' ? (
                  // eslint-disable-next-line
                  <img
                    src={picture.file}
                    alt={!loading ? 'seafarer avatar' : undefined}
                    className={styles.defaultLogo}
                    style={loading ? { filter: 'blur(3px)' } : {}}
                    onLoad={loaded}
                    onError={loaded}
                  />
                ) : (
                  // eslint-disable-next-line
                  <img
                    src={picture.file}
                    alt={!loading ? 'seafarer avatar' : undefined}
                    className={styles.companyLogo}
                    style={loading ? { filter: 'blur(3px)' } : {}}
                    onLoad={loaded}
                    onError={loaded}
                  />
                )}
              </div>
            </div>

            <div>
              <div>
                <label
                  className={cn(styles.chooseLogoControl, {
                    [styles.disable]: loading,
                  })}
                  htmlFor='avatar-editor'
                >
                  {picture.file_name === 'empty-profile-photo.png' ? 'Choose photo' : 'Change photo'}
                </label>

                {picture.file_name !== 'empty-profile-photo.png' && (
                  <label
                    className={cn(styles.deleteLogoControl, {
                      [styles.disable]: loading,
                    })}
                    onClick={handleDelete}
                  >
                    Delete
                  </label>
                )}
              </div>
            </div>

            <div className={styles.footer}>
              <p className={styles.formats}>
                Please use formats: <span className={styles.extensions}>jpg, jpeg, png</span>
              </p>
            </div>
          </div>
        ))}
    </form>
  )
}

export default UserAvatarEditor
