import React from 'react'

import TabNav from '../components/TabNav/TabNav'
import Education from '../components/Documents/Education/Education'
import Experience from '../components/Documents/Experience/Experience'
import Passports from '../components/Documents/Passports/Passports'
import Licenses from '../components/Documents/Licenses/Licenses'
import Certificates from '../components/Documents/Certificates/Certificates'
import Visas from '../components/Documents/Visas/Visas'
import { profilePaths } from '../constants/constans'

const DocumentsEditPage = () => {
  const links = [
    {
      url: profilePaths.education,
      title: 'Education',
      component: Education,
    },
    {
      url: profilePaths.experience,
      title: 'Work experience',
      component: Experience,
    },
    {
      url: profilePaths.passports,
      title: 'Passports',
      component: Passports,
    },
    {
      url: profilePaths.visas,
      title: 'Visas',
      component: Visas,
    },
    {
      url: profilePaths.licenses,
      title: 'Licenses',
      component: Licenses,
    },
    {
      url: profilePaths.certificates,
      title: 'Certificates',
      component: Certificates,
    },
  ]

  return <TabNav title='Documents' links={links} redirectLink={profilePaths.education} />
}

export default DocumentsEditPage
