import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const Terms = () => {
  return (
    <div className={styles.template}>
      <h2>TERMS AND CONDITIONS</h2>
      <h3>1. ACCEPTANCE</h3>
      <p>
        We encourage you to read these Terms & Conditions (the “Terms”). This is a legally binding contract, even if you
        are just browsing through <Link to='/'>nextship.net</Link> (the “Site”) without registering an account or
        contacting us. The term "you" refers to all Site visitors and users. Our Privacy Policy [{' '}
        <Link to='/privacy'>https:/nextship.net/privacy</Link> ] also governs your use of the Site. If you do not agree
        to accept these Terms or the Privacy Policy, you must exit the Site. Note that we may revise the aforementioned
        documents whenever we consider it appropriate, sometimes without prior notification. If you continue visiting
        our Site after we have published the revised versions, this constitutes your acceptance of the changes. If you
        do not agree with any changes, you must exit the Site.
      </p>

      <p>Agreeing to the Terms have effect with the following entities (NextShip entities):</p>

      <p>● “NextShip LLC”, address 211 SE 9th Street, Dania Beach FL 33004-4442, United States</p>
      <p>All references to “NextShip” or “we” in the Terms are made to the entities above.</p>

      <h3>2. VENUE</h3>
      <p>
        a) Neutral Venue. The Site allows companies to post jobs for any positions they need filled.{' '}
        <span className={styles.semiBold}>
          Our site is a neutral venue. We disclaim all liability arising out of users’ listings, interactions and
          transactions. We do not endorse any particular CrewManager or any other site user, and do not guarantee that
          any user will perform their obligations satisfactorily
        </span>
        .
      </p>
      <p>
        b) Third Party Content. A lot of the Site content (e.g., job listings, CV / resumes, reviews, etc.) is provided
        by third parties. We also use third party services to support our Site. Such third parties are not owned or
        controlled by us. We assume no responsibility for the content, privacy policies, or practices of any third party
        content. In addition, our Site will not and cannot censor or edit the content of any third-party website. You
        acknowledge that we will not be liable for any and all claims, demands and damages of any kind and nature
        arising out of or in any way connected to any and all potential liabilities arising from the use of any
        third-party network or content. We cannot guarantee the availability or performance of any third party services
        used to support the Site. Such third party services may change their operations without notifying us. Therefore,
        we shall not be liable for any outages, version changes, delivery delays, failures, bugs, or termination of
        third party network service. Third party networks have their own terms of use, and you must comply with any
        terms of use provided by any third party network.
      </p>
      <h3>3. INTELLECTUAL PROPERTY</h3>
      <p>
        a) Our IP. All Site contents, registered and unregistered trademarks, designs, information and images (the
        “Intellectual Property”) belong to us and our content suppliers. The Intellectual Property is protected by
        international trademark, copyright, privacy, and other intellectual property laws. You obtain no interest in
        that Intellectual Property, provided, however, that you may download and view or print a copy of materials on
        this Site for personal use as long as you do not modify any content (including any copyright notice) in any way.
        All rights not expressly granted under these Terms are reserved by us. Unless expressly stated otherwise, you
        shall not copy, reproduce or replicate any Intellectual Property. We have the right but not the obligation to
        monitor and edit or remove any content submitted by users. We take no responsibility and assume no liability for
        any content posted by users or any third party.
      </p>
      <p>
        b) Copyright Infringement Claims. Unauthorized copying, distribution, modification, public display, or public
        performance of copyrighted works is an infringement of the copyright holder’s rights. You agree that you will
        not use our Site to infringe anyone’s intellectual property rights. We will investigate copyright infringement
        claims if they are reported to us to <a href='mailto:help@nextship.net'>help@nextship.net</a>.
      </p>

      <h3>4. YOUR OBLIGATIONS</h3>
      <p>By using this Site, you represent, warrant and agree that:</p>

      <p>a) You are 18 years of age or older.</p>
      <p>
        b) You will only use the Site for legal purposes, and you will remain responsible for complying with all laws
        and regulations applicable to your use of the Site.
      </p>
      <p>c) You will only post truthful listings and reviews.</p>
      <p>d) You will perform all obligations undertaken when applying for a job or hiring someone via our Site.</p>
      <p>
        e) You will provide a valid payment method information when necessary and pay all necessary fees and charges
        when due.
      </p>
      <p>
        f) It is your sole responsibility to ensure that the service providers or the CrewManager are licensed,
        certified, insured, bonded to the extent that your assignments require.
      </p>
      <p>
        g) You will not create duplicate accounts, and you will not share your account with anyone. You are solely
        responsible for all activity that occurs under your account.
      </p>
      <p>h) Our Site may contain typographical errors or other inaccuracies.</p>
      <p>
        i) Our Site content may not be copied for republication, either online or on paper, without our prior written
        permission. However, you can share Site content via built-in social sharing buttons.
      </p>
      <p>
        j) You will not submit unsolicited bulk or commercial messages ("spam") to our Site, other users or anyone else.
        Any unsolicited message must not direct the recipient to any third party site or another resource.
      </p>
      <p>k) You will not access our Site to gain a competitive advantage.</p>
      <p>
        l) We have the right to refuse access, service or disable your account on our Site at any time for any reason or
        no reason without prior notice, explanation or liability of any kind.
      </p>
      <p>
        m) You may not use any web spiders, bots, indexers, robots, crawlers, harvesters, or any other automatic process
        to access, acquire, copy or monitor any portion of the Site or any content, or in any way reproduce or
        circumvent the navigational structure or presentation of the Site or any content.
      </p>
      <p>
        n) You will not interfere with the proper working of the Site. You will not impersonate any other person or
        entity, submit any false, defamatory, offensive, harassing material, or any material that violates another
        party's rights of privacy and publicity.
      </p>

      <h3>5. REMEDIES FOR BREACH OF THESE TERMS</h3>

      <p>
        a) We may take any action that we deem necessary or appropriate if we believe that any user violates the Terms &
        Conditions, infringes any intellectual property right, privacy right or confidentiality principles, threatens
        anybody’s personal safety or uses offensive language. We may: disclose user’s identity to law enforcement
        agencies, to any third party claiming violation of their intellectual property or privacy right, block user’s IP
        address, notify user’s Internet Service Provider, suspend or terminate any account on our Site, moderate any
        content submitted to us, take any other action specified in these Terms & Conditions or available under equity
        or law.
      </p>
      <p>
        b) Since we cannot ensure that all material submitted to us is accurate and free of third party claims, we
        assume no liability for any action or inaction regarding transmissions, communications or content provided by
        any user or third party.
      </p>
      <h3>6. DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY</h3>
      <p>
        a){' '}
        <span className={styles.semiBold}>
          This site and all of its content are provided on an "as is" basis. You must not rely on any information
          published on or linked to this site without first making your own enquiries to verify it is accurate, current
          and complete. We do not make, and expressly disclaim, any representations, warranties, covenants and
          conditions, express or implied, arising out of operation of law, course of performance, course of dealing,
          usage of trade or otherwise, including any express or implied warranties and conditions of: merchantability;
          fitness for a particular purpose; non-infringement; safety; quality; non-existence of defects or that defects
          will be corrected; uninterrupted, virus-free or error-free use of this site; or accuracy, currency,
          reliability, completeness or appropriateness relating to the content of this site.
        </span>
      </p>
      <p>
        b){' '}
        <span className={styles.semiBold}>
          We shall not be liable to you for indirect, incidental, special or consequential damages, including lost
          profits and property damage, even if we were advised of the possibility of such damages, nor shall we be held
          liable for delay or failure in performance resulting from causes beyond our reasonable control. In no event
          will our aggregate liability exceed the referral fees received. No claim, suit or action may be brought
          against us after six months from the underlying cause of action. Some jurisdictions do not allow the exclusion
          of certain warranties or the limitation, or exclusion of liability for incidental or consequential damages, so
          the above limitations may not apply to you.
        </span>
      </p>
      <h3>7. INDEMNIFICATION</h3>
      <p>
        You agree to defend, indemnify and hold harmless our company, its officers, directors, employees and agents,
        from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses
        (including but not limited to attorney's fees) arising from:
      </p>
      <p>your use of and access to the Site;</p>
      <p>your violation of any provision of these Terms;</p>
      <p>
        your violation of any third party right, including without limitation any copyright, property, or privacy right;
      </p>
      <p>or any claim that one of your user submissions caused damage to a third party.</p>
      <h3>8. GOVERNING LAW & JURISDICTION</h3>

      <p>
        These Terms and any action related thereto will be governed by the laws of England and Wales. Any controversy or
        claim arising out of or relating to these Terms, or the breach thereof, shall be settled by courts located in
        London, the UK.
      </p>

      <h3>9. GENERAL</h3>
      <p>
        a) Electronic Communications. You agree that all notices or other communications regarding your account and/or
        your use of the Site ("Communications"), may be provided to you electronically and you agree to receive all
        Communications from us in electronic form. You may print a copy of any Communications and retain it for your
        records. All electronic Communications will be considered just as legally binding as if they were in paper form.
        You may revoke your consent to receive Communications electronically, but if you revoke your consent to receive
        Communications electronically, we reserve the right to terminate your right to use our Site.
      </p>
      <p>
        b) Relationship of the Parties. You and us are independent contractors to each other. There is no joint venture,
        partnership or employment relationship between us and any Site user.
      </p>
      <p>
        c) Assignment. We may transfer, assign or subcontract the rights, interests or obligations under these Terms, at
        our sole discretion, without obtaining your consent.
      </p>
      <p>
        d) Hyperlinks. You may link to our Site, as long as this is done in a fair way that does not negatively affect
        our reputation or business and does not suggest any form of association where there is none. We reserve the
        right to request that you withdraw any link and you agree to cooperate with us terminate any unauthorized
        framing or linking immediately.
      </p>
      <p>
        e) Severability. Should any part of these Terms & Conditions be rendered or declared invalid by an appropriate
        authority, such invalidation of such part or portion of these Terms & Conditions should not invalidate the
        remaining portions thereof, and they shall remain in full force and effect.
      </p>
      <p>
        f) No Waiver. Enforcement of these Terms & Conditions is solely in our discretion, and failure to enforce the
        Terms & Conditions in some instances does not constitute a waiver of our right to enforce them in other
        instances.
      </p>
      <p>
        g) Prevailing Language. In the event of any discrepancy between the English original version of these Terms and
        any foreign language translation, the English version prevails.
      </p>
      <h3>10. CONTACT US</h3>
      <p>
        Please submit your inquiries to <a href='mailto:help@nextship.net'>help@nextship.net</a>
      </p>

      <h2>NextShip CrewManager Account Terms</h2>

      <h3>1. The product</h3>
      <p>
        These NextShip CrewManager Account Terms (“NEA Terms”) are part of the Terms. Posting a job advertisements on
        NextShip for payment or free, whether either directly or via an XML feed, you agree that you are solely
        responsible for content and target URLs.
      </p>

      <h3>2. Billing & Refund Policy</h3>

      <p>
        You will be charged based on the number of clicks you have received. The NextShip preserves exclusive right to
        determine click count which is binding. All prices are exclusive of all taxes, including VAT. You will be
        charged all applicable taxes as required. All withholding tax remittances to the government are your sole
        responsibility and NextShip shall have no liability whatsoever therefore. Your balance is the total cost accrued
        for promoting jobs plus applicable taxes, e.g. VAT.
      </p>

      <p>Invoices may be provided to you via electronic mail.</p>

      <p>
        NextShip's Billing cycle is a calendar month. Your billing cycle begins on the date your Job Ad and payment
        method chosen are approved (e.g. credit card being approved). Depending on your billing address and the payment
        method you will contract with appropriate NextShip entity.
      </p>

      <p>
        To cancel a paid subscription, you should go to the Job Settings and click Pause to delist a job ad from the
        premium listing or click Remove to delete a job ad permanently from your account. You should Pause or Remove
        every premium job ad.
      </p>

      <p>
        Once all premium job ads are Paused or Removed, your outstanding balance will be charged according to NextShip
        billing cycle.
      </p>

      <p>
        If you remove the payment method from your account, the outstanding balance will be charged at the moment when
        the payment method is being removed
      </p>

      <p>
        Card processing is provided solely in favor of Ladoburn Europe Ltd. Only one credit card per account is allowed.
      </p>

      <p>
        We will first charge your card for an amount below USD 1.00 to validate your card and clear that amount in few
        days.
      </p>
      <p>Then:</p>
      <p>
        (a) if you choose a prepayment method (debit accounts), your card will only be charged for the amounts you enter
        and will be debited to your balance; your spending will be limited to your debit balance.
      </p>
      <p>
        (b) if you choose a post-payment method (credit accounts) you will be charged once your total costs reach USD
        25.00 and then every USD 300.00 or the 1st day of the following month, whichever event happens earlier.
      </p>
      <p>
        If you choose currency other than US Dollars, when registering your account, the payments will be processed
        according to the exchange rate of your currency to US Dollars valid at the moment of transaction. This also
        means, that the amount charged from your card may differ slightly from the amount you have entered on debit
        accounts or the amount of your spending on credit accounts.
      </p>
      <p>
        We reserve the right to determine, in our sole discretion, whether a reasonable basis exists to issue a refund
        of the fees we received from you upon your request. If we decide to issue a refund, we will deduct $50
        commission from the refund issued to you. We will not issue any refunds if the fees we received from you are
        less than $50.
      </p>
    </div>
  )
}

export default Terms
