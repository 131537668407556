import React from 'react'
import cn from 'classnames'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import FileList from '../../FileList/FileList'
import styles from '../../UserCard.module.scss'
import { ReactComponent as CalcOfFiles } from '../../../../assets/icons/calcOfFiles.svg'
import { uniqueId } from 'lodash'

const LicenseItem = ({ title, licenseFiles, endorsementFiles, items }) => {
  const [showFileList, setShowFileList] = React.useState(false)

  return (
    <div className={styles.contentItem}>
      <div className={cn(styles.header, { [styles.license]: true })}>
        <UserCardSmartTooltip
          id={uniqueId('license-item-')}
          type={'simple'}
          title={title}
          titleClass={styles.contentItemTitle}
        />

        <div className={styles.endorsementTitleContainer}>
          <h3 className={styles.endorsementTitle}>Endorsement</h3>
          <div
            className={cn(styles.calcOfFilesContainer, { [styles.calcOfActive]: showFileList })}
            onClick={() => {
              ;(endorsementFiles.length || licenseFiles.length) && setShowFileList(!showFileList)
            }}
          >
            <label className={styles.calcOfFilesContainerLabel}>Photo</label>
            <div className={styles.calcOfFiles}>
              <CalcOfFiles />
              <label className={styles.calcOfFilesLabel}>{endorsementFiles.length + licenseFiles.length}</label>
            </div>
          </div>
        </div>
      </div>

      <div className={cn(styles.body, { [styles.license]: true })}>
        {items?.map((item, index) => (
          <UserCardSmartTooltip
            key={index}
            title={item.title}
            value={item.value}
            id={uniqueId('license-item-')}
            containerClass={styles.item}
            titleClass={styles.itemTitle}
            valueClass={styles.itemValue}
          />
        ))}

        {endorsementFiles.length || endorsementFiles ? (
          <>
            <FileList files={licenseFiles} showFiles={showFileList} type={'license'} />
            <FileList files={endorsementFiles} showFiles={showFileList} type={'endorsement'} />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default LicenseItem
