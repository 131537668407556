import PropTypes from 'prop-types'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import mtButton from './MTButton/mtButton.module.scss'
import SelectItem from '../SelectItem'
import Input from '../../Input/Input'
import MTButtonWithSearch from './MTButtonWithSearch/MTButtonWithSearch'
import { ReactComponent as VesselIcon } from '../../../assets/icons/vessel.svg'
import { ReactComponent as RenameVesselIcon } from '../../../assets/icons/renameVessel.svg'
import PopupRenameVesselName from './PopupRenameVesselName/PopupRenameVesselName'
import { getCompleteVesselInfo } from '../../../helpers/autocomplete.helper'
import ReactJoyride from 'react-joyride'
import { getSteps, joyrideStyles } from './config'
import useClickOutside from '../../../hooks/useClickOutside'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import useInput from '../../../hooks/useInput'
import useDebounce from '../../../hooks/useDebounce'
import { StyledTooltip } from '../../Tooltip/Tooltip.config'

const VesselAutocomplete = ({
  name,
  value,
  vesselId,
  getTitle = 'description',
  label,
  placeholder,
  onChange,
  onClick,
  handleScroll,
  onFocus,
  className = '',
  items = [],
  cursor,
  dateRange = {},
  autoComplete = 'off',
  onSuccess,
  onError,
  isOpen,
  setIsOpen,
  isInvalid = false,
  required = false,
  disabled = false,
}) => {
  const [joyrideRun, setJoyrideRun] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)

  const selectEl = useClickOutside(() => setIsOpen(false))

  const { value: inputValue, onChange: handleChange, setValue } = useInput(value)
  const debounceValue = useDebounce(inputValue)

  const isOpenSelect = Boolean(items.length && inputValue.length && isOpen && !joyrideRun)
  useEffect(() => {
    if (inputValue !== value) {
      onChange(name, { target: { value: inputValue } })
    }

    // eslint-disable-next-line
  }, [debounceValue])

  useEffect(() => {
    setValue(value)

    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    const body = document.documentElement

    joyrideRun ? disableBodyScroll(body) : enableBodyScroll(body)

    return () => enableBodyScroll(body)
  }, [joyrideRun])

  const handleItemClick = (id, value) => {
    setIsOpen(false)

    onClick(name, { id, name: value })
  }

  const getValidVesselName = (name) => {
    const isCharValid = (char) => {
      const forbiddenSymbols = [' ']
      return !forbiddenSymbols.includes(char)
    }

    let canTakeForbiddenSymbols = false

    return name.split('').reduce((acc, char) => {
      if (isCharValid(char)) {
        canTakeForbiddenSymbols = true
      }
      if (canTakeForbiddenSymbols) {
        return acc + char
      } else {
        return acc
      }
    }, '')
  }

  const onButtonClick = () => {
    setJoyrideRun(!joyrideRun)
  }

  const joyrideCallback = ({ action }) => {
    if (action === 'close') setJoyrideRun(false)
  }

  const handleKeyDown = (e) => {
    if (!isOpenSelect) {
      return
    }

    if (e.key === 'ArrowUp' && activeIndex > 0) {
      e.preventDefault()

      setActiveIndex(activeIndex - 1)

      return
    }

    if (e.key === 'ArrowDown' && activeIndex < items.length - 1) {
      e.preventDefault()

      setActiveIndex(activeIndex + 1)

      return
    }

    if (e.key === 'Enter' && activeIndex !== -1) {
      const id = items[activeIndex].id
      const name = items[activeIndex].name

      handleItemClick(id, name)
    }
  }

  return (
    <>
      {label && (
        <label className={styles.label}>
          {label} {required && <span className='required-star'>*</span>}
        </label>
      )}

      <div
        tabIndex='0'
        ref={selectEl}
        className={cn(styles.wrapper, className, {
          [styles.wrapper_invalid]: isInvalid,
          [styles.noEvent]: disabled,
        })}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.wrapperInput}>
          <Input
            id='viewJoyride'
            placeholder={placeholder}
            name={name}
            value={getValidVesselName(inputValue)}
            onChange={handleChange}
            onFocus={(e) => onFocus(name, e)}
            autoComplete={autoComplete}
            isInvalid={isInvalid}
            disabled={disabled}
          />

          {!isInvalid && value.length > 2 && (
            <ReactJoyride
              steps={getSteps(onButtonClick, dateRange, vesselId)}
              run={joyrideRun}
              callback={joyrideCallback}
              styles={joyrideStyles}
            />
          )}

          <StyledTooltip placement='right' title={<PopupRenameVesselName />} arrow={true} className={styles.tooltip}>
            <div>
              <button
                type='button'
                data-tip={true}
                data-for='view'
                className={cn(styles.button, { [styles.button__disabled]: value.length < 2 || isInvalid })}
                onClick={onButtonClick}
                disabled={value.length < 2 || isInvalid}
              >
                <RenameVesselIcon className={styles.renameVesselIcon} />
              </button>
            </div>
          </StyledTooltip>
        </div>

        {isOpenSelect ? (
          <ul className={styles.list} onScroll={handleScroll}>
            {items.map((item, index) => {
              const { [getTitle]: name, place_id: id, id: alt_id } = item
              const isActive = index === activeIndex || name === value

              return (
                <SelectItem
                  tabIndex='0'
                  key={id || alt_id}
                  name={name}
                  id={id || alt_id}
                  displayName={getCompleteVesselInfo(item, getTitle)}
                  isActive={isActive}
                  onClick={handleItemClick}
                />
              )
            })}

            {!cursor && value.length > 2 && (
              <div className={mtButton.buttonWrapper}>
                <MTButtonWithSearch Icon={VesselIcon} onSuccess={onSuccess} onError={onError} value={value} />
              </div>
            )}
          </ul>
        ) : null}
      </div>
    </>
  )
}

VesselAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  vesselId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getTitle: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  cursor: PropTypes.string,
  dateRange: PropTypes.object,
  autoComplete: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default VesselAutocomplete
