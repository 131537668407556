export const intro = [
  {
    target: '#tour-step-1',
    content: 'Navigate through your profile using the menu on the left side of the screen.',
    disableBeacon: true,
    placement: 'right-end',
    spotlightPadding: 0,
  },
  {
    target: '#tour-step-2',
    content: 'Click on the horizontal tabs to fill out the required information.',
    spotlightPadding: 0,
  },
  {
    target: '#tour-step-3',
    content:
      'Hover over the icon to see the recommendations for filling out your data. This information is individually tailored to each section of your profile.',
    placement: 'left',
    spotlightPadding: 4,
    styles: {
      spotlight: {
        borderRadius: 9,
      },
    },
  },
  {
    target: '#tour-step-4',
    content:
      'Hover over the Profile completion status to see the level of your profile completeness and the links to the sections to be filled.',
    spotlightPadding: 12,
    styles: {
      spotlight: {
        borderRadius: 9,
      },
    },
  },
  {
    target: '#tour-step-5',
    content: 'The Support chat helps to resolve any technical issues.',
    spotlightPadding: 5,
    styles: {
      spotlight: {
        borderRadius: 11,
      },
    },
  },
  {
    target: '#tour-step-6',
    content: 'Click here to exit your profile or see the page with vacancies.',
    spotlightPadding: 0,
    styles: {
      spotlight: {
        borderRadius: '50%',
        // marginLeft: 1,
      },
    },
  },
]

export const introStyles = {
  options: {
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    arrowColor: 'transparent',
  },
  spotlightPadding: 0,

  beacon: {
    outline: 'none',
  },
}
