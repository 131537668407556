import { USER_CARD } from '../types'

const initialState = {
  general: {},
  contactInfo: {},
  education: [],
  experience: [],
  passports: [],
  visas: [],
  licenses: [],
  certificates: [],
  sizeInformation: {},
  reviews: [],
  videoUrl: null,
  colleagues: [],
  loading: true,
  loadingGeneral: true,
}

export default function userCardReducer(state = initialState, action) {
  switch (action.type) {
    case USER_CARD.SET_GENERAL_INFO:
      return {
        ...state,
        general: action.payload,
      }
    case USER_CARD.SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.payload,
      }
    case USER_CARD.SET_EDUCATION:
      return {
        ...state,
        education: action.payload,
      }
    case USER_CARD.SET_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
      }
    case USER_CARD.SET_PASSPORTS:
      return {
        ...state,
        passports: action.payload,
      }
    case USER_CARD.SET_VISAS:
      return {
        ...state,
        visas: action.payload,
      }
    case USER_CARD.SET_LICENSES:
      return {
        ...state,
        licenses: action.payload,
      }
    case USER_CARD.SET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      }
    case USER_CARD.SET_SIZE_INFORMATION:
      return {
        ...state,
        sizeInformation: action.payload,
      }
    case USER_CARD.SET_REVIEWS_INFO:
      return {
        ...state,
        reviews: action.payload,
      }
    case USER_CARD.SET_VIDEO_URL:
      return {
        ...state,
        videoUrl: action.payload,
      }
    case USER_CARD.SET_COLLEAGUES:
      return {
        ...state,
        colleagues: action.payload,
      }
    case USER_CARD.SET_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      }
    case USER_CARD.SET_LOADING_GENERAL_STATE:
      return {
        ...state,
        loadingGeneral: action.payload,
      }
    case USER_CARD.CLEAR_USER_CARD_STATE:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}
