import { CONTACTS } from "../types";

const initialState = {
  contacts: [],
  authContacts: [],
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACTS.SET_AUTH_CONTACTS: {
      return {
        ...state,
        contacts: [...state.contacts, ...action.payload],
      };
    }

    case CONTACTS.ADD_AUTH_CONTACT: {
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
      };
    }

    case CONTACTS.DELETE_AUTH_CONTACT: {
      return {
        ...state,
        contacts: state.contacts.filter(
          (contact) => contact.id !== action.payload
        ),
      };
    }

    case CONTACTS.EDIT_AUTH_CONTACT: {
      return {
        ...state,
        contacts: state.contacts.map((contact) => {
          if (contact.id === action.payload.id) {
            return action.payload.contact;
          } else {
            return contact;
          }
        }),
      };
    }

    case CONTACTS.SET_MAIN_CONTACT: {
      return {
        ...state,
        contacts: state.contacts.map((contact) => {
          if (contact.id === action.payload.id) {
            return { ...contact, is_main: !contact.is_main };
          } else {
            if (contact.type.name === action.payload.type) {
              return { ...contact, is_main: false };
            }
            return contact;
          }
        }),
      };
    }

    case CONTACTS.CLEAR_AUTH_CONTACTS: {
      return {
        ...state,
        contacts: [],
      };
    }

    default:
      return state;
  }
}
