import React from 'react'
import Button from '../../UI/Button/Button'
import { Link, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { changePasswordConfirmRequest } from '../../api/postApi'
import InputPassword from '../../UI/Input/InputPassword'
import { getQuery, getTokenFromUrl } from '../../helpers/apiHelper'
import styles from './changePassword.module.scss'
import { userAgentRequest } from '../../api/getApi'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../redux/actions/auth'
import { notify } from '../../helpers/notify'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/,
      'Password should only contain Latin letters, numbers, and special characters'
    )
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/[0-9]+/, 'Password should contain at least one number')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(20, 'Password is too long - should be 20 chars maximum.')

    .required('Field is required'),
  // .notOneOf([Yup.ref('old_password')], 'Your new password must not be the same as your old password'),
  confirm: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum')
    .max(20, 'Password is too long - should be 20 chars maximum.')
    .required('Field is required')
    .oneOf([Yup.ref('password'), null], 'The password must match the new password'),
})
const ChangePassword = () => {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlue: true,

    onSubmit: (values) => handleSubmit(values),
  })

  const dispatch = useDispatch()

  const [status, setStatus] = React.useState(null)
  const action_type = getQuery('action_type')
  const is_user_agent_needed = getQuery('user_agent')

  React.useEffect(() => {
    if (is_user_agent_needed) userAgentRequest(window.location.search).then(() => {})
  }, [is_user_agent_needed])

  if (status) {
    dispatch(userLogout())
    return <Redirect to='/auth/signin' />
  }

  function handleSubmit(values) {
    changePasswordConfirmRequest({
      new_password: values.password,
      ...getTokenFromUrl(),
    })
      .then(() => {
        notify('Success', 'Your Password has been changed!')

        setStatus(true)
      })
      .catch((error) => notify.errorsList(error.errors))
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }
    formik.handleChange(value)
  }

  const { values, errors, touched } = formik

  return (
    <div className={`page ${styles.page}`}>
      <div className={styles.form}>
        <h3 className={styles.title}>{action_type === 'create_password' ? 'Create' : 'Change'} Password</h3>

        <form onSubmit={formik.handleSubmit}>
          <fieldset className='auth-form__fieldset'>
            <div className='form-group'>
              <label className={styles.label}>New password</label>
              <InputPassword
                theme='password'
                name='password'
                value={values.password}
                onChange={handleChangeFormikValue}
                isInvalid={!!(errors.password && touched.password)}
              />
              {errors.password && touched.password ? <div className={styles.error}>{errors.password}</div> : null}
            </div>

            <div className='form-group'>
              <label className={styles.label}>Confirm</label>
              <InputPassword
                theme='password'
                name='confirm'
                value={values.confirm}
                onChange={handleChangeFormikValue}
                isInvalid={!!(errors.confirm && touched.confirm)}
              />
              {errors.confirm && touched.confirm ? <div className={styles.error}>{errors.confirm}</div> : null}
            </div>
          </fieldset>

          <div className={styles.footer}>
            <Link className={styles.close} to='/'>
              Close
            </Link>
            <Button
              style={{
                backgroundColor: 'var(--color-accent-light-blue)',
                borderColor: 'var(--color-accent-light-blue)',
                color: 'var(--second-color)',
                fontWeight: 'bold',
                width: 167,
              }}
              type='submit'
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
