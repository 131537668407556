export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorageItem = (key) => {
  const value = JSON.parse(localStorage.getItem(key))

  if (!value) return null

  return value
}

export const isPathAvailable = (permissions, permission) => {
  return permissions.includes(permission)
}

export const isMobile = () => {
  const { userAgentData } = navigator

  return userAgentData.mobile
}

export const isEveryFieldFilled = (values, skipKeyId = false) => {
  const areAllFieldsFilled = (fieldValue) => {
    if (typeof fieldValue === 'object' && fieldValue !== null) {
      return Object.values(fieldValue).every((nestedValue) => areAllFieldsFilled(nestedValue))
    }

    const isCompleteFieldId = skipKeyId ? true : fieldValue !== null

    return fieldValue !== '' && isCompleteFieldId
  }

  return Object.values(values).every((value) => areAllFieldsFilled(value))
}

export const isDefaultVesselImg = (url = '') => {
  const emptyVesselFileName = 'vessel_default.webp'
  const fileName = url.split('/').pop()

  return fileName === emptyVesselFileName
}
