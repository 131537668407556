import React from 'react'

import styles from '../styles.module.scss'

import Wrapper from '../Wrapper/Wrapper'
import EarlyAccessActivated from './EarlyAccessActivated/EarlyAccessActivated'
import EarlyAccessNewVacancies from './EarlyAccessNewVacancies/EarlyAccessNewVacancies'
import EarlyAccessHasExpired from './EarlyAccessHasExpired/EarlyAccessHasExpired'
import EarlyAccessNotActivated from './EarlyAccessNotActivated/EarlyAccessNotActivated'

import { action } from '../../constants'

const PopupEarlyAccess = ({ notificationAction, isServiceActive, handleClose }) => {
  return (
    <Wrapper
      notificationAction={notificationAction}
      isServiceActive={isServiceActive}
      onClose={handleClose}
      className={styles.wrapper}
    >
      {isServiceActive && notificationAction !== action.info && <EarlyAccessActivated />}

      {notificationAction === action.info && <EarlyAccessNewVacancies />}

      {notificationAction === action.hasExpired && <EarlyAccessHasExpired />}

      {!isServiceActive && notificationAction !== action.hasExpired && <EarlyAccessNotActivated />}
    </Wrapper>
  )
}

export default PopupEarlyAccess
