import React from 'react'
import { useSelector } from 'react-redux'
import Chat from '../components/Chat/Chat'
import Spinner from '../UI/Spinner/Spinner'
import ProfileHeader from '../components/ProfileHeader/ProfileHeader'

const MessagesPage = () => {
  const isSocketConnected = useSelector((state) => state.ui.isSocketConnected)

  return (
    <>
      <ProfileHeader title='Chats' />

      <div className='profile-container chat-page-wrapper'>{isSocketConnected ? <Chat /> : <Spinner size={100} />}</div>
    </>
  )
}

export default MessagesPage
