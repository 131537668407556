import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  type: Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.number().required().positive().integer(),
  }),
  contact: Yup.string()
    .trim()
    .required('Contact is required')
    .test('dynamic-validation', 'Invalid value for contact based on type', function (value) {
      const { type } = this.parent
      if (type) {
        if (
          type.name === 'instagram' ||
          type.name === 'skype' ||
          type.name === 'facebook' ||
          type.name === 'linkedin'
        ) {
          return Yup.string().url().isValidSync(value)
        }
        if (type.name === 'email') return Yup.string().email().isValidSync(value)
        if (type.name === 'phone') return Yup.string().integer().isValidSync(value)
      }
      return Yup.string()
    }),
})
