import scrollPreloader from '../../assets/icons/scroll-preloader.svg'
import styles from './spinner.module.scss'
import React from 'react'

export const SPINNER_TYPE = {
  MAGNIFIER: 'magnifier',
  SPINNER: 'spinner',
}

const useSpinnerElement = ({
  strokeWidth = 6,
  borderColor = '#f3f3f377',
  color = 'var(--color-accent-light)',
  size = 40,
  type = SPINNER_TYPE.SPINNER,
}) => {
  const classes = {
    spinner: {
      width: size,
      height: size,
      border: `${strokeWidth}px solid ${borderColor}`,
      borderTop: `${strokeWidth}px solid ${color}`,
    },
  }

  if (type === SPINNER_TYPE.MAGNIFIER) {
    return (
      <div style={{ width: size, height: size }} className='scroll-preloader' key='exclusive'>
        <img src={scrollPreloader} alt='preloader' />
      </div>
    )
  }

  return <div style={classes.spinner} className={styles.spinner} />
}

export default useSpinnerElement
