import React from 'react';
import PropTypes from 'prop-types';
import './Separator.scss';

const Separator = ({text}) => {
    return (
        <div className="separator">
            <div className="separator__line" />
            <div className="separator__text">{text}</div>
            <div className="separator__line" />
        </div>
    );
};

Separator.propTypes = {
    text: PropTypes.string
};

export default Separator;