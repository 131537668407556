import styles from '../styles.module.scss'
import Button from '../../../../UI/Button/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as ShareIcon } from '../../../../assets/icons/share-icon.svg'
import { StyledTooltip } from '../../../../UI/Tooltip/Tooltip.config'

const VacancyDesktopActionBlock = ({
  onAccept,
  data,
  acceptLoading,
  isCanAccept,
  setIsShareBlockOpen,
  isShareBlockOpen,
}) => {
  const { isAdmin } = useSelector(({ authentication }) => authentication.user)

  return (
    <div className={styles.actionsBlock}>
      <Button
        onClick={() => setIsShareBlockOpen(!isShareBlockOpen)}
        theme='white'
        loading={acceptLoading}
        className={styles.shareButton}
        disabled={isAdmin || acceptLoading}
      >
        <>
          Share
          <ShareIcon className={styles.shareIcon} />
        </>
      </Button>
      {isCanAccept ? (
        data.request_sent_date ? (
          <div className={styles.request_date}>
            <span>Request date</span>
            <span>{data.request_sent_date}</span>
          </div>
        ) : (
          <Button
            onClick={(e) => onAccept(e, data.company_id, data.id)}
            theme='default'
            loading={acceptLoading}
            className={styles.acceptButton}
            disabled={isAdmin || acceptLoading}
          >
            Apply
          </Button>
        )
      ) : (
        <StyledTooltip
          className={styles.applyTooltip}
          title={
            <>
              If you want to send a request for this vacancy, you need to{' '}
              <b>
                <Link to='/auth/signin'>log in</Link>
              </b>{' '}
              or{' '}
              <b>
                <Link to='/auth/signup'>sign up</Link>
              </b>
            </>
          }
          arrow={true}
          placement={'left'}
        >
          <div style={{ paddingBottom: '10px' }}>
            <Button disabled className={styles.acceptButton}>
              Apply
            </Button>
          </div>
        </StyledTooltip>
      )}
    </div>
  )
}
export default VacancyDesktopActionBlock
