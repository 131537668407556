import React, { memo } from 'react'

import PropTypes from 'prop-types'

import styles from './checkBox.module.scss'
import cn from 'classnames'

const CheckBox = memo(
  ({ id, theme = '', children = null, checked, onClick, textStyles = null, className, disabled }) => {
    const handleClick = (event) => {
      onClick(id, event)
    }

    const checkBoxClasses = cn(styles.checkbox, { [styles.active]: checked }, { [styles[theme]]: theme })
    // bordered

    return (
      <div className={cn(styles.wrapper, className, { [styles.disabled]: disabled })} onClick={handleClick}>
        <div className={cn(checkBoxClasses, { [styles.disabledCHeckbox]: disabled })} />

        {children && (
          <div style={textStyles} className={styles.text}>
            {children}
          </div>
        )}
      </div>
    )
  }
)

export default CheckBox

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  // onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.string,
}
