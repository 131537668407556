import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const TableHeadField = ({ children, className, style }) => {
  return (
    <th className={cn(styles.headField, className)} style={style}>
      {children}
    </th>
  )
}

export default TableHeadField
