import { SUPPORT } from '../types'
import { notify } from '../../helpers/notify'
import { setLocalStorage } from '../../helpers/helpers'

export const updateSupportMessages = (payload) => ({
  type: SUPPORT.GET_MESSAGES,
  payload,
})

export const setSupportUndelivered = (count) => ({
  type: SUPPORT.SET_UNDELIVERED,
  payload: { count },
})

export const newSupportMessageAction = (payload) => ({
  type: SUPPORT.NEW_MESSAGE,
  payload,
})

export const supportSetLoading = (status) => ({
  type: SUPPORT.SET_LOADING,
  payload: { status },
})

export const resetTotalUndelivered = () => ({
  type: SUPPORT.RESET_TOTAL_UNDELIVERED,
})

export const setIsSupportOpenedAction = (isOpened) => ({
  type: SUPPORT.SET_IS_OPENED,
  payload: { isOpened },
})

export const setSupportMessageTextAction = (value) => ({
  type: SUPPORT.SET_MESSAGE_TEXT,
  payload: { value },
})

export const setSupportStatus = (value) => ({
  type: SUPPORT.SET_STATUS,
  payload: { value },
})

export const setNextPageSupportMessages = (payload) => ({
  type: SUPPORT.SET_NEXT_PAGE_SUPPORT_MESSAGES,
  payload,
})

export const deleteSupportMessage = (payload) => ({
  type: SUPPORT.DELETE_SUPPORT_MESSAGE,
  payload,
})

export const replySupportMessage = (id) => ({
  type: SUPPORT.REPLY_SUPPORT_MESSAGE,
  payload: { id },
})

export const editSupportMessage = (id) => ({
  type: SUPPORT.EDIT_SUPPORT_MESSAGE,
  payload: { id },
})

export const actionSupportMessageClose = () => ({
  type: SUPPORT.ACTION_SUPPORT_MESSAGE_CLOSE,
})

export const updateSupportMessage = (payload) => ({
  type: SUPPORT.UPDATE_SUPPORT_MESSAGE,
  payload,
})

export const setSupportEditMessageError = (message) => {
  notify.error('Error', message)

  return {
    type: SUPPORT.SET_SUPPORT_EDIT_MESSAGE_ERROR,
  }
}

export const setSupportDeleteMessageError = (message) => {
  notify.error('Error', message)

  return {
    type: SUPPORT.SET_SUPPORT_DELETE_MESSAGE_ERROR,
  }
}

export const searchSupportMessage = (payload) => ({
  type: SUPPORT.SEARCH_SUPPORT_MESSAGE,
  payload,
})

export const setAnonymousId = (payload) => {
  setLocalStorage('anonymous_uuid', payload)

  return {
    type: SUPPORT.SET_ANONYMOUS_UUID,
    payload,
  }
}
