import React, { useEffect, useState } from 'react'
import styles from '../../tabs/review/GetReviews/GetReviews.module.scss'
import { ArrowDownSvg } from '../../UI/Icons/Icons'
import cn from 'classnames'
import StarRating from '../StarRating/StarRating'
import AnimateHeight from 'react-animate-height'
import { getReviewsMoreInfo } from '../../api/getApi'
import { useDispatch, useSelector } from 'react-redux'
import { clearReviewAnswer, setReviewAnswers } from '../../redux/actions/reviews.action'
import useClickOutside from '../../hooks/useClickOutside'
import UseSmartTooltip from '../../hooks/useSmartTooltip'
import moment from 'moment/moment'

function ReviewsListItem({ data }) {
  const [isOpen, setIsOpen] = useState(false)

  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const dispatch = useDispatch()
  const isOpenRef = useClickOutside(() => {
    // dispatch(clearReviewAnswer());
    setIsOpen(false)
  })
  const handleClick = () => {
    setIsOpen(!isOpen)
    const id = data.id

    if (!isOpen) {
      getReviewsMoreInfo(id).then((data) => dispatch(setReviewAnswers(data, id)))
    } else {
      dispatch(clearReviewAnswer())
    }
  }
  useEffect(() => {}, [isOpen])

  const dataDateToDateFormat = (originalDate) => (originalDate ? moment(originalDate).format(dateFormat) : 'current')

  return (
    <div ref={isOpenRef} className={styles.item}>
      <div
        className={cn(styles.cardHeader, {
          [styles.cardHeader_open]: isOpen,
        })}
        onClick={handleClick}
      >
        <div className={styles.profilePhoto}>
          <img src={data.image} alt='user' />
        </div>
        <div className={styles.profile_info}>
          <UseSmartTooltip
            tagType={'p'}
            className={styles.name}
            children={data?.name ?? 'No name'}
            amount_of_line={1}
          />
          <UseSmartTooltip tagType={'p'} className={styles.rank} children={data.rank ?? 'No info'} amount_of_line={1} />
          <p className={styles.vesselName}>{data.vessel_name}</p>
        </div>
        <div className={styles.reviewDate}>
          {dataDateToDateFormat(data.start_date)}-{dataDateToDateFormat(data.end_date)}
        </div>
        <ArrowDownSvg width='14' height='7' fill='var(--second-color)' />
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
        <div className={styles.review}>
          <ul className={styles.questions}>
            {data.questions?.map((item, index) => (
              <li key={index}>
                <p>{item.question}</p>
                <StarRating rating={item.answer} size='21px' />
              </li>
            ))}
          </ul>
          <div className={styles.reviewTextWrapper}>
            <div
              className={cn(styles.reviewText, {
                [styles.reviewText_empty]: !data.description,
              })}
            >
              {(data.description && data.description) || 'The user chose not to leave a review'}
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  )
}

export default ReviewsListItem
