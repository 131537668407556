import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from './styles.module.scss'

const sortButton = ({ onClick, sortFor, sortState, className }) => {
  return (
    <button
      className={cn(
        styles.button,
        className,
        { [styles.button_start]: sortState === 1 },
        { [styles.button_end]: sortState === 2 }
      )}
      onClick={() => onClick(sortFor)}
    />
  )
}

sortButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortFor: PropTypes.string.isRequired,
  sortState: PropTypes.number.isRequired,
}

export default sortButton
