import { API_BASE, METHOD } from './constans'
import { customHeaders, request, upload } from './auth.service'

async function authPutRequest(url, data = {}) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        ...customHeaders,
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: data,
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export async function putProfilePicture(id, file, type) {
  return await upload(`seafarer/uploads/${id}/`, {
    method: METHOD.PUT,
    type,
    file,
  })
}

export async function putProfileGeneral(data) {
  return await authPutRequest(API_BASE + `seafarer/profile/`, JSON.stringify(data))
}

export async function putProfileAddress(data) {
  return await authPutRequest(API_BASE + `seafarer/addresses/`, JSON.stringify(data))
}

export async function putProfileAnthropometry(data) {
  return await authPutRequest(API_BASE + `seafarer/anthropometry/`, JSON.stringify(data))
}

export async function putProfileEducation(data, id) {
  return await authPutRequest(API_BASE + `seafarer/educations/${id}/`, JSON.stringify(data))
}

export async function putSeafarerPassport(data, id) {
  return await authPutRequest(API_BASE + `seafarer/passports/${id}/`, JSON.stringify(data))
}

export async function putSeafarerVisa(data, id) {
  return await authPutRequest(API_BASE + `seafarer/visas/${id}/`, JSON.stringify(data))
}

export async function putRelative(data, id) {
  return await authPutRequest(API_BASE + `seafarer/relatives/${id}/`, JSON.stringify(data))
}

export async function putProfileContact(data, id) {
  return await authPutRequest(API_BASE + `seafarer/contacts/${id}/`, JSON.stringify(data))
}

export async function putSeafarerCertificate(data, id) {
  return await authPutRequest(API_BASE + `seafarer/certificates/${id}/`, JSON.stringify(data))
}

export async function putSeafarerExperience(data, id, type) {
  return await authPutRequest(API_BASE + `seafarer/experiences-${type}/${id}/`, JSON.stringify(data))
}

export async function putSeafarerLicense(data, id) {
  return await authPutRequest(API_BASE + `seafarer/licenses/${id}/`, JSON.stringify(data))
}

export async function putRequestForReview(data, id) {
  return await authPutRequest(API_BASE + `seafarer/requests/${id}/`, JSON.stringify(data))
}

// export const putUkrainianLocationInfo = async (data) => {
//   return await authPutRequest(
//     API_BASE + "seafarer/out-of-residence/",
//     JSON.stringify(data)
//   );
// };

// BILLING

export const setPersonalStatus = async (data) => {
  return await request('seafarer/personal-status/', { method: METHOD.PUT, body: JSON.stringify(data) })
}

// BILLING
