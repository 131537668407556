import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'

import Spinner from '../../UI/Spinner/Spinner'
import VacancyForm from './VacancyForm/VacancyForm'
import VacancyList from './VacancyList/VacancyList'

import { getVacancies } from '../../api/getApi'
import { vacanciesSearchHelper } from '../../helpers/vacanciesSearchHelper'
import { objToQueryConverter } from '../../helpers/apiHelper'
import { addVacancy, clearVacancies, setVacancies } from '../../redux/actions/vacancies.action'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import useViewVacancy from '../../hooks/useViewVacancy'

const ProfileVacancies = () => {
  const { vacancyId } = useViewVacancy(addVacancy)

  const [excludeId, setExcludeId] = useState(vacancyId)

  const { vacancies, filters } = useSelector(({ vacancies }) => vacancies)

  const dispatch = useDispatch()

  const scrollRef = useRef(null)

  const query = (() => {
    const { show_sea, show_land, ..._query } = filters

    const exclude_id = (() => {
      if (!excludeId) return ''
      if (isNaN(Number(excludeId))) return ''

      return excludeId
    })()

    const params = objToQueryConverter({ ..._query, exclude_id })

    return `${params}&show_sea=${show_sea}&show_land=${show_land}`
  })()

  const { handleScroll, dataCount, loading } = useInfiniteScroll(
    getVacancies,
    setVacancies,
    vacanciesSearchHelper,
    query,
    clearVacancies
  )

  useEffect(() => {
    return () => {
      dispatch(clearVacancies())
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (scrollRef.current) executeScroll()
  }, [filters])

  const executeScroll = () => scrollRef.current.scrollIntoView()

  return (
    <div className={styles.vacanciesSearch}>
      <VacancyForm setExcludeId={setExcludeId} />

      {!vacancies.length && loading ? (
        <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
      ) : (
        <VacancyList ref={scrollRef} vacancies={vacancies} count={dataCount} onScroll={handleScroll} />
      )}
    </div>
  )
}

export default ProfileVacancies
