import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCEus3LNgPet-BtxjTtKQ_ZvLIns7w1EDY',
  authDomain: 'nextship-323807.firebaseapp.com',
  projectId: 'nextship-323807',
  storageBucket: 'nextship-323807.appspot.com',
  messagingSenderId: '184026403640',
  appId: '1:184026403640:web:9f5b52ff02663cb32ae7cd',
  measurementId: 'G-K864153WRQ',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export { messaging }
