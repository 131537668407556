import { ReactComponent as DurationIcon } from '../../../assets/icons/vacancyDuration.svg'
import { ReactComponent as JoinDateIcon } from '../../../assets/icons/vacancyJoinDate.svg'
import { ReactComponent as VesselTypeIcon } from '../../../assets/icons/vacancyVesselType.svg'
import { ReactComponent as SalaryIcon } from '../../../assets/icons/vacancySalary.svg'
export const vacancyLandIconsToRender = (vacancy) => {
  return [
    { Icon: SalaryIcon, value: `${vacancy.salary_currency?.symbol} ${vacancy.salary} ${vacancy.salary_rate}` },
    {
      Icon: DurationIcon,
      value:
        `${vacancy.duration} ${vacancy.duration_period}` +
        (vacancy.duration_range ? `(+/- ${vacancy.duration_range}m)` : ''),
    },
    {
      Icon: JoinDateIcon,
      value: vacancy.joinDate || vacancy.join_date,
    },
  ]
}

export const vacancyIconsToRender = (vacancy) => {
  return [
    { Icon: SalaryIcon, value: `${vacancy.salary_currency?.symbol} ${vacancy.salary} ${vacancy.salary_rate}` },
    {
      Icon: DurationIcon,
      value:
        `${vacancy.duration} ${vacancy.duration_period}` +
        (vacancy.duration_range ? `(+/- ${vacancy.duration_range}m)` : ''),
    },
    {
      Icon: VesselTypeIcon,
      value: vacancy.vessel.vessel_type?.name || '—',
    },
    {
      Icon: JoinDateIcon,
      value: vacancy.joinDate || vacancy.join_date,
    },
  ]
}
