import React, { memo, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'
import PropTypes from 'prop-types'

import AnimateHeight from 'react-animate-height'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'
import ImageComponent from '../../../UI/Image/ImageComponent'
import VacancyDesktopActionBlock from './JobItemUI/VacancyDesktopActionBlock'
import ShareBlock from '../../ShareBlock/ShareBlock'
import VacancyDetailsLabel from '../../../UI/VacancyDetailsLabel/VacancyDetailsLabel'

const JobSearchTableItemLand = memo(
  ({ data, isOpen, onClick, isCanAccept, onAccept, acceptLoading, refs, onAnimationEnd, isScrollList }) => {
    const [isShareBlockOpen, setIsShareBlockOpen] = useState(false)

    const { picture, name: company_name } = data.company
    const { description: vacancy_description, title: jobTitle, id } = data
    const handleShareBlockClose = () => {
      setIsShareBlockOpen(false)
    }
    return (
      <div
        ref={refs}
        className={cn(
          styles.item,
          { [styles.item_open]: isOpen },
          { [styles.item_sent]: isCanAccept && data.request_sent_date }
        )}
        key={data.id}
      >
        <div className={cn(styles.shareBlockWrapper, { [styles.shareBlockWrapper_open]: isShareBlockOpen && isOpen })}>
          <ShareBlock id={data.id} onClose={handleShareBlockClose} isOpen={isShareBlockOpen && isOpen} />
        </div>
        <div
          className={cn(styles.row, {
            [styles.row_open]: isOpen,
            [styles.row_is_scroll_list]: isScrollList,
          })}
          onClick={() => onClick(data.id, isOpen)}
        >
          <div className={styles.landUrgent}>
            <UseSmartTooltip
              tagType={'p'}
              М
              className={cn(styles.field, styles.fieldTitle, styles.rankLand)}
              id={`${data.id}-${data.rank_name}`}
              children={data.rank_name || 'No information'}
              update={isOpen}
              amount_of_line={1}
            />

            {data.urgent && <VacancyDetailsLabel label='urgent' className={styles.urgent} urgent />}
          </div>

          <div className={styles.field}>Shore-Based Job</div>

          <UseSmartTooltip
            tagType={'p'}
            className={cn(styles.field, styles.salary)}
            id={`salary${id}`}
            children={`${data.salary_currency?.symbol} ${data.salary}/${data.salary_rate}`}
            update={isOpen}
            amount_of_line={1}
          />

          <div className={styles.field}>
            {data.duration} {data.duration_period}
            {Boolean(data.duration_range) && ` (+/- ${data.duration_range}m)`}
          </div>

          <div className={styles.field}>{data.join_date}</div>
        </div>

        <AnimateHeight
          style={{ flexShrink: 0, background: 'var(--color-white)', borderRadius: '8px' }}
          height={isOpen ? 'auto' : 0}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={styles.columns}>
            <div className={styles.columns_more_info}>
              <div className={styles.vacancy_description}>
                <h2>vacancy description:</h2>
                <UseSmartTooltip tagType={'p'} className={styles.job_title} update={isOpen} amount_of_line={2}>
                  {vacancy_description || jobTitle || 'No information'}
                </UseSmartTooltip>
              </div>

              <div className={cn(styles.field, styles.countryInfo)}>
                <p className={styles.countryInfoField}>
                  <b>country:</b>

                  <span>{data.country.name}</span>
                </p>

                <p className={cn(styles.countryInfoField, { [styles.empty_item]: !data.city })}>
                  <b>city:</b>

                  <span>{data.city || '------'}</span>
                </p>
              </div>
            </div>

            <div className={styles.columns_more_vessel_info}>
              <div className={styles.column_start_without_info}>
                <UseSmartTooltip
                  tagType={'p'}
                  update={isOpen}
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.shipowner.name,
                  })}
                >
                  <b>shipowner:</b>
                </UseSmartTooltip>
                <UseSmartTooltip
                  tagType={'p'}
                  update={isOpen}
                  className={cn(styles.vessel_characteristics_item, {
                    [styles.empty_item]: !data.shipowner.name,
                  })}
                >
                  {data.shipowner.name || 'No information'}
                </UseSmartTooltip>

                <div>
                  <div className={styles.col_without_info}>
                    <p
                      className={cn({
                        [styles.empty_item]: !data.salary_description,
                      })}
                    >
                      <b>Salary details:</b>
                    </p>
                    <span
                      className={cn({
                        [styles.empty_item]: !data.salary_description,
                      })}
                    >
                      {data.salary_description || 'No information'}
                    </span>
                  </div>
                  {/*<div className={styles.col}></div>*/}
                </div>
              </div>

              <div className={styles.column_end}>
                <div className={styles.shipowner_info}>
                  <ImageComponent className={styles.shipowner_avatar} src={picture.file_mini} alt='company' />

                  <UseSmartTooltip
                    tagType={'p'}
                    className={styles.shipowner_name}
                    id={'shipowner_name'}
                    children={company_name}
                    update={isOpen}
                    amount_of_line={1}
                  />
                </div>

                <VacancyDesktopActionBlock
                  onAccept={onAccept}
                  acceptLoading={acceptLoading}
                  data={data}
                  isCanAccept={isCanAccept}
                  setIsShareBlockOpen={setIsShareBlockOpen}
                  isShareBlockOpen={isShareBlockOpen}
                />
              </div>
            </div>
          </div>
        </AnimateHeight>

        {isOpen && (
          <p className={styles.publication_date}>
            Publication date: <span>{data.published_at}</span>
          </p>
        )}
      </div>
    )
  }
)

JobSearchTableItemLand.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isCanAccept: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
}

export default JobSearchTableItemLand
