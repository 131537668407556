import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = () => {
    return (
        <div>
            <div className="page">
                <Header />
                <main style={{paddingBottom: "100px"}}>
                    <div className="container">
                        <PrivacyPolicy />
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;