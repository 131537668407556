import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'

import cn from 'classnames'

export default function CompletionStatus({ value = 0, color, unfilled = [] }) {
  const colorIndicator = (value) => {
    if (value >= 50 && value < 80) return 'rangeBar_fill_more_fifty'
    if (value >= 80) return 'rangeBar_fill_more_eighty'
    return
  }
  return (
    <div className={styles.completionStatus} id='tour-step-4'>
      <div className={styles.wrapper}>
        <div className={styles.title}>Profile completion status</div>
        <div className={styles.percent}>{value}%</div>
      </div>
      <div className={styles.rangeBar}>
        <div
          style={{ width: `${value}%` }}
          className={cn(styles.rangeBar_fill, {
            [styles[colorIndicator(value)]]: value > 50,
          })}
        />
      </div>
      <div className={styles.tooltip}>
        To complete your profile, please add data in the following sections:{' '}
        {unfilled.map(({ name, link }) => (
          <NavLink key={link + name} activeClassName={styles.active} to={link}>
            {name}
          </NavLink>
        ))}
        <div className={styles.tooltipRemark}>
          It is important to note that if your profile is less than 15% complete, you will not be able to apply for
          vacancies. Keep in mind that <b>100% completed CV</b> speeds up the{' '}
          <b>recruitment process of the candidate</b> who needs certain documents such as visas. A crew manager is more
          likely <b>to give preference to the candidate</b> whose application{' '}
          <b>is ready for visa or other documents submission.</b>
        </div>
      </div>
    </div>
  )
}

CompletionStatus.propTypes = {
  value: PropTypes.number,
}
