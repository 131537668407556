export const signUpSubmitHelper = (value) => {
  if (!value) return
  return {
    email: value.email,
    password: value.password,
  }
}

export const singInSubmitHelper = (value) => {
  if (!value) return
  return {
    username: value.email,
    password: value.password,
  }
}
