import { API_BASE, API_BASE_V2 } from './constans'

async function authPatchRequest(url, data) {
  const token = JSON.parse(localStorage.getItem('user') || '{}')
  if (token) {
    const { access_token } = token
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export const setIsContactMain = async (id, status) => {
  return await authPatchRequest(`${API_BASE}seafarer/contacts/${id}/`, {
    is_main: status,
  })
}

export const setIsNextOfKin = async (id, status) => {
  return await authPatchRequest(`${API_BASE}seafarer/relatives/${id}/`, {
    next_of_kin: status,
  })
}

export const setSettings = async (data, scope) => {
  return await authPatchRequest(`${API_BASE}${scope}/settings/`, data)
}

export const setNotificationActive = async (status, scope, id) => {
  return await authPatchRequest(`${API_BASE_V2}${scope}/notifications/${id}/`, {
    is_active: status,
  })
}

export const setUiInfo = async (data) => {
  return await authPatchRequest(`${API_BASE}seafarer/ui/`, data)
}
