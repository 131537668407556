import React from "react";
import styles from "./styles.module.scss";

const LoadingJobSearchTableItem = ({ tableSize }) => {
  const items = [];

  for (let i = 1; i <= tableSize; i++) {
    items.push(
      <div key={i} className={`${styles.item} ${styles.item_loading}`}>
        {" "}
        <div className={styles.row}></div>{" "}
      </div>
    );
  }

  return items;
};

export default LoadingJobSearchTableItem;
