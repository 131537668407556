import React from 'react'
import AccentButton from '../../../UI/AccentButton/AccentButton'
import shieldSvg from '../../../assets/icons/shield.svg'
import styles from './OurTips.module.scss'
import cameraSvg from '../../../assets/icons/video-camera.svg'
import bookSvg from '../../../assets/icons/english-book.svg'
import cn from 'classnames'
import { profilePaths } from '../../../constants/constans'

const items = [
  {
    key: 1,
    icon: bookSvg,
    title: 'Take the test',
    text: 'Confirm your level of language proficiency\n',
    url: profilePaths.request,
    disabled: true,
  },
  {
    key: 2,
    icon: shieldSvg,
    title: 'Request a review!',
    text: 'Improve your rating. Find out the team score',
    url: profilePaths.request,
    disabled: true,
  },
  {
    key: 3,
    icon: cameraSvg,
    title: 'Create a video resume',
    text: 'Its a great way to differentiate yourself from others',
    url: profilePaths.video_resume,
    disabled: true,
  },
]

export default function OurTips() {
  return (
    <section className={styles.section}>
      <div className={cn('container', styles.wrapper)}>
        {items.map(({ key, ...props }) => {
          return <AccentButton key={key} {...props} />
        })}
      </div>
    </section>
  )
}
