import { REVIEWS, REQUEST } from "../types";

export const setReviews = (payload) => {
  return {
    type: REVIEWS.SET_REVIEWS,
    payload,
  };
};

export const setReviewAnswers = (payload, id = "") => {
  return {
    type: REVIEWS.SET_REVIEW_ANSWERS,
    payload,
    id,
  };
};

export const clearReviewAnswer = () => ({ type: REVIEWS.CLEAR_REVIEW_ANSWERS });

export const clearReviews = () => ({ type: REVIEWS.CLEAR_REVIEWS });

export const setRequests = (payload) => {
  return {
    type: REQUEST.SET_REQUEST,
    payload,
  };
};

export const clearRequestQuestions = () => ({
  type: REQUEST.CLEAR_REQUEST_QUESTIONS,
});

export const setRequestQuestion = (payload, id = "") => {
  return {
    type: REQUEST.SET_REQUEST_QUESTIONS,
    payload,
    id,
  };
};

export const setRequestDescription = (payload, id = "") => {
  return {
    type: REQUEST.SET_REQUEST_DESCRIPTION,
    payload,
    id,
  };
};

export const helpRequest = (data, action) => {
  const result = data.map((item) => {
    if (item.id === action.id) {
      return {
        ...item,
        ...action.payload,
      };
    } else {
      return {
        ...item,
      };
    }
  });
  return result;
};

export const clearRequests = () => ({ type: REQUEST.CLEAR_REQUEST });
