import React from 'react'
import './Logo.jsx'
import logoWhiteSvg from '../../assets/images/logo-white.svg'
import logoDarkSvg from '../../assets/images/logo-dark.svg'
import mainLogo from '../../assets/icons/main-logo.svg'
import { Link } from 'react-router-dom'
import styles from './Logo.module.scss'
import cn from 'classnames'

const Logo = ({ theme = 'white', className }) => {
  const option = {
    main: mainLogo,
    white: logoWhiteSvg,
    dark: logoDarkSvg,
  }

  return (
    <Link to='/' className={styles.logo}>
      <img className={cn(styles.logo_img, className)} src={option[theme]} alt='company logo' />
    </Link>
  )
}

export default Logo
