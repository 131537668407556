import { BILLING } from './types'

const initialState = {
  isBillingActive: false,
  services: {
    enhanced_visibility: null,
    personal_status: null,
    early_access: null,
    activity_tracking: null,
  },
  event_requires_confirmation: false,
  permissions: [],
}

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BILLING.SET_IS_ACTIVE:
      return {
        ...state,
        isBillingActive: action.payload,
      }

    case BILLING.SET_SERVICE_DATA: {
      const { service, data } = action.payload

      return {
        ...state,
        services: {
          ...state.services,
          [service]: data,
        },
      }
    }

    case BILLING.SET_BILLING_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      }

    case BILLING.SET_BILLING_PERMISSIONS_CURRENT:
      return {
        ...state,
        permissions: action.payload.permissions,
        isBillingActive: action.payload.status,
      }
    case BILLING.SET_BILLING_EVENT:
      return {
        ...state,
        event_requires_confirmation: action.payload,
      }
    default:
      return state
  }
}

export default billingReducer
