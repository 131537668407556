import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StubIcon } from '../../../../assets/images/education_stub.svg'

const Stub = () => {
  return (
    <div className={styles.wrapper}>
      <StubIcon />

      <p>
        You don't have any education yet. <br /> Please fill in the fields and add the required information
      </p>
    </div>
  )
}

export default Stub
