import React from 'react'
import styles from './styles.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { postEmailSubscribe } from '../../api/postApi'
import { notify } from '../../helpers/notify'

const FollowWidget = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Field is required'),
    }),
    onSubmit: (values) => {
      const clearFunction = formik

      postEmailSubscribe(values).then((data) => {
        if (data.ok) {
          notify('Success', 'You have successfully subscribed to the email newsletter')
        } else {
          data.errors.forEach(({ message }) => notify.error('Error', `${message}`))
        }
        clearFunction.resetForm()
      })
    },
  })

  const { values, errors, touched } = formik

  const handleChangeFormikValue = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    formik.handleChange(event)
  }

  return (
    <div className={styles.widget}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.wrapper}>
          <div className='form-group'>
            <Input
              name='email'
              placeholder='Enter your Email'
              value={values.email}
              label='Email'
              onChange={handleChangeFormikValue}
              isInvalid={errors.email && touched.email}
              className={styles.email_field}
            />
            {errors.email && touched.email ? (
              <div style={{ color: '#ffffff' }} className='input-error-message'>
                {errors.email}
              </div>
            ) : null}
          </div>
          <Button className={styles.button} type='submit' size='small'>
            Follow
          </Button>
        </div>
      </form>
    </div>
  )
}

export default FollowWidget
