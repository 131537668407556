import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import { menShoes, womenShoes } from './content'
import Radio from '../../UI/Input/Radio'
import { getAuthProfile } from '../../api/getApi'

const TableSizes = ({ handleShoeSizeClick, handleSuitSizeClick, className, suitSizes }) => {
  const [activeTab, setActiveTab] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAuthProfile()
      .then((data) => {
        setActiveTab(data.gender.name)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className={className}>
      <div className={styles.table}>
        <div className={styles.header}>
          <div className={styles.headerItem}>Clothes</div>
          <div className={styles.headerItem}>Shoes</div>
          <div className='sex-field'>
            <div className={cn(styles.sex_container, { [styles.sex_container_loading]: loading })}>
              <label>
                <Radio
                  onChange={() => setActiveTab('Female')}
                  name='gender'
                  isChecked={activeTab === 'Female'}
                  label='Female'
                  className={cn(styles.sexRadio, {
                    [styles.sexRadioActive]: activeTab === 'Female',
                  })}
                />
              </label>

              <label>
                <Radio
                  onChange={() => setActiveTab('Male')}
                  name='gender'
                  isChecked={activeTab === 'Male' || activeTab === 'Other'}
                  label='Male'
                  className={cn(styles.sexRadio, {
                    [styles.sexRadioActive]: activeTab === 'Male',
                  })}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          {activeTab === 'Male' ? (
            <div className={styles.tables}>
              <table>
                <thead>
                  <tr>
                    <th>Size</th>
                    <th>Chest (CM)</th>
                  </tr>
                </thead>
                <tbody>
                  {suitSizes.map((item, index) => (
                    <tr onClick={() => handleSuitSizeClick(item.name, item.id)} key={index}>
                      <td>{item.name}</td>
                      <td>{item.male_data}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>UK</th>
                    <th>EU</th>
                    <th>US</th>
                    <th>AU</th>
                    <th>CM</th>
                  </tr>
                </thead>
                <tbody>
                  {menShoes.map((item, index) => (
                    <tr onClick={() => handleShoeSizeClick(item.eu)} key={index}>
                      <td>{item.uk}</td>
                      <td>{item.eu}</td>
                      <td>{item.us}</td>
                      <td>{item.au}</td>
                      <td>{item.cm}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.tables}>
              <table>
                <thead>
                  <tr>
                    <th>Size</th>
                    <th>Chest (CM)</th>
                  </tr>
                </thead>
                <tbody>
                  {suitSizes.map((item, index) => (
                    <tr onClick={() => handleSuitSizeClick(item.name, item.id)} key={index}>
                      <td>{item.name}</td>
                      <td>{item.female_data}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>UK</th>
                    <th>EU</th>
                    <th>US</th>
                    <th>AU</th>
                    <th>CM</th>
                  </tr>
                </thead>
                <tbody>
                  {womenShoes.map((item, index) => (
                    <tr onClick={() => handleShoeSizeClick(item.eu)} key={index}>
                      <td>{item.uk}</td>
                      <td>{item.eu}</td>
                      <td>{item.us}</td>
                      <td>{item.au}</td>
                      <td>{item.cm}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className={styles.warning}>
        The size ratio table is for informational purposes only. It is necessary to specify the sizes of clothes and
        shoes, depending on the manufacturer.
      </div>
      <div className={styles.warning}>*Click to fill in the data</div>
    </div>
  )
}

export default TableSizes
