import React from 'react'
import styles from './styles.module.scss'
import SortButton from '../../SortButton/SortButton'
import { useDispatch, connect } from 'react-redux'
import { changeJobSearchSort } from '../../../redux/actions/mainFilter'
import cn from 'classnames'
const JobSearchTableHeader = (props) => {
  const dispatch = useDispatch()

  const handleSortButtonClick = (name) => {
    props[name] < 2 ? dispatch(changeJobSearchSort(name, props[name] + 1)) : dispatch(changeJobSearchSort(name, 0))
  }

  return (
    <div className={cn(styles.header, { [styles.header_is_scroll]: props.isScrollList })}>
      <div className={styles.header_item}>Rank</div>
      <div className={styles.header_item}>Vessel Type</div>
      <div className={styles.header_item}>
        Salary
        <SortButton
          className={styles.salary_sort}
          sortFor='salary'
          sortState={props.salary}
          onClick={handleSortButtonClick}
        />
      </div>
      <div className={styles.header_item}>
        Duration
        <SortButton
          className={styles.duration_sort}
          sortFor='duration'
          sortState={props.duration}
          onClick={handleSortButtonClick}
        />
      </div>
      <div className={styles.header_item}>
        <span className={styles.header_item_sm}>from</span> Join Date
        <SortButton
          className={styles.join_date_sort}
          sortFor='join_date'
          sortState={props.join_date}
          onClick={handleSortButtonClick}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    duration: state.mainFilter.sort.duration,
    join_date: state.mainFilter.sort.join_date,
    salary: state.mainFilter.sort.salary,
  }
}

export default connect(mapStateToProps)(JobSearchTableHeader)
