import { getColleagues, getUserCardInfo } from '../../api/getApi'
import { USER_CARD } from '../types'
import UserCardHelper from '../../helpers/userCard.helper'

export const getUserCard = (section = '') => (dispatch) => {
  if (!!section) {
    dispatch(setLoadingState(true))
    if (section === 'colleagues') {
      getColleagues()
        .then((data) => {
          dispatch(setColleaguesInfo(UserCardHelper.getColleagues(data[section])))
          dispatch(setLoadingState(false))
        })
        .catch(() => {
          dispatch(setLoadingState(false))
        })
    } else if (section !== 'colleagues') {
      getUserCardInfo(section)
        .then((response) => {
          const data = response[section]
          switch (section) {
            case 'general':
              dispatch(setGeneralInfo(UserCardHelper.getGeneralInfo(data)))
              dispatch(setLoadingGeneralState(false))
              break
            case 'contact_info':
              dispatch(setContactInfo(UserCardHelper.getContactInfo(data)))
              break
            case 'education':
              dispatch(setEducationInfo(UserCardHelper.getEducationInfo(data)))
              break
            case 'experience_all':
              dispatch(setExperienceInfo(UserCardHelper.getExperienceInfo(data)))
              break
            case 'passports':
              dispatch(setPassportsInfo(UserCardHelper.getPassportsInfo(data)))
              break
            case 'visas':
              dispatch(setVisasInfo(UserCardHelper.getVisasInfo(data)))
              break
            case 'licenses':
              dispatch(setLicensesInfo(UserCardHelper.getLicensesInfo(data)))
              break
            case 'certificates':
              dispatch(setCertificatesInfo(UserCardHelper.getCertificatesInfo(data)))
              break
            case 'anthropometry':
              dispatch(setSizeInformationInfo(UserCardHelper.getSizeInformationInfo(data)))
              break
            case 'reviews':
              dispatch(setReviewsInfo(UserCardHelper.getReviewsInfo(data)))
              break
            case 'video_resume':
              if (data) {
                dispatch(setVideoUrl(UserCardHelper.getVideoInfo(data)))
              } else {
                dispatch(setVideoUrl(UserCardHelper.getVideoInfo(null)))
              }
              break
            default:
              break
          }
          dispatch(setLoadingState(false))
        })
        .catch(() => {
          dispatch(setLoadingState(false))
        })
    }
  }
}

export const setGeneralInfo = (payload) => {
  return {
    type: USER_CARD.SET_GENERAL_INFO,
    payload,
  }
}
export const setContactInfo = (payload) => {
  return {
    type: USER_CARD.SET_CONTACT_INFO,
    payload,
  }
}
export const setEducationInfo = (payload) => {
  return {
    type: USER_CARD.SET_EDUCATION,
    payload,
  }
}
export const setExperienceInfo = (payload) => {
  return {
    type: USER_CARD.SET_EXPERIENCE,
    payload,
  }
}
export const setPassportsInfo = (payload) => {
  return {
    type: USER_CARD.SET_PASSPORTS,
    payload,
  }
}
export const setVisasInfo = (payload) => {
  return {
    type: USER_CARD.SET_VISAS,
    payload,
  }
}
export const setLicensesInfo = (payload) => {
  return {
    type: USER_CARD.SET_LICENSES,
    payload,
  }
}
export const setCertificatesInfo = (payload) => {
  return {
    type: USER_CARD.SET_CERTIFICATES,
    payload,
  }
}
export const setSizeInformationInfo = (payload) => {
  return {
    type: USER_CARD.SET_SIZE_INFORMATION,
    payload,
  }
}
export const setReviewsInfo = (payload) => {
  return {
    type: USER_CARD.SET_REVIEWS_INFO,
    payload,
  }
}
export const setVideoUrl = (payload) => {
  return {
    type: USER_CARD.SET_VIDEO_URL,
    payload,
  }
}

export const setColleaguesInfo = (payload) => {
  return {
    type: USER_CARD.SET_COLLEAGUES,
    payload,
  }
}

export const setLoadingState = (payload) => {
  return {
    type: USER_CARD.SET_LOADING_STATE,
    payload,
  }
}
export const setLoadingGeneralState = (payload) => {
  return {
    type: USER_CARD.SET_LOADING_GENERAL_STATE,
    payload,
  }
}

export const clearUserCardState = () => ({ type: USER_CARD.CLEAR_USER_CARD_STATE })
