import { toast } from 'react-toastify'
import React from 'react'

export const useNotify = () => {
  const toastId = React.useRef(null)

  function createContent(title, message) {
    return (
      <div className='toast-root'>
        <figure className='toast-circle-figure' />
        <div className='toast-body-content'>
          <span className='toast-body-title'>{title}</span>
          <span className='toast-body-text'>{message}</span>
        </div>
      </div>
    )
  }

  function createNotifyByType(type) {
    return (title, message, options) => {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast[type](createContent(title, message), options)
      }
    }
  }

  function createErrorsList() {
    return (errors, options) => {
      if (Array.isArray(errors) && errors.length) {
        errors.forEach(({ message }) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(createContent('Error', message), options)
          }
        })
      }
    }
  }

  const notify = (title, message, options) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(createContent(title, message), options)
    }
  }

  notify.error = createNotifyByType('error')
  notify.info = createNotifyByType('info')
  notify.errorsList = createErrorsList()

  return { notify }
}
