import React from "react";
import "../scss/notFountPage.scss";
import ErrorIcon from "../assets/icons/icon-error-404.svg";

const NotFoundPage = () => {
  return (
    <section className="errorWrapper">
      <div className="error">
        <picture>
          <source srcSet={ErrorIcon} type="image/webp" />
          <img src={ErrorIcon} alt="error" />
        </picture>
      </div>
    </section>
  );
};

export default NotFoundPage;
