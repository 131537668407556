import { styled } from '@mui/system'
import { DatePicker, PickersCalendarHeader, PickersDay } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'

export const CustomDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 12px 0 12px',
  },
  '& .MuiButtonBase-root': {
    zIndex: '1',
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      '&.Mui-disabled': {
        zIndex: '1',
        opacity: '1',
      },
      fontFamily: 'Montserrat, sans-serif',
      color: '#06251e',
      opacity: '1',
      fontSize: '14px',
      padding: '9px 0 9px 0',
      '&::placeholder': {
        fontFamily: 'Montserrat, sans-serif',
        color: '#06251e',
        opacity: '1',
        fontSize: '14px',
      },
    },
    '& fieldset': {
      borderColor: '#dff0eb',
    },
    '&:hover fieldset': {
      borderColor: '#dff0eb',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#94cebe',
      borderWidth: '1px',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#c7dfd8',
      backgroundColor: '#def0eb',
      '&:hover': {
        borderColor: '#c7dfd8',
      },
    },
  },
})

export const CustomHeaderContainer = styled(PickersCalendarHeader)({
  backgroundColor: '#43927c',
  margin: '0',
  paddingTop: '24px',
  paddingBottom: '24px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  color: '#fff',
  '& .MuiButtonBase-root': {
    color: '#fff',
    '& .MuiPickersDay-root.Mui-selected': {
      color: '#fff',
      backgroundColor: 'red',
      fontWeight: '500',
    },
  },
})

export const CustomPickersDay = styled(PickersDay)({
  '&.Mui-selected': {
    backgroundColor: '#43927c',
    '&:hover': {
      backgroundColor: '#06251e',
      color: '#fff',
    },
    '&:focus': {
      backgroundColor: '#43927c',
    },
  },
  '&:hover': {
    backgroundColor: '#06251e',
    color: '#fff',
  },
})

export const CustomPickersTextField = styled(TextField)({
  '& .MuiFormHelperText-root.Mui-error': {
    display: 'block',
    lineHeight: '17px',
    marginTop: '2px',
    color: '#ce3b3b',
    fontSize: '10px',
    marginLeft: '0',
  },
})
