import React, { memo } from 'react'

import styles from './styles.module.scss'

const CardPreview = memo(({ last4, exp_y, exp_m }) => {
  const transformExpYear = exp_y.toString().split('').slice(-2).join('')

  return (
    <div className={styles.cardPreview}>
      <div className={styles.cardPreview_number}>
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{last4}</span>
      </div>

      <div className={styles.cardPreview_expInfo}>
        <span>***</span>
        <span>{`${exp_m}/${transformExpYear}`}</span>
      </div>
    </div>
  )
})

export default CardPreview
