import React, { useRef } from 'react'
import cn from 'classnames'
import { motion, useAnimation } from 'framer-motion'
import { CSSTransition } from 'react-transition-group'
import ImageComponent from '../../../UI/Image/ImageComponent'
import styles from './FileList.module.scss'
import FileListPreloader from './FileListPreloader/FileListPreloader'

const FileList = ({ files, showFiles, type = 'default' }) => {
  const control = useAnimation()
  const nodeRef = useRef(null)
  React.useEffect(() => {
    if (showFiles) {
      control.start((i) => ({
        opacity: 1,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    } else {
      control.start((i) => ({
        opacity: -20,
        x: 0,
        transition: { delay: i * 0.1, type: 'spring' },
      }))
    }
    // eslint-disable-next-line
  }, [showFiles])

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={showFiles}
      timeout={500}
      unmountOnExit
      classNames={{
        enter: styles.containerEnter,
        enterActive: styles.containerEnterActive,
        exit: styles.containerExit,
        exitActive: styles.containerExitActive,
      }}
    >
      <div
        ref={nodeRef}
        className={cn(styles.container, {
          [styles.license]: type === 'license',
          [styles.endorsement]: type === 'endorsement',
          [styles.experience]: type === 'experience',
        })}
      >
        {files?.map((file, index) => (
          <motion.div
            key={index}
            className={styles.fileContainer}
            initial={{ opacity: 0, x: -20 }}
            animate={control}
            custom={index}
          >
            <a rel='noopener noreferrer' target='_blank' href={file.file}>
              <ImageComponent
                preloader={<FileListPreloader />}
                className={styles.file}
                src={file.file_mini}
                alt={'file'}
              />
            </a>
          </motion.div>
        ))}
      </div>
    </CSSTransition>
  )
}

export default FileList
