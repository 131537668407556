import * as Yup from 'yup'

import amex from './icons/amex.svg'
import masterCard from './icons/masterCard.svg'
import visa from './icons/visa.svg'

export const paymentSystem = {
  amex: 'amex',
  masterCard: 'mastercard',
  visa: 'visa',
}

export const paymentSystemsMap = {
  [paymentSystem.amex]: amex,
  [paymentSystem.masterCard]: masterCard,
  [paymentSystem.visa]: visa,
}

export const initialValues = {
  name: '',
  country: { id: null, name: '', iso2: '', iso3: '' },
  city: { id: null, name: '' },
  street: { id: null, name: '' },
  phone: '',
  zipCode: '',
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('This field is required'),
  country: Yup.object().shape({
    id: Yup.mixed().required('This field is required'),
  }),
  city: Yup.object().shape({
    // id: Yup.mixed().test('Check city', 'Select city from list', function (value) {
    //   const { name } = this.parent
    //
    //   return name ? Boolean(value) : true
    // }),
    name: Yup.string().required('This field is required'),
  }),
  street: Yup.object().shape({
    // id: Yup.mixed().test('Check street', 'Select street from list', function (value) {
    //   const { name } = this.parent
    //
    //   return name ? Boolean(value) : true
    // }),
    name: Yup.string().required('This field is required'),
  }),
  phone: Yup.number()
    .min(8)
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('This field is required')
    .typeError("That doesn't look like a phone number"),
  zipCode: Yup.string().max(30, 'Max 30 digits').required('This field is required'),
})

export const cardElementOptions = {
  hidePostalCode: true,
  hideIcon: true,
  disableLink: true,

  style: {
    base: {
      iconColor: '#c4f0ff',
      color: 'var(--second-color)',
      fontWeight: '300',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '14px',

      ':-webkit-autofill': {
        color: 'var(--second-color)',
      },

      '::placeholder': {
        color: 'var(--second-color)',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
      },
    },
  },
}

export const tooltipContent =
  'Clicking the "Remove payment information" button will delete your stored payment details from our system. This action ensures these details cannot be used for future transactions on our platform.'
