import React, { useEffect, useState } from 'react'

import Marquee from 'react-fast-marquee'

import styles from './styles.module.scss'
import cn from 'classnames'

import Wrapper from '../Wrapper/Wrapper'
import Button from '../../../../UI/Button/Button'

import { ReactComponent as ProgressIcon } from './icons/progress.svg'
import { ReactComponent as LineIcon } from './icons/line.svg'

import { useNotify } from '../../../../hooks/useNotify'
import { getLastPaymentIntent } from '../../../../api/getApi'
import { deleteBillingPaymentIntents } from '../../../../api/deleteApi'

const PendingPaymentPopup = ({
  stripe,
  data,
  updatePendingPaymentData,
  successProcessPayment,
  unSuccessProcessPayment,
}) => {
  const [clientSecret, setClientSecret] = useState(null)
  const [loading, setLoading] = useState(true)

  const { notify } = useNotify()

  useEffect(() => {
    const getLastPaymentData = async () => {
      try {
        const { client_secret } = await getLastPaymentIntent()

        setClientSecret(client_secret)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getLastPaymentData()

    //eslint-disable-next-line
  }, [])

  const handleInitPayConfirmation = async () => {
    setLoading(true)

    try {
      if (clientSecret) {
        const { paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            return_url: window.location.href,
          },
          {
            handleActions: false,
          }
        )

        if (paymentIntent) {
          const { url = '' } = paymentIntent?.next_action?.redirect_to_url

          window.open(url, '_parent')

          return
        }

        const { id } = data.last_payment_intent

        await deleteBillingPaymentIntents(id)

        successProcessPayment()
        updatePendingPaymentData(null)
      } else {
        setLoading(false)

        notify.error('Error', 'Something went wrong!')
      }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }

  const handlePendingPaymentClose = async () => {
    setLoading(true)

    try {
      const { id } = data.last_payment_intent

      await deleteBillingPaymentIntents(id)

      successProcessPayment('Succeeded!')
      updatePendingPaymentData(null)
    } catch (error) {
      unSuccessProcessPayment(error.errors)
      updatePendingPaymentData(null)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper className={styles.wrapper}>
      <div className={styles.group}>
        <h4 className={styles.title}>process</h4>

        <div className={styles.progress}>
          <Marquee pauseOnHover speed={20} direction='right' className={styles.progress_scale}>
            <span className={styles.shadow_scale}></span>
            <span className={styles.shadow_scale}></span>
            <span className={styles.shadow_scale}></span>
            <span className={styles.shadow_scale}></span>
            <span className={styles.shadow_scale}></span>
          </Marquee>
        </div>

        <ProgressIcon />
        <LineIcon />
      </div>

      <p className={styles.message}>you have a pending payment...</p>

      <div className={styles.controls}>
        <Button
          type='button'
          onClick={handleInitPayConfirmation}
          className={cn(styles.controls_button, styles.go_payment)}
          loading={loading}
          disabled={loading}
        >
          Go to the payment
        </Button>
        <Button
          type='button'
          theme='bordered'
          className={cn(styles.controls_button, styles.delete_payment)}
          onClick={handlePendingPaymentClose}
          loading={loading}
          disabled={loading}
        >
          Delete
        </Button>
      </div>
    </Wrapper>
  )
}

export default PendingPaymentPopup
