import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNotify } from './useNotify'
import { queryToObjectConverter } from '../helpers/apiHelper'

export default function useInfiniteScroll(
  requestFunction,
  updateAction,
  apiHelper = null,
  query = '',
  clearFunction = null,
  withoutDispatch = false
) {
  const [loading, setLoading] = useState(true)
  const [nextPage, setNextPage] = useState(1)
  const [dataCount, setDataCount] = useState(0)
  const [isListEmpty, setIsListEmpty] = useState(false)
  const [fetchStop, setFetchStop] = useState(false)
  const [fetchPage, setFetchPage] = useState(1)

  const currentPage = useRef()
  const isFetching = useRef(false)
  const dispatch = useDispatch()
  const { notify } = useNotify()

  const queryRef = useRef({ current: null, previous: null })

  currentPage.current = nextPage

  const defaultGetFunc = (clearFunc) => {
    requestFunction(currentPage.current, query)
      .then((data) => {
        setLoading(false)
        setDataCount(data.count)

        isFetching.current = false

        if (data.next === null) setNextPage(null)

        if (!data.ok && data.errors) {
          data.errors.forEach(({ message }) => notify.error('Error', message))
        } else {
          if (clearFunc) dispatch(clearFunc())
          if (!withoutDispatch && apiHelper) dispatch(updateAction(apiHelper(data.results, data.count, data)))
          if (withoutDispatch) updateAction((prev) => [...prev, ...data.results])
        }
      })
      .catch(({ errors }) => {
        isFetching.current = false
        notify.errorsList(errors)
      })
    isFetching.current = false
  }

  const fetchWithClearRedux = () => {
    setFetchStop(true)
    setFetchPage(null)
    currentPage.current = 1
    defaultGetFunc(clearFunction)
  }

  useEffect(() => {
    if (withoutDispatch) updateAction([])

    setNextPage(1)
    currentPage.current = 1

    if (isListEmpty) setIsListEmpty(false)

    if (clearFunction) dispatch(clearFunction())

    // eslint-disable-next-line
  }, [query, clearFunction, isListEmpty])

  useEffect(() => {
    if (isFetching.current) return

    queryRef.current.previous = queryRef.current.current
    queryRef.current.current = query

    setLoading(true)
    isFetching.current = true

    const currentReq = queryRef.current.current
    const previousReq = queryRef.current.previous

    if (!Boolean(nextPage) && currentReq === previousReq) {
      isFetching.current = false
      setLoading(false)
      return
    }

    const params = new URLSearchParams(query)
    const queryParams = queryToObjectConverter(query)
    const { show_sea, show_land, page, page_size, category, ...filteredObject } = queryParams

    // eslint-disable-next-line array-callback-return
    Object.keys(filteredObject).map((item) => {
      const value = params.get(item)
      if (value) {
        setFetchPage(null)
      }
    })

    if ((fetchStop && !fetchPage && currentReq === previousReq) || (!fetchPage && currentReq === previousReq)) {
      isFetching.current = false
      setLoading(false)
      return
    }

    if (fetchPage) defaultGetFunc()
    else fetchWithClearRedux()
    // eslint-disable-next-line
  }, [nextPage, requestFunction, apiHelper, updateAction, query, isListEmpty])

  const handleScroll = ({ target }) => {
    if (nextPage) {
      if ((target.scrollHeight - target.scrollTop - target.clientHeight) / target.scrollHeight <= 0.2 && !loading) {
        setLoading(true)
        setNextPage(currentPage.current + 1)
        fetchPage ? setFetchPage(fetchPage + 1) : setFetchPage(1)
      } else {
        return null
      }
    }
  }

  const handleListDelete = () => {
    setIsListEmpty(true)
  }

  return { loading, nextPage, handleScroll, dataCount, setDataCount, handleListDelete }
}
