import { LANDING_STATISTICS_INFO } from '../types'

const initialState = {
  company_count: 0,
  seafarer_count: 0,
  vacancy_count: 0,
}

export default function landingReducer(state = initialState, action) {
  switch (action.type) {
    case LANDING_STATISTICS_INFO.SET_LANDING_STATISTICS:
      return {
        ...state,
        company_count: action.payload.company_count,
        seafarer_count: action.payload.seafarer_count,
        vacancy_count: action.payload.vacancy_count,
      }
    case LANDING_STATISTICS_INFO.CLEAR_LANDING_STATISTICS:
      return {
        ...state,
        company_count: 0,
        seafarer_count: 0,
        vacancy_count: 0,
      }
    default:
      return state
  }
}
