import React, { memo } from 'react'

import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as AlertIcon } from '../../assets/icons/alert-triangle.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-accent-color.svg'
import imagePreviewError from '../../assets/images/image-prewiev-error.svg'

const ProcessingItemsGroupItem = memo(({ data, onDelete, isActive }) => {
  const dispatch = useDispatch()

  const onClick = (e) => {
    e.preventDefault()

    onDelete(data.id, dispatch)
  }

  return (
    <div
      className={styles.files}
      style={{ background: `url(${data.file_mini || imagePreviewError}) no-repeat center / cover` }}
    >
      <a href={data.file} rel='noopener noreferrer' target='_blank' className={styles.file}>
        <div className={styles.status_icon}>{data.is_rejected ? <AlertIcon /> : <ClockIcon />}</div>

        {data.id !== 'default' && (
          <button className={cn(styles.controls, { [styles.activeControls]: isActive })} onClick={onClick}>
            <CloseIcon width={8} height={8} fill='#06251E' />
          </button>
        )}
      </a>
    </div>
  )
})

export default ProcessingItemsGroupItem
