import React, { useCallback, useEffect, useState } from 'react'
import styles from './NotificationList.module.scss'
import NotificationListItem from './NotificationListItem'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  clearNotifications,
  deleteNotifications,
  filterNotifications,
  setAllNotificationsChecked,
  setAllNotificationsUnchecked,
  setNotificationChecked,
  setNotifications,
  updateNotifyState,
} from '../../redux/actions/notifications.action'
import { getNotifications } from '../../api/getApi'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { getNotificationsHelper, objToQueryConverter } from '../../helpers/apiHelper'
import { CircleSvg } from '../../UI/Icons/Icons'
import { postCheckNotify } from '../../api/postApi'
import { notify } from '../../helpers/notify'
import CheckBox from '../../UI/CheckBox/CheckBox'
import Input from '../../UI/Input/Input'
import FilterTabs from '../../components/FilterTabs/FilterTabs'
import Button from '../../UI/Button/Button'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { deleteNotification } from '../../api/deleteApi'
import scrollPreloader from '../../assets/icons/scroll-preloader.svg'
import { Flipped, Flipper } from 'react-flip-toolkit'

const NotificationList = () => {
  const dispatch = useDispatch()
  const { list: data, notification_type: notificationType, is_archive: isArchive } = useSelector(
    ({ notifications }) => notifications
  )
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [unCheckedNotifications, setUnCheckedNotifications] = useState([])

  useEffect(() => {
    if (!data) return
    setUnCheckedNotifications(
      data.reduce((accumulator, item) => {
        if (item.is_read) {
          return accumulator
        } else {
          return [...accumulator, item.id]
        }
      }, [])
    )
  }, [data])

  function toggleCheckbox(id, event) {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setNotificationChecked(id))
  }

  React.useEffect(() => {
    return () => {
      dispatch(clearNotifications())
    }
  }, [dispatch])

  const { handleScroll, nextPage, loading } = useInfiniteScroll(
    getNotifications,
    setNotifications,
    getNotificationsHelper,
    objToQueryConverter({ event_type: notificationType, is_archive: isArchive, page_size: 15, search: searchValue }),
    clearNotifications
  )

  const tabs = [
    {
      title: 'All',
      category: 'all',
      svg: [<CircleSvg fill='#94CEBE' />, <CircleSvg fill='#E7B92A' />, <CircleSvg fill='#EB5757' />],
    },
    {
      title: 'General',
      svg: <CircleSvg fill='#94CEBE' />,
      category: 'general',
    },
    {
      title: 'Warning',
      svg: <CircleSvg fill='#E7B92A' />,
      category: 'warning',
    },
    { title: 'Danger', svg: <CircleSvg fill='#EB5757' />, category: 'danger' },
  ]

  const [activeTab, setActiveTab] = React.useState('all')
  const [isAllChecked, setIsAllChecked] = React.useState(false)
  const [active_delete, set_active_delete] = useState(false)

  React.useEffect(() => {
    setIsAllChecked(!data.find((item) => item.is_checked === false))
  }, [data])

  React.useEffect(() => {
    return () => dispatch(clearNotifications())
  }, [dispatch])

  const handleAllClick = useCallback(() => dispatch(setAllNotificationsChecked()), [dispatch])
  const uncheckAll = useCallback(() => dispatch(setAllNotificationsUnchecked()), [dispatch])

  const handleToDelete = () => {
    const idList = data.filter((item) => item.is_checked === true)
    if (idList) {
      const ids = idList.map((item) => item.id)
      set_active_delete(true)
      deleteNotification(ids)
        .then(() => {
          dispatch(deleteNotifications(ids))
          set_active_delete(false)
          // handleListDelete()
        })
        .catch((err) => {
          set_active_delete(false)
          notify.errorsList('Error', err)
        })
    }
  }
  const handleSetSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const handleCheckNotification = (id) => {
    if (id && !isLoading) {
      setIsLoading(true)
      postCheckNotify(id)
        .then(() => {
          dispatch(updateNotifyState(id))
          setIsLoading(false)
        })
        .catch((err) => notify.errorsList('Error', err))
    }
  }

  const filterClick = (event) => {
    batch(() => {
      dispatch(clearNotifications())
      dispatch(filterNotifications(event))
    })
  }

  return (
    <section className={styles.notifications}>
      <div className={styles.notifications_controls}>
        <div className={styles.notifications_controls__selected_counter}>
          Selected <span>{data.filter((item) => item.is_checked === true).length}</span>
        </div>

        <Button
          className={styles.notifications_controls__to_delete}
          onClick={handleToDelete}
          disabled={!data.some((item) => item.is_checked === true)}
        >
          <span>Delete</span> <TrashIcon />
        </Button>
        <div className={styles.notifications_controls__filter_input_wrapper}>
          <Input value={searchValue} onChange={handleSetSearch} placeholder='Search' />
        </div>
        <FilterTabs active={activeTab} tabs={tabs} onChange={setActiveTab} onClick={filterClick} />
      </div>

      <div className={styles.header}>
        <div className={styles.header__checkbox_wrapper}>
          <CheckBox onClick={isAllChecked ? uncheckAll : handleAllClick} checked={isAllChecked} theme='bordered' />
        </div>
        <div className={styles.header_item}>Status</div>
        <div className={styles.header_item}>Notifications</div>
        <div className={styles.header_item}>Date</div>
        <div className={styles.header_item}>Time</div>
      </div>
      <div className={styles.body} onScroll={(event) => handleScroll(event)}>
        {loading && !data.length ? (
          <div style={{ margin: '40px auto', width: 60, height: 60 }} className='scroll-preloader'>
            <img src={scrollPreloader} alt='preloader' />
          </div>
        ) : data.length ? (
          <Flipper>
            {data.map((item) => (
              <Flipped key={item.id} flipId={item.id} stagger>
                <NotificationListItem
                  isRead={!unCheckedNotifications.includes(item.id)}
                  onClick={toggleCheckbox}
                  onCheck={handleCheckNotification}
                  data={item}
                  key={item.id}
                  search={searchValue}
                  active_delete={item.is_checked && active_delete}
                />
              </Flipped>
            ))}
            {loading && data.length && (
              <div style={{ margin: '40px auto', width: 60, height: 60 }} className='scroll-preloader'>
                <img src={scrollPreloader} alt='preloader' />
              </div>
            )}
            {!nextPage && data.length > 7 && <div className={styles.noContent}>You've seen everything</div>}
          </Flipper>
        ) : (
          <div className={styles.noInfo}>No information</div>
        )}
      </div>
    </section>
  )
}

export default NotificationList
