import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './redux/store'
import { HelmetProvider } from 'react-helmet-async'
import './scss/App.scss'
import 'normalize.css'
import 'react-toastify/dist/ReactToastify.min.css'

import Root from './pages/Root'
import ErrorBoundary from './HOC/ErrorBoundary'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <HelmetProvider>
    <Router>
      <Provider store={store}>
        <ErrorBoundary>
          <Root />

          <ToastContainer stacked closeOnClick position='top-right' autoClose={2000} limit={5} />
        </ErrorBoundary>
      </Provider>
    </Router>
  </HelmetProvider>
)
