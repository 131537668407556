import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const Row = ({ children, isHead, isSortLabel }) => {
  return (
    <tr className={cn(styles.row, { [styles.headRow]: isHead }, { [styles.sortLabel]: isSortLabel })}>{children}</tr>
  )
}

export default Row
