import { useEffect, useRef, useState } from 'react'
import { getUniqueCursorHelper } from '../helpers/apiHelper'

export default function useInfiniteScrollVessel({ requestFunction, setItems, name, initialCursor }) {
  const [loading, setLoading] = useState(false)
  const [nextCursor, setNextCursor] = useState(initialCursor)

  const currentCursor = useRef()

  currentCursor.current = nextCursor

  useEffect(() => {
    if (initialCursor) {
      setNextCursor(initialCursor)
      currentCursor.current = initialCursor
    } else {
      setNextCursor(null)
      currentCursor.current = null
    }
  }, [initialCursor])

  const handleScroll = ({ target }) => {
    if (nextCursor) {
      if (target.scrollTop + 300 >= target.scrollHeight && !loading) {
        setLoading(true)
        requestFunction(name, currentCursor.current).then((data) => {
          if (data) {
            setNextCursor(data.next ? getUniqueCursorHelper(data.next) : null)
            setItems((prev) => [...prev, ...data.results])
            setLoading(false)
          }
        })
      }
    }
  }

  return {
    loading,
    nextCursor,
    handleScroll,
    setNextCursor,
  }
}
