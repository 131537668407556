import React from 'react'
import styles from '../UserCard.module.scss'
import ColleagueItem from './ColleagueItem/ColleagueItem'
import { isEmpty } from 'lodash/lang'
import { ReactComponent as NoInfoImage } from '../../../assets/images/collegues_stub.svg'

const Colleagues = ({ userCardData }) => {
  const { colleagues } = userCardData
  if (isEmpty(colleagues)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={400} height={260} />
        <p>
          Currently, no colleagues have been identified in your profile based on shared vessel and <br />
          contract period experiences. This could be because your experience details are not fully <br /> updated or
          potential colleagues are not registered on NextShip.{' '}
        </p>
      </div>
    )
  }
  return (
    <div className={styles.colleagues_section}>
      <div className={styles.colleagues_head}>
        <span>Seaman</span>
        <span>Rank</span>
        <span>Time</span>
      </div>
      <div className={styles.colleagues_list_section}>
        {colleagues && colleagues.map((colleague) => <ColleagueItem key={colleague.id} colleague={colleague} />)}
      </div>
    </div>
  )
}
export default Colleagues
