import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StubIcon } from '../../../../assets/images/certificates_stub.svg'

const Stub = () => {
  return (
    <div className={styles.wrapper}>
      <StubIcon />

      <p>
        You don't have any licenses yet.
        <br />
        Add a photo of your certificates or fill in the fields
      </p>
    </div>
  )
}

export default Stub
