import { getDateByFormat } from '../../../helpers/timeHelper'
import moment from 'moment'

export const getDateBadge = (fullDate, date) => {
  const formatDate = getDateByFormat(new Date(moment(fullDate).format()), 'MMMM DD')
  const currentFormatDate = getDateByFormat(moment(new Date()).format(), 'yyyy/MM/dd')
  const sendYear = moment(fullDate).year()
  const currentYear = moment().year()
  if (date === currentFormatDate) return 'Today'

  return sendYear < currentYear ? `${formatDate}, ${sendYear}` : `${formatDate}`
}
