import { styled } from '@mui/system'
import { Tooltip, tooltipClasses } from '@mui/material'

export const StyledTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 0 15px rgba(0, 0, 0, .8)',
    padding: '17px 22px',
    maxWidth: '100%',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: '#fff',
      boxShadow: '0 4px 15px 0 rgba(148, 206, 190, 0.2)',
    },
  },
}))
