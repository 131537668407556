export const getAnthropometryHelper = (data) => {
  return {
    weight: data.weight || '',
    foot_size: data.foot_size?.toString() || '',
    height: data.height || '',
    collar: data.collar || '',
    waist: data.waist || '',
    hair_color: data.hair_color || { name: 'None', id: null },
    eye_color: data.eye_color || { name: 'None', id: null },
    suit_size: data.suit_size ? { name: data.suit_size.name, id: data.suit_size.id } : { name: 'None', id: null },
  }
}
