import React from 'react'
import { useEffect } from 'react'
import { confirmVerify, signupVerify } from '../api/postApi'
import { useState } from 'react'
import ModalAlert from '../components/ModalAlert/ModalAlert'
import { getTokenFromUrl } from '../helpers/apiHelper'
import WithPreloader from '../HOC/WithPreloader'
import { useHistory } from 'react-router-dom'
import { userLogout } from '../redux/actions/auth'
import { useDispatch } from 'react-redux'
import { notify } from '../helpers/notify'

const SignupVerifyPage = () => {
  const [isVerifySuccess, setIsVerifySuccess] = useState(null)
  const scope = new URL(window.location).searchParams.get('scope')
  const isConfirm = new URL(window.location).searchParams.get('email_confirm')
  const dispatch = useDispatch()

  const history = useHistory()

  const requestFunction = isConfirm === 'True' ? confirmVerify : signupVerify

  useEffect(() => {
    request()

    // eslint-disable-next-line
  }, [requestFunction, history])

  const request = async () => {
    try {
      const response = await requestFunction(getTokenFromUrl())

      const { need_to_change_password, token, uid } = response

      // if user was registered from CvForm -> redirect to change password page
      if (need_to_change_password) {
        history.push(`/change-password?token=${token}&uid=${uid}&scope=seafarer&action_type=create_password`)
      } else {
        dispatch(userLogout())
      }

      setIsVerifySuccess(true)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  return (
    <div className='page' style={{ height: '100%' }}>
      <main>
        <WithPreloader
          component={ModalAlert}
          isActive={isVerifySuccess === null}
          size={120}
          type='magnifier'
          isOk={isVerifySuccess}
          title={isVerifySuccess ? 'Thank you!' : 'Oops..'}
          scope={scope}
          content={isVerifySuccess ? 'Your email successfully verified.' : 'Something went wrong. Link is invalid.'}
        />
      </main>
    </div>
  )
}

export default SignupVerifyPage
