import React from 'react'
import styles from './dateExpiredChecker.module.scss'
import WarningIcon from '../Icons/./WarningIcon'
import { diffDays } from '../../helpers/itemsGroupHelper'
import { stringToISO } from '../../helpers/timeHelper'
import { StyledTooltip } from '../Tooltip/Tooltip.config'

const DateExpiredChecker = ({ children }) => {
  if (!children) return 'current'

  const currentDate = new Date()
  const isoDate = stringToISO(children)
  const diff = diffDays(new Date(isoDate), currentDate)

  const result = {}

  if (new Date(isoDate).getTime() < currentDate.getTime()) {
    result.warning = 'Document expired'
    result.circleFill = '#EB5757'
  } else if (diff <= 30) {
    result.warning = `Document expires in ${diff} days`
    result.circleFill = '#E7B92A'
  } else {
    result.warning = ''
    result.circleFill = 'inherit'
    result.pathFill = 'inherit'
  }

  return (
    <div className={styles.wrapper}>
      <span>{children}</span>

      <StyledTooltip title={result.warning} arrow={true} placement={'top'}>
        <span className={styles.expireTooltip}>
          <WarningIcon circleFill={result.circleFill} pathFill={result.pathFill} />
        </span>
      </StyledTooltip>
    </div>
  )
}

export default DateExpiredChecker
