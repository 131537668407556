import styles from '../vacancy.module.scss'
import React from 'react'

const VacancyMainInfo = ({ vacancy }) => {
  const vacancyFullInfo = [
    { name: 'Shipowner:', value: vacancy.shipowner.name || '—', isValueOptional: true },
    {
      name: 'Salary:',
      value: `${vacancy.salary} ${vacancy.salary_currency?.symbol} on ${vacancy.salary_rate}`,
      isValueOptional: true,
    },
    { name: 'Salary description:', value: vacancy.salary_description || '–', isValueOptional: true },
    { name: 'Description: ', value: vacancy.description, isValueOptional: true },
  ]

  return (
    <>
      {vacancyFullInfo.map(({ name, value, isValueOptional }, index) => {
        return isValueOptional ? (
          <div className={styles.vacancyField_short} key={index}>
            {value && <p className={styles.vacancyFieldName}>{name}</p>}
            {value && <p>{value}</p>}
          </div>
        ) : (
          isValueOptional && (
            <div className={styles.vacancyField} key={index}>
              <p className={styles.vacancyFieldName}>{name}</p>
              <p>{value}</p>
            </div>
          )
        )
      })}
    </>
  )
}
export default VacancyMainInfo
