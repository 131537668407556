import React, { memo } from 'react'
import styles from './styles.module.scss'
import logoIcon from './icons/icon.svg'
import ImageComponent from '../../../../UI/Image/ImageComponent'

const Banner = memo(() => {
  return (
    <div className={styles.banner}>
      <h1 className={styles.title}>boost your career prospects</h1>
      <div className={styles.description}>
        <p>
          We offer tools that will help you stand out among competitors and accelerate your path to a successful
          maritime career.
        </p>

        <p>Invest in yourself — become an even more sought-after specialist today!</p>
      </div>

      <div className={styles.logo}>
        <ImageComponent src={logoIcon} />
      </div>
    </div>
  )
})

export default Banner
