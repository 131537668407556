import React from 'react'

import styles from '../../styles.module.scss'

const EarlyAccessActivated = () => {
  return (
    <div className={styles.description}>
      <p className={styles.description_item}>Early Access Service Activated!</p>
      <p className={styles.description_item}>
        Now you will be among the first to be notified about the latest vacancies.
      </p>
    </div>
  )
}

export default EarlyAccessActivated
