import React from 'react'
import styles from './changePassword.module.scss'
import { useFormik } from 'formik'
import Button from '../../../UI/Button/Button'
import InputPassword from '../../../UI/Input/InputPassword'
import { changePasswordRequest } from '../../../api/postApi'
import { userLogout } from '../../../redux/actions/auth'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useNotify } from '../../../hooks/useNotify'
import { validationSchema } from './config'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlue: true,

    onSubmit: (values) => handleChangePassword(values),
  })

  const { values, errors, touched } = formik

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }
    formik.handleChange(value)
  }

  const handleChangePassword = ({ old_password, new_password }) => {
    changePasswordRequest({ old_password, new_password })
      .then(() => {
        notify('Success', 'Your password was successfully changed')

        dispatch(userLogout())

        return <Redirect to='/auth/signin' />
      })
      .catch((error) => notify.errorsList(error.errors))
  }

  const completelyFilled = Boolean(values.new_password && values.old_password && values.confirm)

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.block}>
        <div className={styles.field}>
          <div className={styles.label}>Old password</div>
          <InputPassword
            autoComplete='new-password'
            name='old_password'
            value={values.old_password}
            onChange={handleChangeFormikValue}
            isInvalid={!!(errors.old_password && touched.old_password)}
            errorMessage={errors.old_password}
            // theme='bordered'
            theme='password'
          />
        </div>

        <div className={styles.field}>
          <div className={styles.label}>New password</div>
          <InputPassword
            theme='password'
            autoComplete='new-password'
            name='new_password'
            value={formik.values.new_password}
            onChange={handleChangeFormikValue}
            isInvalid={!!(errors.new_password && touched.new_password)}
            errorMessage={errors.new_password}
            // theme='bordered'
          />
        </div>

        <div className={styles.field}>
          <div className={styles.label}>Confirm new password</div>
          <InputPassword
            autoComplete='new-password'
            name='confirm'
            value={formik.values.confirm}
            onChange={handleChangeFormikValue}
            isInvalid={!!(errors.confirm && touched.confirm)}
            errorMessage={errors.confirm}
            theme='bordered'
          />
        </div>

        <div className={styles.controls}>
          <Button disabled={!completelyFilled} type='submit' size='middle'>
            Confirm
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ChangePassword
