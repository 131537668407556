import React from 'react'
import AppleSignin from 'react-apple-signin-auth'
import { useDispatch } from 'react-redux'
import { getUserAppleToken } from '../../redux/actions/auth'
import { ReactComponent as AppleLogo } from '../../assets/icons/Apple-auth-logo.svg'
import SocialButton from '../../components/SocialButton/SocialButton'

const AppleAuthButton = () => {
  const dispatch = useDispatch()

  const redirectURI = () => {
    if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
      return 'https://dev.nextship.net/seafarer/signin/apple'
    } else if (window.location.href.includes('stage')) {
      return `https://stage.nextship.net/seafarer/signin/apple`
    } else {
      return `https://nextship.net/seafarer/signin/apple`
    }
  }

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: 'net.nextship.web',
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: redirectURI(),
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        // nonce: "nonce",
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType='dark'
      /** className */
      className='apple-auth-btn'
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      // buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={(response) =>
        dispatch(
          getUserAppleToken({
            token: response.authorization.id_token,
            grant_type: 'convert_token',
            backend: 'apple-id',
          })
        )
      } // default = undefined
      /** Called upon signin error */
      onError={(error) => console.error(error)} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      // iconProp={{ style: { marginTop: "10px" } }} // default = undefined
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(props) => (
        <div {...props} style={{ background: 'none', border: 'none' }}>
          <SocialButton style={{ backgroundColor: '#000' }}>
            <AppleLogo />
          </SocialButton>
        </div>
      )}
    />
  )
}

export default AppleAuthButton
