import React from 'react'
import styles from './styles.module.scss'
import { paymentIntentStatus } from './constants'
import { getDateByFormat } from '../../../helpers/timeHelper'
import store from '../../../redux/store'
import moment from 'moment'

export const transformBillingDetails = (card, countries) => {
  if (!card.id) return {}

  const { name, country, city, line1, phone, zip } = card

  return {
    name,
    phone,
    country: countries.find(({ iso2 }) => iso2 === country),
    city: { name: city, id: `${country}-${0}` },
    street: { name: line1, id: `${city}-${1}` },
    zipCode: zip,
  }
}

export const centToDollar = (price) => {
  return new Intl.NumberFormat('eu-US').format(price / 100)
}

export const updateUnpaidIds = (unpaidProductsId, id) => {
  if (unpaidProductsId.includes(id)) {
    return unpaidProductsId.filter((productId) => productId !== id)
  }

  return [...unpaidProductsId, id]
}

export const updatePaidIds = (billingSubscription) => {
  const activePaidSubscriptionPlans = billingSubscription.subscription_plan

  if (billingSubscription.is_active) {
    return activePaidSubscriptionPlans.products.map(({ product }) => product.id)
  }

  return []
}

export const updateUnProlongedProducts = (billingSubscription, subscriptionPlans) => {
  const activePaidSubscriptionPlans = (() => {
    // if event, result === {}
    if (Array.isArray(subscriptionPlans)) {
      return subscriptionPlans.find((plan) => plan.is_active)?.products
    }

    return subscriptionPlans.products
  })()

  if (billingSubscription.is_active) {
    return activePaidSubscriptionPlans.filter(({ is_prolongation, expire_datetime, id }) => {
      if (!is_prolongation) return { is_prolongation, expire_datetime, id }

      return false
    })
  }

  return []
}

export const updatePendingPaymentData = (data, updateAction) => {
  const { status = '' } = data.last_payment_intent ?? {}

  if (status === paymentIntentStatus.confirmation || status === paymentIntentStatus.action) {
    updateAction(data)
  }

  if (status === paymentIntentStatus.succeeded) updateAction(null)
  if (!status) updateAction(null)
}

export const getErrorMessageWithMinPrice = (newRenewDate) => {
  const { date_format: userFormat } = store.getState().user.settings

  const newDate = getDateByFormat(moment(newRenewDate), `${userFormat} HH:mm`).replaceAll('/', '.')

  return (
    <span className={styles.minPrice}>
      <b>Temporary Purchase Restriction</b>

      <span>
        Purchases of services costing less than $1 are temporarily restricted. Please initiate your purchase on the day
        of your next billing cycle <b>{newDate}</b> to access all service features.
      </span>
    </span>
  )
}
