import React from 'react'
import styles from './SupportDeleteConfirmation.module.scss'

export const SupportDeleteConfirmation = ({ onTrue, onFalse, onClose }) => {
  return (
    <div className={styles.confirm_wrapper}>
      <div className={styles.confirm}>
        <p>Are you sure?</p>
        <p>Do you want to delete this message?</p>
        <div className={styles.confirm_controls}>
          <button
            data-confirm='yes'
            className={styles.confirm_controls__yesBtn}
            onClick={() => {
              onTrue()
              onClose()
            }}
          >
            Yes
          </button>
          <button
            data-confirm='close'
            className={styles.confirm_controls__closeBtn}
            onClick={() => {
              if (onFalse) onFalse()
              onClose()
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
