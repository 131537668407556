import React from 'react'
import { operationType } from '../../constants/constans'
import replyIcon from '../../assets/icons/reply.svg'
import editIcon from '../../assets/icons/edit.svg'
import deleteIcon from '../../assets/icons/trash.svg'
import useClickOutside from '../../hooks/useClickOutside'
import styles from './styles.module.scss'
import cn from 'classnames'

const OutgoingControls = ({ id, onReply, onEdit, onDelete, setIsOpenMenu, isOpenMenu }) => {
  const ref = useClickOutside(() => setIsOpenMenu(null))

  return (
    <div ref={ref} className={cn(styles.controls, { [styles.open_controls]: isOpenMenu })}>
      <button onClick={() => onReply(id)}>
        <img src={replyIcon} alt='reply message' />

        <span>{operationType.reply}</span>
      </button>

      <button onClick={() => onEdit(id)}>
        <img src={editIcon} alt='edit message' />

        <span>{operationType.edit}</span>
      </button>

      <button onClick={() => onDelete(id)}>
        <img src={deleteIcon} alt='delete icon' />

        <span>{operationType.delete}</span>
      </button>
    </div>
  )
}

export default OutgoingControls
