import React from 'react'

const WarningIcon = ({ width = '17', height = '16', circleFill = '#E7B92A', pathFill = 'var(--color-white)' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='8.5' cy='8' r='8' fill={circleFill} />
      <path
        d='M8.50561 9.60412C8.12563 9.60412 7.81226 9.30641 7.7928 8.92692L7.55091 4.21017C7.52274 3.66081 7.96057 3.19995 8.51065 3.19995V3.19995C9.06154 3.19995 9.49967 3.66211 9.47028 4.21221L9.21835 8.92844C9.19811 9.3073 8.88501 9.60412 8.50561 9.60412V9.60412ZM8.51173 12.8746C8.19244 12.8746 7.92788 12.7697 7.71805 12.5599C7.50823 12.3501 7.40332 12.0992 7.40332 11.8073C7.40332 11.5153 7.50823 11.269 7.71805 11.0683C7.92788 10.8585 8.19244 10.7536 8.51173 10.7536C8.83103 10.7536 9.09103 10.8585 9.29173 11.0683C9.49243 11.269 9.59278 11.5153 9.59278 11.8073C9.59278 12.0992 9.49243 12.3501 9.29173 12.5599C9.09103 12.7697 8.83103 12.8746 8.51173 12.8746Z'
        fill={pathFill}
      />
    </svg>
  )
}

export default WarningIcon
