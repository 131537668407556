import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'
import cn from 'classnames'

import { confirm } from '../../../UI/Confirm/Confirm'
import { Header } from './Header/Header'
import { Message } from './Message/Message'
import { Footer } from './Footer/Footer'
import ChatEmpty from './ChatEmpty/ChatEmpty'
import Spinner from '../../../UI/Spinner/Spinner'

import { createItemRef } from '../../../helpers/createItemRef'
import {
  actionMessageClose,
  changeMessageValue,
  editMessageStart,
  replyMessageStart,
  startPaginationLoading,
} from '../../../redux/actions/chat.action'
import useListenEvent from '../../../hooks/useListenEvent'
import socket from '../../../websocket'
import { events, profilePaths } from '../../../constants/constans'
import { getDateBadge } from './utils'

export const ChatRoom = memo(({ chat_id }) => {
  const [isOnline, setIsOnline] = useState(false)
  const [companyName, setCompanyName] = useState(null)
  const [userEmail, setUserEmail] = useState('#')
  const [contextClickMessage, setContextClickMessage] = useState(null)
  const [scrollDirection, setScrollDirection] = useState({ up: true, down: false })
  const [scrolling, setScrolling] = useState(false)

  const avatar = useSelector(({ user }) => user.profile.picture?.file_mini)
  const dialog = useSelector(({ chat }) => chat.open_dialog)
  const company_user_email = useSelector(({ chat }) => chat.company_user_email)
  const dialogsList = useSelector(({ chat }) => chat.dialogs.list)
  const pagination = useSelector(({ chat }) => chat.pagination)

  const messagesRef = useRef(null)

  const dispatch = useDispatch()

  const { chat } = events

  const history = useHistory()

  const refs = createItemRef(dialog?.messages)

  useEffect(() => {
    const user = dialogsList.find((item) => Number(item.chat_id) === Number(chat_id))

    if (!user) {
      const url = profilePaths.chat

      history.replace(url)
    }

    if (user) {
      setIsOnline(user?.is_online)
      setCompanyName(user?.company_name)
    }

    if (dialog) {
      setUserEmail(company_user_email)
    }

    // eslint-disable-next-line
  }, [dialogsList, chat_id, dialog])

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scroll({ top: 0 })
    }

    dispatch(changeMessageValue(''))
    dispatch(actionMessageClose())

    // eslint-disable-next-line
  }, [chat_id])

  useEffect(() => {
    if (dialog?.searchMessageId)
      refs[dialog.searchMessageId].current.scrollIntoView({
        behavior: 'smooth',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog?.searchMessageId])

  const onMessageCallback = useCallback(
    (data) => {
      const isRoomExist = dialogsList.some((item) => item.chat_id === data.chat_id)

      // if this is a new message and there is no such chat yet
      if (!isRoomExist) socket.emit(chat.rooms)

      if (data.chat_id === Number(chat_id) && messagesRef.current) {
        // if new incoming message was in current chat room, send emmit that message was delivered
        if (!data.is_outgoing) {
          socket.emit(chat.messageDelivered, { chat_id })
        }

        // smooth scroll to bottom after new message in current chat room
        messagesRef.current.scroll({ top: 0, behavior: 'smooth' })
      }
    },

    // eslint-disable-next-line
    [chat_id, dialogsList]
  )

  useListenEvent(chat.newMessage, onMessageCallback, [chat_id, dialogsList])

  const handleReply = useCallback(
    (id) => {
      dispatch(replyMessageStart(id))
      setContextClickMessage(null)
    },

    // eslint-disable-next-line
    []
  )

  const handleEdit = useCallback(
    (id) => {
      dispatch(editMessageStart(id))
      setContextClickMessage(null)
    },

    // eslint-disable-next-line
    []
  )

  const handleDelete = useCallback(
    (id) => {
      confirm('Are you sure?', 'Do you want to delete this message?', () => {
        socket.emit('chat.delete.message', { message_id: id, chat_id: Number(chat_id) })
      })
    },
    [chat_id]
  )

  const scrollEndTimer = () => {
    setTimeout(() => {
      setScrolling(false)
    }, 2000)
  }

  const handleScroll = (e) => {
    setScrollDirection({ up: false, down: false })
    setScrolling(true)

    const { scrollTop, scrollHeight } = e.target

    if (pagination?.has_more_up && !pagination.loading && scrollDirection.up) {
      if (scrollTop + scrollHeight - 800 < 0) {
        dispatch(startPaginationLoading())

        socket.emit(chat.messages, {
          chat_id: Number(chat_id),
          last_message_id: dialog.messages[dialog.messages.length - 1].id,
        })
      }

      return
    }

    if (pagination?.has_more_down && !pagination.loading && scrollDirection.down) {
      if (scrollTop - scrollHeight + 800 < 0) {
        dispatch(startPaginationLoading())

        socket.emit(chat.messages, {
          chat_id: Number(chat_id),
          reverse: true,
          last_message_id: dialog.messages[0].id,
        })
      }
    }

    clearTimeout(scrollEndTimer())
  }

  const handleContextMenuClick = useCallback((e, id) => {
    e.preventDefault()

    setContextClickMessage(id)
  }, [])

  const handleReplyClick = useCallback(
    (id) => {
      dispatch(startPaginationLoading())

      socket.emit(chat.searchMessage, {
        page_size: 5,
        message_id: id,
        chat_id: Number(chat_id),
      })
    },

    // eslint-disable-next-line
    [chat_id]
  )

  const handleScrollDirection = useCallback((e) => {
    if (e.nativeEvent.wheelDelta > 0) {
      setScrollDirection({ up: true, down: false })
    } else {
      setScrollDirection({ down: true, up: false })
    }
  }, [])

  const messages = useMemo(() => {
    if (!dialog) return

    const stack = {}

    return dialog.messages.reduce((acc, cur) => {
      const [date, time] = cur.date.split(' ')
      const dateBadge = getDateBadge(`${date} ${time}`, date)

      if (stack[date]) {
        return acc.map((dateObj) => {
          if (dateObj.date !== date) return dateObj

          return { ...dateObj, items: [...dateObj.items, cur] }
        })
      }

      stack[date] = true

      return [...acc, { date, dateBadge, items: [cur] }]
    }, [])
  }, [dialog])
  return (
    <div className={styles.room}>
      {dialogsList.length === 0 && <ChatEmpty />}

      {dialog && chat_id && (
        <>
          <Header interlocutor={dialog.interlocutor} isOnline={isOnline} companyName={companyName} email={userEmail} />
          <div className={styles.main} ref={messagesRef} onScroll={handleScroll} onWheel={handleScrollDirection}>
            {pagination?.has_more_down && pagination.loading && (
              <div className={styles.spinnerWrapper}>
                <Spinner />
              </div>
            )}

            {messages &&
              messages.map(({ date, dateBadge, items }) => (
                <div key={date}>
                  <div className={cn(styles.dateBadge, { [styles.dateBadge__active]: scrolling })}>
                    <span>{dateBadge}</span>
                  </div>

                  <div className={styles.messages}>
                    {items.map((message) => (
                      <Message
                        key={message.id}
                        refs={refs[message.id]}
                        id={message.id}
                        userName={message?.user?.name?.split(' ')[1]}
                        avatar={message.is_outgoing ? avatar : message?.user?.avatar}
                        message={message.message}
                        replyMessage={message.reply}
                        editMessage={message.edited}
                        isOutgoing={message.is_outgoing}
                        date={message.date}
                        onReply={handleReply}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onContextMenu={handleContextMenuClick}
                        isOpenMenu={contextClickMessage === message.id}
                        setIsOpenMenu={setContextClickMessage}
                        onReplyClick={handleReplyClick}
                        searchMessageId={dialog.searchMessageId}
                      />
                    ))}
                  </div>
                </div>
              ))}

            {pagination?.has_more_up && pagination.loading && (
              <div className={styles.spinnerWrapper}>
                <Spinner />
              </div>
            )}
          </div>

          <Footer chat_id={chat_id} />
        </>
      )}
    </div>
  )
})
