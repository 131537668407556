import React from 'react'
import ReviewQuestion from './ReviewQuestion/ReviewQuestion'
import styles from '../../UserCard.module.scss'
import cn from 'classnames'
import UseSmartTooltip from '../../../../hooks/useSmartTooltip'

const ReviewItem = ({ review }) => {
  return (
    <div className={styles.review}>
      <div className={styles.reviewHeader}>
        <h4 className={styles.headerTitle}>{review.rank}</h4>
        <p className={styles.headerDate}>{review.date}</p>
      </div>
      <div className={styles.reviewBody}>
        <h4 className={styles.bodyTitle}>{review.vesselName}</h4>

        {review.questions?.map((question) => (
          <ReviewQuestion key={question.id} reviewQuestion={question} />
        ))}

        <div className={styles.reviewDescription}>
          <p
            className={cn(styles.reviewDescriptionText, { [styles.reviewDescriptionText_empty]: !review.description })}
          >
            {review.description ? review.description : 'The user chose not to leave a review'}
          </p>
        </div>
      </div>
      <div className={styles.reviewFooter}>
        <h5 className={styles.footerTitle}>From: </h5>
        <UseSmartTooltip
          tagType={'b'}
          amount_of_line={1}
          className={styles.fromSeafarer}
          children={review.seafarerName}
        />
      </div>
    </div>
  )
}

export default ReviewItem
