import React from 'react'
import TabNav from '../components/TabNav/TabNav'
import Services from '../components/Store/Services/Services'
import History from '../components/Store/History/History'

import { profilePaths } from '../constants/constans'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const links = [
  {
    url: profilePaths.billing_services,
    title: 'Services',
    component: Services,
  },
  {
    url: profilePaths.billing_history,
    title: 'Payment history',
    component: History,
  },
]

const BillingPage = () => {
  const { isBillingActive } = useSelector(({ billing }) => billing)

  if (!isBillingActive) {
    return <Redirect to='/profile/edit/personal-data/general' />
  }
  return <TabNav title='Store' links={links} redirectLink={profilePaths.billing_services} />
}

export default BillingPage
