import styles from '../styles.module.scss'
import React, { memo } from 'react'
import { ReactComponent as JoinDateIcon } from '../../../../assets/icons/vacancyJoinDate.svg'
import { ReactComponent as DurationIcon } from '../../../../assets/icons/vacancyDuration.svg'
import { ReactComponent as SalaryIcon } from '../../../../assets/icons/vacancySalary.svg'
import { ReactComponent as VesselIcon } from '../../../../assets/icons/vacancyVesselType.svg'
import PropTypes from 'prop-types'
import cn from 'classnames'

const JobTabletMainInfo = memo(({ item, isAppPopUpOpen }) => {
  const { join_date, vessel, salary, duration, duration_period, salary_currency } = item

  const { vessel_type } = vessel

  const { name: vessel_category } = vessel_type
  const { symbol: currency_symbol } = salary_currency

  const mainInfo = [
    { Icon: SalaryIcon, value: `${currency_symbol} ${salary}` },
    { Icon: DurationIcon, value: `${duration} ${duration_period}` },
    { Icon: VesselIcon, value: vessel_category },
    { Icon: JoinDateIcon, value: join_date, last: true },
  ]
  return (
    <div className={cn(styles.main_info)}>
      {mainInfo.map(
        ({ Icon, value, last }, index) =>
          value && (
            <div className={styles.main_info_block} key={index}>
              <p>
                <Icon />
                <span>{value}</span>
              </p>
              {mainInfo.length - 1 !== index && <div className={styles.dot}></div>}
            </div>
          )
      )}
    </div>
  )
})

JobTabletMainInfo.propTypes = {
  item: PropTypes.object.isRequired,
}
export default JobTabletMainInfo
