import React from "react";
import styles from "./TechnicalWorks.module.scss";

function TechnicalWorks({ data }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>
        Technical works. Probable downtime: {data.downtime}
      </div>
    </div>
  );
}

export default TechnicalWorks;
