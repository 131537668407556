import React from "react";
import Portal from "../../components/Portal/Portal";
import styles from "./Modal.module.scss";
import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";

export default function Modal({ children, onClose }) {
  return (
    <Portal>
      <div className={styles.modal}>
        {children}
        <button onClick={onClose} className={styles.closeButton}>
          <CloseSvg />
        </button>
      </div>
    </Portal>
  );
}
