import React from 'react'
import RatingIcon from '../StarRating/RatingIcon'
import styles from './StarRating.module.scss'

function StarRating({ fill, rating, size, count = 5, onSaveRating, hoverRating, onMouseEnter, onMouseLeave }) {
  const starsAmount = Array.from({ length: count })
  return (
    <div className={styles.ratingPicker}>
      {starsAmount.map((item, index) => {
        if (onSaveRating) {
          return (
            <RatingIcon
              size={size}
              key={index}
              index={index + 1}
              rating={rating}
              hoverRating={hoverRating}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onSaveRating={onSaveRating}
            />
          )
        } else {
          return <RatingIcon fillColor='#1CCF9E' rating={rating} index={index + 1} size={size} key={index} />
        }
      })}
    </div>
  )
}

export default StarRating
