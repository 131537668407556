import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import '../../scss/App.scss'
import { StyledTooltip } from './Tooltip.config'

const Tooltip = ({ symbol = '?', children = 'Example text' }) => {
  return (
    <div className={styles.wrapper}>
      <StyledTooltip title={children} arrow placement='right'>
        <span className={styles.symbol}>{symbol}</span>
      </StyledTooltip>
    </div>
  )
}

export default Tooltip

Tooltip.propTypes = {
  symbol: PropTypes.node,
  children: PropTypes.node.isRequired,
}
