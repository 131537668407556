import React, { useMemo, useRef } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-accent-color.svg'
import styles from './shareBlock.module.scss'
import Button from '../../UI/Button/Button'
import cn from 'classnames'
import { copyText } from '../../helpers/copyText.helper'
import { getLinks } from './config'

const ShareBlock = ({ id, onClose, isOpen, isMobileView }) => {
  const textToCopyRef = useRef(null)

  const link = encodeURIComponent(`${window.location.origin}/vacancy/?id=${id}`)

  const links = useMemo(() => getLinks(id), [id])

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper_transparent]: !isOpen,
        [styles.wrapper_mobile]: isMobileView,
        [styles.wrapper_mobile_open]: isOpen && isMobileView,
        [styles.wrapper_opening]: isOpen && !isMobileView,
        [styles.wrapper_closing]: !isOpen && !isMobileView,
      })}
    >
      <button className={styles.close}>
        <CloseIcon stroke='#07251e' onClick={onClose} />
      </button>
      <b>Share</b>
      <div
        className={cn(styles.networks_links, {
          [styles.networks_links_mobile]: isMobileView,
        })}
      >
        {links.map(({ Icon, link }, index) => (
          <a key={index} href={link} target='_blank' rel='noopener noreferrer'>
            <Icon className={styles.icon} />
          </a>
        ))}
      </div>
      <div className={styles.link}>
        <span ref={textToCopyRef}>{decodeURIComponent(link)}</span>
        <Button className={styles.shareBtn} onClick={() => copyText(textToCopyRef)} size={'middle-full'}>
          Copy
        </Button>
      </div>
    </div>
  )
}
export default ShareBlock
