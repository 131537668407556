import { useEffect, useRef, useState } from 'react'
import { getUniquePageHelper } from '../helpers/apiHelper'

export default function useInfiniteScrollAutocomplete({ requestFunction, setItems, search, initialPage, apiHelper }) {
  const [loading, setLoading] = useState(false)
  const [nextPage, setNextPage] = useState(initialPage)
  const currentPage = useRef()
  currentPage.current = nextPage
  useEffect(() => {
    if (initialPage) {
      setNextPage(initialPage)
      currentPage.current = initialPage
    } else {
      setNextPage(null)
      currentPage.current = null
    }
  }, [initialPage])

  const handleScroll = ({ target }) => {
    if (nextPage) {
      if (target.scrollTop + 300 >= target.scrollHeight && !loading) {
        setLoading(true)
        requestFunction(currentPage.current, search).then((data) => {
          if (data) {
            setNextPage(data.next ? getUniquePageHelper(data.next) : null)
            if (apiHelper) {
              setItems((prev) => {
                return [...prev, ...apiHelper(data?.results)]
              })
            } else {
              setItems((prev) => {
                return [...prev, ...data?.results]
              })
            }
            setLoading(false)
          }
        })
      }
    }
  }

  return {
    loading,
    nextPage,
    handleScroll,
    setNextPage,
  }
}
