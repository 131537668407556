import React, { useState } from 'react'

import styles from '../styles.module.scss'

import Wrapper from '../Wrapper/Wrapper'
import ActivityTrackingActivated from './ActivityTrackingActivated/ActivityTrackingActivated'
import ActivityTrackingNewProfileViews from './ActivityTrackingNewProfileViews/ActivityTrackingNewProfileViews'
import ActivityTrackingHasExpired from './ActivityTrackingHasExpired/ActivityTrackingHasExpired'
import ActivityTrackingNotActivated from './ActivityTrackingNotActivated/ActivityTrackingNotActivated'
import Spinner from '../../../../../UI/Spinner/Spinner'

import { action, category, serviceMap } from '../../constants'
import { getServiceDataByCategory } from '../../../../../api/getApi'
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll'

const PopupActivityTracking = ({ notificationAction, isServiceActive, handleClose }) => {
  const [views, setViews] = useState([])

  const { handleScroll, loading, dataCount: count } = useInfiniteScroll(
    getServiceDataByCategory,
    setViews,
    null,
    `service=${serviceMap.activityTracking}&category=${category.all}`,
    null,
    true
  )

  const [firstItem = {}] = views
  const newAction = notificationAction ?? firstItem?.notification_data?.action

  const isViews = isServiceActive && newAction === action.info && count !== 0
  const isActivated = isServiceActive && newAction !== action.info && count === 0
  const isLoading = isServiceActive && newAction !== action.hasExpired

  return (
    <Wrapper
      notificationAction={notificationAction}
      isServiceActive={isServiceActive}
      onClose={handleClose}
      className={styles.wrapper}
    >
      {isActivated && !loading && <ActivityTrackingActivated />}

      {isViews && <ActivityTrackingNewProfileViews views={views} viewsLength={count} onScroll={handleScroll} />}

      {!isViews && isLoading && loading && <Spinner className={styles.spinner} />}

      {notificationAction === action.hasExpired && <ActivityTrackingHasExpired />}

      {!isServiceActive && notificationAction !== action.hasExpired && <ActivityTrackingNotActivated />}
    </Wrapper>
  )
}

export default PopupActivityTracking
