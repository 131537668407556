export const menSuits = [
  { size: "XXS", chest: "76-81.5" },
  { size: "XS", chest: "84-89" },
  { size: "S", chest: "91.5-96.5" },
  { size: "M", chest: "99-104" },
  { size: "L", chest: "106.5-112" },
  { size: "XL", chest: "114.5-119.5" },
  { size: "2XL", chest: "122-127" },
  { size: "3XL", chest: "129.5-134.5" },
  { size: "4XL", chest: "137-142" },
  { size: "5XL", chest: "145-150" },
];

export const womenSuits = [
  { size: "XXS", chest: "76-82" },
  { size: "XS", chest: "82-85" },
  { size: "S", chest: "86-89" },
  { size: "M", chest: "90-93" },
  { size: "L", chest: "94-97" },
  { size: "XL", chest: "98-102" },
  { size: "2XL", chest: "103-107" },
  { size: "3XL", chest: "108-112" },
  { size: "4XL", chest: "112-119" },
  { size: "5XL", chest: "120-125" },
];

export const menShoes = [
  { uk: "6.5", eu: "40", us: "7.5", au: "6.5", cm: "25" },
  { uk: "7", eu: "41", us: "8", au: "7", cm: "25.5" },
  { uk: "8", eu: "42", us: "9", au: "8", cm: "26.5" },
  { uk: "9", eu: "43", us: "10", au: "9", cm: "27.5" },
  { uk: "10", eu: "44", us: "11", au: "10", cm: "28.5" },
  { uk: "11", eu: "45", us: "12", au: "11", cm: "29.5" },
  { uk: "11.5", eu: "46", us: "12.5", au: "11.5", cm: "30" },
  { uk: "12", eu: "47", us: "13", au: "12", cm: "31" },
  { uk: "13", eu: "48", us: "14", au: "13", cm: "31.5" },
  { uk: "14", eu: "49", us: "15", au: "14", cm: "32.5" },
];

export const womenShoes = [
  { uk: "4", eu: "37", us: "5", au: "5.5", cm: "23.1-23.4" },
  { uk: "4.5", eu: "37.5", us: "5.5", au: "6", cm: "23.5-23.8" },
  { uk: "5", eu: "38", us: "6", au: "6.5", cm: "23.9-24.1" },
  { uk: "5.5", eu: "38.5", us: "6.5", au: "7", cm: "24.1-24.4" },
  { uk: "6", eu: "39", us: "7", au: "7.5", cm: "24.5-24.8" },
  { uk: "6.5", eu: "39.5", us: "7.5", au: "8", cm: "24.9-25.0" },
  { uk: "7", eu: "40", us: "8", au: "8.5", cm: "25.1-25.3" },
  { uk: "7.5", eu: "40.5", us: "8.5", au: "9", cm: "25.4-25.8" },
  { uk: "8", eu: "41", us: "9", au: "9.5", cm: "25.9-26.2" },
  { uk: "8.5", eu: "41.5", us: "9.5", au: "10", cm: "26.4-26.6" },
];
