import { useEffect } from 'react'

import { getToken } from 'firebase/messaging'
import { useSelector } from 'react-redux'

import { messaging } from '../firebase'
import { notify } from '../helpers/notify'
import { postFCMToken } from '../api/postApi'

const VAPID_KEY = 'BJzJqSaLA66672G3hjgCQN1irlCFmRBNdJ4809iXKPZrXwAr8RSxyPP3aGuw_52wJfSWu7ZOtPvWLNJIsF3h87Y'

const useFCMToken = () => {
  const { isAuth } = useSelector(({ authentication }) => authentication)

  useEffect(() => {
    const getFCMToken = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: VAPID_KEY,
        })

        if (token) await postFCMToken(token)
      } catch (error) {
        notify.error('Error', error.message)
      }
    }

    const requestPermission = async () => {
      if (!isAuth) return

      try {
        const permission = await Notification.requestPermission()

        if (permission === 'granted') await getFCMToken()
      } catch (error) {
        notify.error('Error', error.message)
      }
    }

    requestPermission()
  }, [isAuth])
}

export default useFCMToken
